/* stylelint-disable */
.graphHeader-37XrJ {
}

/* stylelint-enable */
.container-1d_Na {

	padding: 28px 30px;
	height: 350px;
}

@media (max-width: 1024px) {
	.container-1d_Na {
		padding: 0 14px 60px 14px;
		max-width: 450px;
		height: auto;
	}
}

@media screen and (max-width: 600px) {
	.container-1d_Na {
		max-width: 100%;
	}
}

.calendar-3H1Fh {

	padding-left: 0;
	padding-right: 0;
}

@media (max-width: 1024px) {
	.calendar-3H1Fh {
		padding-left: 50px;
	}
}

.graphHeader-37XrJ:after {
	left: 38px;
}
