

.promotion__body-21akx {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.promotion__img-3aaYg {
	display: block;
	width: 100%;
	max-width: 420px;

	border-radius: 6px;
	overflow: hidden;
}
