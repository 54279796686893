.form-bqBmR,
label.formControl-2ALqx,
span.label-1sO5L {
	width: 100%;
}

label.formControl-2ALqx {
	margin: 0;
}

span.checkbox-134U7 {
	position: absolute;
	left: -40px;
}

@media screen and (max-width: 600px) {
	span.checkbox-134U7 {
		left: -30px;
	}
}
