

.content-3JYY2 {
	margin-top: 20px;
}

.footer-2O1lJ {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 40px;
}
