.dayStyles__dayWithPrice-3szMv {
	width: 47px;
	height: 40px;

	border-radius: 4px;

	font-weight: 500;

	font-size: 16px;
}

.dayStyles__dayWithPrice-3szMv,
.dayStyles__dayWithPrice-3szMv > div {
	color: var(--text-pale);
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayHighlighted-3e88Y,
.dayStyles__dayWithPrice-3szMv.dayStyles__dayHighlighted-3e88Y > div {
	color: var(--text-base);
}

.dayStyles__dayWithPrice-3szMv.dayStyles__daySelected-3cC8t {
	padding-top: 11px;

	font-size: 18px;
	font-weight: 500;

	background: var(--brand-3-0);
}

.dayStyles__dayWithPrice-3szMv.dayStyles__daySelected-3cC8t,
.dayStyles__dayWithPrice-3szMv.dayStyles__daySelected-3cC8t > div {
	color: var(--white);
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayInPeriod-3ajTB,
.dayStyles__dayWithPrice-3szMv.dayStyles__dayWeekend-aYlGW.dayStyles__dayInPeriod-3ajTB {
	background: var(--brand-3-5);
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayStartPeriod-3Vc-z {
	border-radius: 4px 0 0 4px;
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayEndPeriod-4eg0q {
	border-radius: 0 4px 4px 0;
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayStartPeriod-3Vc-z,
.dayStyles__dayWithPrice-3szMv.dayStyles__dayEndPeriod-4eg0q {
	position: relative;

	padding-top: 6px;
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayStartPeriod-3Vc-z:before,
.dayStyles__dayWithPrice-3szMv.dayStyles__dayEndPeriod-4eg0q:after {
	content: '';

	position: absolute;
	left: 50%;
	bottom: 3px;

	width: 12px;
	height: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMyAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjIxNDI5IDMuNzA1TDMuOTE5MjkgM0w2LjkxOTI5IDZMMy45MTkyOSA5TDMuMjE0MjkgOC4yOTVMNS41MDQyOSA2TDMuMjE0MjkgMy43MDVaTTYuNDE5MyAzLjcwNUw3LjEyNDMgM0wxMC4xMjQzIDZMNy4xMjQzIDlMNi40MTkzIDguMjk1TDguNzA5MyA2TDYuNDE5MyAzLjcwNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) no-repeat center / contain;
	transform: translateX(-50%);
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayEndPeriod-4eg0q:after {
	transform: translateX(-50%) rotate(180deg);
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayStartPeriod-3Vc-z > div,
.dayStyles__dayWithPrice-3szMv.dayStyles__dayEndPeriod-4eg0q > div {
	font-weight: 500;
}

div.dayStyles__dayEndHoverWithPrice-6sTqS,
.dayStyles__dayWithPrice-3szMv:not(.dayStyles__dayStartHoverWithPrice-hOqxJ):not(.dayStyles__dayEndHoverWithPrice-6sTqS):not(.dayStyles__daySelected-3cC8t):hover,
.dayStyles__dayWithPrice-3szMv:not(.dayStyles__dayStartHoverWithprice-1FRIc):not(.dayStyles__dayEndHoverWithPrice-6sTqS):not(.dayStyles__daySelected-3cC8t):hover {
	border: 1px solid var(--brand-1-0);
}

.monthHeaderStyles__selectMonth-2FHfa,
.monthHeaderStyles__selectYear-2ft0t {
	font-size: 18px;
	font-weight: 700;
}

.monthHeaderStyles__selectMonth-2FHfa {
	color: var(--text-base);
}

.monthHeaderStyles__selectYear-2ft0t {
	color: var(--text-pale);
}

.weeklyHeaderStyles__dayOfWeekNameWithPrices-2qbgA {
	width: 47px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 16px;
}

.weeklyHeaderStyles__dayOfWeekNameWithPrices-2qbgA:not(:last-child) {
	margin-right: 1px;
}

button.searchFormDatepickerStyles__footerDone-GT-0s {
	background: var(--brand-1-0);
}

button.searchFormDatepickerStyles__footerDone-GT-0s:hover {
	background: var(--brand-1--1);
}

.dayStyles__dayToday-MbvfC:not(.dayStyles__daySelected-3cC8t) {
	border: 1px solid var(--line-dot-line);
}

.months_wrapper-1BKUh,
.monthsList-343Wb,
.monthsList_withPrice-1lBuY {
	width: 100%;
}

.monthsList-343Wb,
.monthsList_withPrice-1lBuY {
	justify-content: space-evenly;
}

.content-24GD3 {
	min-width: unset;
}

.monthStyles__week-1VOT7,
.monthStyles__weekWithPrice-1z6Yv {
	grid-gap: 1px;
	gap: 1px;
}

.monthStyles__week-1VOT7:not(:last-child),
.monthStyles__weekWithPrice-1z6Yv:not(:last-child) {
	margin-bottom: 4px;
}

.monthStyles__month-MtLrx {
	padding-right: 20px;
}

.day__price-299pT {
	font-size: 12px;
}

@media screen and (max-width: 600px) {
	.searchFormDatepickerStyles__dialogBar-f_ZOE {
		background-color: var(--white);
		box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.05);
	}

	.searchFormDatepickerStyles__dialogDates-3cCAX {
		font-size: 18px;
		font-weight: 700;
		color: var(--text-base);
	}

	.searchFormDatepickerStyles__dialogActiveDate-2ro97 {
		color: var(--text-accent);
	}

	.dayStyles__dayWithPrice-3szMv {
		font-weight: 500;
		font-size: 16px;
	}

	.monthStyles__month-MtLrx {
		margin-top: 20px;
	}

	div.searchFormDatepickerStyles__footerDone-GT-0s {
		padding: 10px 20px;

		border-radius: 4px;

		font-weight: 700;
		font-size: 14px;

		background: var(--brand-3-0);
	}

	div.searchFormDatepickerStyles__footerDone-GT-0s:hover {
		background: var(--brand-3--1);
	}

	div.searchFormDatepickerStyles__footerClear-2k3iJ {
		padding: 10px 20px;

		border-radius: 4px;

		color: var(--text-mid);
		font-weight: 700;
		font-size: 14px;

		opacity: 1;
		background: var(--bg-fill);
	}

	div.searchFormDatepickerStyles__footerButtons-1TV73 {
		background: var(--white);
		box-shadow: 0 -7px 20px 0 rgba(0, 0, 0, 0.03);
	}
}
