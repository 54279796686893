

.content-lFQ5L {
	position: relative;
	top: 20px;
	left: -50%;
	z-index: 1;

	display: flex;
	flex-direction: column;
	grid-gap: 2px;
	gap: 2px;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;

	border-radius: 6px;

	background: var(--bg-white);
}

.name-1MryT {
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	color: var(--text-base);
}

.iata-2plOC {
	font-size: 11px;
	line-height: 14px;
	color: var(--text-light);
}
