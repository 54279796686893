

.price-2DAoo,
.day_selected-3h9wD .price-2DAoo {
	font-size: 24px;
	color: var(--text-base);
}

.date-1PfSc {
	color: var(--text-mid);
	font-weight: 500;
}

.day_selected-3h9wD .date-1PfSc {
	font-size: 16px;
}

.day_selected-3h9wD .day-2ytNS {
	border-bottom-width: 2px;
}

.date__info-21bih {
	font-size: 13px;
}

.date__info-21bih span {
	position: relative;
}

.date__info-21bih span:before {
	left: -25px;
}

.date__info-21bih span:after {
	right: -25px;
}

.date__info-21bih span:before,
.date__info-21bih span:after {
	position: absolute;
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMSIgdmlld0JveD0iMCAwIDI1IDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIwLjUiIHkxPSIwLjUiIHgyPSIyNC41IiB5Mj0iMC41IiBzdHJva2U9IiNEOUQ5RDkiIHN0cm9rZS1kYXNoYXJyYXk9IjQgMiIvPgo8L3N2Zz4K);

	top: -3px;
}

.dayWrapper-1Ef6l.dayWrapper_selected-1yV6e {
	padding-top: 12px;
}
