

.container-2Mdi2 {
	position: relative;
}

div.notification-37yEE:not(.notification__html-2bCs4) {
	width: 100%;
	height: 100%;
}

.notification-37yEE .notification__content-2IAkF {
	width: inherit;
	height: inherit;
}

.notification__html-2bCs4 {
	overflow: hidden;
}

.notifications-269EE {
	display: flex;
	grid-gap: 5px;
	gap: 5px;
	flex-direction: column;
	align-items: center;
}
