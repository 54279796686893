.service-xbjvE {
	min-height: 100%;
	height: auto;
	padding-bottom: 80px;
}

.wrapper-2u5TA {
	margin: 0 auto;
	padding: 0 28px 28px;
	max-width: 1100px;

	box-sizing: content-box;
}

.title-f88xF {
	text-align: center;
	font-weight: 600;
	font-size: 20px;
	line-height: 100%;
}

@media (max-width: 1024px) {
	.wrapper-2u5TA {
		max-width: 724px;
	}
}

@media screen and (max-width: 600px) {
	.service-xbjvE {
		min-height: 100%;
		height: 100%;

		background-color: var(--mobileStepbar-background);
	}

	.wrapper-2u5TA {
		max-width: none;
		padding: 25px 17px 17px;

		min-height: 100%;

		border-radius: 12px 12px 0 0;

		background-color: var(--gray05);
	}
}
