.wrapper-i258r {
	padding: 40px 28px 28px;
}

.header-3XZh0 {
	margin-bottom: 10px;

	font-weight: 500;
	font-size: 22px;

	color: var(--dark-gray);
}

.desc-5pGji {
	margin-top: 10px;

	font-size: 15px;
	color: var(--common-gray);
}

.price-gY4Y3.crossed-EjP9q {
	text-decoration: line-through;
}

@media screen and (max-width: 600px) {
	.header-3XZh0 {
		font-size: 18px;
	}

	.desc-5pGji {
		margin-top: 16px;

		font-size: 14px;
	}
}

.buttons-p7_SU {
	display: flex;
	justify-content: flex-end;
	margin-top: 28px;
}

button.changeFlight-2w8_F {
	margin-right: 12px;

	color: #777777;

	background: #ededed;
}

button.changeFlight-2w8_F:hover {
	color: #777777;

	background: #ededed;
}

.old_price-Dd2GC {
	color: var(--dark-red);
	font-size: 16px;
	font-weight: 500;
}

.price_lower-3w1Ut .header-3XZh0 {
	color: #009660;
}

@media screen and (max-width: 600px) {
	.wrapper-i258r {
		padding: 20px;
	}

	.buttons-p7_SU button {
		font-size: 13px;
	}
}
