

.image-3Jfcy {
	max-width: 250px;
}

.slider-2UE1d .slick-slider {
	margin-top: inherit;
	margin-bottom: inherit;
}
