

.documentsSection-q2nFJ {
	display: flex;
	flex-direction: column;
	width: 100%;

	border-bottom: 1px solid var(--line-separator);
}

.documentsSection__fields-24jwc {
	display: flex;
	flex-direction: column;
	grid-gap: 28px;
	gap: 28px;
}

.documentsSection__controls-3hiI3 {
	display: flex;
	margin: 28px 0;
}

.documentsSection__addFields-3r26B {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;

	color: var(--brand1);
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	word-wrap: break-word;

	cursor: pointer;
}

button.buttonAdd-140fR {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	justify-content: center;
	width: 30px;
	height: 30px;

	border-radius: 50%;

	color: var(--brand1);

	background: var(--brand1-5);
}

button.buttonAdd-140fR svg {
	width: 18px;
	height: 18px;
}

.documentsSection__emptyWarning-3gAMe {
	margin: 0;
	padding: 0;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	word-wrap: break-word;
	color: var(--text-light);
}
