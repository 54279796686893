.root-3hrW6 {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;

	border-radius: 8px;

	background: var(--white);
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
}

.options-1pvgE {
	display: flex;
	width: 100%;
	flex: 1;
}

.included_block-3XZAV {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	min-width: 0;
	flex: 1;
	flex-shrink: 0;
}

.upgrade_block-3hVJU {
	display: flex;
	flex-direction: column;
	flex: 1;
	flex-grow: 1;
	flex-shrink: 1;
	margin-left: 12px;
}

.header-4YN7A {
	min-height: 30px;
	margin-bottom: 20px;

	font-weight: 700;
	font-size: 18px;
	line-height: 1.4;
}

.header-4YN7A h2 {
	position: relative;

	display: flex;
	align-items: center;
	margin: 0;
	padding-left: 28px;

	line-height: 1.4;

	font-size: 18px;
}

.header-4YN7A span {
	position: absolute;
	top: 0;
	left: 0;

	flex: 0;
	margin-right: 8px;

	color: var(--success);
}

.body-3-SVJ {
	flex: 1;
	display: inline-flex;
	justify-content: center;
}

.footer-2kawS {
	padding-top: 16px;
	flex: 0;
}

.upgrade_block-3hVJU .footer-2kawS {
	display: flex;
	justify-content: flex-end;
	margin-top: auto;
}

.fare_family-2FVn3 {
	display: inline-block;

	margin-bottom: 4px;
	padding: 9px 12px;

	border: 1px solid var(--brand1-3);

	border-radius: 100px;

	color: var(--brand1);

	font-size: 14px;
	line-height: 1;

	background: var(--brand1-5);
}

/* stylelint-disable */
.options_withoutUpgrade-3r_r2 {
}

@media screen and (max-width: 600px) {
	.root-3hrW6 {
		padding: 20px;
	}

	.options-1pvgE {
		flex-direction: column;
	}

	.upgrade_block-3hVJU {
		margin: 24px 0;
		width: 100%;
	}
}
