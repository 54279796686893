.legends-39Yte {
	position: absolute;
	right: 0;
	bottom: 43px;
	left: 76px;

	height: 100%;
}

.money-2-VgX {
	white-space: nowrap;
}

@media (max-width: 1024px) {
	.legends-39Yte {
		bottom: 2px;
		left: 0;
	}
}

@media screen and (max-width: 600px) {
	.legends-39Yte {
		bottom: 0;
	}
}

.legendsWrapper-208mY {
	position: relative;

	height: 100%;
}

.line-3ttta {
	position: absolute;
	bottom: 39%;

	width: 100%;
	height: 1px;

	border-top: 1px dashed var(--line-separator);
}

.label-1TsdW {
	position: absolute;
	top: -9px;
	right: 100%;

	padding: 0 8px;

	color: var(--text-light);
	font-size: 12px;

	transform: translateX(-15%);
}

.line-3ttta .money-2-VgX {
	color: var(--text-light);

	font-weight: 500;
	font-size: 12px;
	line-height: 1.3;

	background: var(--bg-fill);
}

.line_min-2jYlo .money-2-VgX {
	color: var(--success-20);
}

.line_min-2jYlo .money-2-VgX,
.line_max-Cw_ln .money-2-VgX {
	padding: 2px 4px;

	border-radius: 20px;
}

.line_min-2jYlo .label-1TsdW,
.line_max-Cw_ln .label-1TsdW {
	padding: 0;
}

@media (max-width: 1024px) {
	.label__text-2GS0l {
		display: none;
	}

	.line_max-Cw_ln .money-2-VgX {
		color: var(--text-light);
	}

	.line_min-2jYlo .money-2-VgX {
		color: var(--pricegraph-min-price);

		background: var(--brand-3-5);
	}
}

.line_bottom-1MYyU {
	position: absolute;
	bottom: 1px;

	width: 100%;
	height: 1px;

	border-top: 1px solid var(--line-separator);
}

.zero-1GB_E {
	bottom: 0;
}

.zero-1GB_E,
.average-2c8BZ,
.max-IliGq {
	position: absolute;
}
