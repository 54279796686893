

div.slide-zZAFy {
	display: flex;
	flex-direction: column;
	max-height: calc(100% - 200px);
	padding: 0;
}

div.slide__wrapper-2Vmd3 {
	background: none;
}

.content-nLWB2 {
	flex: 1;
	padding: 20px;
	overflow-y: auto;

	background: var(--gray05);
}

.header-1Cu33 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	color: var(--gray70);
	font-weight: 700;
	font-size: 20px;
}

.main-2Z2yH {
	display: flex;
	align-items: center;
}

.main__icon-7GBEf {
	margin-right: 8px;

	color: var(--active);
}

.price__wrapper-2yyL2 {
	vertical-align: bottom;

	color: var(--base);
	font-weight: 400;
	font-size: 15px;
}

.money-2AFdN {
	color: var(--base);
	font-weight: 700;
	font-size: 24px;
}

.controls-vSsEJ {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.control-364iz {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-right: 12px;

	border-radius: 4px;

	color: var(--selected-color);

	font-weight: 700;
	font-size: 22px;

	cursor: pointer;
	background: var(--selected);
}

.control_selected-3bVTx {
	color: var(--selected2-color);

	background: var(--selected2);
}

.control_free-fnbOh {
	background: var(--seat-free);
}

.actions-2uzaT {
	display: flex;
	align-items: center;
	min-height: 69px;
	padding: 0 20px;

	box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

.actions-2uzaT button {
	flex: 1;
	height: 49px;

	line-height: 49px;
	white-space: nowrap;
}

.actions-2uzaT button:not(:last-child) {
	margin-right: 12px;
}

/* stylelint-disable-next-line block-no-empty */
button.cancel-3V7i2 {
}

/* stylelint-disable-next-line block-no-empty */
button.confirm-3Yi8F {
}
