.root-2pbyM {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.header-2BAwL {
	display: flex;
	align-items: center;
}

.wrapper-c9k_2 {
	display: flex;
	flex-direction: column;
	grid-gap: 4px;
	gap: 4px;

	justify-content: center;
	align-items: center;

	padding: 16px 0;

	border-radius: 6px;

	background: var(--brand-1-1);
}

.title-2KzMO {
	color: var(--text-light);
	font-size: 16px;
	line-height: 1.3;
}

.loader-15KH9 {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 64px;
}

.info_field-1t7gW {
	display: flex;

	color: var(--white);
	font-size: 18px;
	line-height: 130%;
}

.info_field__cities-2GYt_ {
	font-weight: 700;
	text-transform: uppercase;
}

.info_field__travellers-30hu0 {
	position: relative;

	margin-left: 15px;
	padding-left: 15px;
}

.info_field__travellers-30hu0:before {
	content: '|';

	position: absolute;
	left: -2px;
}

.footer-31GGa {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	grid-gap: 10px;
	gap: 10px;
}

.footer-31GGa button {
	flex: 1;
}

button.button-33doL.button_cancel-1XH7t {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.clear-Bl9aO {
	grid-gap: 4px;
	gap: 4px;

	padding: 4px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 16px;
}

.clear__icon-2Snh3 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
}

@media screen and (max-width: 600px) {
	.footer-31GGa {
		flex-direction: column;
		align-items: normal;
		justify-content: normal;
	}
}
