.container-3f7j9 {
	margin: 0 40px 20px;
	padding-top: 20px;

	border-top: 1px dashed var(--line-dot-line);
}

.fareName-zYLHa {
	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
}

.services-Z3agN {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 8px;
}

.service-2jYCK {
	position: relative;

	display: flex;
	align-items: center;
	width: 100%;
	min-height: 60px;
	padding: 16px;

	border-radius: 4px;

	color: var(--text-base);
	font-size: 14px;

	cursor: pointer;

	background: var(--brand-1-5);
}

.service-2jYCK.service_selected-2UvR3 {
	background: var(--bg-fill);
}

.service__icon-3kCxp {
	margin-right: 16px;

	color: var(--text-pale);
}

button.service__add-3sFie {
	position: absolute;
	top: 0;
	right: 12px;
	bottom: 0;

	width: 20px;
	height: 20px;
	margin: auto;

	border-radius: 50%;

	color: #ffffff;

	background: var(--brand-1-0);
}

.service__add-3sFie svg {
	width: 20px;
	height: 20px;
}

.wrapper-witOT {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}

.wrapper-witOT button {
	font-size: 14px;
	font-weight: 400;
}

@media (max-width: 1024px) {
	.container-3f7j9 {
		margin: 24px 0 0 0;
		padding: 0;

		border-top: 0;
	}

	.services-Z3agN {
		display: block;
	}

	.services-Z3agN > div {
		margin-bottom: 8px;
	}
}
