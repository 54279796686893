

.passengers_wrapper-URo2L {
	display: flex;
	justify-content: center;
	padding: 30px 0;
}

div.root-2vgTc {
	background: var(--gray05);
}

.root-2vgTc span.close-33O5H {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	background: #ffffff;
}

.contacts-FBU5a {
	margin-bottom: 12px;
}
