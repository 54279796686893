

.container-3c4vX {
	padding: 0;

	border: none;
	border-radius: 0;

	background: none;
}

.switch__label-3bC79 {
	align-items: center;

	font-size: 16px;
	font-weight: 400;
	line-height: 1;
}

.switch__icon-3X8Py {
	transform: translateY(-1px);
}

.description-5UIz7 {
	padding: 12px;

	border: 1px solid var(--warning-icon);

	background: var(--white);
}
