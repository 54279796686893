.title-1-lul {
	margin-bottom: 16px;

	color: var(--gray70);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.title-1-lul > span {
	display: none;
}

.container-1fAFi {
	display: flex;
	flex-wrap: wrap;
}

div.fare-3LNjp {
	border-radius: 4px;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.rules-1p_gR {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

/* stylelint-disable */
.button__rules-1fQwY {
}
/* stylelint-enable */
.options-jY0mD {
	margin: 6px 0;
	padding: 0;
}

div.keyFeature-fTXJz {
	padding: 8px 4px;
	justify-content: space-between;
	align-items: center;
}

div.keyFeature-fTXJz span {
	position: relative;

	text-align: center;
	font-size: 14px;
	font-weight: 400;
}

.fare-3LNjp .option-2M63n {
	display: flex;
	align-items: center;
	padding: 12px 0;

	font-size: 14px;
	line-height: 1.3;
}

.fare-3LNjp .option-2M63n:not(:last-of-type) {
	border-bottom: 1px dashed #d9d9d9;
}

.fare-3LNjp .option-2M63n > span {
	color: var(--gray50);
}

div.keyFeature-fTXJz span:first-child {
	margin-bottom: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.fareConditions__closeButton-3skEE {
	z-index: 11;
}
/* stylelint-disable */
button.button-vmcWK {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	div.fare-3LNjp {
		width: 100%;
	}

	.rules-1p_gR {
		flex-direction: column;
	}
}
