div.paper-vIaJP {
	max-width: 680px;
	padding: 40px;
}

@media screen and (max-width: 600px) {
	div.paper-vIaJP {
		padding: 20px;
	}
}

.content-3oF_C {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
	padding: 20px 0;
}

.serviceName-2VPYE {
	font: var(--medium-1-mobile-bold);
}

.serviceDescription-27ZT0 {
	padding: 12px 0;

	font: var(--normal-mobile);
}

.heading-38xx4 {
	display: flex;
	align-items: baseline;
	margin-bottom: 12px;
	padding: 8px 12px;

	border-top-left-radius: 4px;
	border-top-right-radius: 4px;

	background: var(--brand-1-5);
}

.date-1KFXa,
.cost-14fNt {
	color: var(--text-mid);
	font: var(--normal-mobile);
}

.date-1KFXa {
	padding-right: 20px;
	margin-right: 20px;

	border-right: 1px solid var(--line-separator);
}

.cost-14fNt {
	margin-left: auto;
}

.city-2gbkN {
	font: var(--medium--1-mobile-bold);
	color: var(--text-base);
}

.passenger-2nPXZ {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	align-items: baseline;
	padding: 0 12px;

	cursor: pointer;
}

.checkbox-1fm6D {
	position: relative;
	top: 5px;
}

.passenger-2nPXZ:not(:last-of-type) {
	margin-bottom: 12px;
	padding-bottom: 12px;

	border-bottom: 1px dashed var(--line-separator);
}

.passengerNumber-1YkF5 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	border-radius: 50%;

	color: var(--text-light);
	font: var(--normal-desktop-accent);

	background-color: var(--bg-fill);
}

.passengerName-N5EYN {
	color: var(--text-base);
	font: var(--medium--1-mobile);
}

.passengerPrice-s920E {
	margin-left: auto;

	color: var(--text-base);
	font: var(--medium--1-desktop-accent);
}

.footer-4TSB- {
	display: flex;
	align-items: center;
}

button.confirm-2NEVe {
	font-family: inherit;
}

@media screen and (max-width: 600px) {
	button.confirm-2NEVe,
	button.cancel-2T4zB {
		padding: 10px 10px;
	}
}

.clear-3a3Ov {
	color: var(--brand-1-0);
	font: var(--normal-desktop);

	cursor: pointer;
}

.total-3ajMV {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.price-3uZy0 {
	font: var(--medium-1-desktop-bold);
	color: var(--text-base);
}

.divider-Z0fcF {
	min-height: 30px;
}

.footerControls-e7hvw {
	margin-left: auto;
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}
