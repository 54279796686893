.warning-1rBc5 {
	padding: 12px;
	margin-top: 28px;

	border-radius: 4px;

	background-color: var(--warning20);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
}

.warning__trigger-3fvWe {
	display: flex;
	justify-content: space-between;
	align-items: center;

	cursor: pointer;
}

.warning__title-3gLax {
	display: flex;
	align-items: center;

	color: var(--gray80);
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
}

.warning__title-3gLax svg {
	margin-right: 8px;
}

.warning__text-1zTO6 {
	margin-top: 12px;

	color: var(--gray50);
	font-size: 14px;
	line-height: 130%;
}

.warning__arrow-18z3M {
	display: flex;
	align-items: center;

	transition: transform 0.15s;
}

.warning__arrow_rotated-2kwvm {
	transform: rotate(180deg);
}

@media screen and (max-width: 600px) {
	.warning-1rBc5 {
		margin-top: 20px;
	}
}
