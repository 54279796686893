.container-zBzUi {
	grid-template-columns: 1fr;
}

.wrapper--chBW {
	display: flex;
	justify-content: flex-start;
}

.toggle-2eujD {
	margin-bottom: 18px;
}

.toggle-2eujD:before {
	opacity: 1;
}

.toggle-2eujD:after {
	background: #d9d9d9;
}

.toggle-2eujD.toggle_active-1qISR:after {
	background: var(--brand-1-0);
}

.toggle__label-f3GQJ {
	opacity: 1;
}

.label-3LICO {
	position: relative;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.description-3MNem {
	position: absolute;

	color: var(--text-light);
	font-size: 14px;
}

label.control-1j2zl {
	margin-left: 0;
	margin-right: 0;
}

.control__label-2tmhf {
	display: flex;
	align-items: center;
}

.input-14MZf {
	display: none;
}

.collapse-2YXQ9 {
	display: flex;
	align-items: center;

	padding-top: 20px;
}

.collapse__icon-Ryb19 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	min-width: 44px;
	height: 44px;

	margin-right: 12px;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-1-0);
}

.collapse__content-2xI-h {
	display: flex;
	flex-direction: column;
	grid-gap: 6px;
	gap: 6px;
}

.collapse__contentInfo-XEotK {
	display: flex;
	flex-direction: column;
}

.collapse__title-1a6mw {
	display: flex;
	align-items: center;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.collapse__titleWrapper-2-haf {
	display: flex;
	align-items: center;
}

.collapse__titleIcon-3aB9F {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;

	margin-right: 4px;

	color: var(--success-icon);

	transform: translateY(-2px);
}

.collapse__format-TPVP_ {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.collapse__error-28U2b {
	color: var(--error-icon);
	font-size: 14px;
}

.collapse__files-m7NZT {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 6px;
	gap: 6px;
}

.collapse__filesItem-M0eHd {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;

	height: 26px;

	padding: 0 12px;

	border-radius: 40px;

	font-weight: 500;

	font-size: 14px;

	background: var(--brand-1-5);
}

.collapse__filesItem-M0eHd span {
	max-width: 140px;

	color: var(--brand-1-0);
	line-height: 26px;

	white-space: nowrap;
	text-overflow: ellipsis;

	overflow: hidden;
}

.collapse__filesItemSize-3daKT {
	margin-bottom: -2px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1;
}

.collapse__filesItemIcon-jtcwS {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;

	color: var(--text-base);
}

@media (max-width: 1024px) {
	.container-zBzUi {
		grid-gap: 0;
		gap: 0;
		grid-auto-rows: auto;
		grid-column: 1/3;
	}

	.description-3MNem {
		margin-top: 2px;
	}
}

@media screen and (max-width: 600px) {
	.container-zBzUi {
		grid-column: unset;
	}

	.toggle-2eujD {
		padding-right: 0;
	}

	.label-3LICO {
		margin-bottom: 30px;
	}

	.collapse-2YXQ9 {
		padding-top: 8px;
	}

	.collapse__icon-Ryb19 {
		margin-right: 16px;
	}

	.collapse__title-1a6mw {
		flex-direction: column;
		align-items: unset;

		font-size: 15px;
	}
}
