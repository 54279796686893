

.container-118HX {
	font-size: 14px;
	color: var(--text-base);
}

.group-p5Ovc:not(:last-child) {
	border-bottom: 1px solid var(--text-base);
}

.header-1182X {
	margin-bottom: 3px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 14px;
}

.item-DXSL3 {
	padding: 12px 0;
}

.item__content-3AZXG {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.item-DXSL3:not(:last-child) {
	border-bottom: 1px dashed var(--line-dot-line);
}

.group-p5Ovc:first-child .item-DXSL3:first-child {
	padding-top: 0;
}
