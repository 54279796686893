div.paper-1t-aD {
	border-radius: 20px;
}

div.paper-1t-aD > span {
	top: 8px;
	right: 8px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-pale);

	background: var(--bg-fill);
}

div.paper-1t-aD > span:hover {
	background: var(--bg-elements);
}

div.paper-1t-aD > span svg {
	transform: scale(0.5);
}

@media screen and (max-width: 600px) {
	div.scrollBody-2P7sg {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.scrollBody-2P7sg div.paper-1t-aD {
		border-radius: 8px 8px 0 0;
	}
}
