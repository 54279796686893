.container-3ssof {
	display: flex;
	min-height: 224px;
}

.card-WfzFE {
	flex-shrink: 0;
}

.content-3ULIQ {
	flex: 1;
	padding: 20px;

	border-radius: 0 6px 6px 0;

	background-color: var(--white);
}

@media screen and (max-width: 600px) {
	.container-3ssof {
		flex-direction: column;
	}

	.content-3ULIQ {
		border-radius: 0 0 6px 6px;
	}

	.footer-XBlRB {
		flex-direction: column;
		align-items: inherit;
	}

	.links-1KVI2 {
		margin-top: 20px;
	}

	button.button-2TGOb {
		margin-right: 0;
		margin-bottom: 10px;
	}

	button.button-2TGOb:last-child {
		margin-bottom: 0;
	}

	div.modal_scrollBody-n2Yck {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.modal_scrollBody-n2Yck div.modal_paper-3HL9B,
	div.modal_scrollBody-n2Yck div.modal_recovery_paper-3Me5A,
	div.modal_scrollBody-n2Yck div.modal_connect_paper-1iKlK {
		min-height: auto !important;

		border-radius: 0;

		overflow: auto;
	}

	button.menu-3DHmA {
		position: absolute;
		right: 20px;
		top: 0;

		transform: translateY(-50%);
	}
}
