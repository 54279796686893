

.container-2KTLC {
	pointer-events: none;
}

.content-1UNKe {
	position: relative;
	bottom: 140px;

	display: flex;
	flex-direction: column;
	max-width: 300px;
	min-width: 200px;
	padding: 8px 16px;

	border-radius: 6px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);

	pointer-events: none;
}

.content-1UNKe:after {
	position: absolute;
	top: 98%;
	left: 10px;
	content: '';

	width: 0;
	height: 0;

	border-style: solid;
	border-width: 30px 10px 0 10px;
	border-color: var(--bg-white) transparent transparent transparent;

	transform: skew(-40deg, 5deg);
}

.center-2wFW5 {
	align-self: center;
}

.start-3ua5G {
	align-self: flex-end;
	margin-right: auto;
}

.end-1RY4J {
	align-self: flex-end;
	margin-left: auto;
}

.ellipsis-2z08p {
	text-overflow: ellipsis;
	overflow: hidden;
}

.aircraft-2DP7W {
	display: flex;
	justify-content: center;

	color: var(--brand-1-0);
}

.flightInfo-39Ng3 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 6px;
	padding-bottom: 10px;

	border-bottom: 1px dashed var(--line-dot-line);

	color: var(--brand-1-0);
}

.raceNumber-2nTH9 {
	line-height: 16px;
	font-weight: 500;
}

.flightStatus-2R9rh {
	font-size: 13px;
}

.routes-3fR-9 {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.text_left-395Qz {
	align-self: flex-start;
}

.text_right-1W3mc {
	align-self: flex-end;
}

.routeInfo-3b32X {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex: 0 1 auto;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.icon-1wrDH {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.departure-3Etxk {
	margin-bottom: 3px;

	font-weight: 500;
	line-height: 18px;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text_light-2mVIc {
	font-size: 13px;
	color: var(--text-light);
	line-height: 16px;
}
