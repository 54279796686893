.groupOrder-1qwFl {
	width: 100%;
	padding-bottom: 85px;
}

.header-y-6y1 {
	display: flex;
	align-items: center;
	height: 69px;

	font-weight: 700;
	font-size: 24px;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

.container-iiW_p {
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
}

.flight-2tRsr {
	margin-top: 28px;
}

.passengers-FLDov {
	margin-top: 12px;
}

.passenger-1gvOw,
label.passenger-1gvOw {
	width: 100%;
	min-height: 69px;
	margin-right: 0;
	margin-left: 0;
	padding: 20px;

	border-bottom: 1px dashed #c2c2c2;

	background: #ffffff;
}

.passenger-1gvOw.addPassenger-2HM3y {
	display: flex;
	align-items: center;
	min-height: 84px;
	padding: 20px;

	color: var(--base);
	font-weight: 700;
	font-size: 16px;

	cursor: pointer;
}

.editPassenger-2IaAU {
	width: 100%;
}

label.passenger-1gvOw.error-11J45 {
	color: var(--error);
}

span.passenger__label-buP06 {
	width: 100%;
	margin-left: 14px;

	font-weight: 700;
	font-size: 22px;
}

.passengerValue-1SiR_ {
	display: flex;
	flex-direction: column;
}

span.errorHint-2zSHw {
	font-size: 13px;
	font-weight: 400;
}

span.checkbox-3iM0V {
	margin: 0;
}

label.add-1vkbS {
	display: inline-flex;
	align-items: center;
	width: 100%;

	cursor: pointer;
}

.addIcon-gl4HB {
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: 8px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--base);
}

span.checkboxColor-_Iupa,
span.checkboxColor-_Iupa.checked-11k_4 {
	color: var(--base);
}

.notifications-A_7vi {
	margin-top: 12px;
}

.notification__item-OMyDq:not(:last-child) {
	margin-bottom: 12px;
}

@media screen and (max-width: 600px) {
	.passengers-FLDov {
		padding: 12px;
		margin-top: 0;
	}

	label.passenger-1gvOw {
		padding: 12px;
	}

	.passenger-1gvOw.addPassenger-2HM3y {
		border-bottom: 0;
	}

	.addPassenger-2HM3y > div {
		width: 100%;
	}

	button.addButton-3BhLv {
		width: 100%;
		padding: 10px;

		border-radius: 4px;

		color: var(--base-15);
		font-weight: 600;
		font-size: 16px;

		background: var(--base80);
	}

	span.passenger__label-buP06 {
		font-size: 18px;
	}

	.header-y-6y1 {
		padding: 0 12px;

		font-size: 22px;
	}

	.flight-2tRsr {
		padding: 12px;
		margin-top: 0;
	}

	.segment-1TVek {
		box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
	}
}
