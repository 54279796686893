.container-19xI0 {
	position: relative;

	display: inline-block;
	width: 100%;
	height: 100%;

	line-height: normal;
}

.header-2l8h- {
	position: relative;

	display: flex;
	align-items: center;
}

.routeType-27LZk {
	position: relative;

	display: inline-block;
	margin-right: 20px;
	padding-right: 20px;

	color: var(--text-base);
	font-weight: 600;
	font-size: 26px;
	line-height: 100%;
}

.routeType-27LZk:after {
	position: absolute;
	right: 0;

	width: 1px;
	height: 100%;

	border-right: 1px solid var(--line-separator);
	content: '';
}

@media (max-width: 1024px) {
	.routeType-27LZk {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.container-19xI0 {
		position: absolute;
		top: 0;
		bottom: 0;

		display: flex;
		flex-direction: column;
	}

	.routeType-27LZk {
		display: none;
	}
}

.calendar-34vvB {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	.calendar-34vvB {
		flex-grow: 1;
		display: flex;
		padding: 0 60px;
		padding-right: 0;
	}

	div.calendar_longPrice-1lSkJ {
		padding-left: 80px;
	}
}

.daysWrapper-3WhMi {
	position: relative;

	height: 100%;
}

@media screen and (max-width: 600px) {
	.daysWrapper-3WhMi {
		min-height: 100%;
		width: 100%;
	}
}

.slider-2ZZbO {
	display: flex;
}

.days-F1t9D {
	display: flex;
	margin-top: 43px;
	margin-bottom: 43px;
	padding: 0 15px;

	border-bottom: 1px solid var(--line-separator);
}

@media (max-width: 1024px) {
	.days-F1t9D {
		padding: 0;
		margin-bottom: 38px;
	}
}

.monthSelector-3amAd select {
	border: none;

	background: none;
}

.bestPriceOfMonth-2nZc2 {
	display: inline-block;
	padding-left: 20px;

	font-size: 14px;
	color: var(--brand-1-0);
}

.graphHeader-om15V {
	position: relative;

	display: flex;
	align-items: center;
	width: 100%;
	padding: 12px;

	border-radius: 2px;

	color: var(--white);

	background: var(--brand-1-0);
}

.graphHeader-om15V:after {
	position: absolute;
	bottom: 0;
	left: 62px;

	width: 0;
	height: 0;

	border-width: 8px 6px 0 6px;
	border-style: solid;
	border-color: var(--brand-1-0) transparent transparent transparent;

	transform: translateY(calc(100% - 1px));
	content: '';
}

.graphHeader_longPrice-3aWbM:after {
	left: 82px;
}

.graphHeader__dates-2T3Rd {
	flex-grow: 1;
	flex-basis: 100%;

	display: flex;
	align-items: center;
}

.graphHeader__price-1UTJF {
	flex-grow: 1;
	flex-basis: 100%;
	height: 100%;
	padding: 8px 0;

	border-left: 1px dashed var(--pricegraph-price-border-left-color);

	text-align: right;
}

.graphHeader__priceWrapper-VOS3F {
	margin-left: 8px;
	padding-left: 8px;

	border-left: 1px solid var(--line-separator);

	color: var(--additional-checkin);
	font-size: 15px;
	font-weight: 500;

	line-height: 1.3;
}

/* stylelint-disable */
.graphHeader__money-WNsXl {
}
/* stylelint-enable */
.graphHeader__select-1DSyn {
	font-size: 14px;
}

.graphHeader__outDate-2MWyy,
.graphHeader__backDate-Jm2Te {
	position: relative;

	padding-left: 25px;

	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

.graphHeader__backDate-Jm2Te {
	margin-top: 5px;
}

.graphHeader__outDate-2MWyy:before,
.graphHeader__backDate-Jm2Te:before {
	content: '';

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;

	margin: auto;
	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzcgMTYuMzMzM0gxMC42NjdMNi41MDAzMyA5LjY2NjY3SDEuOTE2OTlDMS4yMjUzMyA5LjY2NjY3IDAuNjY2OTkyIDkuMTA4MzMgMC42NjY5OTIgOC40MTY2N0MwLjY2Njk5MiA3LjcyNSAxLjIyNTMzIDcuMTY2NjcgMS45MTY5OSA3LjE2NjY3SDYuNTAwMzNMMTAuNjY3IDAuNUwxMi4zMzM3IDAuNUwxMC4yNTAzIDcuMTY2NjdIMTQuODMzN0wxNi4wODM3IDUuNUgxNy4zMzM3TDE2LjUwMDMgOC40MTY2N0wxNy4zMzM3IDExLjMzMzNMMTYuMDgzNyAxMS4zMzMzTDE0LjgzMzcgOS42NjY2N0gxMC4yNTAzTDEyLjMzMzcgMTYuMzMzM1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.graphHeader__outDate-2MWyy:before {
	transform: rotate(180deg);
}

.graphHeader__date_notActive-g6yzc {
	opacity: 0.7;
}

.footer-u2AJ9 {
	margin-top: 14px;
	margin-left: -20px;
	margin-bottom: -20px;
	padding: 12px;

	display: flex;
	align-items: center;

	width: calc(100% + 40px);

	box-shadow: 0 -7px 20px 0 rgba(0, 0, 0, 0.03);
}

button.button-1VkEY {
	flex: 1;
}
