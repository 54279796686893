.container-aOTup {
	position: relative;

	padding: 40px 0;
}

.content-3AZD3 {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;

	max-width: 1100px;
	margin: 0 auto;
}

.controls-YcIYw {
	z-index: 3;

	display: flex;
	align-items: center;
}

.flights-e9d9V {
	display: flex;
	flex-direction: column;
}

div.error-hOfq3 {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 0;
}

.disclaimer-220ob {
	grid-gap: 8px;
	gap: 8px;

	color: var(--text-pale);
}

.disclaimer__text-A0Qms {
	color: var(--text-light);
	font: var(--normal-mobile);
}

@media (max-width: 1024px) {
	.container-aOTup {
		padding: 40px 20px;
	}
}

@media screen and (max-width: 600px) {
	.container-aOTup {
		padding: 20px 12px 40px;
	}
}
