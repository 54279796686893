.root-3xNno {
	border-radius: unset;
}

div.paper-nr6cN {
	position: relative;

	padding: 0;

	display: flex;
	flex-direction: column;

	border-radius: var(--modal-radius);

	background: var(--bg-white);
}

div.paper-nr6cN.paper__fullScreen-1CEcO {
	max-height: unset;
	justify-content: space-between;

	border-radius: 0;
}

div.paper_paperScrollBody-3zrjU {
	margin-left: auto;
	margin-right: auto;
}

div.fullscreen-1rICv {
	padding: 40px;
	max-height: 100%;
	grid-gap: 24px;
	gap: 24px;
}

div.small-1mxMh.paper__noPadding-2Z_3X,
div.medium-2GZBX.paper__noPadding-2Z_3X,
div.large-1BZ_8.paper__noPadding-2Z_3X,
div.xlarge-bb7hA.paper__noPadding-2Z_3X,
div.fullscreen-1rICv.paper__noPadding-2Z_3X {
	padding: 0;
}

div.small-1mxMh {
	max-width: 460px;
	padding: 32px;
	grid-gap: 24px;
	gap: 24px;
}

div.medium-2GZBX {
	max-width: 640px;
	padding: 40px;
	grid-gap: 24px;
	gap: 24px;
}

div.large-1BZ_8 {
	max-width: 800px;
	padding: 40px;
	grid-gap: 24px;
	gap: 24px;
}

div.xlarge-bb7hA {
	max-width: 1000px;
	padding: 40px;
	grid-gap: 24px;
	gap: 24px;
}

.top_wrapper-31sKX {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
	padding: 0;
	max-height: 100%;
	overflow-y: hidden;
}

.content-2Cio_ {
	overflow-y: auto;
}

div.dialog_container-3ynM-:after {
	height: 0;
}

button.closeButton-P24lr {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 1;

	width: 32px;
	height: 32px;

	color: var(--text-pale);

	background: var(--bg-fill);
}

.swipeBlock-1yEu9 {
	position: absolute;
	top: -40px;
	left: 0;
	right: 0;

	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	background: transparent;
}

.swipeBlock_line-2iO_9 {
	display: block;
	width: 70px;
	height: 6px;

	border-radius: 10px;

	background: var(--text-pale);
}

@media screen and (max-width: 600px) {
	div.paper-nr6cN {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;

		width: 100%;
		max-width: 100%;
		min-width: 100%;
		max-height: 90vh;
		margin: 0;

		overflow-y: visible;

		border-radius: var(--modal-mobile-radius);
	}

	div.small-1mxMh,
	div.medium-2GZBX,
	div.large-1BZ_8,
	div.xlarge-bb7hA,
	div.fullscreen-1rICv {
		max-width: 100%;
		padding: 20px;
		grid-gap: 24px;
		gap: 24px;
	}

	div.paper-nr6cN.paper_paperScrollBody-3zrjU {
		position: relative;
		top: 20vh;
		bottom: unset;

		width: 100%;
		max-width: 100%;
		max-height: unset;
	}

	div.paper-nr6cN.paper__fullScreen-1CEcO {
		position: relative;
		top: unset;
		bottom: unset;

		max-height: unset;

		border-radius: 0;
	}

	div.paper-nr6cN.paper_paperScrollBody-3zrjU.paper__fullScreen-1CEcO {
		height: auto;
	}
}

/* @media (max-width: 507.95px) {
	div.paper_paperScrollBody {
		width: 100%;
		max-width: 100% !important;
	}
} */
