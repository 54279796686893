

.options-2a1tb {
	position: absolute;
	z-index: 4;

	width: 100%;
	min-width: 200px;
	max-width: 250px;
	max-height: 540px;
	margin-top: 4px;

	padding: 8px 0;

	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
	background-color: #ffffff;
	overflow-y: auto;
}

.allDirections-2-Uja {
	padding: 8px 16px;
}

button.allDirections__button-P7T7J {
	width: 100%;
	height: 40px;

	font-weight: 600;
	font-size: var(--autocomplete-dropdown-allDirections-button-fz);
	text-transform: uppercase;
}
