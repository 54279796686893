.content-v3xFX {
	padding: 28px;
}

.title-3iQgE {
	margin: 0 0 16px;

	color: var(--dark-gray);
	font-size: 22px;
	line-height: 31px;
}

.routes-vGKVW {
	display: flex;
}

.segment-20oEt {
	padding: 19px 0;
	display: flex;
	align-items: center;
}

.segment-20oEt:not(:last-of-type) {
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
}

.segment-20oEt:last-of-type {
	border-bottom: 1px solid #e4e4e4;
}

.iata-1mDjE {
	line-height: 19px;
	font-weight: 600;
	color: #292929;
}

.cities-Yk1tQ {
	margin-left: 10px;

	color: #727272;
	font-size: 15px;
	line-height: 18px;
}

.controls-2i1O_ {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.content-v3xFX {
		padding: 20px;
	}

	.cities-Yk1tQ {
		margin-left: 0;
	}

	.routes-vGKVW {
		flex-direction: column;
	}

	div.scrollBody-FC-GM {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.paper-3KUYD.paper-3KUYD {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
