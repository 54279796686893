button.locator-79KBQ {
	padding: 0;
}

.destination-18xBA {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
	flex-wrap: wrap;
}

.destination-18xBA span {
	color: var(--line-separator);
}

button.locator-79KBQ span {
	color: var(--text-link);
}

@media (max-width: 768px) {
	.orderGroup-z3c5w {
		align-items: flex-start;
		flex-direction: column;
		grid-gap: 8px;
		gap: 8px;
	}
}
