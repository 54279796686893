.wrapper-3bHGd {
	display: flex;
	align-items: center;

	width: 100%;
	max-width: 1100px;
	height: 20px;
	margin: 0 auto;

	color: var(--text-light);

	font-size: 13px;
}

.wrapper-3bHGd:before {
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjY2IDEwLjAwMDFDMS42NjY2NiA1LjQwMDA4IDUuMzk5OTkgMS42NjY3NSA5Ljk5OTk5IDEuNjY2NzVDMTQuNiAxLjY2Njc1IDE4LjMzMzMgNS40MDAwOCAxOC4zMzMzIDEwLjAwMDFDMTguMzMzMyAxNC42MDAxIDE0LjYgMTguMzMzNCA5Ljk5OTk5IDE4LjMzMzRDNS4zOTk5OSAxOC4zMzM0IDEuNjY2NjYgMTQuNjAwMSAxLjY2NjY2IDEwLjAwMDFaTTEwLjgzMzMgOS4xNjY3NVYxNC4xNjY3SDkuMTY2NjZWOS4xNjY3NUgxMC44MzMzWk05Ljk5OTk4IDE2LjY2NjdDNi4zMjQ5OCAxNi42NjY3IDMuMzMzMzIgMTMuNjc1MSAzLjMzMzMyIDEwLjAwMDFDMy4zMzMzMiA2LjMyNTA4IDYuMzI0OTggMy4zMzM0MSA5Ljk5OTk4IDMuMzMzNDFDMTMuNjc1IDMuMzMzNDEgMTYuNjY2NiA2LjMyNTA4IDE2LjY2NjYgMTAuMDAwMUMxNi42NjY2IDEzLjY3NTEgMTMuNjc1IDE2LjY2NjcgOS45OTk5OCAxNi42NjY3Wk0xMC44MzMzIDUuODMzNDFWNy41MDAwOEg5LjE2NjY2VjUuODMzNDFIMTAuODMzM1oiIGZpbGw9IiNCQkJDQkQiLz4KPC9zdmc+Cg==);

	width: 20px;
	height: 20px;
	margin-right: 7px;
}

@media screen and (max-width: 600px) {
	.wrapper-3bHGd {
		height: auto;

		line-height: 150%;
	}

	.wrapper-3bHGd:not(:first-of-type) {
		border-top: 1px dashed #bababa;
	}

	.wrapper-3bHGd:first-of-type {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	.wrapper-3bHGd:last-of-type {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	.wrapper-3bHGd:only-child {
		border-radius: 4px;
	}

	.wrapper-3bHGd:not(:last-child) {
		margin-bottom: 12px;
	}

	.wrapper-3bHGd svg {
		flex-shrink: 0;
		margin-right: 12px;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.wrapper-3bHGd {
		margin-top: 12px;
	}
}
