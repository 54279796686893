.container-1VDum {
	display: flex;
	align-items: center;
	padding-top: 15px;
}

.inputs-3H31o {
	display: flex;
	margin-right: 18px;
}

.action-20s3K {
	color: #ffffff;
	font-size: 15px;
	font-weight: 500;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.container-1VDum {
		flex-direction: column;
		align-items: flex-start;
	}

	.inputs-3H31o {
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
	}

	.inputs-3H31o > div {
		flex: 1;
	}

	.inputs-3H31o input {
		width: 100%;
		max-width: 100%;
		min-width: 0;
	}
}
