

.contactsSection-3uxBM {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
	width: 100%;
}

.input-3mW94 {
	width: 100%;
}

.contactsSection-3uxBM div.phoneInput-2fhP_ > div > div > div {
	background: var(--brand-1-5);
}

.contactsSection-3uxBM div.phoneInput-2fhP_ .phoneInputInput-3TTqk > div > input {
	border: 1px solid var(--brand-1-3);
}

.contactsSection-3uxBM div.phoneInput-2fhP_ > div > div > label[class*='focus'] {
	color: var(--brand-1-0);
}

.contactsSection-3uxBM div.phoneInput-2fhP_ > div > div > div[class*='focus'] > input {
	background: var(--white);
}
