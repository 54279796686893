div.scrollBody-3Lwqr {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	div.scrollBody-3Lwqr {
		display: flex;
		justify-content: flex-end;
		align-items: inherit;
	}

	div.scrollBody-3Lwqr div.paper-PiGOs {
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
	}
}
