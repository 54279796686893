

@keyframes planeSpinner-2PyOq {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader-2Z-WC {
	width: 119px;
	height: 120px;

	animation: planeSpinner-2PyOq 2s linear infinite;
	overflow: hidden;
}

.wrapper-d3Nf8 {
	overflow: hidden;

	color: black;
}
