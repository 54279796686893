.container-305fS {
	display: flex;
	flex-direction: column;
	grid-gap: 28px;
	gap: 28px;

	width: 100%;
	max-width: 600px;
	padding: 32px;

	border-radius: 20px;

	background: var(--bg-white);
}

.container_checkin-ZeFET {
	padding-top: 68px;
}

.title-fEMDd {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

.container_checkin-ZeFET .title-fEMDd {
	display: none;
}

.form-1mQYB {
	display: flex;
	flex-direction: column;
	grid-gap: 28px;
	gap: 28px;
}

.form__row-3-J2G {
	position: relative;
}

.form__input-1v0yG {
	width: 100%;
}

.form-1mQYB .form__input-1v0yG label {
	overflow: hidden;

	width: 100%;

	padding-right: 50px;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.form-1mQYB .form__input-1v0yG label[data-shrink='true'] {
	width: 100%;
	padding-right: 0;
}

.form__row_footer-1hOKM {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
/* stylelint-disable */
.button-1g470 {
}
.button_disabled-1gyIj {
}
/* stylelint-enable */
.checkinHint-3uZFZ {
	flex: 1;
	margin-right: 10px;
}

.tooltip__icon-3WhGe {
	position: absolute;
	top: 28px;
	right: 12px;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	color: var(--text-pale);

	transform: translateY(-50%);
}

.notification_context-WzXba .notification__text-3Xpz1 {
	font-weight: 500;
}

@media screen and (max-width: 600px) {
	.container-305fS {
		padding: 20px;

		border-radius: 0;

		box-shadow: none;
	}

	.form-1mQYB {
		margin-top: 15px;
	}

	.checkinHint-3uZFZ {
		margin: 0 0 20px 0;
		align-self: flex-start;
	}

	div.tooltip-2iuYu {
		margin-top: -34px !important;
		margin-left: -140px;
	}
}
