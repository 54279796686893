div.header-39pls {
	padding: 40px 40px 0;

	border-radius: 20px 20px 0 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;

	background: var(--white);
}

div.text-3RU8q {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

div.text-3RU8q > p {
	margin: 6px 0;
}

div.content-3pjrT {
	padding: 12px 40px 40px;

	border-radius: 0 0 20px 20px;
}

div.footer-3fZIG {
	flex-direction: row;
	align-items: center;
}

div.footer-3fZIG > div:last-of-type {
	margin-left: auto;
}

.segments-3Pqgc {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
}

.info-1Iyx1 {
	display: flex;
	align-items: center;

	margin-top: 12px;
	margin-bottom: 24px;
}

.toggle-2K3cV {
	margin-left: auto;
}

div.disclaimer-2Kl3H a {
	color: var(--text-light);
}

.content_disclaimer-3mVaN {
	display: flex;
	align-items: center;

	text-decoration: none;
	font-size: 14px;
	line-height: 1.5;
}

.content_disclaimer-3mVaN svg {
	width: 18px;
	height: 18px;
	margin-right: 6px;
}

@media screen and (max-width: 600px) {
	div.header-39pls {
		display: flex;
		align-items: center;
		min-height: 56px;

		padding: 12px;

		border-radius: 0;

		font-size: 18px;

		box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.05);
	}

	div.content-3pjrT {
		padding: 20px 12px;

		border-radius: 0;
	}

	div.footer-3fZIG {
		flex-direction: column;
		align-items: flex-end;
		justify-content: inherit;
	}

	div.footer-3fZIG > div:last-of-type {
		width: 100%;
		margin-left: 0;
	}

	div.footer-3fZIG > div:last-of-type > button {
		flex: 1;
	}
}
