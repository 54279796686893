

button.button-2bpkC {
	min-width: 120px;

	padding: 14px 45px;

	border-radius: 4px;

	color: #ffffff;
	font-weight: 600;
	font-size: 17px;
	line-height: 120%;

	background-color: var(--active);

	transition: background-color 0.2s, box-shadow 0.2s;
}

button.button-2bpkC:hover,
button.button-2bpkC:focus {
	background-color: var(--active-30);
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}

button.button-2bpkC:focus {
	background-color: var(--active-50);
}

button.button-2bpkC:disabled {
	color: var(--gray30);

	background-color: var(--gray10);

	cursor: not-allowed;
}

button.button_small-3Meeq {
	padding: 12px 16px;

	font-size: 14px;
}
