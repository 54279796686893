.social__buttons__logo-1VmV3 {
	margin-right: 8px;
	display: flex;
	align-items: center;
}

.social__buttons__wrapper-1BL2i {
	position: relative;
}

.social__buttons__wrapper-1BL2i:nth-child(2) {
	margin: 0 30px;
}

.social__buttons__control-BhrKw {
	position: absolute;
	right: -14px;
	top: 7px;

	z-index: 1000;

	height: 28px;

	border-radius: 50%;
	border: 4px solid #ffffff;

	cursor: pointer;

	background-color: #ffffff;
}

.social__buttons__control-BhrKw svg {
	height: 20px;
	width: 20px;
}

.social__buttons__control-BhrKw svg path {
	transition: all 0.2s;

	fill: #cdcdcd;
}

.social__buttons__control-BhrKw:hover svg path {
	fill: #9a9a9a;
}

.social__buttons__wrapper-1BL2i button.google-3UI9g {
	justify-content: center;
}

.social__buttons__wrapper-1BL2i button.facebook-1oPV- {
	border-color: #3c5b96;

	color: #ffffff;

	background-color: #3c5b96;
}

.social__buttons__wrapper-1BL2i > button {
	padding: 7px 17px;
	justify-content: flex-start;
	align-items: center;

	font-weight: 600;
	line-height: 22px;
}

.social__buttons__wrapper-1BL2i > button.twitter-2iaGo {
	border-color: #59adeb;

	color: #ffffff;

	background-color: #59adeb;
}

.disabled-2kwlU {
	opacity: 0.5;
}

.social__buttons__control-BhrKw.add__icon-1WMWc svg path {
	fill: var(--cabinet-action);
}

.social__buttons__wrapper-1BL2i > button.button__disabled-28NpN {
	border: 1px solid rgba(0, 0, 0, 0.23);

	color: var(--dark-gray);
}

.social__buttons__wrapper-1BL2i > button.button__disabled-28NpN.facebook-1oPV-,
.social__buttons__wrapper-1BL2i > button.button__disabled-28NpN.twitter-2iaGo {
	border-color: inherit;

	color: #ffffff;
}

@media screen and (max-width: 600px) {
	.social__buttons__wrapper-1BL2i:nth-child(2) {
		margin: 20px 0;
	}

	.social__buttons__wrapper-1BL2i button {
		width: 100%;
		justify-content: center;
	}
}
