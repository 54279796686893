.root-23eHZ {
	color: var(--error);
	font-weight: 500;
}

.icon-1aOMq {
	color: var(--error);
}

.root-23eHZ.account-1S8rx {
	background: var(--errorBG);
}

@media screen and (max-width: 600px) {
	.root-23eHZ {
		min-height: 36px;
	}

	.payload-2Widk {
		align-items: center;
	}
}
