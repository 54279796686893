div.input__wrapper-3rHtX {
	margin-right: 6px;
	flex: 1;
}

div.input__wrapper-3rHtX > div {
	width: 100%;
}

.input__inner-3cnNf {
	width: 100%;
	height: var(--input-height);
	padding: 0 16px;

	border: none;
	border-radius: 2px;

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	line-height: var(--input-height);
	text-align: left;

	background-color: rgba(255, 255, 255, 0.2);

	transition: background-color 0.15s, color 0.15s;

	appearance: none;
}

div.input__inner-3cnNf:before {
	background: none;
}

label.label-dtDlZ {
	color: #ffffff;

	transform: translate(16px, 18px) scale(0.9);
}

label.label-dtDlZ.label_shrink-IlGV6 {
	z-index: 2;

	transform: translate(16px, 1px) scale(0.75);
}

label.label-dtDlZ + div.input__inner-3cnNf {
	margin-top: 0;
}

div.input__inner-3cnNf input {
	padding: 0;

	color: #ffffff;
}

div.input_focused-24yvn.input__inner-3cnNf {
	background-color: #ffffff;
	opacity: 1;
}

div.input_focused-24yvn.input__inner-3cnNf input {
	border: none;

	color: var(--dark-gray);
}

@media screen and (max-width: 600px) {
	div.input__wrapper-3rHtX {
		margin-bottom: 6px;
		margin-right: 0;
	}
}

/* stylelint-disable */
.label_error-15ETM {
}
/* stylelint-enable */
