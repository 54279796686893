.wrapper-2oBnE {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.wrapper_return-2U7K9 {
	flex-direction: column;
}

.disclaimer-3h6Zw {
	padding: 24px 0;
}

.leg-1WDVk {
	padding-bottom: 16px;

	border-bottom: 1px dashed #a5a5a5;

	font-weight: 600;
	font-size: 19px;
}

.leg-1WDVk:not(:first-child) {
	margin-top: 20px;
}

.controls__wrapper-2FIrL {
	width: 100%;
	max-width: 1100px;
	margin: 15px 0;
}

@media screen and (max-width: 600px) {
	.wrapper-2oBnE:not(:first-child) {
		margin-top: 16px;
	}

	.controls__wrapper-2FIrL {
		display: flex;
		justify-content: space-between;

		margin: 0 auto;
		padding: 12px 0;
	}

	.leg-1WDVk {
		width: 100%;
		margin: 0 auto;
	}
}
