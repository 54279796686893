.wrapper-1cJsa {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;

	width: 100%;
	max-width: 1140px;

	margin: 0 auto;
	padding-top: 30px;
	padding-left: 20px;
	padding-right: 20px;
}

.content-1h8RS {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}

.conditions-Eptoi,
.form-3wiCI {
	flex: 1;

	border-radius: 20px;
}

div.modal__paper-2X4si {
	max-width: 1250px;
}

@media screen and (max-width: 600px) {
	.wrapper-1cJsa {
		padding: 12px;
	}

	.content-1h8RS {
		flex-direction: column;
	}

	div.conditions-Eptoi {
		order: 1;

		border-radius: 20px;
	}

	.hint-2f0Xg,
	.hint-2f0Xg:hover {
		margin-top: 0;
		padding: 20px;

		border-radius: 0;

		background-color: transparent;
		box-shadow: none;
	}

	div.modal-_LIxW {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	div.modal-_LIxW div.modal__paper-2X4si {
		margin: 0;
		width: 100%;

		border-radius: 0;
	}
}
