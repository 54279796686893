.wrapper-3d5g- {
	display: flex;
	max-width: 840px;
	margin: 0 auto;

	padding: var(--payment-wrapper-padding);

	border-radius: var(--payment-wrapper-border-radius);

	box-shadow: 2px 1px 40px rgba(0, 0, 0, 0.03);

	background: #ffffff;
	overflow: hidden;
}

.wrapper-3d5g-.oneMethod-nDc9p {
	flex-direction: row;
}

.details-3-60i {
	flex-shrink: 0;
	flex-basis: 300px;
	min-height: 400px;
	padding: var(--payment-wrapper-padding);

	border-radius: var(--payment-wrapper-border-radius);

	background-color: var(--bg-fill);
}

@media (max-width: 1024px) {
	.details-3-60i {
		max-width: 300px;
		flex-basis: auto;
		flex-shrink: 1;
	}
}

.details__total-TY68S {
	margin: 8px 0;

	font-size: 18px;

	white-space: nowrap;
}

.details__parts-2D5N4 {
	margin: 40px 0 0;
	padding: 0;
}

.content-2kkZI {
	flex-grow: 1;
	padding: 28px 30px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.content__title-2Ths3 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0;

	font-weight: 500;
	font-size: 22px;
	color: var(--text-base);
}

div.tooltip-1A5Pf {
	margin-bottom: 5px;
}

.content__tooltip-yBGgz {
	margin-top: 10px;
	margin-bottom: 20px;

	color: var(--text-light);
	font-size: 14px;
	text-decoration: underline;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.content__tooltip__text-2UNrx {
	text-align: center;
}

.oneMethod-nDc9p .content__title-2Ths3 {
	color: var(--dark-gray);
	font-size: 26px;
	line-height: 150%;
}

div.payment__result-3sCOU {
	max-width: 480px !important;
	min-width: 480px;
	min-height: 375px;
	overflow: visible;
}

div.payment__result-3sCOU > span {
	display: none;
}

button.content__title__back-1Rg7_ {
	font-weight: 400;
	font-size: 14px;
}

.gateways-3gtMV {
	margin: 0;
	padding: 0;
}

.gateways_hidden-yaPBU {
	display: none;
}

.description-3EGpm {
	margin: 0 0 40px;

	font-size: 15px;
	line-height: 139.45%;
	color: var(--common-gray);
}

.cards-2suOV {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	max-width: 65%;
}

.card-1APBW {
	margin-top: 2px;
	margin-right: 10px;
	margin-bottom: 2px;
}

.card-1APBW:last-child {
	margin-right: 0;
}

.paylate-8K_W3 {
	height: 28px;
}

.paylate_asCard-3fnhp {
	height: 28px;
	display: none;
}

.idram-LARWX {
	height: 26px;
}

.mastercard-2yB1g {
	height: 16px;
}

.visa-2trsV {
	height: 10px;
}

.cards_header-2gfkH {
	position: relative;
}

.sbp-2tDUP {
	display: none;
	height: 18px;
}

.sberpay-Bx8tj {
	display: none;
	height: 19px;
}

.mir-3CAKP {
	height: 10px;
}

.applepay-2Jdgs {
	height: 16px;
}

.googlepay-KjnfF {
	height: 15px;
}

.vakifbank-V92Vn {
	height: 18px;
	display: none;
}

.yandexpay-1IBvT {
	height: 18px;
	display: none;
}

.unionpay-195UG {
	height: 24px;
	display: none;
}

.invoicebox-172yA {
	height: 20px;

	transform: translateY(4px);
}

.kaspi-3VdxY {
	height: 18px;
}

div.timelimit-2XWDl {
	margin-bottom: 20px;
}

.details__totalItem-25Vn6 {
	flex-wrap: wrap;
}

button.details__button-3P7wi {
	margin-left: -8px;
}

.qiwi-3HSG1 {
	width: 70px;
	height: 26px;
}

img.icon-ttJrq {
	width: 60px;
	height: 30px;
}

.visible-30xgz {
	display: block;
}
/* stylelint-disable */
.summaryGroup_order-15YSH {
}

.summaryGroup_priceParts-2bk6A {
}

.summaryGroup_total-1Tyrc {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.wrapper-3d5g- {
		display: flex;
		flex-direction: column;
		padding: var(--payment-wrapper-padding) 0 0 0;

		background: unset;
	}

	.oneMethod-nDc9p.wrapper-3d5g- {
		flex-direction: column;
	}

	.details-3-60i {
		min-height: auto;
		flex-basis: unset;
		max-width: 100%;
		padding: 4px 20px 24px;

		background: unset;
	}

	.details__total-TY68S {
		text-align: right;

		line-height: 150%;
	}

	.details__parts-2D5N4 {
		margin: 20px 0 0;
	}

	.content-2kkZI {
		padding: 20px;

		border-radius: 8px 8px 0 0;

		background: #ffffff;
	}

	.content__title-2Ths3 {
		justify-content: flex-start;

		font-size: 17px;
		font-weight: 500;
		line-height: 150%;
		color: #292929;
	}

	.oneMethod-nDc9p .content__title-2Ths3 {
		justify-content: space-between;
	}

	.content__title__back-1Rg7_ {
		display: flex;
		margin-right: 16px;

		line-height: 25px;

		transform: rotate(180deg);
	}

	.cards-2suOV {
		margin-bottom: 15px;
	}

	.cards_noTitle-iVKW2 {
		position: absolute;

		margin-bottom: 0;
	}

	.paylate-8K_W3 {
		position: static;
	}
}
