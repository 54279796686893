

.group__groupLabel-170up {
	font-size: 13px;
	font-weight: 700;
	color: var(--brand-1-0);
}

.option__label-ZbGVd {
	color: var(--text-base);
}

.option__code-3ycMn {
	width: 45px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 16px;
}

.option__option-2C3e6:hover {
	background-color: var(--brand-1-5);
}

button.dropdown__allDirectionsButton-3rEHk {
	font-weight: 500;
	font-size: 12px;

	background-color: var(--brand-1-0);
}

button.dropdown__allDirectionsButton-3rEHk:hover {
	background-color: var(--brand-1--1);
}

.directionsDialog__label-1KDXT {
	color: var(--brand-1--2);
	font-size: 22px;
	font-weight: 700;
}

.directionsDialog__countryActive-25LUS {
	background: var(--brand-1-0);
}

.directionsDialog__country-3Wr_O {
	border-radius: 4px;

	font-size: 16px;
	line-height: 16px;
}

.directionsDialog__countriesList-30mKR {
	grid-gap: 5px;
	gap: 5px;
}

.directionsDialog__iata-1u-p5,
.directionsDialog__city-2mVrB {
	font-size: 16px;
}

.directionsDialog__iata-1u-p5 {
	color: var(--text-pale);
}

.directionsDialog__city-2mVrB {
	border-bottom: 0;

	color: var(--text-base);
}

.directionsDialog__city-2mVrB:hover {
	background: var(--brand-1-5);
}
