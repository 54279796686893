

.step-1p39G {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 5px 12px 5px 10px;

	border: 1px solid var(--brand-1-1);
	border-top: 0;
	border-right: 0;

	color: var(--brand-1-3);
	font: var(--small-desktop-bold);

	white-space: nowrap;
	text-transform: uppercase;

	cursor: default;
}

.step-1p39G:last-child {
	padding-right: 0;
	margin-right: 0;
}

.icon-QSJiT {
	height: 24px;
}

.id-1A9qb {
	margin: 0 8px;
}

.title-32EEa {
	display: none;
}

.arrow-3IyAB {
	display: flex;
	margin-left: 15px;
}

.step-1p39G:last-child .arrow-3IyAB {
	display: none;
}

.step_active-3kKw1 {
	border: 0;

	color: var(--brand-1-0);

	background: var(--bg-white);
}

.step_active-3kKw1 .title-32EEa {
	display: inline-block;
}

.step-1p39G.step_passed-2o_fA {
	color: var(--bg-white);
}

.step-1p39G.step_disabled-n0Onc {
	border: none;

	color: var(--brand-1-2);
}

.step-1p39G.step_passed-2o_fA.step_clickable-u32d5:hover .label-3S__t,
.step-1p39G.step_active-3kKw1.step_clickable-u32d5:hover .label-3S__t {
	text-decoration: underline;
}

.step_clickable-u32d5 {
	cursor: pointer;
}
