

.baggageFooter_clear-woLt3 {
	font-size: 14px;
}

.baggageFooter_price_block__delimiter-1cNZD {
	background: var(--line-separator);
}

.baggageSegment_root-1UStP {
	background: var(--brand-1-5);
}

.baggagePassenger_root-1vjK- {
	padding-left: 12px;
}

.baggagePassenger_number-1MnA- {
	width: 24px;
	height: 24px;
	padding: 5px 9px;
}

.close-OKxWb {
	top: 8px;
	right: 8px;
}

.mobileBaggageSelector_footer-3SQ0y {
	grid-gap: 20px;
	gap: 20px;
}
