button.passengerStyles__increase-2gYd5,
button.passengerStyles__decrease-18Y4G {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.passengerStyles__increase-2gYd5:hover,
button.passengerStyles__decrease-18Y4G:hover {
	color: var(--white);

	background: var(--brand-1-0);
}

button.passengerStyles__increase-2gYd5:disabled,
button.passengerStyles__decrease-18Y4G:disabled {
	color: var(--line-dot-line);
}

.passengerStyles__counter-2mJNm {
	color: var(--text-base);
}

.passengerStyles__passengerType-1Va57 {
	font-size: 16px;
	font-weight: 500;
	color: var(--text-base);
}

.passengerStyles__age-2Ut3i {
	font-weight: 400;
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.passengerStyles__increase-2gYd5 svg,
	.passengerStyles__decrease-18Y4G svg {
		fill: var(--brand-1-0);
	}

	.passengerStyles__passengerType-1Va57 {
		font-size: 18px;
	}

	.passengersStyles__item-173qW {
		padding: 12px 0;

		border-top: 1px solid var(--line-separator);
	}

	.passengersStyles__item-173qW:not(:last-of-type) {
		border-bottom: 0;
	}

	button.passengerStyles__increase-2gYd5:hover svg,
	button.passengerStyles__decrease-18Y4G:hover svg {
		fill: var(--white);
	}
}
