.segment__container-27onp.summary-21ivq {
	padding-bottom: 16px;
}

.segment__container-27onp:not(.summary-21ivq):not(:last-child) {
	border-bottom: 1px dashed var(--gray-line);
}

.flight-tB_VP {
	width: 100%;
	margin: 0 auto;

	border: 1px solid #e5e5e5;

	border-radius: 4px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);

	background: #ffffff;
}

.flight-tB_VP:not(:last-of-type) {
	margin-bottom: 16px;
}

.flight-tB_VP.flight_selected-1acQs,
.flight-tB_VP.readonly-1tDEi {
	border: 1px solid var(--gray-line);
	border-radius: 4px;

	box-shadow: none;
}

.infoMessage-2dwsp {
	margin-top: 16px;
}

.infoMessage__htmlMessage-1TcrZ {
	width: 100%;
}

.segments-12YfR {
	position: relative;
}

.flight-tB_VP:not(.readonly-1tDEi) .segments-12YfR {
	border-bottom: 1px dashed #cccccc;
}

.subsidies-3L_YD {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 17px;
	margin-bottom: 8px;

	color: var(--brand-1-0);
	font-size: 14px;
}

.subsidies-3L_YD svg {
	flex-shrink: 0;
	margin-left: 4px;
	margin-bottom: 2px;
}

.selected-2PM69 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 12px;

	color: var(--base-30);
	font-size: 14px;
}

.flight-tB_VP.flight_canceled-Qeh27 .segments-12YfR {
	opacity: 0.4;
}

.canceled-2VWxQ {
	color: var(--error);
}

.expand-1rMsW {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin: auto;

	border: 1px solid #d9d9d9;
	border-radius: 50%;

	transform: translateY(50%);
	cursor: pointer;
	background: #ffffff;
}

.price__close-2PzUF {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	margin-left: 8px;

	border-radius: 50%;

	color: var(--brand-1-1);

	background: var(--brand-1-5);
}

.price__close-2PzUF svg {
	width: 14px;
	height: 14px;
}

.expand-1rMsW svg {
	transition: transform 0.2s;
}

.expand-1rMsW.expand_open-2XyGT svg {
	transform: rotate(180deg);
}

.segment-3EAps {
	padding: 16px 12px 12px;
}

.segments_expanded-3NxqB .segment-3EAps:not(:last-child) {
	border-bottom: 1px dashed #d9d9d9;
}

@media screen and (max-width: 600px) {
	.flight-tB_VP {
		border-radius: var(--results-mobile-mobileFlight-bdrs);
	}
}

button.price-HjHoo {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 150px;
	min-height: 44px;
	padding: 0 12px;

	border-radius: 100px;

	line-height: 18px;

	font-weight: 400;
}

.price__from-177GP {
	padding-bottom: 3px;
	align-self: flex-end;
}

.price__money-29wuK {
	padding: 0;
	margin-left: 8px;

	font-size: 20px;
	font-weight: 900;
	color: #ffffff;
}

.price__miles-2nYun .currency-1Qo9a {
	font-size: 14px;
	font-weight: 400;
}

.miles__taxes-_iB39 {
	margin-bottom: 16px;

	color: var(--gray50);
	font-size: 14px;
	text-align: center;
}

.price__text-1DsKf {
	display: flex;
	align-items: center;
}

.price__text-1DsKf:after {
	display: none;
	width: 24px;
	height: 24px;
	margin-left: 4px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMiAxLjQxTDEwLjU5IDBMNiA0LjU4TDEuNDEgMEwwIDEuNDFMNiA3LjQxTDEyIDEuNDFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) no-repeat center center;
	content: '';
}

.flight-tB_VP svg.recommended {
	bottom: -33px;
}

.flight_withAircraft-36pGM svg.recommended {
	bottom: -176px;
}

.selectedFare-oLs4u {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 13px 12px;

	font-size: 14px;
	font-weight: 500;
	color: var(--base);
}

.selectedFare__priceWrapper-TSpNB {
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.selectedFare__price-1h3C2 {
	font-size: 20px;
	font-weight: 700;
}

.stopPoint-2Dp50 {
	width: 100%;

	text-align: center;
	font-size: 13px;
	font-weight: 600;
	color: var(--error);
}

.price_original-m0T7o {
	margin-bottom: 8px;

	text-align: center;
}

span.price_originalMoney-1Evg3 {
	color: var(--active);
	font-weight: 500;
	text-decoration: line-through;
}

.price__wrapper-2U1Al {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 16px 0;
}

.transitTariff-1suHM {
	margin-bottom: 8px;
}

.subStatus-2OJSj {
	padding: 13px 12px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text-mid);
	font-size: 14px;

	text-align: center;
}

.flightInfoMessage-1lS2Y {
	width: 100%;
	height: 216px;
}

.flightInfoMessage__htmlMessage-3IxEw {
	width: 100%;
	height: 100%;
}

/* stylelint-disable */
.expanded-OeqIE {
}

/* stylelint-enable */
@media screen and (max-width: 320px) {
	.segment-3EAps {
		padding-left: 10px;
		padding-right: 10px;
	}
}
