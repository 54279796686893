

div.tooltip-31sJz {
	transform: translateY(-8px) !important;
}

div.tooltip-31sJz:after {
	border-top-color: var(--brand-1-0);
}

div.tooltip-31sJz.tooltip_recommend-2YswN {
	background: var(--brand-1-0);
}

.tooltip-31sJz.tooltip_withInfo-Gugur:after,
.tooltip-31sJz.tooltip_recommend-2YswN:after {
	border-top-color: var(--brand-1-0);
}

div.info-2FyuM,
.tooltip-31sJz.tooltip_recommend-2YswN .info-2FyuM {
	background: var(--brand-1-1);
}

.popper-3PI4W[data-rfics='STX'] .tooltip-31sJz {
	background: #ffc692;
}

.popper-3PI4W[data-rfics='STX'] .number-2Oz-1 {
	border-color: #e19e5a;

	color: var(--text-mid);
}

.popper-3PI4W[data-rfics='STX'] .info-2FyuM,
.popper-3PI4W[data-rfics='STX'] .details__price-18C2f {
	color: var(--text-mid);
}

.popper-3PI4W[data-rfics='STX'] div.info-2FyuM,
.popper-3PI4W[data-rfics='STX'] .tooltip-31sJz.tooltip_recommend-2YswN .info-2FyuM {
	background: #ffcfa0;
}

.popper-3PI4W[data-rfics='STX'] .details__seatName-3DflE {
	color: var(--text-light);
}

.popper-3PI4W[data-rfics='STX'] .tooltip-31sJz:after,
.popper-3PI4W[data-rfics='STX'] .tooltip-31sJz.tooltip_withInfo-Gugur:after,
.popper-3PI4W[data-rfics='STX'] .tooltip-31sJz.tooltip_recommend-2YswN:after {
	border-top-color: #ffc692;
}
