

.wrapper-2Yqq_ {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	width: 100%;

	border-radius: 4px;

	color: #ffffff;

	background-color: var(--common-blue);
}

.header-1EcaM {
	align-self: stretch;
	padding: 16px 16px 14px 16px;
}

.airport-31vV8 {
	display: flex;
	align-items: center;

	opacity: 0.6;
}

.direction-1FcOE {
	display: flex;
	align-items: center;
	margin-right: 9px;
}

.direction-1FcOE svg {
	width: 36px;
	height: 36px;
}

.airport__iata-10kPT {
	font-size: 39px;
	font-weight: 600;
	text-transform: uppercase;
}

.airport__name-N5PdI {
	margin-left: 9px;

	font-size: 13px;
	line-height: 130%;
}

.bottom-2hOX4 {
	margin-top: 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.when-2NGj8 {
	display: flex;
	align-items: center;

	font-size: 20px;
	font-weight: 600;
	line-height: 120%;
	text-transform: uppercase;
}

.date-2Fmt7 {
	margin-left: 6px;
}

.terminal-15j0A {
	display: flex;
	align-items: flex-end;

	flex-wrap: wrap;

	font-size: 13px;
	line-height: 140%;
}

.terminal__code-3-DhW {
	margin-left: 5px;

	font-size: 20px;
	font-weight: 600;
	line-height: 120%;
	text-transform: uppercase;
}

.footer-QSKtF {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-grow: 1;

	min-height: 118px;
	padding: 16px;

	background-color: var(--base15);
	background-size: cover;
	background-repeat: no-repeat;
}

.footer__direction-2xg3L {
	position: absolute;

	top: 8px;
	left: 50%;

	transform: translate(-50%, 0);
	opacity: 0.4;
}

.footer__direction-2xg3L svg {
	width: 72px;
	height: 72px;
}

.weather-3YsZB {
	display: flex;
	align-items: center;
}

.weather-3YsZB svg {
	width: 28px;
	height: 28px;
}

.weather__value-2Xgli {
	margin-left: 5px;

	font-size: 20px;
	line-height: 100%;
}

.details-ClerB {
	display: flex;
	align-items: center;

	font-size: 13px;
	font-weight: 500;
	line-height: 28px;

	cursor: pointer;
}

.details-ClerB svg {
	margin-left: 4px;
}
