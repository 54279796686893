

span.checkbox-1kon6 {
	width: 18px;
	height: 18px;

	color: var(--text-pale);
}

span.checkbox-1kon6.checked-3dHgl,
span.checkbox-1kon6.indeterminate-2T_IG {
	color: var(--brand-1-0);
}

span.checkbox-1kon6.required-2zwVO:not(.checked-3dHgl) {
	color: var(--error-stroke-hover);

	background: var(--error-bg);
}

span.checkbox-1kon6.disabled-335G1 {
	color: var(--line-dot-line);
}
