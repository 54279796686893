.preTagWrapper-1S3Q7 pre {
	padding: 0;

	font-size: 12px;

	line-height: 1.3;
	white-space: pre;

	font-weight: 300;
	color: var(--text-light);
}

.container-1ohvI {
	margin-bottom: 20px;
	padding: 28px;

	border-radius: 4px;

	background-color: var(--white);
	overflow: hidden;
}

.title-1xxnn {
	margin-bottom: 20px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 22px;
	line-height: 1.3;
}

.list-3AiPi {
	display: flex;
	flex-direction: column;
}

.listHeader-2k3jd,
.listItem-3kfne {
	display: flex;

	border-bottom: 1px solid var(--gray10);
}

.listHeader_item-36i7w {
	flex: 0 1 100%;
	padding: 10px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;
}

.listItem_col-p3ZGS {
	flex: 0 1 100%;
	padding: 10px;

	font-size: 14px;
	line-height: 1.3;
}

.listHeader_item-36i7w:first-child,
.listItem_col-p3ZGS:first-child {
	flex: 1 1 40%;
}

.listHeader_item-36i7w:nth-child(2),
.listItem_col-p3ZGS:nth-child(2) {
	flex: 1 1 200%;
}

.listHeader_item-36i7w:nth-child(3),
.listItem_col-p3ZGS:nth-child(3) {
	flex: 1 1 120%;
}

.listHeader_item-36i7w:nth-child(3),
.listHeader_item-36i7w:nth-child(4),
.listItem_col-p3ZGS:nth-child(3),
.listItem_col-p3ZGS:nth-child(4) {
	text-align: right;
}

.listItem_col-p3ZGS:first-child span,
.listItem_col-p3ZGS:nth-child(2) span {
	font-weight: 500;
}

.label_accrued-3mNf7 {
	color: #29a644;
}

.label_spent-1aaC1 {
	color: #c21212;
}

.balance-3F6es {
	display: inline-block;
	margin-left: 20px;
}

.number-OAI5j {
	white-space: nowrap;
}

.listItem-3kfne:has(.pre_listItem_col-3CQCY) {
	padding: 0;
}

@media screen and (max-width: 600px) {
	.title-1xxnn {
		padding: 20px 20px 0;

		font-size: 18px;
	}

	.container-1ohvI {
		padding: 0;
	}

	.wrapper-3oXHD {
		overflow-y: scroll;
	}

	.list-3AiPi {
		width: 1000px;
		padding: 0 20px 20px;
	}
}
