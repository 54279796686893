.group-1hSdD {
	padding: 30px 40px;
	display: flex;
}

.group__description-19FAT {
	width: 330px;
	margin-right: 12px;
}

.icon-1-xTs {
	margin-right: 12px;
}

.timeline-16F-A {
	display: flex;
	margin-bottom: 20px;
	align-items: center;
}

.direction-1kxhg {
	font-size: 18px;
	font-weight: 700;
	line-height: 130%;
	color: var(--text-base);
}

.time-1rTq3 {
	font-size: 27px;
	line-height: 130%;
	color: var(--text-base);
	font-weight: 700;
}

.description-2NIN3 {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 130%;
}

.services-P1RPN {
	display: grid;
	flex: 1;
	grid-template-columns: 1fr 1fr;
	grid-gap: 12px;
}

.empty-3x1qZ {
	display: flex;
	align-items: center;

	color: var(--text-mid);
	font-size: 14px;
	line-height: 130%;
}

@media screen and (max-width: 600px) {
	.group-1hSdD {
		display: block;
		padding: 0;
	}

	.group__description-19FAT {
		width: 100%;
	}

	.group-1hSdD:not(:last-child) {
		margin-bottom: 24px;
		padding-bottom: 24px;

		border-bottom: 1px solid var(--line-separator);
	}

	.description-2NIN3 {
		width: 100%;
	}

	.services-P1RPN {
		display: block;
	}

	.services-P1RPN > div:not(:last-child) {
		margin-bottom: 12px;
	}

	.direction-1kxhg {
		font-size: 16px;
		font-weight: 500;
	}

	.time-1rTq3 {
		font-size: 22px;
		font-weight: 700;
	}
}
