.root-3naqD {
	display: flex;
	align-items: center;
	width: 100%;
}

.root-3naqD.type_account-iEOOp {
	color: var(--gray70);
	font-size: 15px;
	font-weight: 500;
}

.icon-Rf3my {
	color: #b3b3b3;
}

.root-3naqD.type_account-iEOOp .icon-Rf3my {
	color: var(--success);
}

.root-3naqD.type_review-3R_At .orderCode-2LDhj {
	font-weight: 500;
	font-size: 14px;
}

.root-3naqD.type_review-3R_At .price-204fx,
.root-3naqD.type_review-3R_At .security_code-3JW3s {
	margin-left: 12px;
}

.root-3naqD.type_review-3R_At .security_code-3JW3s .value-c2SC5,
.root-3naqD.type_review-3R_At .orderCode-2LDhj .value-c2SC5 {
	display: inline-flex;
}

.download_wrapper-2d3KS {
	margin-left: auto;
}

button.download-12sBv {
	display: inline-flex;
	align-items: center;
}

button.download-12sBv svg {
	margin-left: 8px;
}

@media (max-width: 768px) {
	.root-3naqD.type_review-3R_At span.security_code-3JW3s,
	.root-3naqD.type_review-3R_At span.price-204fx {
		display: block;
		margin: 0;
		padding-top: 6px;

		font-weight: 400;
	}
}
