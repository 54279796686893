.wrapper-3KmWd {
	position: relative;

	display: inline-block;
	margin: 5px 0;

	cursor: pointer;
}

.active-1etfF.wrapper-3KmWd:before {
	left: 15px;
}

.active-1etfF.wrapper-3KmWd:after {
	opacity: 1;
}

.wrapper-3KmWd:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 1;

	width: 32px;
	height: 20px;
	margin: auto;

	border-radius: 10px;

	background-color: var(--switch-default-background);
	content: '';
}

.wrapper-3KmWd:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 3px;
	z-index: 2;

	width: 14px;
	height: 14px;
	margin: auto;

	border-radius: 50%;

	opacity: 0.8;
	background-color: #ffffff;

	transition: all 0.2s ease-out;
	content: '';
}

.label-26koI {
	display: flex;
	padding-left: 40px;

	font-size: 14px;
	line-height: 20px;

	opacity: 0.7;

	transition: opacity 0.2s ease-out;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.active-1etfF .label-26koI {
	opacity: 1;
}

.disable-16zTq {
	opacity: 0.8;
	cursor: not-allowed;
}

.right-sWGpm.wrapper-3KmWd:before {
	right: 15px;
	left: auto;
}

.right-sWGpm.wrapper-3KmWd:after {
	right: 0;
	left: auto;
}

.right-sWGpm.active-1etfF.wrapper-3KmWd:before {
	right: 3px;
}

.right-sWGpm .label-26koI {
	padding-left: 0;
	padding-right: 40px;
}

@media (max-width: 1024px) {
	.wrapper-3KmWd {
		padding: 0 20px;
	}
}
