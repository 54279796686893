.root-2A0B- {
	display: flex;
	flex-direction: column;
	padding: 20px;

	border-radius: 8px;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);

	background: var(--white);
}

.header-SRNYg {
	display: flex;
	align-items: center;

	min-height: 30px;

	font-weight: 700;

	font-size: 18px;
	line-height: 1.4;
}

.body-1iUCX {
	padding-top: 20px;
	display: flex;
	justify-content: center;
	flex: 1;
}

.footer--MPGS {
	padding-top: 16px;
	flex: 0;
}

.promo_message-123Fg {
	margin-left: 12px;
}

@media screen and (max-width: 600px) {
	.header-SRNYg {
		flex-direction: column;
		align-items: flex-start;

		font-size: 20px;
	}

	.promo_message-123Fg {
		margin-left: 0;
		margin-top: 10px;
	}

	.footer--MPGS {
		padding-top: 24px;
	}
}
