

.container-3faHj {
	position: relative;

	max-width: 1100px;

	margin: 0 auto 12px;

	padding: 17px 17px 17px 50px;

	border: 1px solid var(--warning);

	color: var(--gray80);
	font-size: 15px;
	line-height: 130%;

	background: var(--warning20);
}

.html-27eQv a {
	color: var(--text-link);
}

/* stylelint-disable */
.reject-3X1aP {
}

.notice-1hB0Y {
}

.error-2QseA {
}

.time-2rvtV {
}

/* stylelint-enable */
.header-1Al50 {
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
}

.icon-1HXMD {
	position: absolute;
	top: 18px;
	left: 18px;
}
