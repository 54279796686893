

.close-1N2tn {
	position: absolute;
	top: 12px;
	right: 12px;

	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 28px;
	min-width: 28px;
	height: 28px;

	border-radius: 50%;

	color: var(--white);

	background: rgba(0, 0, 0, 0.1);
	cursor: pointer;

	transition: color 0.15s, background 0.15s;
}

.close-1N2tn:hover {
	background: rgba(0, 0, 0, 0.15);
}

.close-1N2tn svg {
	width: 60%;
	height: 60%;
}
