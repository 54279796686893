.root-3IEj4 {
	display: flex;
	align-items: stretch;

	max-width: 1100px;
	margin: 0 auto 12px;

	background: var(--white);
}

.description_block-s5pFe {
	flex-grow: 1;
}

.switch_block-mqK6o {
	min-width: 200px;
}

@media screen and (max-width: 600px), (max-width: 1024px) {
	.root-3IEj4 {
		flex-direction: column;
		margin-top: 30px;
	}

	.switch_block-mqK6o {
		width: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
