

.stop-3bmbm {
	position: absolute;
	right: 0;
	bottom: -13px;
	left: 0;
	z-index: 2;

	padding: 0 4px;

	color: var(--error);
	text-align: center;
	font: var(--small-desktop);

	transform: translateY(100%);
}

.stop__label-4X3OV {
	padding: 0 4px;

	background: #ffffff;
}
