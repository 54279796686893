

button.modal__button-_P2A_ {
	border-radius: 6px;

	background: var(--brand-3-0);
}

button.modal__button-_P2A_:hover {
	background: var(--brand-3--1);
}

button.modal__button_decline-2EnzM {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.modal__button_decline-2EnzM:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}
