.servicePrice-Srw60 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 48px;
	margin-right: 20px;
	padding-right: 20px;

	border-right: 1px solid #d9d9d9;

	color: var(--common-gray);
	font-weight: 600;
	font-size: 22px;
}

div.additionalServices-3vEIz {
	width: 100%;

	padding-top: 30px;
	padding-bottom: 30px;
}

.additionalServices-3vEIz > div {
	flex-basis: 50%;
	max-width: 540px;
}

.additionalServices-3vEIz > div:first-child {
	margin-right: 20px;
}

.upgrade-12y8W {
	padding-right: 12px;
	padding-left: 12px;
}

@media screen and (max-width: 600px) {
	div.upgrade-12y8W {
		padding-bottom: 110px;
	}
}

@media (max-width: 1024px) {
	div.additionalServices-3vEIz {
		padding-bottom: 30px;
	}

	.additionalServices-3vEIz > div:first-child {
		margin-right: 0;
	}
}

.extras-3lvr1 {
	display: flex;
	flex-direction: column;

	/* full height - offsetTop */
	min-height: calc(100vh - 0);
	min-height: calc(100vh - var(--wx-custom-variable-extras-offset-top, 0));

	padding-bottom: 84px;
}

.extras-3lvr1 .wrapper-2XPRJ {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media screen and (max-width: 600px) {
	.extras-3lvr1 {
		height: auto;

		padding-bottom: 50px;
	}
}

/* stylelint-disable */
.seats-2XCwN {
}
