

.sexSelector-1nS4s {
	display: flex;
	flex-direction: column;
}

.sexSelector__container-O5rNg {
	display: flex;
	grid-gap: 1px;
	gap: 1px;
	margin-top: 8px;
}

.sexSelector__helperText-2RWG4 {
	margin-top: 4px;

	color: var(--error-icon);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.1;
}

.input-1WhOa {
	display: none;
}

.label-hFDuR {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 52px;
	height: 48px;

	border: 1px solid var(--brand-1-3);

	color: var(--brand-1-0);

	cursor: pointer;

	background: var(--brand-1-5);
}

.label-hFDuR:first-of-type {
	padding-left: 4px;

	border-radius: 100px 0 0 100px;
}

.label-hFDuR:last-of-type {
	padding-right: 4px;

	border-radius: 0 100px 100px 0;
}

.label-hFDuR.checked-2EXjU {
	border-color: var(--brand-1-0);

	color: var(--white);

	background: var(--brand-1-0);
}

.label-hFDuR.error-1Kcpc {
	border-color: var(--error-stroke-hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

.label-hFDuR.disabled-127eo {
	cursor: auto;
	cursor: initial;
}

.icon-T82o8 {
	display: flex;
	align-items: center;
	justify-content: center;
}
