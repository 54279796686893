.header-3PAgz {
	position: relative;

	padding: 32px 40px;

	border-radius: 8px 8px 0 0;

	color: var(--white);
	font: var(--big-desktop-bold);

	background: var(--bg-fill);
}

.header-3PAgz:after {
	content: '';

	position: absolute;
	top: 50%;
	right: 40px;

	max-width: 20%;
	width: 100%;
	height: 75%;

	transform: translateY(-50%);
}

.subheader-3vozV {
	margin-top: 4px;

	font-weight: 700;
	font-size: 20px;
	line-height: 1.2;
}

.wrapper-3sX3l {
	position: relative;

	display: flex;
	flex-direction: column;
}

.content-2_Uvq {
	padding: 30px 40px 40px;

	border-radius: 0 0 8px 8px;

	background-color: var(--white);
}

.content_text-1CfTA {
	margin-bottom: 12px;

	color: var(--text-base);
	line-height: 1.4;
	white-space: break-spaces;
}

.content_text-1CfTA a {
	color: var(--brand-1-0);
	text-decoration: underline;
}

.content_text-1CfTA > p {
	margin: 14px 0;
}

.content_text-1CfTA > p:first-of-type {
	margin-top: 0;
}

.content_text-1CfTA > p:last-of-type {
	margin-bottom: 0;
}

.content_disclaimer-hDeLt {
	display: flex;
	align-items: center;
	margin-bottom: 28px;

	color: var(--text-light);
}

.content_disclaimer-hDeLt svg {
	margin-right: 8px;
}

.content_wrapper-6TgBM {
	margin-bottom: 28px;
}

.total-1O3RV {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 16px;
}

.total__price-3n7BX {
	margin-right: 4px;
	padding-right: 12px;

	border-right: 1px solid #d9d9d9;

	font: var(--medium-1-mobile-bold);
}

button.total__clear-3io-g {
	font: var(--normal-desktop-accent);
	font-weight: 500;
	font-size: 15px;
}

.footer-3FRZX {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	grid-gap: 16px;

	gap: 16px;
}

.controls-F9Hvv {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	grid-gap: 12px;
	gap: 12px;
}

button.button-1T9xN {
	padding-right: 20px;
	padding-left: 20px;

	font: var(--normal-desktop-bold);
}

button.button-1T9xN.cancel-3VvBo {
	font: var(--normal-desktop);
}

/* stylelint-disable-next-line block-no-empty */
button.button_disabled-Yp6Cs {
}

@media screen and (max-width: 600px) {
	.header-3PAgz {
		padding: 50px 20px 20px;

		font: var(--medium-1-mobile-accent);
	}

	.content-2_Uvq {
		padding: 20px;

		border-radius: 0;
	}

	.controls-F9Hvv {
		width: 100%;
	}

	button.button-1T9xN {
		width: 100%;
	}
}
