

span.radioButton-lMIrD {
	color: var(--text-pale);
}

span.radioButton-lMIrD.checked-1Un8g {
	color: var(--brand-1-0);
}

span.radioButton-lMIrD.disabled-2yqKy {
	color: var(--gray20);
}
