.summary-1bqfY {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.summary-1bqfY {
		padding: 0 20px;
		height: 46px;
	}
}

.route-3BW69 {
	position: relative;

	width: 200px;
	height: 20px;

	background-color: rgba(255, 255, 255, 0.2);
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	.route-3BW69 {
		width: 100px;
	}
}

.trigger-2uylr {
	display: flex;
	align-items: center;
	height: 28px;
	margin-left: 15px;
	padding-left: 15px;

	border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.trigger__inner-1s3FY {
	position: relative;

	width: 120px;
	height: 20px;

	background-color: rgba(255, 255, 255, 0.2);
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	.trigger__inner-1s3FY {
		width: 40px;
	}
}

.route-3BW69:after,
.trigger__inner-1s3FY:after {
	position: absolute;
	top: 0;
	z-index: 1;

	width: 100%;
	height: 20px;

	transform: translateX(100%);
	opacity: 0.7;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.3) 50%,
		rgba(128, 186, 232, 0) 99%,
		rgba(125, 185, 232, 0) 100%
	);

	animation: slide-1eYtZ 1s infinite 0.5s;
	content: '';
}

@keyframes slide-1eYtZ {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
