

.disclaimer-266eU {
	padding: 12px;

	border: 1px solid var(--warning-icon);

	background: var(--white);
}

.disclaimer-266eU > span:first-of-type {
	display: none;
}

.disclaimer-266eU > p {
	margin: 3px 0;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

.disclaimer-266eU > p:first-of-type {
	margin-top: 0;

	font-weight: 700;
}

.disclaimer-266eU > p:last-of-type {
	margin-bottom: 0;
}
