.contacts-2mEsT {
	order: 1;
}

.contacts-2mEsT,
.contacts-2mEsT:hover {
	box-shadow: none;
}

.header-1fBSw {
	background: var(--white);
}

div.field__contact-kxPQY:before,
div.field__contact-kxPQY div:before {
	border-color: var(--text-light);

	background: none;
}

.description__header-38VY5:before {
	background-color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.footer__inner-3rh-7 {
		max-width: 66%;
	}

	.footer__text-2HztY,
	.footer__text-2HztY span {
		text-align: center;
	}
}

@media screen and (max-width: 375px) {
	.footer_login-2w-IG {
		padding: 12px;
	}

	.footer__inner-3rh-7 button {
		font-size: 14px;
	}
}
