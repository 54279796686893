.container-1RBka {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
	padding: 20px;
	margin-top: 56px;
}

.icon-2Ge3N {
	color: var(--base);
}

.icon-2Ge3N > svg {
	display: flex;
	width: 100px;
	height: 100px;
}

.description-3_TNv {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 20px;
	white-space: pre-line;
	text-align: center;
}

.buttons-25J6c {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.buttons-25J6c > button {
	font-size: 15px;
	font-weight: 500;
}

button.refuseBtn-1kVY1 {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.refuseBtn-1kVY1:hover,
button.refuseBtn-1kVY1:focus {
	background: var(--gray-line);
}

button.agreeBtn-2puQJ {
	background: var(--brand-3-0);
}

@media screen and (max-width: 600px) {
	.buttons-25J6c > button {
		padding: 12px 20px;
	}

	div.scrollBody-_383N {
		display: flex;
		justify-content: flex-end;
	}

	div.scrollBody-_383N div.paper-jks9g {
		width: 100%;
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
	}
}
