

.personalInfoSection--u95V {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
	width: 100%;
}

.input-IEl5g {
	width: 100%;
}
