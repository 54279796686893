.wrapper-1_ZE7 {
	display: flex;
	padding: 20px 20px 0;

	border-radius: 8px;

	background-color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	transition: box-shadow 0.2s;
}

.wrapper-1_ZE7:hover {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 3px 10px 29px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 600px) {
	.wrapper-1_ZE7 {
		flex-direction: column;
	}
}

.block-yPsCY {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	min-width: calc(50% - 10px);
}

.block-yPsCY:not(:last-of-type) {
	margin-right: 20px;
}

@media screen and (max-width: 600px) {
	.block-yPsCY:not(:last-of-type) {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

.block__body-360wm {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 600px) {
	.block-yPsCY:last-of-type .block__body-360wm {
		flex-direction: row;
	}

	.block__body__header-27KeE {
		max-width: 50%;
	}
}

.block__footer-2EnNC {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}

.block__title-2LAG8 {
	display: flex;
	align-items: center;
	margin: 0 0 8px;

	font-size: 18px;
	line-height: 23px;
}

.block__title_highlighted-16_2R {
	color: var(--baggage-block-title-highlighted);
}

.block__sub-14hnb {
	margin: 0;
	padding-right: 15px;

	font-size: 13px;
	line-height: 150%;
	color: var(--common-gray);
}

.block__sub_highlighted-3xf8g {
	color: var(--common-blue);

	cursor: pointer;
}

.bags-2BDqC {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	margin: 50px 0;
}

@media screen and (max-width: 600px) {
	.block-yPsCY:last-of-type .bags-2BDqC {
		margin: 20px 0;
	}
}

.bag-O5sOi {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.bag-O5sOi:not(:last-of-type) {
	margin-right: 40px;
}

.bag__count-2uiBQ {
	position: absolute;
	bottom: 0;

	font-size: 18px;
	font-weight: 600;
	line-height: 26px;

	transform: translateY(calc(100% + 9px));
}

@media screen and (max-width: 600px) {
	.block-yPsCY:last-of-type .bag__count-2uiBQ {
		position: static;

		margin-top: 8px;

		transform: none;
	}
}

.fareFamilyName-15mzi {
	display: inline-flex;
	padding: 0 8px;

	border-radius: 20px;

	color: #ffffff;
	line-height: 26px;
	font-size: 13px;
	font-weight: 500;

	background-color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.fareFamilyName-15mzi {
		margin-left: 8px;
	}
}

.price-2nGvx {
	display: flex;
	align-items: baseline;

	padding-right: 12px;
	margin-right: 8px;

	border-right: 1px solid #d1d1d1;

	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
	color: var(--common-blue);
}

.price_withIncluded-1UmP0 {
	color: #9a9a9a;
}

button.block__button-30d2S {
	font-weight: 500;
	font-size: 15px;
}
