

.container-GCDsX {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.button-1LcEc {
	min-height: 46px;

	padding: 0 24px;

	border-radius: 6px;

	color: var(--white);
	font-weight: 700;
	font-size: 16px;

	background: var(--brand-3-0);
}

button.button-1LcEc:hover {
	background: var(--brand-3--1);
}

button.button-1LcEc:not(:first-child) {
	margin-left: 8px;
}

button.button_delete-3M3CK {
	color: var(--error-text);

	background: var(--white);
}

button.button_delete-3M3CK:hover {
	color: var(--error-text);

	background: var(--error-bg);
}
