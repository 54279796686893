.mainWrapper-3sccr {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1501;
}

.wrapper-3J8UZ {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	opacity: 0.7;
	background: #333333;

	animation-name: wrapperOpacity-3wr1G;
	animation-duration: 0.2s;
	animation-timing-function: ease-in;
}

.wrapper_closed-3ornf .wrapper-3J8UZ {
	animation: wrapperOff-3ERAf 0.3s 1;
}

.container-3btIB {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;

	height: auto;
	max-height: calc(100% - 100px);
	padding: 20px;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	background: #ffffff;
}

.header-3IZmG {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	color: var(--gray70);
	font-weight: 700;
	font-size: 20px;
}

@media screen and (max-width: 320px) {
	.container-3btIB {
		max-height: calc(100% - 80px);
	}
}

.container_noPadding-2TemQ {
	padding: 0;
}

.container_scrollable-1VtOp {
	overflow-y: auto;
}

@keyframes wrapperOpacity-3wr1G {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.6;
	}
}

@keyframes wrapperOff-3ERAf {
	from {
		opacity: 0.6;
	}

	to {
		opacity: 0;
	}
}
