.card-3APBP {
	position: relative;

	display: grid;
	grid-template-columns: 1fr 20px;
	grid-gap: 12px;
	gap: 12px;
	align-items: center;
	padding: 24px;

	border: 1px solid var(--white);
	border-radius: 4px;

	color: var(--text-base);

	background-color: var(--white);
}

.card_unread-B8Uci {
	border-color: var(--brand-1-3);

	background-color: var(--brand-1-5);
}

.card__wrapper-1QLCG {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
}

.card__group-3L9PF {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 4px;
	gap: 4px;
}

.card__group_content-2lPl7 {
	grid-gap: 12px;
	gap: 12px;
}

.card__group_content-2lPl7 p {
	margin: 0;
}

.card__title-2nxib {
	font-weight: 700;
}

.card__info-3Zfbs {
	font-size: 14px;
	color: var(--text-light);
	line-height: 18px;
	text-align: left;
	font-weight: 400;
}

.card__info_right-1Bgcu {
	margin-left: auto;

	text-align: right;
}

.card__loading-34EE8 {
	position: absolute;
	top: 0;
	left: 0;

	justify-content: center;
	width: 100%;
	height: 100% !important;

	background: rgba(255, 255, 255, 0.4);
}

.card__dot-3Jasb {
	display: flex;
	min-height: 28px;
}

.card__dot-3Jasb span {
	margin: auto;
}

@media screen and (max-width: 600px) {
	.card-3APBP {
		padding: 16px;
		display: block;
	}
}
