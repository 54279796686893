

div.status-26uot {
	display: inline-flex;
	align-items: center;
	padding: 4px 7px 4px 4px;
	max-height: 30px;

	border-radius: 100px;

	color: #ffffff;
	font-size: 14px;
	line-height: 21px;
	font-weight: 700;
}

div.waiting-zujxD {
	background: var(--active-30);
}

div.rejected-1D7m6 {
	background: var(--error);
}

div.processing-uGgds {
	color: var(--gray80);

	background: var(--warning);
}

.icon-2PFf2 {
	display: flex;
	margin-right: 5px;

	color: #ffffff;
}

.processing-uGgds .icon-2PFf2 {
	color: inherit;
}
