.option-cYLPM {
	padding: 6px 16px;

	cursor: pointer;

	transition: background-color 0.2s;
}

.option-cYLPM:hover {
	background-color: #f6f6f6;
}

.aside-3f_9w {
	display: flex;
}

.info-2W6M6 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	line-height: 20px;
}

.label-D365g {
	color: var(--autocomplete-option-label);
	font-weight: 500;
	font-size: 16px;

	text-transform: capitalize;
	text-align: left;
}

.code-256Q5 {
	width: 40px;

	color: var(--autocomplete-option-code);
	font-weight: var(--autocomplete-option-code-weight);
	font-size: 12px;

	white-space: nowrap;
	text-align: right;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
}

.stops-39UIT {

	display: none;
	width: auto;

	color: var(--common-blue);

	color: var(--autocomplete-option-stops-color, var(--common-blue));
	text-transform: uppercase;
}

@media screen and (max-width: 600px) {
	.stops-39UIT {
		display: block;
	}
}

.meta-tEjXq {
	margin-top: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 12px;
	line-height: 16px;
	white-space: nowrap;
}

.meta_gray-22Xwm .meta__label-38ikO {
	color: var(--dark-gray);
}

.meta_gray-22Xwm .meta__value-3va4x {
	color: var(--light-gray);
}

.meta_probable-1Ot1A {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.option-cYLPM {
		padding: 6px 20px;
	}
}
