

.header-28hMX {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding: 0 16px 0 20px;

	color: #ffffff;

	color: var(--mobile-stepbar-header-color, #ffffff);
	font-weight: 600;
	font-size: 16px;

	cursor: pointer;
}

/* stylelint-disable */
.header_zeroPrice-3OfMN {
}
/* stylelint-enable */
.stepIndex-2oQpF {
	display: flex;
}

.stepIndex__label-1tEJ2 {
	margin-left: 12px;
}

.price-U5RWO {
	display: flex;
	align-items: center;

	color: inherit;

	color: var(--mobile-stepbar-price-color, inherit);
}

.price__wrapper-2jyVm {
	display: flex;
	align-items: center;
}

.price_title-eNk7H:after {
	content: ':';
}

.expand-2MOha {
	width: 24px;
	height: 24px;
	margin-left: 8px;

	border-radius: 50%;

	background: rgba(255, 255, 255, 0.3);

	transition: transform 0.3s;
}

.expand_open-3TWET {
	transform: rotate(-180deg);
}
