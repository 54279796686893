

.info-1aWQ3 {
	font-size: 14px;
}

.segments-yew25 {
	padding: 28px 20px;
}

.footer-3zhpx {
	padding: 20px;

	text-align: right;
}

/* stylelint-disable */
button.button_default-3-Ok1 {
}

button.button-3oF0R {
}

button.button-3oF0R:hover {
}
/* stylelint-enable */
