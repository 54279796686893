label.label-3B6J8 {
	color: var(--input-color);
	font-size: 16px;

	font-family: inherit;
}

label.label-3B6J8.label_focused--59Af {
	color: var(--passengers-input-bg);
}

div.select-2VAC_:before,
div.select-2VAC_:after {
	display: none;
}

div.input-257OE {
	align-items: flex-end;
	padding: 8px 12px;

	font-family: inherit;
}

div.input-257OE:not(.input_standard-1cK1c) {
	border: 1px solid var(--input-outline);
	border-radius: var(--input-border-radius);
}

div.input-257OE.error-3L1sQ {
	border-color: var(--input-error-border);

	background: var(--input-error-background-filled);
}

div.input-257OE.error-3L1sQ:before {
	background: var(--input-error-background-filled);
}

label.label-3B6J8 + div.input-257OE {
	margin-top: 0;
}

div.input-257OE.input_focused-3wgdZ {
	border-color: var(--input-outline-active);
}

div.input-257OE:before {
	content: '';

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	display: block;
	height: 100%;
}

div.real_input-3Ln3w {
	padding: 23px 0 0;
}

div.real_input-3Ln3w:focus {
	background: none;
}

.input_readonly-1NEEz {
	pointer-events: none;
}

.input_readonly-1NEEz svg {
	display: none;
}

p.helperText-2ML4v {
	position: absolute;
	bottom: 0;

	margin-top: 0;

	font-family: inherit;
	letter-spacing: normal;
	line-height: inherit;

	transform: translateY(140%);
}

li.option-3rSDF {
	font: var(--medium--1-desktop);
	font-family: inherit;
}

svg.icon-2L76F {
	top: calc(50% - 14px);
	right: 8px;
}

@media (max-width: 1024px) {
	label.label-3B6J8 {
		max-width: 100%;
		padding-right: 20px;
		overflow: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;
	}

	div.input-257OE.error-3L1sQ:before {
		background: var(--bg-white);
	}

	li.option-3rSDF {
		font: var(--medium-mobile);
		color: var(--text-mid);
	}
}

@media screen and (max-width: 600px) {
	div.input-257OE.error-3L1sQ {
		border-color: transparent;

		background: none;
	}
}
