.wrapper-1ScQ- {
	display: flex;
	width: 100%;
	margin: 84px auto;
	max-width: 1100px;

	color: inherit;
}

.homeUserCard-W0iXy {
	margin-bottom: 12px;

	border-radius: 8px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	background: #ffffff;
}

.link-3UcxV {
	color: var(--warnings-text-color);
}

div.card-3u5ZB {
	box-sizing: border-box;
}

div.warning-3dngL {
	margin-top: 0;
}

.loginWrapper-3gPwb {
	margin: 100px auto;
	max-width: 660px;
	width: 100%;
	overflow: hidden;

	border-radius: var(--login-wrapper-border-radius);

	background-color: #ffffff;

	box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.05);
}

.active__route-1DN5g {
	margin-left: 40px;
	flex-grow: 1;
	overflow: hidden;
}

.route__title-1xZBj {
	margin: 0 0 24px;

	font-weight: 700;
	font-size: 24px;
	color: var(--text-base);
}

.container__title-2FT1l {
	margin: 40px 0 24px;

	font-weight: 700;
	font-size: 24px;
	color: var(--text-base);
}

.container__content-1tIOQ {
	padding: 40px;

	border-radius: 9px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	background: #ffffff;
}

.footer-2pMsp {
	margin-top: 40px;
}

/* stylelint-disable */
.orders__wrapper-FQZYR {
}

.profileControls-XSvSh {
}

.container_authMethods-1qrDJ {
}

.container_mailSettings-2P_rS {
}
/* stylelint-enable */
.tabs__flexContainer-3eNp0 {
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.tabs__flexContainer-3eNp0 .tab-3vW7k {
	width: -moz-fit-content;
	width: fit-content;
	min-width: 0;
	min-width: initial;
	min-height: 30px;

	padding: 0;

	white-space: nowrap;
}

.tabs__flexContainer-3eNp0 .tabLabel-3Z6fs {
	color: var(--text-light);

	text-transform: none;
}

.tabs__flexContainer-3eNp0 .tabSelected-VKp-s .tabLabel-3Z6fs {
	color: var(--brand-1-0);
}

.orders__header-1AtuC .tabs-a_d0r {
	margin-top: 0;

	border-bottom: none;
}

.tabs-a_d0r .tab-3vW7k {
	margin: 0;
}

.tabs-a_d0r .tabs__container-To5LY .tabIndicator-2jhwD {
	display: inline;
	display: initial;

	height: 3px;

	background-color: var(--brand-1-0);
}

.tabs-a_d0r .tabs__container-To5LY {
	min-height: 30px;
}

.noFlightsTitle-3ovWP {
	margin-bottom: 24px;

	font-size: 20px;
}

nav.pagination-1EgSZ {
	margin-bottom: 0;
	margin-top: 20px;
}

@media (max-width: 1024px) {
	.active__route-1DN5g {
		position: relative;
		top: -8px;
		z-index: 1;

		margin-left: 0;
		max-width: 100%;
		padding: 16px 20px 0;

		border-radius: 8px 8px 0 0;

		overflow: hidden;

		background: #ededed;
	}

	.wrapper-1ScQ- {
		flex-direction: column;
		margin-top: 0;
	}

	.container-1r8D2 {
		margin-top: 16px;

		border-radius: 9px;

		box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
		background: #ffffff;
	}

	.container__title-2FT1l {
		margin: 0;
		padding: 16px 20px;

		font-size: 18px;
	}

	.container__content-1tIOQ {
		padding: 0;

		box-shadow: none;
	}

	.route__title-1xZBj {
		display: none;
	}

	.tabs-a_d0r {
		width: 100%;
	}

	.tabs__flexContainer-3eNp0 .tabLabel-3Z6fs {
		padding-bottom: 8px;
	}

	.noFlightsTitle-3ovWP {
		margin-bottom: 20px;

		font-size: 16px;
	}
}

.orderStub-1XeKo {
	margin-bottom: 20px;
}

.orders__header-1AtuC {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 20px;
}

.orders__title-zDnP- {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	.account__fullscreen-1eJlK {
		padding: 0;
	}

	.route__title-1xZBj.orders__title-zDnP- {
		font-size: 18px;
	}

	.loginWrapper-3gPwb {
		height: 100vh;
		padding: 47px 28px;
		margin: 0;

		border-radius: 0;
	}
}

@media screen and (max-width: 380px) {
	.container__title-2FT1l {
		padding: 16px;
	}
}
