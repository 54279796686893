.promoCode-2b03T {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.promoCode-2b03T {
		margin-left: 0;
	}

	.promoCode-2b03T[class*='applied'] {
		padding-left: 28px;
	}
}
