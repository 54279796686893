

.selectedFare-34-au {
	display: flex;
}

.image-B2b1K {
	width: 280px;

	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.content-5WKg2 {
	flex: 1;
	padding: 17px 40px 0;

	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;

	background: var(--white);
}

@media screen and (max-width: 1300px) {
	.content-5WKg2 {
		padding: 20px 40px;
	}
}

.title-3fce4 {
	display: flex;
	justify-content: space-between;
	margin: 0 0 16px;
	padding: 0;

	color: var(--text-base);
	font-weight: 600;
	font-size: 23px;
	line-height: 23px;
}

@media screen and (max-width: 1300px) {
	.title-3fce4 {
		margin-bottom: 10px;
	}
}

a.link-2da8D {
	display: inline-flex;
	align-items: center;

	color: var(--brand-1-0);
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

.features__list-3aUr3 {
	display: grid;
	grid-template: repeat(auto-fill, 30px) / repeat(auto-fill, minmax(230px, 1fr));
	grid-column-gap: 50px;
	margin: 0 0 16px;
	padding: 0;

	color: var(--text-mid);
	font-size: 14px;

	list-style-type: none;
}

.controls-3mn66 {
	margin-left: auto;
}

.controls-3mn66 .link-2da8D:nth-of-type(2) {
	margin-left: 16px;
	padding-left: 16px;

	border-left: 1px solid var(--gray20);
}

div.fareGroup-2OTxX {
	min-height: auto;
	max-width: 420px;
	margin: 0 auto;
	width: 100%;
}

div.fareGroup-2OTxX button {
	display: none;
}

.features__item-1EqSI {
	display: flex;
	align-items: center;
	padding: 8px 0;

	border-bottom: 1px solid var(--line-separator);
}

.link__chevron-2Xbm_ {
	width: 24px;
	height: 24px;

	color: var(--gray30);
}

.serviceIcon-142tS {
	position: relative;
	top: 1px;

	margin-right: 17px;
}

.icon-34TBR {
	position: relative;
	top: 2px;

	margin-left: auto;
}

.serviceIcon-142tS,
.icon-34TBR {
	color: var(--text-light);
}

.icon-34TBR .icon_included__check {
	color: var(--success-icon);
}

div.popup__close-1tvwD {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 3;

	background-color: var(--bg-fill);
}

div.popup__paperScrollBody-1TDvs {
	position: relative;
}
