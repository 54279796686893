

button.button-2CMTV {
	margin-left: 8px;

	border-color: transparent;
}

.button_loading-1D_Rl {
	margin-top: 7px;
	margin-bottom: 7px;
}

.root-190zo {
	color: #b3b3b3;

	background: var(--warningBG);
}

/* stylelint-disable */
.button_checkin-FA_rG {
}
