@media screen and (max-width: 600px) {
	div.scrollBody-2j8Qf {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.scrollBody-2j8Qf div.paper-2VanC {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
