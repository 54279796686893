.container-iCohc {
	position: relative;

	padding: 16px 0 20px;

	background: var(--brand-1-0);
}

.wrapper-3QMe9 {
	max-width: 1100px;
	margin: 0 auto;
}

.title-TToSe {
	margin-bottom: 20px;

	color: var(--white);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.form-20N_u {
	display: flex;
}

.locations-1BuRw {
	position: relative;

	flex: 1;
	display: flex;
	align-items: center;
}

.location-Wf9m7 {
	border-right: 1px solid var(--line-separator);
}

.location-Wf9m7.from-GIahg .input-1ufeL {
	border-radius: 4px 0 0 4px;
}

button.button-3ncl9 {
	min-width: 64px;
	min-height: 48px;

	border-radius: 0 4px 4px 0;

	background: var(--brand-3-0);
}

button.button-3ncl9:hover {
	background: var(--brand-3--1);
}

button.buttonSwitch-1UJt8 {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	display: none;
	width: 32px;
	min-width: 32px;
	height: 32px;
	min-height: 32px;
	padding: 6px;

	border-radius: 50%;

	transform: translate(-50%, -50%);
	background: var(--brand-1-0);
}

button.buttonSwitch-1UJt8 svg {
	transform: rotate(90deg);
}

@media (max-width: 1024px) {
	.container-iCohc {
		padding: 20px 20px 40px;
	}
}

@media screen and (max-width: 600px) {
	.container-iCohc {
		padding: 16px 20px 40px;
	}

	.title-TToSe {
		margin-bottom: 14px;

		font-size: 18px;
	}

	.form-20N_u {
		flex-direction: column;
	}

	.location-Wf9m7 {
		flex: 0 1 50%;

		border-right: none;
	}

	.location-Wf9m7.from-GIahg {
		margin-right: 4px;
	}

	.location-Wf9m7.from-GIahg .input-1ufeL {
		padding-right: 24px;

		border-radius: 4px 0 0 0;
	}

	.location-Wf9m7.to-ttmOq .input-1ufeL {
		padding-left: 24px;

		border-radius: 0 4px 0 0;
	}

	button.button-3ncl9 {
		width: 100%;
		min-height: 44px;
		margin-top: 4px;

		border-radius: 0 0 4px 4px;
	}

	button.buttonSwitch-1UJt8 {
		display: flex;
	}
}
