div.paper-2F3Si {
	max-width: max-content;
}

div.modal-18_i0 {
	padding: 40px;
}

@media screen and (max-width: 600px) {
	div.modal-18_i0 {
		padding: 20px;
	}
}
