.container-2kj7w {
	padding: 20px;

	background-color: var(--white);
}

.wrapper-1vUnG {
	display: flex;
	align-items: center;
	max-width: 1100px;
	margin: 0 auto;
}

.arrow-1LYIh {
	display: none;
}

.title-2MOiP {
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--gray80);
}

.close-2uiOJ {
	display: flex;
}

@media screen and (max-width: 600px) {
	.arrow-1LYIh {
		display: flex;
		margin-right: 16px;

		color: var(--gray50);

		transform: rotate(-180deg);
	}

	.title-2MOiP {
		font-size: 18px;
	}
}
