.multiCity-3L1ea {
	position: relative;
}

.label-kH_Xs {
	display: flex;

	cursor: pointer;
}

.caption-aA5jW {
	font-size: 14px;
	color: #ffffff;

	opacity: 0.7;
}

.input-3M8M3 {
	display: none;
}

@media screen and (max-width: 600px) {
	.multiCity-3L1ea {
		line-height: 28px;
	}
}
