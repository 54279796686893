.searchForm__searchForm-6iln3 {
	background: var(--brand-1--2);
}

.value__value-1ftrP {
	color: var(--text-base);
}

.value__code-VjuRk {
	color: var(--text-light);
}

.datepicker__dateInputWrapper-2VFyD {
	font-size: 16px;
}

.passengers__selector-3FN3b {
	font-size: 16px;
}

.startSearch__startSearch-20M5y button,
.startSearch__startSearch-20M5y button:hover {
	background-color: var(--brand-3-0);
}

.multiCity__caption-2b6JX {
	font-weight: 500;
	color: var(--brand-1-3);

	opacity: 1;
}

.datepicker__datesToFocused-2GPGT,
.datepicker__datesBackFocused-3YAtB {
	color: var(--text-base);

	box-shadow: 0 -3px 0 0 #ff8a00 inset;
}

.datepicker__highlightedDateWithPrice-fTn9- {
	background: var(--brand-1-5);
}

.datepicker__highlightedMinPrice-44KOu {
	background: var(--brand-3-5);
}

span.datepicker__dayPrice-3HN64 {
	font-size: 11px;
}

.datepicker__highlightedMinPrice-44KOu .datepicker__dayPrice-3HN64 {
	color: var(--brand-3-0);
}

@media screen and (max-width: 600px) {
	.value__wrapperAlternative-1P0p6 .value__value-1ftrP {
		color: var(--text-mid);
		font-size: 13px;
		font-weight: 400;
	}

	.value__wrapperAlternative-1P0p6 .value__code-VjuRk {
		color: var(--text-base);
		font-size: 20px;
		font-weight: 700;
	}

	.datepicker__dow-1Y8-I {
		opacity: 1;
	}

	.datepicker__alternative-3k-if .datepicker__dow-1Y8-I {
		display: inline;
	}

	.datepicker__alternativeDow-33lsr {
		display: none;
	}

	.segment__withMultiCity-1JINg .datepicker__alternativeDow-33lsr {
		display: block;

		font-size: 13px;
	}

	.datepicker__alternative-3k-if .datepicker__dates-3wTra {
		font-size: 16px;
		font-weight: 500;
	}

	.datepicker__datesTo-3kGhC,
	.datepicker__datesBack-3GP9I {
		color: var(--text-base);
	}

	.multiCity__caption-2b6JX {
		font-size: 16px;
		font-weight: 400;
		color: var(--brand-1-5);
	}

	button.actions__addSegment-Niu9r,
	button.actions__addSegment-Niu9r:not(:disabled):hover,
	button.actions__removeSegment-2IwmR,
	button.actions__removeSegment-2IwmR:not(:disabled):hover {
		color: var(--text-pale);

		background-color: var(--white) !important;
	}

	.segment__alternative--1qyJ .segment__withMultiCity-1JINg .segment__cellLocation-Yh5AI,
	.segment__alternative--1qyJ .segment__withMultiCity-1JINg .segment__cellLocation-Yh5AI .segment__input-H_rN8 {
		height: 61px;

		line-height: 61px;
	}

	.datepicker__alternative-3k-if.datepicker__dates-3wTra {
		height: 61px;
	}

	.segment__alternative--1qyJ .segment__cellDate-1Bx7_ {
		height: 61px;
	}

	.searchForm__searchForm-6iln3 {
		background: var(--brand-1--2);
	}

	.location__switcher-3oy6x.location__switcherAlternative-3XyI8 {
		background: var(--brand-1-0);
	}

	.passengers__mobileTitle-1S1mA {
		border-bottom: 0;

		font-size: 22px;
		font-weight: 700;
		color: var(--text-base);
	}

	.segment__alternative--1qyJ .segment__withMultiCity-1JINg {
		grid-gap: 1px;
		gap: 1px;
	}

	.datepicker__alternative-3k-if .datepicker__datesTo-3kGhC {
		font-size: 16px;
	}

	.value__withMultiCity-11Px5.value__wrapperAlternative-1P0p6 .value__code-VjuRk {
		font-size: 18px;
	}

	.segment__withMultiCity-1JINg .location__switcher-3oy6x.location__switcherAlternative-3XyI8 {
		right: 3px;
	}
}
