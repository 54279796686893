.flight-tdZM5 {
	min-height: 84px;

	border-radius: 12px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	cursor: auto;
}

.flight-tdZM5:not(:last-child) {
	margin-bottom: 12px;
}

.flightSegments-vm6eg {
	flex: 0 1 600px;
}

.segmentInfo-2_EkN {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 22%;
	height: 100%;
	padding-right: 20px;

	border-right: 1px dashed var(--line-dot-line);
}

.segmentInfo__flightNumber-36RHk {
	margin-bottom: 2px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 14px;
	line-height: 1.3;
}

.segmentInfo__operating-VNkWo {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

div.segmentInfo__segment-2qxL- {
	padding-left: 20px;
}

div.segment__planeContainer-1vdiG {
	flex: 1;
	max-width: 25%;
	align-items: flex-start;
	justify-content: center;

	border-right: 1px dashed var(--line-dot-line);
}

.segment__routeInfo-1KB3L {
	min-width: 50%;
	padding: 12px 20px;

	border-right: 1px dashed var(--line-dot-line);
}

.totalDuration-3nUlU {
	color: var(--error-text);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;

	transform: translateY(100%);
}

.totalDuration__decorator-3B2P6 {
	border-right: 1px dashed var(--line-dot-line);
}

.routeArrow__container-32shc {
	min-width: 100px;
	max-width: 120px;
	justify-content: center;
}

.routeArrow__flightNumber-cErwg:not(.routeArrow__stop-2GvMQ) {
	display: none;
}

.routeArrow__route-1mwHp:before {
	top: 50%;
	bottom: unset;

	transform: translateY(-50%);
}

.routeArrow__circle-3o5sG {
	top: 0;
}

.routeArrow__timeInRoute-3vZlS {
	top: 0;

	font-size: 14px;
	font-weight: 500;
	line-height: 1.2;
}

.point__time-xhHMq {
	margin: 0;

	font-size: 24px;
	line-height: 1.3;
}

.point__dateContainer-ped4k {
	justify-content: center;
}

.stop__label-2PKMz {
	color: var(--error-text);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.planeInfo-1QJp3 {
	display: flex;
	flex-direction: column;
	padding: 0 12px;
}

.aircrafts-36l5Z {
	margin-bottom: 2px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.2;
}

.amenities-21Mmo {
	margin-left: 0;
}

.mobileSegment__aircraft-Mfvxo {
	display: none;
}

.mobileFlight__flight-1Sp8i:not(.mobileFlight__readonly-1teHd, .mobileFlight__expanded-1cdOi) .mobileFlight__segments-2Tqu5 {
	border-bottom: none;
}

.mobileFlight__flight-1Sp8i.mobileFlight__expanded-1cdOi .mobileFlight__segments-2Tqu5 {
	margin-bottom: 16px;
}

.mobileFlight__expand-2B54t {
	position: relative;

	margin-bottom: -14px;

	transform: none;
}

.companyName-1Wp0j {
	margin-bottom: 4px;

	color: var(--text-light);
	font-size: 13px;
	line-height: 1.2;
	text-align: center;
}

@media (max-width: 1024px) {
	.flight-tdZM5 {
		flex-direction: column;
	}

	.flightSegments-vm6eg {
		flex: 1;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.segment__routeInfo-1KB3L {
		border-right: none;
	}

	div.stop-1N3g5,
	div.totalDuration-3nUlU {
		bottom: -6px;
	}

	.point__date-boa84 {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.routeArrow__container-32shc {
		justify-content: space-between;
	}
}
