@media screen and (max-width: 600px) {
	.wrapper-D6R31 {
		padding: 0 12px 12px 12px;

		border-radius: 12px 12px 0 0;

		background: var(--bg-fill);
	}

	.title-hFrbN {
		padding: 16px 12px;
		justify-content: center;
		align-items: center;

		color: var(--text-base);
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		line-height: 110%;
	}

	.service-3xk20 {
		padding-bottom: 0;
	}
}
