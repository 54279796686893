

div.wrapper-22BWy {
	background: transparent;
}

.files-2Uxj3 .file-16pmV:not(:last-of-type) {
	padding-bottom: 20px;
	margin-bottom: 20px;

	border-bottom: 1px solid var(--gray20);
}

p.info-nFDT8 {
	margin-top: 20px;

	font-size: 13px;
	line-height: 17px;
	color: var(--gray50);
	text-align: center;
}

p.hasErrors-3rIKD {
	color: var(--error);
	font-weight: 500;
}
