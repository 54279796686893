

div.modal_paper-2hjX5 {
	display: block;
	width: max-content;
}

.root-sFMBr {
	width: 830px;
	padding: 43px 40px 40px;
}

.header-32E9A {
	font-weight: 300;
	font-size: 36px;
	line-height: 42px;
	color: var(--common-gray);
}

.table-3Paji {
	margin-top: 32px;
}

.row-2XlWZ {
	display: flex;
	align-items: center;

	border-bottom: 1px solid var(--gray10);
}

.time-kJyZd {
	width: 200px;
	flex-shrink: 0;
	padding: 20px 12px 15px;

	white-space: pre-line;
	font-weight: 700;
	font-size: 15px;
	line-height: 130%;
	color: var(--gray90);
}

.action-2ck4j {
	flex-grow: 1;
	padding: 20px 12px 15px;

	white-space: pre-line;
	font-size: 14px;
	line-height: 130%;
	color: var(--dark-gray);
}

.row-2XlWZ.table_header-ypBlv {
	border-bottom: none;
}

.table_header-ypBlv .time-kJyZd,
.table_header-ypBlv .action-2ck4j {
	padding: 15px 12px 12px;

	font-weight: 700;
	font-size: 15px;
	line-height: 130%;
	color: var(--white);

	background: var(--base);
}
