.day-34ho_ {

	font-size: 12px;
}

.dayOfWeekName-2R3M0 {
	cursor: default;
}

.dayOfWeekName-2R3M0 span,
.dayOfWeekName_withPrices-34Aw- span {
	border-bottom: 0;

	color: var(--light-gray);
	font-weight: 400;
}

.dayOfWeekName-2R3M0:nth-child(6n) span,
.dayOfWeekName-2R3M0:nth-child(7n) span,
.dayOfWeekName_withPrices-34Aw-:nth-child(6n) span,
.dayOfWeekName_withPrices-34Aw-:nth-child(7n) span {
	font-weight: 400;
}

.dayOfWeekName_withPrices-34Aw- {
	width: 44px;
	height: 44px;

	cursor: default;
}
