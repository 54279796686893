.container-2U3Dl {
	max-width: 1100px;
	margin: 12px auto;
}

.buttons-DC8_y {
	max-width: 1100px;
	margin: 0 auto;
	width: 100%;
}

button.next-JtbOa {
	justify-content: flex-start;
	width: 100%;
	margin: 16px 0;
	padding: 12px 60px 12px 12px;

	border-radius: 4px;

	color: var(--text-link);
	font-weight: 400;
	font-size: 16px;
	text-align: left;

	background: var(--brand-1-5);
}

button.next-JtbOa svg {
	position: absolute;
	right: 12px;
}

.seatsService-3e1Wq span:not(:last-child):after {
	content: ', ';
}

div.hideServiceCard-cUpDZ {
	display: none;
}

div.paper-2tFFJ {
	max-width: 728px;
}

.aggregatorService__container-2sDdN .serviceSummaryDivider-2y_54:last-of-type:after {
	display: none;
}

@media (max-width: 1024px) {
	.aggregatorService__container-2sDdN {
		margin-top: 0;
	}
}

@media screen and (max-width: 600px) {
	.buttons-DC8_y {
		padding: 0 12px;
	}

	.wrapper-1y0zZ {
		background: #ffffff;
	}
}
