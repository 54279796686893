

div.paper-1ogu9 {
	background: var(--cart-background);
}

.additional-3vil5 {
	display: flex;
	align-items: center;

	height: 100%;

	font: var(--medium-desktop-accent);
	color: var(--white);
}
