.wrapper-tj42B {
	display: flex;
	flex-grow: 1;

	border-right: 1px solid var(--line-separator);
}

.withMultiCity-3wsnL {
	position: relative;

	border-right: none;
}

.withMultiCity-3wsnL .datepicker_value-3dih_ {
	border-radius: 0 4px 4px 0;
}

@media (max-width: 1024px) {
	.wrapper-tj42B {
		flex-wrap: wrap;

		border-right: none;
	}

	.withMultiCity-3wsnL .datepicker_value-3dih_ {
		border-radius: 4px;
	}
}

.segment-hFqtx {
	display: flex;
}

.segment_advanced-1Tu3a {
	position: relative;
}

.segment-hFqtx:not(:first-of-type) {
	margin-top: 4px;
}

@media screen and (max-width: 600px) {
	.segment-hFqtx {
		display: block;
	}

	.segment-hFqtx:not(:first-of-type) {
		margin-top: 24px;
	}

	.withMultiCity-3wsnL .datepicker_value-3dih_ {
		border-radius: 0 4px 4px 0;
	}
}

.cell-iwglb {
	display: flex;
	flex-grow: 1;
	flex-basis: 226px;
}

.input-1wrIg {
	width: 100%;
	height: var(--input-height);

	padding: 0 16px;

	border: none;
	border-radius: 0;

	color: var(--text-base);
	font-weight: 500;

	font-size: 15px;
	line-height: var(--input-height);
	text-align: left;

	background-color: var(--white);

	transition: background-color 0.15s, color 0.15s;

	appearance: none;
}

.input-1wrIg:-ms-input-placeholder {
	color: var(--text-light);
}

.input-1wrIg::placeholder {
	color: var(--text-light);
}

.input_focused-3NlUn,
.input-1wrIg:focus {
	color: var(--dark-gray);

	background-color: #ffffff;
}

.cell-iwglb:first-child .input-1wrIg,
.cell-iwglb:first-child .location__value-X_EjQ {
	border-radius: 4px 0 0 4px;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.cell-iwglb {
		flex-basis: 50%;
	}

	.cell-iwglb:nth-child(2) .input-1wrIg,
	.cell-iwglb:nth-child(2) .location__value-X_EjQ {
		border-radius: 0 4px 4px 0;
	}

	.wrapper-tj42B:not(.withMultiCity-3wsnL) .cell-iwglb:last-child {
		border-right: 1px solid var(--line-separator);
	}
}

.cell_location-2RvhJ {
	flex-grow: 2.5;

	border-right: 1px solid var(--line-separator);
}

.cell_location_arrival-1bMPd {
	display: flex;
}

.cell_date-3b7Yk {
	flex-grow: 3;
	flex-basis: 272px;
}

@media (max-width: 1024px) {
	.cell_date-3b7Yk {
		flex-grow: 0;
		flex-basis: 50%;
		height: 48px;
		margin-top: 4px;
	}

	.cell_location-2RvhJ:nth-child(2) {
		border-right: none;
	}

	.withMultiCity-3wsnL .cell_date-3b7Yk {
		flex-basis: 100%;
	}

	div.control-2wbPA:hover {
		background: var(--brand-1-1);
	}
}

@media screen and (max-width: 600px) {
	.cell_date-3b7Yk {
		flex-grow: 1;
		flex-basis: 100%;
		margin-top: 0;
		padding-right: 0;

		border-right: none;
	}

	.cell_location-2RvhJ {
		border-right: none;
	}

	.cell-iwglb:first-child .input-1wrIg,
	.cell-iwglb:first-child .location__value-X_EjQ {
		border-radius: 4px 0 0 0;
	}

	.cell-iwglb:nth-child(2) .input-1wrIg {
		border-radius: 0 4px 0 0;
	}

	.withMultiCity-3wsnL .cell-iwglb:first-child .input-1wrIg,
	.withMultiCity-3wsnL .cell-iwglb:first-child .location__value-X_EjQ {
		border-radius: 4px 0 0 4px;
	}

	.withMultiCity-3wsnL .cell-iwglb:nth-child(2) .input-1wrIg {
		border-radius: 0;
	}
}

.cell_fixed-2YHzH {
	flex-basis: 230px;
	flex-grow: 0;
}

@media screen and (max-width: 600px) {
	.cell-iwglb:not(:last-of-type) {
		flex-basis: 100%;
		margin-right: 0;
		margin-bottom: 4px;
	}
}

@media screen and (max-width: 600px) {
	.input_text-2WgUN {
		line-height: normal;
	}

	.input_focused-3NlUn,
	.input-1wrIg:focus {
		color: #ffffff;

		background-color: rgba(255, 255, 255, 0.2);
	}
}

.input_focused-3NlUn:-ms-input-placeholder, .input-1wrIg:focus:-ms-input-placeholder {
	color: var(--dark-gray);
}

.input_focused-3NlUn::placeholder,
.input-1wrIg:focus::placeholder {
	color: var(--dark-gray);
}

.departureInputWrapper-2Jl1I {
	position: relative;

	width: 100%;
}

.control-2wbPA {
	position: absolute;
	right: -43px;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 36px;
	height: 36px;
	margin-top: 5px;

	border-radius: 100px;

	color: #ffffff;

	cursor: pointer;
	background: var(--brand-1-1);

	transition: opacity 0.15s;
}

.control-2wbPA:hover {
	background: var(--brand-1-2);
}

.control-2wbPA svg {
	width: 20px;
	height: 20px;
}

@media screen and (max-width: 600px) {
	.input_text-2WgUN {
		line-height: normal;
	}

	.alternative-1Scjn .cell_location-2RvhJ {
		flex-basis: 50%;
		flex-grow: 2;
	}

	.alternative-1Scjn .cell_location-2RvhJ:first-of-type {
		padding-right: 2px;
	}

	.alternative-1Scjn div.cell_location-2RvhJ:nth-child(2) {
		padding-left: 2px;
	}

	.alternative-1Scjn .cell_location-2RvhJ .input-1wrIg {
		height: 72px;

		line-height: 72px;
		text-align: center;
	}

	.alternative-1Scjn .cell_date-3b7Yk {
		height: 72px;
	}

	.alternative-1Scjn .withMultiCity-3wsnL {
		flex-wrap: nowrap;
	}

	.alternative-1Scjn.segment-hFqtx:not(:first-of-type) {
		margin-top: 0;
	}

	.alternative-1Scjn .withMultiCity-3wsnL .actions-1s6E2 {
		margin-top: 0;
	}

	.alternative-1Scjn .withMultiCity-3wsnL .cell_location-2RvhJ {
		flex-basis: 100%;
		height: 52px;
	}

	.alternative-1Scjn .withMultiCity-3wsnL .cell_location-2RvhJ:first-of-type {
		padding-right: 0;
	}

	.alternative-1Scjn .withMultiCity-3wsnL .cell_location-2RvhJ:nth-child(2) {
		padding-left: 0;
	}

	.alternative-1Scjn .withMultiCity-3wsnL .cell_location-2RvhJ .input-1wrIg {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 52px;

		line-height: 52px;
	}

	.alternative-1Scjn .withMultiCity-3wsnL .cell_date-3b7Yk {
		height: 52px;
	}

	.alternative-1Scjn .withMultiCity-3wsnL .input-1wrIg .input_text-2WgUN {
		display: none;
	}
}
