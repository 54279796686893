.list-29cfZ {
	padding: 0;
	margin: 0;
	max-width: 240px;
	min-width: 240px;
}

.item-2FI2n {
	list-style-type: none;
}

.item_FNN-3KykZ {
	display: none;
}

.item-2FI2n:before {
	display: none;
}

.item-2FI2n:not(:first-of-type) {
	padding-top: 16px;
}

.item-2FI2n:not(:last-of-type) {
	padding-bottom: 16px;

	border-bottom: 1px solid #d9d9d9;
}

.withGroupsWrapper-18uAx {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
}

.withGroupsDelimiter-1wUy5 {
	width: 0;
	min-height: 100%;

	border-right: 1px dashed var(--line-separator);
}

.groupsWrapper-2vI7-,
.passengersWrapper-2hHhZ {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.groupsTitle-3Cf7r,
.passengersTitle-1nIl- {
	color: var(--brand-1-0);
	font: var(--small-desktop-bold);
	text-transform: uppercase;
}

.fareGroups-7zDg8 {
	display: flex;
	flex-direction: column;
	grid-gap: 0;
	gap: 0;
	align-items: flex-start;
}

button.fareGroup-23T4D {
	padding: 12px 16px;

	font: var(--medium--1-desktop-accent);

	color: var(--text-base);
	white-space: nowrap;
}

button.currentGroup-27zpb,
button.currentGroup-27zpb:hover {
	padding: 12px 16px;

	background-color: var(--brand-1-5);
}

.slider-3FG6Y {
	max-width: 100%;
}

@media screen and (max-width: 600px) {
	.withGroupsWrapper-18uAx {
		display: flex;
		flex-direction: column;
		grid-gap: 16px;
		gap: 16px;
	}

	.fareGroups-7zDg8 {
		position: absolute;
		left: 0;
		right: 0;

		margin-top: 30px;
	}

	.withGroupsDelimiter-1wUy5 {
		margin-top: 40px;
	}

	button.fareGroup-23T4D {
		border-radius: 40px;

		background: var(--bg-fill);
	}

	button.currentGroup-27zpb,
	button.currentGroup-27zpb:hover {
		color: var(--bg-white);

		background-color: var(--brand-1-0);
	}

	div:has(> button.fareGroup-23T4D) {
		padding: 0 8px 0 8px;

		transform: translateX(20px);
	}

	div:has(> button.fareGroup-23T4D):first-child {
		padding: 0 8px 0 0;
	}

	.list-29cfZ {
		max-width: unset;
		min-width: unset;
	}
}
