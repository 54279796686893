

.root-2T4QA .labelIn-2s4u5:not(.withoutLabel-B8Cjx) .outlined-1v6fZ .input-1HC2l {
	padding-top: 15px;
	padding-bottom: 0;
}

.root-2T4QA .labelIn-2s4u5:not(.withoutLabel-B8Cjx) .outlined-1v6fZ {
	height: 56px;
}

div.paper-38VRX {
	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
}

ul.listbox-2WS_M .option-3KtAl {
	padding-top: 10px;
	padding-bottom: 10px;
}

ul.listbox-2WS_M .option-3KtAl:not(:last-of-type) {
	border-bottom: 1px solid var(--bg-elements);
}

div.endAdornment-260Vm {
	top: 50%;

	transform: translate(0, -50%);
}

button.popupIndicator-3-y1I {
	padding: 2px;
}
