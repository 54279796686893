
/* stylelint-disable */
.container-2iX8A {
}

.seat__container-Ersul {
}

.seat-3Venw {
}

/* stylelint-enable */
