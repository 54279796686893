.form-28NCF {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 446px;
}

@media screen and (max-width: 600px) {
	.form-28NCF {
		min-width: auto;
	}
}

div.email-1PyMf {
	margin-bottom: 18px;
}

.phone__input-3Knva .MuiInput-formControl:hover:before {
	border-bottom-color: var(--base) !important;
}

.email__input-1ijF8:hover:before {
	border-bottom-color: var(--base) !important;
}

div.button-kz0AO,
button.button-kz0AO {
	max-width: 120px;
	margin-left: auto;
	margin-top: 28px;
}
