.meals-Epb9P {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 28px;
	flex-grow: 1;

	margin-top: 28px;
}

.meals_singleMeal-2rTZu {
	grid-template-columns: 1fr;
	justify-content: center;
}

.infoMessage-s2uGW {
	margin-top: 28px;
}

div.infoMessage__htmlMessage-29CJp {
	width: 100%;
	height: 52px;
}

.segmentTabs-1p-Bj {
	margin-right: 10%;
}

/* stylelint-disable */
.segmentTabs_meal-2V0td {
}

.segmentTabs_segments-1dgPv {
}

/* stylelint-enable */
.meals_purchased-3ge1r {
	padding: 20px;
	margin-top: 40px;

	border-radius: var(--baggage-additional-wrapped-border-radius);

	background: #ffffff;
	box-shadow: var(--meal-box-shadow);
}
@media (max-width: 1024px) {
	.meals-Epb9P:not(.meals_singleMeal-2rTZu) {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 600px) {
	.meals-Epb9P:not(.meals_singleMeal-2rTZu),
	.meals_singleMeal-2rTZu {
		grid-template-columns: 1fr;
		grid-gap: 17px;

		margin-top: 20px;
	}
}
