.group-1VDRp:not(:last-of-type) {
	padding-bottom: 8px;
	margin-bottom: 8px;

	border-bottom: 1px solid #d9d9d9;
}

.group__label-E0MOT {
	padding: 0 16px;
	padding: var(--autocomplete-group-label-padding, 0 16px);
	display: block;

	color: var(--autocomplete-group-label);
	font-weight: 500;
	font-size: 12px;
	font-size: var(--autocomplete-group-label-fz, 12px);
	line-height: 24px;
	text-transform: uppercase;
}

@media screen and (max-width: 600px) {
	.group__label-E0MOT {
		padding: 0 20px;
	}
}
