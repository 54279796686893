.header-2vHf0 {
	display: inline-block;
}

.currentMonth-2E4pJ {
	display: inline-flex;
	align-items: center;

	color: var(--text-base);
	font-weight: 600;
	font-size: 14px;

	cursor: pointer;
}

.currentMonth__monthName-2jkmN {
	text-transform: capitalize;
}

.currentMonth__monthName-2jkmN:after {
	content: ',';
}

.currentMonth__year-2cLqo {
	padding-left: 5px;

	color: var(--text-pale);
}

.currentMonth-2E4pJ:after {
	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNEw2IDlMMTEgNEwxIDRaIiBmaWxsPSIjQTVBNUE1Ii8+Cjwvc3ZnPgo=) no-repeat center / 50%;
	content: '';
}

.monthList-KwDtd {
	position: absolute;

	top: 100%;
	z-index: 3;

	width: 320px;
	padding-top: 8px;
	padding-bottom: 8px;

	box-shadow: 2px 1px 9px rgba(0, 0, 0, 0.15);
	transform: translate(90%, 0);
	background: #ffffff;
}

@media (max-width: 1024px) {
	.monthList-KwDtd {
		position: relative;
		top: 0;

		width: 100%;
		padding: 10px 0;

		box-shadow: none;
		transform: none;
	}

	.currentMonth-2E4pJ {
		margin-top: 20px;
		margin-bottom: 16px;

		font-size: 17px;
		font-weight: 500;
	}
}

.month-1kmfy {
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
	height: 32px;
	padding-right: 16px;
	padding-left: 16px;

	text-transform: capitalize;

	cursor: pointer;
}

.month-1kmfy:hover {
	background: var(--brand-1-5);
}

@media (max-width: 1024px) {
	.month-1kmfy {
		padding: 0;
	}

	.month-1kmfy:hover {
		background: none;
	}
}

.month__name-3AlX-,
.month__price-1Sd2o {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.5;
}

.month__name-3AlX- {
	width: 100%;
	max-width: 86px;
}

.month__price-1Sd2o {
	min-width: 70px;

	padding: 0;

	white-space: nowrap;

	text-align: right;
}

.bar-3wJY8 {
	position: relative;

	flex: 1;
	height: 3px;
}

.progress-1_LXy {
	position: absolute;
	top: -2px;
	left: 0;

	height: 6px;

	background: var(--pricegraph-dropdown-default-progress);
}

.month_min-4PCQW .progress-1_LXy {
	background: var(--pricegraph-min-price);
	opacity: 1;
}

.month_max-_oZAy .progress-1_LXy {
	background: var(--pricegraph-dropdown-progress);
	opacity: 1;
}

.mobileHeader-NFJnc {
	font-size: 20px;
	font-weight: 500;
	color: var(--text-base);
}
