.wrapper-2ej9h {
	width: 100%;
}

.image-2QcOX {
	position: relative;

	height: 350px;

	border-radius: var(--baggage-additional-wrapped-border-radius) var(--baggage-additional-wrapped-border-radius) 0 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.marker-2gxvK {
	position: absolute;
	top: 20px;
	left: 20px;

	padding: 4px 16px;

	border-radius: 100px;

	color: var(--white);
	font-size: 16px;
	line-height: 1.3;
	font-weight: 600;

	background: var(--error);
}

.row-WcrPa {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.row-WcrPa:not(:first-of-type) {
	margin-top: 16px;
	padding-top: 16px;

	border-top: 1px dashed var(--line-dot-line);
}

.name-3VH6g {
	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
	text-transform: uppercase;
}

.weight-3Bkem {
	margin-left: 40px;

	color: var(--text-light);
	font: var(--medium--1-desktop);

	white-space: nowrap;
}

.content-3mIxy {
	display: flex;
	flex-direction: column;

	padding: 20px 32px 12px;

	background: var(--bg-fill);
}

.trigger-5Jc_m {
	display: flex;
	align-items: center;

	color: var(--text-mid);
	font: var(--medium--1-desktop-bold);

	cursor: pointer;
}

.trigger__icon-19H2m {
	display: flex;
	align-items: center;

	margin-left: 4px;

	color: var(--text-pale);

	transition: transform 0.15s;
}

.trigger__icon_rotated-1yXBg {
	transform: rotate(180deg);
}

.priceWrapper-2G3MT {
	display: flex;
	align-items: center;
}

.price-1_eKr {
	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.oldPrice-1n8XJ {
	margin-right: 8px;

	color: var(--error-icon);
	font-weight: 600;
	font-size: 18px;
	line-height: 170%;
	text-decoration: line-through;
}

.description-ajcBE {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;

	margin-top: 16px;
	padding-top: 16px;

	border-top: 1px dashed var(--line-dot-line);

	color: var(--text-mid);
	font: var(--normal-desktop);
}

.description__row-3diFw {
	display: flex;
	flex-direction: column;
}

.description__header-_BT1L {
	font: var(--medium--1-desktop-bold);
}

.selector-2bqhZ {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;

	padding: 8px 32px 32px;
}

.counters-3RRyh {
	display: flex;
	flex-direction: column;
}

.counter-yLYwZ {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 16px 0;

	border-bottom: 1px dashed var(--line-separator);
}

.passenger-bOLhd {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;

	color: var(--text-mid);
	font: var(--medium--1-mobile);
}

.passenger__index-1UoTi {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;

	border-radius: 50%;

	color: var(--text-light);
	font: var(--normal-desktop-accent);

	background: var(--bg-fill);
}

/* stylelint-disable-next-line block-no-empty */
button.button-2q7wJ {
}

button.button_empty-3w7FW {
	opacity: 0.6;
}

button.button_empty-3w7FW:hover,
button.button_empty-3w7FW:focus,
button.button_empty-3w7FW:active {
	opacity: 1;
}

.footer-1XUNa {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
}

.footer__price-3t4_m {
	display: flex;
	align-items: center;
}

.footer__price_visible-B2LEE {
	opacity: 1;
}

.totalPrice-PP8CY {
	display: flex;
	align-items: baseline;
}

.total-2Y3HJ {
	padding-left: 0;
}

.total-2Y3HJ,
.totalText-1aXYe {
	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.clear-OL8Mb {
	margin-left: 12px;
	padding-left: 12px;

	border-left: 1px solid var(--gray10);

	color: var(--brand-1-0);
	font: var(--normal-desktop-accent);

	cursor: pointer;
}

.addForAll-3f9JQ {
	margin-left: 0;
	padding-left: 0;

	border-left: none;
}

button.disabled-2rt-q {
	opacity: 0.6;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.wrapper-2ej9h {
		min-height: 100%;
	}

	.image-2QcOX {
		height: 250px;

		border-radius: 0;
	}

	.content-3mIxy {
		padding: 20px 20px 12px;
	}

	.selector-2bqhZ {
		padding: 8px 20px 32px;
	}

	.counters-3RRyh {
		max-height: 182px;
		overflow-y: auto;
	}
}

/* buttons wrap for small mobile screens */
@media (max-width: 424px) {
	.footer-1XUNa {
		grid-gap: 5px;
		gap: 5px;
	}

	.footer__price-3t4_m {
		display: inline;
		display: initial;
	}

	.totalPrice-PP8CY {
		margin-bottom: 10px;
	}

	.clear-OL8Mb {
		margin-left: 0;
		padding-left: 0;

		border: medium none currentColor;

		border: initial;
	}
}

@media screen and (max-width: 320px) {
	.image-2QcOX {
		height: 150px;
	}

	.name-3VH6g {
		font-size: 18px;
	}
}
