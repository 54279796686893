
/* stylelint-disable */
.monthHeader__select_month-jUxl8 {
}

.monthHeader__select_year-2cuzb {
}

/* stylelint-enable */
.monthHeader-2fAAh {
	height: 30px;

	line-height: 30px;
	text-align: center;
}

.monthHeader__select-fJD13 {
	display: inline-block;
}

.monthHeader__select_asPlainText-3eiwm {
	padding-right: 5px;
}
