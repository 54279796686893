.actions-k0PeL {
	position: absolute;

	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	.actions-k0PeL {
		position: relative;

		display: flex;
		margin-top: 6px;
	}
}

button.addSegment-314V_,
button.removeSegment-1NxWE {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 138px;
	height: var(--input-height);
	padding: 0;

	border: none;
	border-radius: 4px;

	cursor: pointer;

	background-color: rgba(255, 255, 255, 0.2);

	transition: background-color 0.15s, opacity 0.15s;
}

button.addSegment-314V_:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

button.removeSegment-1NxWE {
	position: relative;

	display: none;
}

button.removeSegment-1NxWE svg {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	margin: auto;
}

button.addSegment-314V_:not(:disabled):hover,
button.removeSegment-1NxWE:hover {
	background-color: rgba(255, 255, 255, 0.35);
}

@media (max-width: 1024px) and (min-width: 601px) {
	.addSegment-314V_ {
		width: 68px;
	}
}

@media screen and (max-width: 600px) {
	button.addSegment-314V_,
	button.removeSegment-1NxWE {
		width: 100%;
		flex-grow: 1;
	}

	button.removeSegment-1NxWE {
		display: block;
		margin-left: 4px;
	}

	.actions_alternative-38Nao {
		margin-top: 0;
	}

	button.addSegment_alternative-1a4t9,
	button.removeSegment_alternative-2BWJq {
		border-radius: 0;
	}
}
