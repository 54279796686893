.input_wrapper-2C0O4 {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.input-3vYD1 {
	width: 100%;
	min-height: 48px;
	padding: 14px 12px;

	border: none;

	line-height: 1.2;

	background: var(--white);
}

.input-3vYD1:-ms-input-placeholder {
	color: var(--text-light);
}

.input-3vYD1::placeholder,
div.input-3vYD1 {
	color: var(--text-light);
}

div.input-3vYD1 {
	display: flex;
	align-items: center;
}

.clear-1rX8X {
	position: absolute;

	right: 6px;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	padding: 2px;

	border-radius: 24px;

	color: var(--text-pale);

	background: var(--bg-white);
	cursor: pointer;
}

.clear-1rX8X:hover {
	color: var(--text-pale);

	background: var(--bg-elements);
}

.clear-1rX8X svg {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	.input-3vYD1 {
		min-height: 68px;
	}

	.clear-1rX8X {
		right: 18px;
	}
}
