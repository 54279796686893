

.icon-37eUM {
	position: absolute;
	left: 20px;
	top: 16px;
}

.icon-37eUM.red-1-kgP {
	color: var(--error);
}

.icon-37eUM.gray-2v9cp {
	color: var(--gray50);
}

.note-3OzU8 {
	position: relative;

	max-width: 1100px;
	margin: 12px auto;
	padding: 17px 20px 17px 50px;

	border: 1px solid var(--warning);

	color: var(--gray80);

	background: var(--warning20);
}

.text-HAR8B a {
	color: var(--text-link);
}

.title-2Y7TB {
	display: flex;
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.text-HAR8B {
	margin: 0;

	font-size: 15px;
	line-height: 19px;
	white-space: pre-wrap;
}

/* stylelint-disable */
.info-1GzRZ {
}

.timelimit-3Lmid {
}

.restriction-3OSxC {
}
