

.container-fY0FZ {
	position: relative;

	display: flex;
	flex-direction: column;
	margin-right: 8px;

	text-overflow: ellipsis;
}

.label-6A4Dx {
	position: absolute;
	top: -15px;

	max-width: 100%;

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	white-space: nowrap;
	text-overflow: ellipsis;

	overflow: hidden;
}

.input-1prsc {
	margin-top: 4px;
	max-width: 120px;
	min-width: 120px;
	height: var(--input-height);
	padding: 0 16px;

	border: none;
	border-radius: 2px;

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;

	background-color: rgba(255, 255, 255, 0.2);

	transition: background-color 0.15s, color 0.15s;
}

.input-1prsc:hover {
	background-color: rgba(255, 255, 255, 0.4);
}

.input-1prsc:focus {
	color: var(--dark-gray);

	background-color: #ffffff;
}

.container-fY0FZ:last-child {
	margin-right: 0;
}
