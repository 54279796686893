.wrapper-17W7a {
	position: relative;

	padding: 30px 32px 32px;

	border-radius: 20px;

	background-color: var(--white);
	overflow: hidden;
}

.close-3knlX {
	position: absolute;
	top: 30px;
	right: 32px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;
	background-color: #f6f6f6;
}

.close-3knlX:before,
.close-3knlX:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 1px;

	background-color: var(--gray80);
	transform: translate(-50%, -50%) rotate(-45deg);
}

.close-3knlX:after {
	transform: translate(-50%, -50%) rotate(45deg);
}

.title-3Cq0X {
	margin-bottom: 22px;
	padding-right: 40px;

	color: #262626;
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.table-3r8zD {
	display: flex;
	flex-direction: column;
}

.row-1j3mi {
	display: flex;

	border-bottom: 1px solid #d9d9d9;
}

.row-1j3mi:nth-child(even) {
	background-color: #f6f6f6;
}

.column-3FKoa {
	flex: 1;
	padding: 12px;
}

.table-3r8zD .column-3FKoa:last-child {
	flex: 2;
}

.tableHead-3NOsg {
	display: flex;
	flex-direction: column;

	color: var(--white);
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
}

.tableHead-3NOsg .row-1j3mi {
	background-color: var(--base);
}

@media screen and (max-width: 600px) {
	.wrapper-17W7a {
		padding: 20px;
	}

	.close-3knlX {
		top: 20px;
		right: 20px;
	}

	.table-3r8zD {
		overflow: scroll;
	}

	.row-1j3mi {
		min-width: 780px;
	}
}
