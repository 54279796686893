.wrapper-UdXr_ {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px;

	width: 100%;

	border-radius: 4px;

	color: var(--gray50);

	background-color: #ffffff;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
}

.header-36AOl {
	display: flex;
	justify-content: space-between;
}

.airline-IWQsN {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 140px;

	min-height: 2em;

	text-overflow: ellipsis;
}

.airline__logo-1sjZJ {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.flight-2jnmI {
	display: flex;
	align-items: center;

	color: var(--text);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

.flight-2jnmI > svg {
	margin-right: 8px;

	color: #c2c2c2;
}

.flight__block-1O_ef:not(:last-of-type) {
	margin-right: 12px;
}

.flight__title-3Umzh {
	font-size: 13px;
}

.aircraft-3I3bx {
	text-align: center;
}

.aircraft__image-JP02L {
	width: 70%;
}

.aircraft_return-1znas .aircraft__image-JP02L {
	transform: none;
}

@media screen and (max-width: 600px) {
	.aircraft__image-JP02L {
		width: 90%;
	}
}

.footer-3p_wb {
	display: flex;
	align-items: center;
	justify-content: center;
}

.airline__number-G68Bx:after {
	content: ',';

	margin-right: 4px;
}

.airline__number-G68Bx,
.airline__name-1e1ZZ {
	white-space: nowrap;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.aircraft__number-nG1iN:not(:last-child):after {
	content: ', ';
}

.aircraft__name-SC-9N {
	display: flex;
	align-items: center;

	padding-right: 15px;

	border-right: 1px solid #d9d9d9;

	font-weight: 400;

	font-size: 13px;
	line-height: 1.3;
}

div.aircraft__name_withNoAmenities-1PH1e,
div.aircraft__name_withNoAmenities-1PH1e:after {
	border-right: unset;
}

.aircraft__name_active-2PER1 {
	color: var(--base);

	cursor: pointer;
}

.info-1wP5p {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	margin-left: 4px;

	color: var(--gray30);

	cursor: help;
}

.aircraft__nameTitle-2s7ur {
	max-width: 140px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/* stylelint-disable */
.amenities-30F8P {
}
/* stylelint-enable */
