

.segment-_EQEf {
	display: flex;
	flex-direction: column;
}

.label-3CUU7 {
	padding: 16px 20px;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;

	background: var(--bg-fill);
}
