.header-285_E {
	padding: 24px 32px 16px;
}

.title-223oI {
	font-size: 20px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--text-base);
}

.content-1bzEa {
	padding: 20px 40px 40px;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

.text-XuAWa {
	color: var(--text-mid);
	font-weight: 400;
	font-size: 18px;
	line-height: 1.3;
}

button.buttonSubmit-19Skw {
	width: auto;
}

.footer-1-S_B {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.types-dOB2d {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

@media screen and (max-width: 600px) {
	.header-285_E {
		padding: 0;
	}

	.content-1bzEa {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}
}
