.root-1kh0s {
	padding: 43px 40px 47px;
	max-width: 660px;
}

.title-2IBhr {
	font-size: 30px;
	font-weight: 500;
	color: var(--gray80);
}

.text-3uUxD {
	margin-top: 20px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 18px;
	line-height: 1.3;
}

.row-3CLM5 {
	display: flex;
	margin-top: 50px;
}

.input_wrapper-wR4wd {
	flex-grow: 1;
}

.button_wrapper-2wxuz {
	margin-left: 20px;
	padding-top: 4px;
}

.social_wrapper-3wrss {
	margin-top: 44px;
}

.social_title-2F1s5 {
	margin-bottom: 14px;

	font-size: 13px;
	color: var(--gray50);
}

.button_continue-3YJWr {
	margin: 0;
}

.loyaltySwitch_wrapper-34SAk {
	position: absolute;

	margin-top: -5px;
}

.loyaltySwitch_root-3AEK4 .loyaltySwitch_label-24NOs {
	color: var(--text-mid);
}

@media screen and (max-width: 600px) {
	.root-1kh0s {
		width: auto;
		padding: 0;

		/* padding: 47px 28px; */
	}

	.loyaltySwitch_wrapper-34SAk {
		position: static;

		padding: 0;
	}

	.loyaltySwitch_root-3AEK4 {
		padding: 0;
	}

	.row-3CLM5 {
		flex-direction: column;
		margin-top: 48px;
	}

	.button_wrapper-2wxuz {
		margin-left: 0;
		margin-top: 20px;
		padding-top: 0;
	}
}
