

.sectionWrapper-1hU8K {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
}

.sectionWrapper__title-BVPgS {
	color: var(--text);
	font-size: 18px;
	font-weight: 700;
	line-height: 23px;
	word-wrap: break-word;
}

.sectionWrapper__content-3oCj_ {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
}
