

button.submit-BGUkB {
	padding: 12px 24px;
	height: 48px;

	border-radius: 6px;

	font-size: 18px;
	font-weight: 700;

	background-color: var(--light-violet);
}

button.submit-BGUkB:hover {
	background-color: var(--bright-violet);
}
