.day-1Symv {
	width: 40px;
	height: 36px;
	padding: 0 2px;

	color: #b0b0b0;
	font-size: 13px;
	line-height: 36px;

	transition: padding 0.1s ease-in-out;
}

@media screen and (max-width: 600px) {
	.day-1Symv {
		font-size: 13px;
	}
}

.day_endHover_withPrice-2PSuO > div,
.day_startHover_withPrice-1k6dj > div {
	border-radius: 0;

	background: none;
}

.day_fromOtherMonth-3wSs9 {
	opacity: 0;

	pointer-events: none;
}

.day_today-1jRIN,
.day_weekend-2QV-z.day_today-1jRIN {
	color: var(--common-blue);
	font-weight: 500;
}

.day_startPeriod-3sTd3:after {
	position: absolute;
	top: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_hasPrice-35lSt {
	font-size: inherit;
}

.day_endPeriod-_MDQM:before {
	position: absolute;
	top: 0;
	left: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_selected-3Dg80 {
	position: relative;

	background: none;
}

.day-1Symv.day_selected-3Dg80 {
	background: none;
}

.day_startPeriod-3sTd3.day_endPeriod-_MDQM:before {
	display: none;
}

.day_endPeriod-_MDQM.day_startPeriod-3sTd3:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 1px;
	z-index: 2;

	width: 33.85px;
	height: 33.85px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41NzcxNDgiIHk9IjAuNTc3MTQ4IiB3aWR0aD0iMzIuODQ2MSIgaGVpZ2h0PSIzMi44NDYxIiByeD0iMTYuNDIzMSIgc3Ryb2tlPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMSAxOUg0TDIgMTFMMSAxNVYxOVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMyAxOEgyOS42NjY3TDMxIDI1LjVMMzMgMjJWMThaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat;
	content: '';
}

.day__inside-3l1kF {
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;

	border: 1px solid transparent;
	border-radius: 50%;
}

.day_selected-3Dg80 .day__inside-3l1kF {
	position: relative;
	z-index: 1;

	width: 36px;
	height: 36px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;

	background: var(--datepicker-day-selected-background);
}

.day-1Symv.day_inPeriod-1LV2R,
.day-1Symv.day_weekend-2QV-z.day_inPeriod-1LV2R {
	position: relative;

	border-radius: 0;

	color: #b0b0b0;

	background: var(--datepicker-day-period-background);
}

.day_inPeriod-1LV2R:last-child:after {
	display: none;
}

.day_inPeriod-1LV2R:first-child:before {
	display: none;
}

.day_inPeriod-1LV2R.day_highlighted-34CMl div {
	font-weight: 600;
}

/* calendar with prices */
.day_withPrice-2wVba {
	display: flex;
	justify-content: center;
	align-items: flex-start;

	width: 44px;
	height: 44px;

	padding-top: 6px;

	border: 1px solid transparent;

	color: #000000;
	font-size: 13px;
	line-height: 16px;

	background: #ffffff;
}

.day__price-jIm31 {
	display: block;

	margin-top: 1px;
	padding-left: 0 !important;

	color: #808080;

	font-size: 9px;
	font-weight: 400;
}

.day__inside-3l1kF:hover {
	box-sizing: border-box;
	width: 36px;
	height: 36px;

	border: 1px solid var(--datepicker-day-selected-background);
}

.day_withPrice-2wVba.day_selected-3Dg80 {
	padding-top: 13px;

	border-radius: 6px;

	background: var(--common-orange);
}

.day_withPrice-2wVba:not(.day_endHover_withPrice-2PSuO):not(.day_startHover_withprice-3rqvx):not(.day_selected-3Dg80):hover {
	border: 1px solid var(--common-orange);
}

.day_withPrice-2wVba.day_selected-3Dg80 > div {
	color: #ffffff;
}

.day_withPrice-2wVba.day_selected-3Dg80 .day__price-jIm31 {
	display: none;
}

.day_withPrice-2wVba.day_inPeriod-1LV2R {
	position: relative;
}

.day_withPrice-2wVba.day_startPeriod-3sTd3:after,
.day_withPrice-2wVba.day_endPeriod-_MDQM:before {
	display: none;
}

.day_withPrice-2wVba.day_startPeriod-3sTd3 {
	border-radius: 6px 0 0 6px;
}

.day_withPrice-2wVba.day_endPeriod-_MDQM {
	border-radius: 0 6px 6px 0;
}

.day_withPrice-2wVba.day_endPeriod-_MDQM.day_inPeriod-1LV2R,
.day_withPrice-2wVba.day_startPeriod-3sTd3.day_inPeriod-1LV2R {
	border-radius: 0;
}

.day_withPrice-2wVba.day_startPeriod-3sTd3.day_withPrice-2wVba.day_endPeriod-_MDQM {
	border-radius: 6px;
}

.day_withPrice-2wVba.day__price-jIm31 {
	margin-top: 1px;
	padding-left: 0;

	color: var(--common-blue);

	font-size: 10px;
	font-weight: 400;
}

div.day_startHover_withPrice-1k6dj,
div.day_endHover_withPrice-2PSuO {
	border: 1px solid var(--common-orange);

	background: var(--datepicker-day-period-background);
}

.day_endHover_withPrice-2PSuO:before,
.day_startHover_withPrice-1k6dj:after {
	display: none;
}
