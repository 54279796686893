

.wrapper-3XoUD {
	position: absolute;
	right: 0;
	top: 0;

	display: flex;
	justify-content: flex-end;
	flex-wrap: nowrap;
	align-items: center;
	width: 206px;
	padding: 8px 20px;

	border-radius: 0 4px;

	color: #313131;
	font-size: 11px;
	font-weight: 500;
	line-height: 16px;
	text-align: right;

	background: var(--orderSummary-timelimit-background);
}

.wrapper-3XoUD.expired-1eOPG {
	color: var(--dark-red);

	background: #ffffff;
}

.content-2nuhY {
	display: flex;
	flex-direction: column;
	max-width: 130px;
}

.logo-2Bg6n {
	width: 20px;
	height: 20px;
	margin-left: 8px;
}
