label.control-3Fcg3 {
	grid-gap: 12px;
	gap: 12px;

	margin-left: 0;
	margin-right: 0;
}

span.label-3CzpO {
	font-family: inherit;
	font-size: 16px;
	line-height: 1.3;
}

span.checkbox-3xuK8.checkbox_checked-2dkE1 {
	color: var(--common-blue);
}

.label-3CzpO a,
.label-3CzpO span[data-type] {
	color: var(--text-link);
}

.label-3CzpO span:not(:last-child):after {
	content: ', ';
}

div.popper-1liqm {
	z-index: 3;
}

@media screen and (max-width: 600px) {
	span.label-3CzpO {
		font-size: 15px;
	}
}
