.container-7rHnu {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

.title-2rc-l {
	margin: 0;

	font-size: 20px;
	font-weight: 700;

	color: var(--text-base);
}

.icon-3twmJ {
	width: -moz-fit-content;
	width: fit-content;

	color: var(--text-pale);
}

.icon-3twmJ > svg {
	width: 64px;
	height: 64px;
}

.content-3VNh7 {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	margin: 0;
	padding: 0;

	color: var(--text-mid);
	font-size: 16px;
	line-height: 1.3;
}

.condition-2kyL8 {
	display: flex;

	list-style-type: none;
}

.condition-2kyL8:before {
	display: flex;
	flex-shrink: 0;
	align-self: start;
	width: 8px;
	height: 8px;
	margin: 0.35em 13px 0 0;

	border-radius: 50%;

	background: var(--line-separator);
	content: '';
}

@media screen and (max-width: 600px) {
	.icon-3twmJ {
		padding: 0 20px;
	}

	.content-3VNh7 {
		padding: 0 20px;
	}
}
