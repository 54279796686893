

.root-3G_3b {
	position: relative;

	display: flex;
	align-items: flex-end;

	background: var(--base15);
	background-size: cover;
	background-position: center center;
}

.clickable-2KqaH {
	cursor: pointer;
}

.overlay-17xgQ {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;

	width: 100%;
	height: 100%;

	background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 100%);
}

.inner-rTxKR {
	position: relative;
	z-index: 20;

	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;

	flex-grow: 1;
	padding: 24px;

	color: var(--white);
	text-align: center;
}

.city-2Y0Np {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.2;

	text-transform: uppercase;
}

.city-2Y0Np.main_city-1AiJy {
	font-size: 25px;
}

.cities_chain-nqI2L {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.cities_chain-nqI2L .city-2Y0Np:not(:last-child):after {
	content: '\2022';

	display: inline-block;
	padding: 0 5px;
}

.period-BrTkk {
	margin-top: 4px;

	font-size: 17px;
	line-height: 1.3;
}
