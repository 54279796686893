.form-19CNe,
.form-19CNe:hover {
	box-shadow: none;
}

.checkboxes-3WKhY {
	grid-gap: 10px;
	gap: 10px;
}

.checkboxes-3WKhY label {
	grid-gap: 12px;
	gap: 12px;
	margin-left: 0;
}

.checkboxes-3WKhY label > span:last-child {
	line-height: 1.2;
}

.loyalty__desc-2UyKf a {
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.header-1DAJ7 {
		margin-bottom: 0;
	}

	.popup__controls-3dkhf {
		display: flex;
		justify-content: space-between;
	}

	button.popup__cancel-33q8w {
		width: 40px;
		min-width: auto;
		height: 40px;
		padding: 0;

		border-radius: 50%;

		font-size: 0;

		background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=) no-repeat center;
	}
}
