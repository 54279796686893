

.serviceCard-2ygWI {
	height: 100%;
	min-height: 308px;
}

/* Bus for Z01-Z22 */
.serviceCard-2ygWI[data-rfisc='Z01'],
.serviceCard-2ygWI[data-rfisc='Z02'],
.serviceCard-2ygWI[data-rfisc='Z03'],
.serviceCard-2ygWI[data-rfisc='Z04'],
.serviceCard-2ygWI[data-rfisc='Z05'],
.serviceCard-2ygWI[data-rfisc='Z06'],
.serviceCard-2ygWI[data-rfisc='Z07'],
.serviceCard-2ygWI[data-rfisc='Z08'],
.serviceCard-2ygWI[data-rfisc='Z09'],
.serviceCard-2ygWI[data-rfisc='Z10'],
.serviceCard-2ygWI[data-rfisc='Z11'],
.serviceCard-2ygWI[data-rfisc='Z12'],
.serviceCard-2ygWI[data-rfisc='Z13'],
.serviceCard-2ygWI[data-rfisc='Z14'],
.serviceCard-2ygWI[data-rfisc='Z15'],
.serviceCard-2ygWI[data-rfisc='Z16'],
.serviceCard-2ygWI[data-rfisc='Z17'],
.serviceCard-2ygWI[data-rfisc='Z18'],
.serviceCard-2ygWI[data-rfisc='Z19'],
.serviceCard-2ygWI[data-rfisc='Z20'],
.serviceCard-2ygWI[data-rfisc='Z21'],
.serviceCard-2ygWI[data-rfisc='Z22'] {
	background-color: #ff9900;
}

.serviceCard-2ygWI[data-rfisc='Z01']:after,
.serviceCard-2ygWI[data-rfisc='Z02']:after,
.serviceCard-2ygWI[data-rfisc='Z03']:after,
.serviceCard-2ygWI[data-rfisc='Z04']:after,
.serviceCard-2ygWI[data-rfisc='Z05']:after,
.serviceCard-2ygWI[data-rfisc='Z06']:after,
.serviceCard-2ygWI[data-rfisc='Z07']:after,
.serviceCard-2ygWI[data-rfisc='Z08']:after,
.serviceCard-2ygWI[data-rfisc='Z09']:after,
.serviceCard-2ygWI[data-rfisc='Z10']:after,
.serviceCard-2ygWI[data-rfisc='Z11']:after,
.serviceCard-2ygWI[data-rfisc='Z12']:after,
.serviceCard-2ygWI[data-rfisc='Z13']:after,
.serviceCard-2ygWI[data-rfisc='Z14']:after,
.serviceCard-2ygWI[data-rfisc='Z15']:after,
.serviceCard-2ygWI[data-rfisc='Z16']:after,
.serviceCard-2ygWI[data-rfisc='Z17']:after,
.serviceCard-2ygWI[data-rfisc='Z18']:after,
.serviceCard-2ygWI[data-rfisc='Z19']:after,
.serviceCard-2ygWI[data-rfisc='Z20']:after,
.serviceCard-2ygWI[data-rfisc='Z21']:after,
.serviceCard-2ygWI[data-rfisc='Z22']:after {
	width: 220px;
	height: 138px;

	background: url('https://cdn.websky.aero/content/frontend/images/services/ZZ/transfer/Z08.svg') no-repeat bottom
		right;
}

.serviceCard-2ygWI[data-rfisc='TS1'] {
	background-color: #95b049;
}

.serviceCard-2ygWI[data-rfisc='TS1']:after {
	width: 220px;
	height: 138px;

	background: url('https://cdn.websky.aero/content/frontend/images/services/ZZ/transfer/TS1.svg') no-repeat bottom
		right;
}

.serviceCard-2ygWI[data-rfisc='TX2'],
.serviceCard-2ygWI[data-rfisc='TX4'],
.serviceCard-2ygWI[data-rfisc='TK1'] {
	background-color: #4b95ac;
}

.serviceCard-2ygWI[data-rfisc='TX2']:after,
.serviceCard-2ygWI[data-rfisc='TX4']:after,
.serviceCard-2ygWI[data-rfisc='TK1']:after {
	width: 220px;
	height: 138px;

	background: url('https://cdn.websky.aero/content/frontend/images/services/ZZ/transfer/TK1.svg') no-repeat bottom
		right;
}

.serviceCard-2ygWI[data-rfisc='VT3'] {
	background-color: #60bf8c;
}

.serviceCard-2ygWI[data-rfisc='VT3']:after {
	width: 220px;
	height: 138px;

	background: url('https://cdn.websky.aero/content/frontend/images/services/ZZ/transfer/VT3.svg') no-repeat bottom
		right;
}

.serviceCard-2ygWI[data-rfisc='VT1'],
.serviceCard-2ygWI[data-rfisc='VT2'] {
	background-color: #4c596c;
}

.serviceCard-2ygWI[data-rfisc='VT1']:after,
.serviceCard-2ygWI[data-rfisc='VT2']:after {
	width: 220px;
	height: 138px;

	background: url('https://cdn.websky.aero/content/frontend/images/services/ZZ/transfer/VT2.svg') no-repeat bottom
		right;
}

.serviceCard-2ygWI[data-rfisc='BT1'],
.serviceCard-2ygWI[data-rfisc='BT2'],
.serviceCard-2ygWI[data-rfisc='BT3'],
.serviceCard-2ygWI[data-rfisc='BT4'] {
	background-color: #5f7d86;
}

.serviceCard-2ygWI[data-rfisc='BT1']:after,
.serviceCard-2ygWI[data-rfisc='BT3']:after,
.serviceCard-2ygWI[data-rfisc='BT2']:after,
.serviceCard-2ygWI[data-rfisc='BT4']:after {
	width: 220px;
	height: 138px;

	background: url('https://cdn.websky.aero/content/frontend/images/services/ZZ/transfer/BT4.svg') no-repeat bottom
		right;
}

.header-1Ecbw,
.headerText-8_XMs {
	font-family: inherit;
}

.headerPrice-1PmKO {
	font-family: inherit;
}

.description-3gpCO {
	color: var(--white);
}

.buttons-1CRN2 {
	justify-content: flex-start;
}

.serviceCard-2ygWI.active-15VF2 {
	background: var(--white);
}
