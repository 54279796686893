.aeroexpress-2Npxs {
	position: relative;

	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: space-between;
	width: 360px;
	height: 490px;

	border-radius: 4px;

	line-height: 23px;

	background: #ffffff;
}

.aeroexpress_white-24rBK {
	border-radius: 8px;

	background-color: #ffffff;
}

.aeroexpress-2Npxs:not(:last-of-type) {
	margin-right: 14px;
}

.aeroexpress__title-2YXno {
	position: relative;

	display: flex;
	align-items: center;
	padding: 20px;
	height: 170px;

	border-top-right-radius: 4px;
	border-top-left-radius: 4px;

	color: #ffffff;

	font-style: normal;
	font-weight: 500;
	font-size: 41px;
	line-height: 48px;

	background: linear-gradient(180deg, rgba(50, 144, 255, 0.8) 0%, var(--common-blue) 100%);
}

.aeroexpress__logoWrp-2uf_W {
	display: inline-block;
	margin-bottom: 10px;
	padding: 5px 42px 5px 10px;

	border-radius: 4px;

	background: #ffffff;
}

.aeroexpress__logo-12wpN {
	width: 70px;
	height: 26px;
}

.insuranceIcon-1octh {
	position: absolute;

	top: 11%;
	left: 48%;

	height: 198px;
}

.aeroexpressIcon-9hID0 {
	position: absolute;

	top: 20%;
	left: 65%;

	height: 198px;
}

.packetName-19Hxd {
	margin-bottom: 25px;

	font-size: 20px;
	font-weight: 600;
	line-height: 23px;
}

.text-taSK1 {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 23px;
}

.aeroexpress_route-3WsUL {
	position: relative;
}

.price-3O7us {
	margin-left: 5px;

	font-size: 22px;
	font-weight: 500;

	color: var(--common-blue);
}

div.modal-oIyVH {
	top: 50%;

	max-width: 750px;
	width: 100%;

	transform: translate(0, calc(-50% - 52px));
}

.terms_conditions_wrap-eaJtm {
	display: flex;
	align-items: center;
}

.terms_conditions_link-TMz0v {
	color: var(--common-blue);
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
}

.terms_conditions-2hK2r {
	display: flex;
	align-items: center;
	padding: 0 20px;
}

.total_price_wrap-38At1 {
	padding: 0 20px;

	border-left: 1px solid var(--light-gray);

	font-weight: 600;
}

.infoIcon-33nD7 {
	padding: 6px;

	line-height: 20px;
}

.infoIcon-33nD7 svg {
	width: 17px;
	height: 17px;
}

.infoIcon-33nD7 path:nth-child(2) {
	fill: var(--common-blue);
}

.transferIcon-3D28S {
	display: flex;
	align-items: center;
	height: 36px;
}

.card_footer-2V3-V {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	border-radius: 0 0 4px 4px;
}

.card_footer_text-29luG {
	margin: 15px;

	color: var(--common-blue);
	font-size: 20px;
	font-weight: 600;
}

.paperWidthMd-1D9uE {
	max-width: 680px;
}

.stepbar__actions-3B8AC {
	display: flex;
	align-items: center;
}

.stepbar__action-2Hqwb {
	display: inline-block;

	width: 24px;
	height: 24px;

	margin-left: 7px;

	cursor: pointer;
}

.stepbar__action_close-Wl2B6 {

	margin-right: 2px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwQzAgNC40NyA0LjQ3IDAgMTAgMEMxNS41MyAwIDIwIDQuNDcgMjAgMTBDMjAgMTUuNTMgMTUuNTMgMjAgMTAgMjBDNC40NyAyMCAwIDE1LjUzIDAgMTBaTTEzLjU5IDE1TDE1IDEzLjU5TDExLjQxIDEwTDE1IDYuNDFMMTMuNTkgNUwxMCA4LjU5TDYuNDEgNUw1IDYuNDFMOC41OSAxMEw1IDEzLjU5TDYuNDEgMTVMMTAgMTEuNDFMMTMuNTkgMTVaIiBmaWxsPSIjOUE5QTlBIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.stepbar__action_downloadTickets-30Rs9 {
	position: relative;
	top: 1px;
	right: 1px;

	color: #9a9a9a;

	transform: rotate(-90deg) scale(0.85);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiM5YTlhOWEiLz4KPHBhdGggZD0iTTE2LjU5IDguNTkwMDlMMTIgMTMuMTcwMUw3LjQxIDguNTkwMDlMNiAxMC4wMDAxTDEyIDE2LjAwMDFMMTggMTAuMDAwMUwxNi41OSA4LjU5MDA5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
}

.stepbar__progress-1KfZq {
	color: var(--common-blue);
}

button.modal__footerBtn-3nwen {
	max-width: 185px;
	margin-right: 10px;
	padding: 15px 60px;

	font-weight: 400;
	font-size: 14px;

	line-height: 16px;
}

button.button-az0Eu {
	min-width: 120px;
	height: 44px;

	border-radius: 24px;

	line-height: 44px;
}

button.modal_footer_btn_disabled-baMJ9 {
	color: #9a9a9a;

	background: var(--half-transparent-gray);
}

button.modal_footer_btn_disabled-baMJ9:hover {
	color: #9a9a9a;

	background: var(--half-transparent-gray);
}

.modal__footer-1_LBi {
	display: flex;
	justify-content: flex-end;
	height: 20%;
	padding: 32px 40px;

	border-radius: 5px;

	background: #ffffff;
}

div.dialog__header-17H1m {
	position: absolute;
	z-index: 2;

	height: 45px;

	border-bottom: none;

	background: transparent;
}

div.dialog__header-17H1m > div:last-child {
	color: #ffffff;
}

div.dialog__content-1MVTY {
	margin-top: 0;
}

div.aeroexpress__scrollBody-3v7LX div.aeroexpress_paper-2U8Y4 {
	max-width: 840px;

	border-radius: 20px;

	background: transparent;
}

div.close-ZxMwE {
	width: 32px;
	min-width: 32px;
	height: 32px;

	color: var(--text-pale);

	background: var(--bg-fill);
}

div.close-ZxMwE:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

div.option-2u5Pc {
	align-items: flex-start;
}

@media screen and (max-width: 600px) {
	div.dialog__header-17H1m > div:first-of-type {
		display: none;
	}

	div.aeroexpress_paper-2U8Y4 {
		border-radius: 0;
	}
}
