.container-2Rkzj {
	position: relative;
}

.wrapper-2t1Z3 {
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
}

.ticket-1fJ7r {
	margin-bottom: 20px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.messages-1E5y7 {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.services-1LRlI {
	margin-bottom: 20px;
}

.receipt-2F_xX {
	margin-bottom: 20px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.form-1Rthh {
	padding: 20px 20px 40px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

div.breadcrumb-3-ijv {
	padding: 0;

	color: var(--text-base);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;

	background: transparent;
}

.footer-3LcGV {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/* stylelint-disable */
.scrollBody-1w29J {
}

.paper-3MQcE {
}
/* stylelint-enable */
