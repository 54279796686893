

button.button-10bHW {
	border-color: var(--gray05);

	color: var(--gray70);
	font-weight: 500;

	font-size: 17px;
	line-height: 20px;

	background: var(--gray05);
}

button.button-10bHW:hover,
button.button-10bHW:focus,
button.button-10bHW:active {
	border-color: var(--gray05);

	background: var(--gray05);
}

button.disabled-10jr- {
	font-size: inherit;
}

button.inverted-3wHNf {
	background: #ffffff;
}

button.inverted-3wHNf:hover,
button.inverted-3wHNf:focus,
button.inverted-3wHNf:active {
	background: #ffffff;
}

button.active-3o9To {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

button.active-3o9To:hover,
button.active-3o9To:focus,
button.active-3o9To:active {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

button.uppercase-36y4N {
	text-transform: uppercase;
}

button.icon-vaKpi {
	padding: 0;

	border-radius: 50%;
}

button.icon-vaKpi.common-3zWAf {
	width: 36px;
	min-width: 36px;
	height: 36px;

	line-height: 36px;
}

button.icon-vaKpi.small-2zRao {
	width: 28px;
	min-width: 28px;
	height: 28px;

	line-height: 28px;
}

button.icon-vaKpi.large-21GHE {
	width: 44px;
	min-width: 44px;
	height: 44px;

	line-height: 44px;
}
