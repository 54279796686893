.item-1tDYf {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	padding: 12px 8px;

	border-radius: 4px;

	color: var(--gray80);

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	background: #ffffff;
}

.item_withSeat-3pUJz {
	position: relative;
}

.freeSeat__text-wTgQo {
	margin-left: 8px;

	max-width: 60px;
}

.clear-29gzA {
	transform: scale(0.9) translateY(2px);
}

.item_recommend-g3oYS {
	color: #ffffff;

	box-shadow: none;
	background: var(--selected);
}

.item_disabled-FDMdD {
	opacity: 0.6;

	cursor: inherit;
}

.avatar-1a9hu {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-right: 10px;

	flex-shrink: 0;

	border-radius: 50%;

	color: var(--gray50);
	font-weight: 500;
	font-size: 14px;

	background: var(--gray05);
}

.item_recommend-g3oYS .avatar-1a9hu {
	color: #ffffff;

	background: rgba(255, 255, 255, 0.2);
}

.main-30hAe {
	display: flex;
	align-items: center;
}

.passengerName-2zFfy {
	max-width: 140px;

	font-weight: 500;
}

.freeSeat__text-wTgQo,
.passengerName-2zFfy {
	overflow: hidden;

	text-overflow: ellipsis;
}

.item-1tDYf.item_active-2vIAX {
	color: #ffffff;

	background: var(--selected);
}

.item-1tDYf.item_recommend-g3oYS.item_active-2vIAX {
	background: var(--selected2);
}

.item_active-2vIAX .avatar-1a9hu {
	color: var(--selected);

	background: #ffffff;
}

.item-1tDYf.item_recommend-g3oYS.item_active-2vIAX .avatar-1a9hu {
	color: var(--selected2);
}

.payload-kS_lg {
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;

	font-weight: 400;
	font-size: 15px;
}

.payload-kS_lg svg {
	color: var(--gray30);
}

.numbers-1Hc9D {
	white-space: nowrap;
}

.select-10ulo {
	color: var(--gray30);
}

.money-3wcOz {
	color: var(--base);
}

.item-1tDYf.item_recommend-g3oYS .money-3wcOz,
.item-1tDYf.item_recommend-g3oYS .select-10ulo,
.item-1tDYf.item_recommend-g3oYS svg,
.item-1tDYf.item_active-2vIAX .money-3wcOz,
.item-1tDYf.item_active-2vIAX .select-10ulo,
.item-1tDYf.item_active-2vIAX svg {
	color: #ffffff;
}

button.select-10ulo {
	background: var(--base);
}

button.selectMobile-1-JXs {
	position: relative;
}

button.selectMobile_selected-16GRN {
	border: 1px solid var(--active);

	color: var(--active);

	background: #ffffff;
}

/* stylelint-disable */
.seatPrice-kaYQT {
}
/* stylelint-enable */
@media (max-width: 768px) {
	.item-1tDYf {
		height: 68px;
		padding: 0;

		border-bottom: 1px solid var(--gray10);
		border-radius: 0;

		box-shadow: none;
		background: none;
	}
}

@media screen and (max-width: 600px) {
	.passengerName-2zFfy,
	.payload-kS_lg {
		font-size: 14px;
	}
}
