.wrapper-EZlmS {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 8px;
	gap: 8px;
}

.item-3YOS3 {
	display: flex;
	align-items: center;

	padding: 11px 15px;

	border-radius: 100px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 14px;
	line-height: 100%;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	cursor: pointer;
	background-color: var(--meal-categories-button-background);

	transition: color 0.15s, box-shadow 0.15s;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.item-3YOS3:hover {
	box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
}

.item_selected-3eKCD {
	padding: 8px 15px 8px 8px;

	color: var(--success-20);
}

.item__icon-3KNmp {
	display: flex;
	align-items: center;

	margin-right: 6px;

	color: #5fca43;
}

@media screen and (max-width: 600px) {
	.item-3YOS3 {
		background-color: var(--gray05);
	}

	.item_selected-3eKCD {
		color: var(--gray80);

		background-color: var(--success90);
	}
}
