

.loader_spinner-3VxzT {
	width: 28px;
	height: 28px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPcSURBVHgBtZY7aFRBFIZn793N7proxvdziQgqFjFWGrBRK4mSd4I20SAYsEglNhZJJYKFtYVgI0jI02I1WsSIjYWIIiKBYKEh0SjZ6Gazj3vv+p2b2TU+4hpzPTA7587Mnn/OP2fOGZ/6C+nr69th2/Yev9+/k891hmGsojdpGcdxPvI9lcvlXjc0NIwXs+X702R/f/8+DFWbplmOYT+GDQESXXo+C7pucb6Hm5ubny4LsKenp6ykpOQ4f97s8/kMepNeAM3FgLTCGBvzy1rpaTOMXW9sbPxcFLC3t3c3HlWjhvM7x6gFyBtonRAKW1tbZ2XtwMBAOWNC8UHaHoA25v+DnsLOrbq6uudLAg4ODkbZ5TGMmJo+VPvVxMTEy87OzrQqIkNDQycAOikMiLcMicc38fTJL4DDw8OlyWTyBIuDmqYk/T12+FUtQzj39YBdQt0EmIEunl6ura39JPNGfmE6nT4qVLAATGc2m83GlgsmIucGwDXszOiNr7Ys62J+3gUk7CsACKHaAjg3N/eAc0qofxTxBrAreJjWTuzivI8UAJncqz1z6F+0tbXNqRWKgAJ4X5+leHraxRLOwXG9A/hLfX39a+WRJBKJGLbzXm4hKCsN+N0sYLp9VB6KMIUTj9TC3RXQaoMDLkWRs7Oh4J3yWAB8JUlDJ5AD8lPKuIDhrJVUHgt239LkDAOAbjVIYT68tASUyJxXHgtX64NaCBpJAhE/mcRByan/K5Lq3CQjGSyldNDEYrGg8li441vpApIEoHReonReKJVGtgkrjwUGt6nv5WvcoKjOCK0A2wRQmfJYAoHASTq/LlvjRiaTkdplM2EHg8HylpYWU3krh5T2EEpHDcmZgCXlWgCu2tvb1ymPhMxygS6q09sUT5Bnbi6F0ikoFVodknhkZGTEr1YovBq203UqnWVw6IaMu4Agx7mPcuklWtX09PS27u7ufwaVRxes3dYXXqicomwNFQBFJicn3xO6WaLVYbFRWVm5kV0u+zwFDIAe1AptP0FgnsnP//DEgMqyeDy+QfIq5+kwZIdCoS+UmqIpjzu8JpVKdfDfc3hWJuVOLVSg82Sbh78FFMGrMGe6FopzAqxBs+FwODM6Oprp6uqS8Zz2pgJG9qMe5pxO0QuQpEkBm0Hvotz1Lra/1DPRJIDWQoXMu6CRSMRiE/bY2JgN/XZVVZUlka0B3DV823qTL9nA2aampvc/2/YVoSlIEQ1Bbw4DdjQadQE7OjosngxiuACmy5tUhqsEyJ2lbP4xEmtqaqRap3XwmIDl8M7N9dwxWTIL0DibeczY3cXPwaXkG+dzLAklPPF8AAAAAElFTkSuQmCC) center no-repeat;

	animation: rotation-3m0Bj 0.9s infinite linear;

	pointer-events: none;
}

.loader-16k87 {
	position: relative;

	margin-right: 10px;

	width: 28px;
	height: 28px;

	border-radius: 50%;
}

.loader__content-jZmlM {
	display: flex;
	align-items: center;
	height: 32px;

	color: #9a9a9a;
	font-size: 16px;
}

@keyframes rotation-3m0Bj {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
