div.modal-32Wm1 {
	max-width: 460px;
}

.content-y4h2A {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0;

	font-size: 20px;
	line-height: 28px;
	color: var(--gray80);
	text-align: center;
}

.header-3iy3S {
	margin-top: 23px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 24px;
}

.icon-3m3ci {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;

	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

.icon-3m3ci svg {
	width: 20px;
	height: 20px;
}

.text-3ZfPO {
	margin: 20px 0;

	color: var(--text-light);
	font-size: 16px;
	line-height: 21px;
	text-align: center;
}

.buttons-1ByR_ {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

.text-3ZfPO,
.text-3ZfPO p {
	white-space: pre-wrap;
}

button.button-26SK2,
button.button-26SK2:hover {
	font-weight: 700;
	font-size: 14px;

	background: var(--active);
}

button.button-26SK2.no-175pb {
	color: var(--gray50);

	background: var(--gray05);
}

button.button-26SK2.no-175pb:hover,
button.button-26SK2.no-175pb:active,
button.button-26SK2.no-175pb:focus {
	background: var(--gray05);
}

@media screen and (max-width: 600px) {
	div.modal-32Wm1 {
		top: 50%;

		min-height: auto !important;

		transform: translateY(-50%);
	}
}
