
/* stylelint-disable */
.root-3iFw_ {
}

.root_zeroPrice-2jso- {
}

.root__text-2Z668 {
	display: none;
}

.root__money-2vkpl {
}

.money-1RcKf {
}

.amount-3bmmC {
}

.currency-lpvsa {
}
/* stylelint-enable */
