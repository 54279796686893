

.modal__title-1sV-7 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 28px 40px;

	border-bottom: 1px solid var(--light-gray);

	font-size: 24px;
	font-weight: 500;

	background: var(--half-transparent-gray);
}

.modal__content-3Cu_4 {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.modal__serviceName-1LLn5 {
	font-weight: 600;
}

.modal_content_transfer-CYOy8 {
	display: flex;
	flex-direction: column;

	padding: 40px 40px 0;
}

.heading-3k2YB {
	display: flex;
	flex-direction: row;

	box-sizing: content-box;
	padding: 20px 0;

	border-bottom: 1px solid #d9d9d9;

	font-weight: 400;
}

.select__wrp-CbF-p {
	display: flex;
	margin-left: 20px;
}

.select__wrp-CbF-p > div {
	font-family: inherit;
}

.heading_route_part-1p7Lh {
	font-weight: 500;
}

.heading_date-T5YzQ {
	font-weight: 500;
	font-size: 15px;

	color: var(--common-gray);
}

.heading_date-T5YzQ,
.heading_city-3UPVc,
.heading_route_part-1p7Lh {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 17px;
	font-weight: 500;
}

.heading_city-3UPVc {
	margin-left: 20px;
}

.icon-31C6E {
	height: 24px;
	margin-left: 10px;
}

.icon-31C6E.button_close-2VszT {
	transform: rotate(45deg);

	fill: #dadada;
}

.icon-31C6E.button_add-2Zzul {
	fill: #ffffff;
}

.button_download-2LxCy {
	margin-left: 3px;

	transform: rotate(90deg) scale(0.7) translate(0, 1px);

	fill: var(--common-blue);
}

button.button_remove-24lTr {
	justify-content: space-between;
	padding: 0 12px;
	margin-left: auto;

	width: 190px;

	border-radius: 24px;

	line-height: 19px;

	font-weight: 400;
}

/* loading button */
div.button_remove-24lTr {
	margin-left: auto;

	background-color: var(--cabinet-action);
}

.modal-2AS4B {
	max-width: 680px;
}

.modal__close-B5UXk {
	top: 24px;
	right: 24px;

	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: 12px;

	border-radius: 50%;

	color: var(--text-pale);

	cursor: pointer;
	background: #f6f6f6;
}

.modal__close-B5UXk:hover {
	color: var(--text-light);

	background: #edebeb;
}

.modal__close-B5UXk svg {
	width: 20px;
	height: 20px;
}

.select_disabled-1M7sk:before {
	display: none;
}

.terms__conditionsWrap-1S_XC {
	display: flex;
	align-items: center;
}

.modal__conditionsLink-3axSx {
	color: var(--common-blue);
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
}

.modal__conditions-3OPVZ {
	display: flex;
	align-items: center;
	padding: 0 0 0 20px;
}

.modal__infoIcon-2W1c3 {
	padding: 6px;

	line-height: 20px;
}

.modal__infoIcon-2W1c3 svg {
	width: 17px;
	height: 17px;
}

.modal__infoIcon-2W1c3 path:nth-child(2) {
	fill: var(--common-blue);
}

.modal__totalPriceWrp-2zjnS {
	position: relative;

	padding: 0 0 0 20px;
	margin-left: 20px;

	font-size: 24px;

	font-weight: 600;
}

.modal__totalPriceWrp-2zjnS:before {
	position: absolute;

	top: 50%;
	left: -1px;

	content: '';

	height: 120%;
	width: 1px;

	transform: translate(0, -50%);

	background: var(--light-gray);
}

.modal__price-1tJDE {
	position: relative;
	left: 4px;

	display: flex;
	justify-content: space-between;
	width: 100%;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 17px;
}

.modal__price_white-3nw3W {
	color: #ffffff;
}

svg.select_icon-1XgOc.select_icon_disabled-3VCUP {
	display: none;
}
