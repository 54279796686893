.wrapper-nk0R8 {
	padding: 40px 32px 32px;
}

.header-rTgEx {
	color: #1d1d1d;
	font: var(--medium-1-mobile-bold);
}

.buttons-37jgM {
	display: flex;
	justify-content: flex-end;
	margin-top: auto;
}

div.buttonSubmit-K__t9 {
	background: var(--brand-3-0);
}

button.buttonSubmit-K__t9 {
	height: 52px;
	padding: 0 24px;

	border-radius: 4px;

	font: var(--normal-desktop-bold);

	background: var(--brand-3-0);
}

button.buttonSubmit-K__t9:hover {
	background: var(--brand-3-1);
}

button.buttonSubmit-K__t9.buttonSubmit_disabled-WSThJ {
	background: var(--brand-3-3);
}

.about-3zl6C {
	margin: 20px 0;

	color: #595959;
	font: var(--medium--1-desktop);

	white-space: break-spaces;
}

.about__item-3RWl0 {
	position: relative;

	padding-left: 21px;

	list-style-type: none;
}

.about__item-3RWl0:not(:last-of-type) {
	margin-bottom: 20px;
}

.about__item-3RWl0:before {
	position: absolute;
	top: 50%;
	left: 0;

	width: 8px;
	height: 8px;

	border-radius: 50%;

	transform: translateY(-50%);
	background: var(--line-separator);
	content: '';
}

.form--xJKS {
	margin-top: 40px;
	display: flex;
}

.fields-1hcO5 {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.fields-1hcO5 > div:last-of-type > div:before {
	content: none;
}

.fields-1hcO5 > div:not(:last-of-type) {
	margin-bottom: 25px;
}

.month_wrapper-3FEJ2 {
	margin-right: 40px;
	padding: 0;

	border: none;
}

.month_nav_prev-14PKO {
	left: 1px;
}

.month_nav_next-1Hw_3 {
	right: 1px;

	transform: rotate(180deg);
}

.month_nav_prev-14PKO,
.month_nav_next-1Hw_3 {
	top: 2px;

	color: transparent;
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb24iPgo8cGF0aCBpZD0iVW5pb24iIGQ9Ik0xNS40MSA3LjQxTDE0IDZMOCAxMkwxNCAxOEwxNS40MSAxNi41OUwxMC44MyAxMkwxNS40MSA3LjQxWiIgZmlsbD0iIzgwODA4MCIvPgo8L2c+Cjwvc3ZnPgo=);
}

.monthHeader-3Q3pV {
	padding: 0 16px;
}

.monthHeader__select-2J_Tr {
	font-size: 18px;
	font-weight: 700;
	color: var(--text-base);
}

.datePicker_day-2Mw7X {
	border: 1px solid transparent;
	border-radius: 50%;

	color: var(--text-base);
}

.weeklyHeader_dayOfWeekName-1yjGB,
.datePicker_day-2Mw7X {
	width: 36px;
	height: 36px;

	line-height: 36px;
}

.datePicker_day-2Mw7X,
.weeklyHeader_dayOfWeekName-1yjGB span {
	font-weight: 500;
	font-size: 16px;
}

.weeklyHeader_dayOfWeekName-1yjGB span {
	border-bottom: none;

	color: var(--text-pale);
}

.datePicker_day_notAvailable-3dnCS {
	color: var(--text-pale);
}

.datePicker_day-2Mw7X:not(.datePicker_day_notAvailable-3dnCS):hover {
	color: var(--white);

	background: var(--brand-1-0);
}

.datePicker_day-2Mw7X.datePicker_day_selected-3YMxp {
	border: 1px solid var(--brand-1-0);

	color: var(--text-base);

	background: none;
}

.monthHeader__select-2J_Tr:last-of-type {
	padding-left: 3px;

	color: var(--text-pale);
}

div.input_number-341z2 input,
div.input_number-341z2 input::-webkit-inner-spin-button,
div.input_number-341z2 input::-webkit-outer-spin-button {
	appearance: none;
}

.select-5DJ9j {
	width: 100%;
}

.success-3cp7W {
	margin: 15px 0;
}

.error-2jtHF {
	color: var(--bright-red);
	font-size: 12px;
}

@media screen and (max-width: 600px) {
	.wrapper-nk0R8 {
		padding: 25px;

		background: var(--white);
	}

	.form--xJKS {
		flex-direction: column;
	}

	.fields-1hcO5 {
		margin-top: 20px;
	}

	.buttons-37jgM {
		margin-top: 20px;
	}

	.month_wrapper-3FEJ2 {
		margin-right: 0;
	}
}
