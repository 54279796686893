

button.primary-2PYHd {
	font-weight: 500;
	color: #ffffff;

	background-color: var(--base);
}

button.primary-2PYHd:hover,
button.primary-2PYHd:focus {
	background-color: var(--base-15);
}

button.primary-2PYHd:disabled {
	color: var(--gray30);

	background-color: var(--gray10);
}

button.secondary-FKZH_ {
	font-weight: 500;
	color: var(--gray70);

	background-color: var(--gray05);
}

button.secondary-FKZH_:hover,
button.secondary-FKZH_:focus {
	background-color: var(--gray10);
}

button.secondary-FKZH_:disabled {
	color: var(--gray30);

	background-color: var(--gray10);
}
