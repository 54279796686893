.root-1b9uo {
	display: grid;
	grid-template-columns: 1fr max-content;
	align-items: center;
}

.passengers_wrapper-2b2qn {
	display: flex;
	align-items: center;

	min-width: 0;
}

.icon-spcHS {
	margin-right: 8px;
}

.passengers-3yIAm {
	max-width: calc(100% - 32px);

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.control-mm2mt {
	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}

div.paper-3u-Np {
	max-width: 728px;
}

@media (max-width: 768px) {
	.root-1b9uo.account-MPow3 {
		font-weight: 500;
	}

	div.paper-3u-Np {
		max-height: 100%;
		overflow-y: auto;
	}
}

@media screen and (max-width: 600px) {
	/* stylelint-disable */
	div.bottomDrawer-1nNhl {
	}
	/* stylelint-enable */
	div.paper-3u-Np {
		border-radius: 0;
	}
}
