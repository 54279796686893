.content-1hqJF {
	padding: 16px;
	max-width: 375px;
}

.header-3xre6 {
	margin: 0 0 12px;
}

.helperText-23tsN {
	position: absolute;
	top: 100%;
}

@media screen and (max-width: 600px) {
	.content-1hqJF {
		max-width: 100%;
	}
}

.input-1Cb_2 {
	width: 100%;
}

.iconText-2xiP6 {
	display: none;
}

div.slideBottom-2sUpK {
	padding: 0;
}

.warningContent-19_oh {
	padding: 12px;

	font-weight: 500;
	font-size: 16px;

	background: var(--errorBG);
}

.warningText-3nDUn,
.warningIcon-3nIWk {
	color: var(--error-text);
}

@media screen and (max-width: 600px) {
	div.warningContent-19_oh {
		flex-direction: row;
		align-items: center;
	}

	.warningIcon-3nIWk {
		margin-bottom: 0;
	}
}

@media (max-width: 768px) {
	div.paper-21Wn8 {
		border-radius: 0;
	}
}

.form-19ElL {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

button.button-3K9EO {
	width: 100%;
	margin-top: 35px;

	font-size: 14px;
}

.controls-807MO {
	justify-content: space-between;
	margin-top: 16px;
	display: none;
}
