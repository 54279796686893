.wrapper-2Xa1l {
	border-radius: 4px;

	color: var(--text-mid);
}

.wrapper_info-2nDmJ,
.wrapper_stophint-1OJzJ {
	margin: 0 40px;
	padding: 12px;

	font-size: 14px;

	background: var(--bg-fill);
}

.wrapper_warning-3Q7c5 {
	position: relative;

	padding: 18px 20px 18px 60px;

	background: var(--warning-bg);
}

.wrapper_warning-3Q7c5:before {
	position: absolute;
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljk5OTk0IDFIMTcuOTk5OUMxOS4wOTk5IDEgMTkuOTk5OSAxLjkgMTkuOTk5OSAzVjIxQzE5Ljk5OTkgMjIuMSAxOS4wOTk5IDIzIDE3Ljk5OTkgMjNINy45OTk5NEM2Ljg5OTk0IDIzIDUuOTk5OTQgMjIuMSA1Ljk5OTk0IDIxVjE4SDcuOTk5OTRWMjBIMTcuOTk5OVY0SDcuOTk5OTRWNkg1Ljk5OTk0VjNDNS45OTk5NCAxLjkgNi44OTk5NCAxIDcuOTk5OTQgMVpNMy40NTk5NCAxMC45Mkw2LjAwOTk0IDEzLjQ3TDExLjkxOTkgNy41NEwxMy4xODk5IDguODFMNS45OTk5NCAxNkwyLjE4OTk0IDEyLjE5TDMuNDU5OTQgMTAuOTJaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=);

	left: 22px;

	transform: translateY(-4px);
}

.wrapper_promo-2A1nj {
	padding: 22px;
}

.stopHint-5tskI {
	display: flex;
	align-items: center;
}

.stopHint-5tskI .terminalChanger-205LK {
	display: flex;
	align-items: center;
	margin-left: 20px;

	color: var(--error-icon);
}

.stopHint-5tskI .terminalChanger-205LK svg {
	transform: scale(0.73);
}

.stopHint-5tskI .terminalChanger-205LK .value-2pUZQ {
	display: flex;
	align-items: center;
	margin-left: 4px;
}

@media screen and (max-width: 600px) {
	.wrapper-2Xa1l {
		border-radius: 8px;

		font-size: 14px;
	}

	.wrapper_info-2nDmJ,
	.wrapper_stophint-1OJzJ {
		margin: 0 12px;
	}

	.stopHint-5tskI {
		display: block;
	}

	.stopHint-5tskI .terminalChanger-205LK {
		margin-top: 4px;
		margin-left: 0;
		display: block;
	}

	.stopHint-5tskI .terminalChanger-205LK .value-2pUZQ {
		margin-left: 0;
	}
}
