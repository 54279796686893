.container-lVmcZ {
	display: flex;
	align-items: center;
}

.item-Bb5jK {
	padding: 8px 20px;

	border-radius: 100px;

	color: var(--brand-1--1);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;

	background: var(--brand-1-5);
}

.item-Bb5jK:not(:last-child) {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	.container-lVmcZ {
		flex-direction: column;
		align-items: flex-start;
	}

	.item-Bb5jK:not(:last-child) {
		margin-right: 0;
		margin-bottom: 8px;
	}
}
