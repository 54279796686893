

button.root-3-Q5E {
	width: 32px;
	min-width: unset;
	height: 32px;
	min-height: unset;

	padding: 7px;

	border-radius: 50%;
}

button.root-3-Q5E svg {
	width: 24px;
	height: 24px;
}

button.root-3-Q5E.prev-2axIe,
button.root-3-Q5E.next-3wAdN {
	color: var(--text-mid);

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
	background: var(--white);
}

button.root-3-Q5E.prev-2axIe:hover:not(.disabled-zaVA1),
button.root-3-Q5E.next-3wAdN:hover:not(.disabled-zaVA1) {
	color: var(--white);

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
	background: var(--brand-1-0);
}

button.root-3-Q5E.goTo-38QrA {
	color: var(--brand-1-0);

	background: var(--white);
}

button.root-3-Q5E.disabled-zaVA1 {
	color: var(--line-dot-line);

	background: var(--white);
}
