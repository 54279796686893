.wrapper-3lYBJ {
	display: flex;
	flex-direction: row-reverse;

	max-width: 1100px;
}

.form-1trlN {
	flex: 1;
}

.hint-r1hPF {
	margin-top: 0;

	margin-right: 12px;

	border-radius: 20px;

	box-shadow: none;
}

.hint-r1hPF,
.wrapper-3lYBJ .retrieveBooking-3mXA5 {
	flex: 1;
}

.hint-r1hPF:hover {
	box-shadow: none;
}

.wrapper-3lYBJ .retrieveBooking__title-2Delo,
.wrapper-3lYBJ .retrieveBooking__tooltipIcon-3B0Vg {
	display: none;
}

@media screen and (max-width: 600px) {
	.wrapper-3lYBJ {
		flex-direction: column;
		padding: 20px 12px;
	}
}
