.month-2ArR3 {

	padding-right: 15px;
}

.week-3JYtP {
	margin-top: 4px;
	display: flex;
}

.week_withPrice-2TJhv {
	margin-top: 0;
}

@media (max-width: 1024px) {
	.month-2ArR3 {
		padding-right: 0;
	}
}
