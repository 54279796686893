div.paper-35ZGh {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.header-1l8q0 {
	width: 100%;
	margin: 0;
	display: flex;
	flex-direction: row;
}

.content-3OwmA {
	flex-grow: 1;

	color: var(--text-light);
	font: var(--medium--1-desktop);
	overflow-x: hidden;
}

div.dialogContent_root-2Fvmv {
	padding: 0;
}

.title-3O2xj {
	color: var(--text-base);
	font: var(--medium-desktop-bold);
}

.footer-hKtPx {
	width: 100%;

	display: flex;
	align-items: center;
	flex-wrap: wrap;

	grid-gap: 12px;

	gap: 12px;

	margin: 0;
}

.buttonsExtra-2DRWO {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.buttons-3iZsX {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	grid-gap: 12px;
	gap: 12px;
	flex-grow: 1;
}

button.cancelButton-1jS3c {
	flex-basis: unset;
	padding: 10px 24px;

	border-radius: 4px;

	color: var(--text-light);
	font: var(--normal-desktop);

	text-align: center;

	background: var(--bg-fill);
}

button.cancelButton-1jS3c:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

button.cancelButton-1jS3c.disabled-2Sa85 {
	color: var(--text-pale);

	background: var(--bg-fill);
}

button.confirmButton-2MJT3 {
	flex-basis: unset;
	padding: 10px 24px;

	border-radius: 4px;

	color: var(--bg-white);
	text-align: center;
	font: var(--normal-desktop-bold);

	background: var(--brand-3-0);
}

button.cancelButton-1jS3c:not(:only-child),
button.confirmButton-2MJT3:not(:only-child) {
	flex-grow: 1;
}

button.confirmButton-2MJT3:hover {
	color: var(--bg-white);

	background: var(--brand-3-1);
}

button.confirmButton-2MJT3.disabled-2Sa85 {
	color: var(--bg-white);

	background: var(--brand-3-3);
}

div.small-2PUqi {
	justify-content: center;
}

div.medium-3YtpY.buttons-3iZsX,
div.large-EgHCg.buttons-3iZsX,
div.xlarge-Y34w_.buttons-3iZsX,
div.fullscreen-ukq6y.buttons-3iZsX {
	justify-content: flex-end;
}

div.medium-3YtpY.buttons-3iZsX button.cancelButton-1jS3c,
div.medium-3YtpY.buttons-3iZsX button.confirmButton-2MJT3,
div.large-EgHCg.buttons-3iZsX button.cancelButton-1jS3c,
div.large-EgHCg.buttons-3iZsX button.confirmButton-2MJT3,
div.xlarge-Y34w_.buttons-3iZsX button.cancelButton-1jS3c,
div.xlarge-Y34w_.buttons-3iZsX button.confirmButton-2MJT3,
div.fullscreen-ukq6y.buttons-3iZsX button.cancelButton-1jS3c,
div.fullscreen-ukq6y.buttons-3iZsX button.confirmButton-2MJT3 {
	flex-basis: unset;
	flex-grow: 0;
}

@media screen and (max-width: 600px) {
	div.medium-3YtpY.buttons-3iZsX button.cancelButton-1jS3c,
	div.medium-3YtpY.buttons-3iZsX button.confirmButton-2MJT3,
	div.large-EgHCg.buttons-3iZsX button.cancelButton-1jS3c,
	div.large-EgHCg.buttons-3iZsX button.confirmButton-2MJT3,
	div.xlarge-Y34w_.buttons-3iZsX button.cancelButton-1jS3c,
	div.xlarge-Y34w_.buttons-3iZsX button.confirmButton-2MJT3,
	div.fullscreen-ukq6y.buttons-3iZsX button.cancelButton-1jS3c,
	div.fullscreen-ukq6y.buttons-3iZsX button.confirmButton-2MJT3 {
		flex-basis: unset;
		flex-grow: 1;
	}

	div.buttons-3iZsX {
		flex-wrap: wrap-reverse;
	}
}
