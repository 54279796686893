.segments-1Rp73 {
	flex: 1;
	padding: 0;

	list-style: none;
}

.segment-3pjJE {
	display: flex;
	margin-bottom: 8px;

	font-size: 19px;
}

.segment-3pjJE:last-child {
	margin-bottom: 0;
}

.segment__info-3MxHd {
	display: flex;
}

.segment__info-3MxHd div {
	text-align: center;
}

.segment__info-3MxHd div:not(:last-child) {
	padding-right: 28px;
}

@media screen and (max-width: 600px) {
	.segment-3pjJE {
		display: block;

		font-size: 16px;
	}

	.segment-3pjJE:not(:first-child) {
		margin-top: 20px;
	}

	.segment__info-3MxHd div:not(:last-child) {
		padding-right: 15px;
	}

	.segment__info-3MxHd {
		padding-top: 5px;
	}
}

.segment__airports-SlCjf {
	flex: 1;

	min-width: 150px;

	font-weight: 600;

	white-space: nowrap;
	text-transform: uppercase;
	text-overflow: ellipsis;
	overflow: hidden;
}

.segment__date-lsoiy {
	font-weight: 600;
}

@media screen and (max-width: 600px) {
	.segment__date-lsoiy {
		font-weight: 400;
	}
}
