

.container-13_6t {
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 12px;
	overflow-y: auto;
}

button.selector-DgdNK {
	flex-shrink: 0;
	width: 58px;
	min-width: auto;
	height: 58px;
	padding: 0;

	border-radius: 50%;

	font-size: 16px;
}

button.selector-DgdNK.selected-TLtXk {
	color: #ffffff;

	background: var(--brand1);
}

button.selector-DgdNK:not(:last-child) {
	margin-right: 20px;
}

.description-GsY9X {
	color: var(--text-light);
	font-size: 14px;
	line-height: 150%;
}

.container-13_6t.BaggageExcess-123dC .description-GsY9X {
	display: none;
}

.footer-26TJr {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
	padding: 12px 0;

	border-bottom: 1px solid #d9d9d9;

	color: var(--brand1);
	font-weight: 500;
	font-size: 14px;
}

.price-1R8Hn {
	color: var(--text);
	font-size: 16px;
	font-weight: 700;
}

.icon-2tchO {
	width: 40px;
	height: 70px;
	margin-right: 20px;
	flex-shrink: 0;
}

.icon-2tchO svg {
	width: 40px;
	height: 70px;
}
