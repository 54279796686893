.root-2jlRv {
	width: 100%;
	display: flex;
	flex-direction: row;
	grid-gap: 32px;
	gap: 32px;
	max-width: 1100px;
	padding: 20px;

	border: 1px solid var(--brand-1-3);
	border-radius: 4px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--brand-1-5);
}

@media screen and (max-width: 600px) {
	.root-2jlRv {
		flex-direction: column;
		align-items: center;
	}
}

.icon__wrapper-29AzU {
	display: flex;
	flex-grow: 0;
	justify-content: center;
	align-items: center;
	width: 76px;
}

.icon-3KHPq {
	display: flex;
	flex-grow: 0;
	justify-content: center;
	align-items: center;
	width: 76px;
	height: 76px;
	padding: 10px;

	border: var(--brand-1-2) 1px solid;
	border-radius: 200px;

	background-color: var(--brand-1-4);
	background-image: linear-gradient(
		135deg,
		rgba(37, 108, 191, 0) 50%,
		rgba(37, 108, 191, 0.2) 50%,
		rgba(37, 108, 191, 0) 100%
	);
}

.icon-3KHPq svg {
	width: 100%;
	height: 100%;

	color: var(--brand-1-0);

	transform: rotate(-90deg);
}

.text__wrapper-2EC2D {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

.text__title-18D0M {
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.text__main-8_jIW {
	display: flex;
	flex-direction: column;
	grid-gap: 4px;
	gap: 4px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
}

.text__main-8_jIW ul {
	margin: 0;
	padding: 0 0 0 28px;
}

.text__more-2__yI {
	display: flex;
	flex-direction: row;
	grid-gap: 6px;
	gap: 6px;
}

.text__more__icon-3kj8m {
	color: var(--text-light);
}
