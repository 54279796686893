.paper-1HZ3F {
	padding: 28px;
}

.title-1KDLF {
	margin-bottom: 20px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 18px;
	line-height: 1.4;
	text-align: center;
}

.description-3MOQx {
	margin-bottom: 30px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.4;
	text-align: center;
}

.footer-1xAiH {
	display: flex;
	align-items: center;
	justify-content: center;
}

button.button-19z8W {
	margin-right: 12px;
}

button.button-19z8W:last-child {
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	div.scrollBody-3ZDNj {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.paper-1HZ3F.paper-1HZ3F {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
