.header-3JMMu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 40px 0;
}

.header__name-1Pfsz {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
	color: var(--gray80);
}

.header__name-1Pfsz svg {
	color: #5fca43;
}

.header__icon-2aHFr {
	margin-right: 16px;
	display: flex;
}

.change-EyMy1 {
	font-weight: 500;
	font-size: 15px;
	color: var(--base);

	cursor: pointer;
}

.flights-2Scn7 {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.flights-2Scn7 > div {
	margin: 0;
	max-width: 100%;
}

.flights-2Scn7 > div:not(:last-child) {
	border-bottom: 1px dashed var(--gray20);
}

.flight__container-1T-yK {
	display: flex;
	margin-bottom: 8px;

	border-radius: 4px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.price-1lTM5 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	min-width: 200px;
	padding: 0 12px;

	border-left: 1px dashed var(--gray20);

	color: var(--base-30);
	font-size: 14px;
}

.money-2TmEw {
	margin-top: 8px;

	font-weight: 600;
	font-size: 23px;
}

.charge-OfVTF {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	padding: 21px 16px;

	color: var(--base-30);
	font-size: 14px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.charge__money-CJFdU {
	margin-top: 0;
}

.total-1QjiF {
	display: flex;
	align-items: flex-end;

	color: var(--base-30);
	font-size: 19px;
}

.total__money-e3rGm {
	margin-top: 0;
	margin-left: 12px;

	font-size: 36px;
}

.container_flat-3lKyT .flight-3v7im {
	margin-bottom: 0;

	border-bottom: 1px solid #f4f4f4;
}

.container_flat-3lKyT .charge-OfVTF:not(:last-of-type) {
	margin-bottom: 0;

	border-bottom: 1px solid #f4f4f4;
}

@media screen and (max-width: 600px) {
	.header-3JMMu {
		margin-top: 0;
		margin-bottom: 16px;
	}

	.header__name-1Pfsz {
		font-size: 20px;
	}

	.header__name-1Pfsz svg {
		display: none;
	}

	.flight__container-1T-yK {
		flex-direction: column;

		background: none;
		box-shadow: none;
	}

	.flights-2Scn7 > div:not(:last-of-type) {
		margin: 0;

		box-shadow: none;
	}

	.price-1lTM5 {
		flex-direction: row;

		justify-content: flex-end;
		align-items: center;
		padding: 18px 12px;

		border-left: none;

		border-radius: 4px;

		background: #ffffff;
	}

	.money-2TmEw {
		margin-top: 0;
	}

	.total-1QjiF {
		font-size: 18px;
	}

	.total__money-e3rGm {
		font-size: 30px;
	}

	.flight__container-1T-yK .flights-2Scn7 > div {
		margin-bottom: 8px;
	}

	.flights-2Scn7 > div:not(:last-child) {
		border-bottom: 1px solid var(--gray-line);
	}
}
