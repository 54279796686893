.sunInfo-3MScP {
	display: flex;
	align-items: center;

	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;

	text-align: left;
}

.sunInfo-3MScP svg {
	margin-right: 6px;

	color: var(--text-pale);
}

.sunInfo__inner-3dOp3 {
	position: relative;

	display: inline-block;

	white-space: nowrap;
}
