

.option-1NPvn.small-3h3Yv {
	background: var(--brand-1-0);
}

.header-6flEB {
	margin-bottom: 16px;

	color: var(--text-mid);
	font-weight: 700;
	font-size: 22px;
}

div.recommended-2o-nf {
	box-shadow: 0 0 0 2px var(--fareGroup-recommended-border-color);
}
