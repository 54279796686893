.additional-2vcnH {
	position: relative;

	display: flex;
	align-items: center;

	font-size: 14px;
	color: #ffffff;
}

@media screen and (max-width: 600px) {
	.item-12rno {
		display: block;
		padding-top: 8px;
		padding-bottom: 8px;

		border-bottom: 1px solid rgba(255, 255, 255, 0.4);

		line-height: 28px;
	}

	span.item-12rno:not(:last-of-type) {
		margin-right: 0;
	}
}

.voucher-ovoxH {
	position: absolute;
	left: -55px;
	z-index: 1;

	width: 300px;

	margin-top: 4px;

	border-radius: 2px;

	line-height: 20px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	background-color: #ffffff;
}

.vouchers-22xch {
	line-height: 20px;
}

.vouchers__title-2QrpP {
	opacity: 0.7;
	cursor: pointer;
}

.separator-2hogQ {
	height: 20px;
	width: 1px;

	margin: 0 16px;

	background-color: rgba(255, 255, 255, 0.3);
}

.miles-1UiQx {
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	.miles-1UiQx {
		display: flex;
		align-self: center;
		margin-top: 7px;
		padding-bottom: 8px;
		padding-left: 0;

		line-height: 20px;
	}

	.miles-1UiQx:after {
		display: none;
	}

	.miles__switch-2MfcI {
		flex-basis: 100%;
	}
}
