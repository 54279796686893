

label.label-CKCZU {
	display: flex;
	margin-right: 0;
	margin-left: 0;
}

span.labelContent-1om14 {
	display: flex;
	align-items: center;
	width: 100%;

	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
}

.input-iZLH7 {
	display: none;
}

.icon-3VWXo {
	display: flex;
	align-items: center;
	margin-right: 10px;
	width: 20px;
	height: 20px;
}

.addIcon-rcMGS {
	color: var(--base);
}

.link-rvXp4 {
	text-decoration: none;
}

.addedIcon-2tyOJ {
	border-radius: 50%;

	color: #ffffff;

	background: var(--success);
}

.addedIcon-2tyOJ > svg {
	transform: scale(0.7) translateY(-1px);
}

.removeIcon-3UH3- {
	margin-left: auto;
	display: flex;

	color: #dadada;
}
