

.iconWrapper_pointer-KqO_T svg path {
	fill: var(--brand-1-0);
}

button.baggageList_add-2gNLI:hover {
	background: var(--brand-1-1);
}

.baggageCount_root-1LlmV {
	font-weight: 500;
}

.baggageIcon_wrapper-1aA8d svg rect,
.baggageIcon_wrapper-1aA8d svg path {
	opacity: 1;
}

.icon-14k0E svg path:first-of-type,
.icon-14k0E svg path:nth-of-type(2) {
	fill: var(--brand-1-0);
}

.icon-14k0E svg circle,
.baggageIcon_wrapper-1aA8d svg rect,
.baggageIcon_wrapper-1aA8d svg path:first-of-type:not(:last-of-type) {
	fill: var(--brand-1-4);
}
