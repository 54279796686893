.header__text-1Jkpi.header__payment_cancelled-372RG {
	justify-content: flex-start;
}

.header-37asg {
	padding: 30px 40px;
}

.header__info-286BW,
.header__payment-199t8 {
	display: flex;
	align-items: center;
}

.header__image-2BYtH {
	width: 80px;
	height: 80px;

	border-radius: 50%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.header__info-286BW {
	display: flex;
	height: 18px;
}

.header__info-286BW .header__infoItem-11xxV:not(:first-of-type) {
	margin-left: 20px;
	padding-left: 20px;

	border-left: 1px solid var(--gray-line);
}

.header__title-3igWg {
	margin: 0 0 19px 0;

	color: var(--text-base);
	font-size: 22px;
}

.button__wrapper-3-egK {
	position: relative;
}

.header__traveller-3rmEt {
	text-transform: capitalize;
}

.header__traveller-3rmEt:not(:last-child):after {
	content: ', ';
}

.info__wrapper-3mjzN {
	display: flex;
	align-items: center;
}

.header__text-1Jkpi {
	display: flex;
	align-items: center;
	height: 18px;
	margin: 0;

	color: var(--text-light);
	font-size: 14px;
}

.header__text-1Jkpi .header__textIcon-OFR2h {
	display: flex;
	justify-content: center;
	width: 24px;
	margin-right: 11px;

	color: var(--text-pale);
}

.header__text-1Jkpi.header__payment-199t8,
.header__text-1Jkpi.header__payment-199t8 .header__textIcon-OFR2h {
	color: var(--error-icon);
}

.header__text-1Jkpi.header__payment_confirmed-1rSdP .header__textIcon-OFR2h {
	color: var(--success-icon);
}

button.header__infoButton-34tyQ {
	margin-left: 4px;
	margin-bottom: 1px;

	color: var(--text-link);
	font-weight: 400;
	font-size: 14px;
}

button.header__infoButton-34tyQ:active {
	background: none;
}

@media screen and (max-width: 600px) {
	.header-37asg {
		display: block;
		margin-bottom: -20px;
		padding: 12px;
	}

	.header__info-286BW {
		display: block;
		height: auto;
	}

	.header__text-1Jkpi {
		height: auto;
		justify-content: space-between;
	}

	.header__title-3igWg {
		display: none;
	}

	.header__info-286BW .header__infoItem-11xxV:not(:first-of-type) {
		margin: 0;
		margin-top: 5px;
		padding: 0;

		border: none;
	}

	.header__payment_confirmed-1rSdP {
		margin-top: 16px;
	}

	.header__text-1Jkpi.header__payment-199t8 {
		justify-content: flex-start;
	}

	.header__payment-199t8 .header__details-27qj3 {
		text-align: left;
		font-size: 14px;
	}

	button.header__infoButton-34tyQ {
		margin-left: auto;
	}
}
