.wrapper-2Zn5z {
	position: relative;

	padding: 30px 32px 32px;

	border-radius: 20px;

	background-color: var(--white);
}

.close-1StWB {
	position: absolute;
	top: 28px;
	right: 32px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;
	background-color: #f6f6f6;
}

.close-1StWB:before,
.close-1StWB:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 50%;
	height: 1px;

	background-color: #262626;
	transform: translate(-50%, -50%) rotate(-45deg);
}

.close-1StWB:after {
	transform: translate(-50%, -50%) rotate(45deg);
}

.title-1_69c {
	margin-bottom: 22px;

	padding-right: 40px;

	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.passengers-24xo3 {
	display: flex;
	flex-direction: column;
}

.disabilities-3Ls9A {
	width: 100%;
	margin-top: 10px;
	padding: 10px 0;

	border-top: 1px dashed var(--line-separator);

	color: var(--text-light);
}

.disabilities__header-2C_Z0 {
	margin-bottom: 10px;

	font-size: 16px;
	font-weight: 700;
}

.item-2SLhK {
	display: flex;
	flex-direction: column;
	padding: 16px 0;
}

.item-2SLhK:last-child {
	margin-bottom: 0;
}

.accompanied-33zf6 {
	width: 100%;
	margin-top: 15px;
}

.accompanies-2F3k8 {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 15px;

	border-top: 1px dashed var(--line-separator);

	color: var(--text-light);

	background-color: var(--white);
}

.accompanies__text-2ioA_ {
	width: -moz-fit-content;
	width: fit-content;
	margin-top: -10px;
	padding: 0 10px;

	background-color: var(--white);
}

.passenger-14nHb {
	display: flex;
	align-items: flex-start;
}

.item-2SLhK .accompanied-33zf6:first-child {
	border-top: 1px dashed var(--line-separator);
}

.item_relinking-3lbrq {
	margin-bottom: 0;
	padding: 16px 0 0 0;

	border: none;
}

button.confirmRelink-1lHex,
div.confirmRelink-1lHex {
	margin-top: 25px;
}

span.passenger__checkbox-ptc_C {
	margin-right: 10px;
}

.number-2SLDK {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);
	font-weight: 700;
	line-height: 1.3;

	background-color: #5fca43;
	overflow: hidden;
}

.content-2kJlC {
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 10px;
	gap: 10px;
	width: 100%;
}

.item_content-sWeLp {
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	flex: 1;
}

.item_relinking-3lbrq .item_content-sWeLp {
	padding-top: 0;
}

.name-1kpLb {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;

	color: #262626;
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.item_relinking-3lbrq .name-1kpLb {
	cursor: pointer;
}

.name__link-3K-yS {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;

	cursor: pointer;
}

.info-1B_3K {
	display: flex;
	flex-direction: column;

	color: var(--gray70);
	font-size: 16px;
	line-height: 1.3;
}

.item_relinking-3lbrq .info-1B_3K {
	margin: 5px 0 0 -32px;
}

.info-1B_3K > span {
	margin-bottom: 2px;
}

.info-1B_3K > span:last-child {
	margin-bottom: 0;
}

/* stylelint-disable */
.button-3O6aP {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.wrapper-2Zn5z {
		padding: 20px;

		border-radius: 0;
	}

	.close-1StWB {
		top: 20px;
		right: 20px;
	}
}
