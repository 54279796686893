.container-21-Gz {
	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 14px 12px;

	font-size: 16px;
	line-height: 1.2;

	pointer-events: none;
}

.name-3Yp2u {
	text-align: center;
	color: var(--text-base);
	font-weight: 500;
}

.iata-3oIyI {
	margin-left: auto;
	padding-right: 24px;

	color: var(--text-light);
	font-weight: 500;
}

.loader-22NEA {
	margin-left: auto;
}

div.colorPrimary-wOQF4 {
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.container-21-Gz {
		flex-direction: column-reverse;
		justify-content: center;
		padding: 14px 24px;
	}

	.name-3Yp2u {
		color: var(--text-mid);
		font-size: 13px;
		font-weight: 400;
		line-height: 1.3;
	}

	.iata-3oIyI {
		margin-bottom: 4px;
		margin-left: 0;

		color: var(--text-base);
		font-weight: 700;
		font-size: 20px;
		line-height: 1.3;
	}

	.placeholder-zXme- {
		display: none;
	}
}
