button.button-9nuGV {
	color: var(--brand-1-0);
}

.icon-2dthV {
	width: 20px;
	height: 20px;
	margin-left: 6px;
}

.icon-2dthV svg {
	width: 100%;
	height: 100%;
}

div.paper-1Sm2E {
	border-radius: 20px;
}

button.close-3OqoX {
	position: absolute;
	top: 32px;
	right: 32px;
	z-index: 1;
}

@media screen and (max-width: 600px) {
	button.close-3OqoX {
		z-index: 2;
		top: 12px;
		right: 12px;
	}
}
