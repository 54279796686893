.header-k04rF {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.container-22ezQ {
		padding: 12px 12px 12px 12px;

		border-radius: 12px 12px 0 0;

		background: var(--bg-white);
	}
}
