.helpfulInformation-1rFvR {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	grid-gap: 20px;
	gap: 20px;
}

@media screen and (max-width: 600px) {
	.helpfulInformation-1rFvR {
		flex-direction: column;
	}
}

.icon-3-qtA {
	width: 20px;
	height: 20px;

	color: var(--text-light);
}

.helpfulInformation__content-3y7e9 {
	display: flex;
	flex-direction: column;
	grid-gap: 4px;
	gap: 4px;
}

.helpfulInformation__contentParagraph-22tPR {
	margin: 0;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;

	color: var(--text-light);
}

.helpfulInformation__contentParagraph_bold-1Mh8t {
	font-weight: 700;
}

.helpfulInformation__contentLink-1a_0U {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;

	color: var(--text-light);
}
