

.predictionItem-35bVw {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.predictionItem__header-1zsPt {
	width: 100%;
	max-width: 444px;
	margin-bottom: 20px;

	font: var(--medium-1-desktop-bold);
	text-align: center;
}

.predictionItem__footer-JBEWk {
	margin-top: 40px;
	display: flex;
	grid-gap: 20px;
	gap: 20px;
	width: 100%;
}

button.predictionItem__btn-1XTJb {
	min-height: 48px;
	padding: 12px 23px;
}

@media mobile {
	.predictionItem__footer-JBEWk {
		flex-direction: column;
	}
}

.predictionCard-2-uza {
	max-width: 360px;
	width: 100%;

	border-radius: 8px;
	overflow: hidden;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.predictionCard__meal-GMbcp {
	width: 100%;
	height: 180px;

	background-position: center;
	object-fit: cover;
}

.predictionCard__content-1RcGO {
	display: flex;
	padding: 16px 32px;
	grid-gap: 20px;
	gap: 20px;

	box-shadow: var(--shdw-card);
	background-color: var(--bg-white);
}

.predictionCard__desc-1As2Q {
	display: flex;
	flex: 1;
	flex-direction: column;
	grid-gap: 4px;
	gap: 4px;

	text-align: center;
	color: var(--text-light);

	font: var(--small-desktop);
}

.predictionCard__descLabel-33Uhq {
	font: var(--medium-mobile-bold);
	color: var(--text-base);
}

.predictionCard__cost-lCvV- {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	gap: 10px;

	padding-left: 20px;

	border-left: 1px dashed var(--line-separator);

	text-align: center;
}

.predictionCard__price-JcEHA {
	padding-left: 0;

	color: var(--brand-1-0);
	font: var(--medium-1-desktop-bold);
}

.predictionCard__baggage-1r_HX {
	height: 180px;

	padding: 20px;

	display: flex;

	justify-content: center;

	align-items: center;

	background: var(--brand-1-5);
}

.predictionCard__baggage-1r_HX svg,
.predictionCard__baggage-1r_HX {
	color: var(--brand-1-0);
}
