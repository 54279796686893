

button.closeFullscreen-2bMMJ,
button.closePopup-1Rb5_ {
	width: 32px;
	min-width: unset;
	height: 32px;
	min-height: unset;
	padding: 0;

	border-radius: 50%;
}

/* CloseFullscreen */
button.closeFullscreen-2bMMJ {
	color: var(--white);

	background: var(--brand-1-0);
}

button.closeFullscreen-2bMMJ:hover {
	background: var(--brand-1-1);
}

/* ClosePopup */
button.closePopup-1Rb5_ {
	color: var(--text-pale);

	background: transparent;
}

button.closePopup-1Rb5_:hover {
	background: var(--bg-elements);
}

/* Close Icon */
.closeIcon-vkhk_ {
	display: flex;
	align-items: center;
	justify-content: center;
}
