.container-TR0c1 {
	position: relative;
}

.title-1jiGF {
	margin-bottom: 28px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.locations-RJzXb {
	column-count: 2;
}

button.location-1G2TC {
	width: 100%;
	margin-bottom: 6px;
}

div.progress-3zXeq {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

div.barColorPrimary-388cy {
	background: var(--results-loader-progress-background);
}

div.colorPrimary-2Y-3u {
	background: var(--results-loader-progress-color);
}

@media screen and (max-width: 600px) {
	.locations-RJzXb {
		column-count: 1;
	}
}
