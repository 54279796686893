

.point-2RQX4 {
	position: relative;

	padding-left: 22px;

	font-weight: 600;
	white-space: nowrap;
}

.point-2RQX4:before {
	position: absolute;
	top: 50%;
	left: 0;

	box-sizing: border-box;

	width: 10px;
	height: 10px;

	border: 2px solid #cdcdcd;
	border-radius: 50%;

	transform: translateY(-50%);
	content: '';
}

.point-2RQX4 span {
	margin-right: 8px;
}

a.link-_LgX5 {
	margin-right: 0;

	font-weight: 400;
}

span.airportName-SNEQP {
	margin-right: 4px;
}

.terminal-1ky1G {
	color: var(--common-gray);
	font-weight: 400;
}
