.notifications__head-1kBKc {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	margin-bottom: 20px;
}

.notifications__top-3vuwL {
	display: flex;
	align-items: center;

	grid-gap: 8px;

	gap: 8px;
}

.notifications__top-3vuwL h1 {
	margin-bottom: 0;
}

.notifications__sub-JfbwE {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.notifications__tabs-1Pe9l {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}

@media (min-width: 1025px) {
	.notifications__tabs-1Pe9l {
		flex-wrap: wrap;
	}
}

@media (max-width: 1024px) {
	.notifications__tabs-1Pe9l {
		overflow-y: auto;

		margin-left: -20px;
		margin-right: -20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.notifications__tabs-1Pe9l::-webkit-scrollbar {
		display: none;
		width: 0;
	}
}

button.notifications__tabsItem-1mjW8 {
	flex-shrink: 0;
}

@media screen and (max-width: 600px) {
	button.notifications__tabsItem-1mjW8 {
		padding-left: 16px;
		padding-right: 16px;

		font-size: 16px;
	}

	.notifications__head-1kBKc {
		margin-bottom: 16px;
	}

	.notifications__sub-JfbwE {
		order: 1;
		justify-content: flex-end;
	}

	.notifications__top-3vuwL button {
		padding-left: 4px;
		padding-right: 4px;
	}
}
