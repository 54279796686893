

.header-2mtfu {
	background-color: var(--common-blue);
}

.header__info-1yXdc {
	display: flex;

	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.airport-dg0uD {
	display: flex;
	align-items: center;
	max-width: 650px;
	width: 100%;
	padding: 28px 0 25px;

	color: #ffffff;
}

.airport__iata-2mWy0 {
	display: flex;
	align-items: center;
	margin-left: 40px;
	margin-right: 16px;
	height: 68px;

	font-size: 82px;
	font-weight: 500;
}

.airport__info-1ddNI {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.airport__city-1w8ty {
	margin-bottom: 3px;

	font-size: 22px;
	font-weight: 500;
	line-height: 26px;
}

.airport__description-2hqiD {
	max-width: 362px;

	font-size: 15px;
	line-height: 130%;
	color: rgba(255, 255, 255, 0.8);
}

.header__buttons-1sJhe {
	display: flex;
	max-width: 450px;
	width: 100%;
}

button.header__button-1dyKw {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;

	border-radius: 0;
	border-left: 1px solid rgba(255, 255, 255, 0.3);

	font-size: 14px;
	line-height: 150%;
	font-weight: 400;
}

.button__icon-2pcSG {
	height: 24px;
	margin-bottom: 14px;
}

div.header__tabs-3DK3a {
	margin-left: 40px;
	height: 56px;
	margin-top: 0;
}

.header__tabs-3DK3a button.tabs__tab-3YSnz {
	max-width: 160px;
	width: 100%;
	height: 56px;
	margin: 0;

	color: #ffffff;

	background-color: rgba(255, 255, 255, 0.3);

	opacity: 1;

	transition: background-color, color 150ms;
}

button.tabs__tab-3YSnz div {
	color: #ffffff;
}

button.tabs__tab-3YSnz.tabs__tab_selected-2tpA7 {
	background-color: #ffffff;
}

span.indicator-1kscb,
.tabs__tab-3YSnz .tab__label-3o2C2 {
	display: none;
}

.tabs__tab-3YSnz .tab__header-2sIfS {
	font-weight: 500;
	font-size: 16px;
	text-transform: none;
}

a.link-1QJNw {
	text-decoration: none;
}
