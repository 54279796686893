

.root-14pIo {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
	padding: 12px;
}

.segmentInfo-3SD62 {
	display: flex;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
}

.segmentInfo__cityName-d1a8G {
	font-weight: 700;
	font-size: 16px;
	color: var(--text-base);
}

.aircraftInfoAndAmenities-1jqCK {
	display: flex;
	justify-content: space-between;
}

.amenities-3fm3H {
	margin-left: 5px;
	padding-left: 5px;

	border-left: 1px solid var(--line-separator);
}

.company-2h1lY {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.company__logo-3XzmN {
	width: 28px;
}

.aircraftInfo-13lsR {
	display: inline-flex;
	align-items: center;

	text-decoration: none;

	cursor: pointer;
}

.aircraftInfo-13lsR > svg {
	margin-left: 2px;
	width: 16px;
	height: 16px;
}

.aircraftInfo-13lsR,
.company__name-2YeG4,
.segmentInfo__date-t9Svr,
.segmentInfo__airportIATA-3bRTF {
	font-size: 13px;
	color: var(--text-light);
}

.flightNumber-r8hLW {
	z-index: 2;

	padding: 0 5px;
}

div.routeArrow-2MoKU {
	margin: 0 !important; /* :( */
}

div.stopsInfo-3KOzl {
	bottom: 50%;

	transform: translateY(50%);
}

.routeArrow-2MoKU div.route-32jzM {
	margin-top: 0;
}

.timeInRoute-3epl2 {
	display: none;
}

div.route-32jzM:before,
div.circle-3SGUy {
	top: 7px;
}
