.title-1el8j {
	margin-top: 0;
	margin-bottom: 20px;

	font-size: 24px;
}

.list-377-E {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

button.btn-qnhMw {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	align-items: center;

	font-weight: 500;
}

button.btn_back-cbQV6 {
	color: var(--text-light);
}

button.btn_back-cbQV6 svg {
	width: 24px;
	height: 24px;
}

div.btn__loader-2OPOr,
button.btn_read-1tqKv svg {
	width: 22px;
	height: 22px;
}

.btn__loader-2OPOr > div {
	width: auto;
	height: auto;
	margin-right: 0;
}

.btn__loader-2OPOr .btn__loaderSpinner-1x_2W {
	width: 20px;
	height: 20px;

	background-size: 20px;
}

.empty-EJWOl {
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.title-1el8j {
		font-size: 18px;
	}
}
