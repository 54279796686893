.card-3Xzfc {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: auto 1fr;
	padding: 24px;

	border-radius: 4px;

	color: var(--text-light);
	font-size: 14px;

	background-color: var(--white);
}

.card__icon-hPqJd {
	width: 32px;
	height: 32px;

	color: var(--brand-1-0);
}

.card__group-2EHtN {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 10px;
	gap: 10px;
}

.card__group_center-2Dwzl {
	align-items: center;
}

.card__title-20mrt {
	margin: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 130%;
}

.card__subTitle-3c1vG {
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.card__body-2vH2X {
	margin-top: 8px;
}

.card__desc-3dXDi {
	margin: 0;
	grid-column: span 2;
}

.card__group_custom-24k05 .card__desc-3dXDi {
	grid-column: auto;
}

.card__group_custom-24k05 span {
	order: 1;
}

@media screen and (max-width: 600px) {
	.card-3Xzfc {
		padding: 16px;
	}
}
