

.wrapper-2bXJ2 {
	position: relative;

	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.wrapper_handbag-1kBj- {
	display: inline-flex;
}

.background-2YZOf {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--baggage-bag-weight-color);
}

.wrapper_included-1HlgR .background-2YZOf {
	color: var(--baggage-bag-weight-color-included);
}

.weight-3Iy7G {
	position: absolute;

	margin-left: -1px;

	text-align: center;
	color: var(--baggage-bag-weight-color);

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.wrapper_included-1HlgR .weight-3Iy7G {
	color: var(--baggage-bag-weight-color-included);
}

.wrapper_handbag-1kBj- .weight-3Iy7G {
	display: flex;
}

/* Weight block position, depending on bag size and type. */
.wrapper_handbag-1kBj-.wrapper_small-I5-hw .weight-3Iy7G {
	bottom: 11px;

	line-height: 22px;
}

.wrapper_handbag-1kBj-.wrapper_medium-2o0FP .weight-3Iy7G,
.wrapper_handbag-1kBj-.wrapper_big-2ZWes .weight-3Iy7G {
	bottom: 18px;

	line-height: 24px;
}

.wrapper_bag-nasK8.wrapper_small-I5-hw .weight-3Iy7G {
	bottom: 16px;
}

.wrapper_bag-nasK8.wrapper_medium-2o0FP .weight-3Iy7G {
	bottom: 19px;
}

.wrapper_bag-nasK8.wrapper_big-2ZWes .weight-3Iy7G {
	bottom: 24px;
}

/* Weight amount */
.weight__amount-2p65U {
	margin: 0;

	font-size: 20px;
	font-weight: 600;
	line-height: 90%;
}

.wrapper_handbag-1kBj-.wrapper_small-I5-hw .weight__amount-2p65U {
	line-height: 22px;
}

.wrapper_handbag-1kBj-.wrapper_medium-2o0FP .weight__amount-2p65U,
.wrapper_handbag-1kBj-.wrapper_big-2ZWes .weight__amount-2p65U {
	line-height: 24px;
}

/* Weight amount measurement */
.weight__measurement-1kqbY {
	margin: 0;

	font-size: 13px;
}

.wrapper_handbag-1kBj- .weight__measurement-1kqbY {
	position: relative;
	bottom: -2px;

	margin-left: 1px;

	font-size: 16px;
}
