

.seat-14BTF.seat_business-YjYPe:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before,
.seat-14BTF[data-rfisc='SR1']:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before {
	content: '';

	position: absolute;
	top: 3px;
	left: 50%;

	width: 12px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;

	transform: translateX(-50%);
}

.seat-14BTF[data-rfisc='SR1']:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before {
	transform: translateX(-50%) rotate(180deg);
}

.seat-14BTF.seat_recommend-1fZkc {
	background: var(--brand-1-0);
}

.seat-14BTF.seat_recommend-1fZkc.seat_recommendCurrent-3Adpx {
	background: var(--white);
}

.seat-14BTF.seat_recommend-1fZkc.seat_recommendCurrent-3Adpx:before {
	content: '';

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border: 3px solid var(--brand-1-0);
	border-radius: 4px;

	pointer-events: none;
}

.seat-14BTF .number-1eNOm {
	margin-bottom: -2px;

	color: var(--text-mid);
}

.seat_occupied-1qs_Z .number-1eNOm {
	margin-bottom: 0;

	color: var(--white);
}

.seat-14BTF.seat_recommend-1fZkc.seat_recommendCurrent-3Adpx .number-1eNOm,
button.seat-14BTF.seat-14BTF.seat_recommend-1fZkc.seat_recommendCurrent-3Adpx:hover .number-1eNOm {
	color: var(--brand-1-0);
}

button.seat-14BTF[data-rfisc='STX']:not(.seat_unavailable-1VV0j) {
	border-color: #ffc692;

	background: #ffc692;
}
