div.paper-2vP8Z {
	padding: 40px;
	width: 100%;
	max-width: 1390px;

	border-radius: 16px;

	background: var(--checkout-background);
}

div.paper_fullScreen-2mVqT {
	max-width: 100% !important;
	min-height: auto !important;
	padding: 0;

	border-radius: 0 !important;
	overflow: auto !important;
}

@media screen and (max-width: 1390px) {
	div.paper-2vP8Z {
		max-width: 1230px;
	}
}

@media (min-width: 1025px) {
	div.paper-2vP8Z {
		min-height: 780px;
	}
}

@media (max-width: 1024px) {
	div.paper-2vP8Z {
		width: 1024px;
		padding: 0;
		height: auto;
		min-height: 100%;

		border-radius: 0;
	}

	div.scrollBody-2gABU {
		padding: 0;
	}
}

@media (max-width: 768px) {
	div.paper-2vP8Z {
		height: auto;
	}
}

@media screen and (max-width: 600px) {
	div.paper-2vP8Z {
		width: 100%;
		padding: 0;
	}
}

div.paper-2vP8Z > span {
	background: #ffffff !important;
	transform: scale(0.9);
}

.closeIcon-hBPTS svg {
	transform: scale(0.6);
}

@media screen and (max-width: 600px) {
	.closeIcon-hBPTS {
		width: 28px;
		height: 28px;
	}

	.closeIcon-hBPTS svg {
		position: relative;
		top: -1px;

		transform: scale(0.9);
	}
}
