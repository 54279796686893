

.wrapper-2mcuG {
	width: 28px;
	height: 28px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
	border: 1px solid transparent;

	color: var(--gray50);

	background-color: #ffffff;

	cursor: pointer;

	transition: color 0.15s, border-color 0.15s;
}

.wrapper-2mcuG:hover {
	border-color: var(--gray10);

	color: var(--gray80);
}

.wrapper_gray-27GLV {
	background-color: var(--gray05);
}
