.header-22Aqk {
	padding: 0 0 25px !important;
}

span.heading-356sq {
	font-size: 20px !important;
	line-height: 26px !important;
	font-weight: 700 !important;
	font-family: inherit;
	color: var(--text-base) !important;
}

@media (max-width: 1024px) {
	.header-22Aqk {
		padding: 25px 40px 25px 24px !important;
	}
}

@media screen and (max-width: 600px) {
	.header-22Aqk {
		position: relative !important;

		border-bottom: 1px solid #d9d9d9;

		background: #ededed;
	}

	span.heading-356sq {
		font-weight: 400 !important;
		font-size: 20px !important;
		line-height: 30px !important;
		color: var(--modalHeading-color) !important;
	}
}
