.wrapper-3utZX {
	width: 100%;
	max-width: 374px;
	margin: 0 auto 4px;

	border-radius: 4px;

	background: var(--mobileSummaryFlight--background);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.wrapper_flat-3JKCK {
	border-radius: 0;

	background: var(--mobileSummaryFlight--background-flat);
}

.header-bYDqj {
	display: flex;
	justify-content: center;

	padding: 12px 12px 4px;

	line-height: 150%;
	color: #ffffff;
	font-size: 17px;
	font-weight: 500;
}

.header_business-1Fi32 {
	line-height: normal;
	line-height: initial;
}

.leg-3NZ61 {
	display: flex;
	align-items: center;
}

.leg__date-915pr {
	margin-left: 5px;
}

.closer-2VJiA {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	margin-left: 4px;

	cursor: pointer;
}

.body-1mpmk {
	padding: 0 16px 12px;

	cursor: pointer;
}

.body_flat-pKBn6 {
	padding: 18px 18px 24px;
}

.body-1mpmk.body_canceled-waX9m {
	opacity: 0.4;
}

.footer-278Gg {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px 8px 6px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.4);

	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
}

.footer_flat-YpnkS {
	padding: 12px 20px;

	border-top: 1px dashed #cccccc;

	color: #2365b2;
	line-height: 18px;
}

.footer__price-1AVOt {
	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 21px;
}

.footer__price_flat-3qG9f {
	font-size: 25px;
	line-height: 25px;
	color: #256cbf;
	font-weight: 900;
}

.wrapper_flat-3JKCK .selected {
	bottom: -39px;
}

.price__miles-2QbAQ .currency-2asB_ {
	font-size: 14px;
	font-weight: 400;
}

.miles__taxes-ra_3a {
	margin-top: 6px;

	color: var(--gray50);
	font-weight: 400;
	font-size: 14px;
	text-align: right;
}

.canceled-27Kq9 {
	color: var(--error);
}

div.paper-355Qb {
	background: var(--checkout-background);
}

@media screen and (max-width: 600px) {
	div.slideBottom__container-1iL_e {
		max-height: calc(100% - 180px);
	}
}
