.title-RnhLU {
	margin: 20px 0;

	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
}

.checkin-2E43r {
	display: flex;
	align-items: center;
	padding: 12px 16px;

	background: var(--warning-bg);
}

.checkin__icon-3L_ir {
	margin-right: 16px;

	line-height: 0;
}

.checkin__text-hG2c6 {
	color: var(--text-mid);
	line-height: 0;
}

@media screen and (max-width: 600px) {
	.title-RnhLU {
		margin: 12px 0;
	}
}
