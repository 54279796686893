.service-2jnxW {
	position: relative;

	max-width: 360px;
	width: 100%;

	display: flex;
	flex-direction: column;
	min-height: 228px;

	border-radius: 4px;

	color: #ffffff;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

	background: #37beed;
}

.service-2jnxW:after {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 0;

	border-bottom-right-radius: 4px;
	content: '';
}

.service-2jnxW .exclusivePlate-3vSxU {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;

	content: '';
}

div.service_active-2-O4D {
	color: currentColor;

	background: #ffffff;
}

div.service_active-2-O4D:after {
	display: none;
}

.header-2bita {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 35px 20px 20px;

	font-size: 20px;
}

.service_active-2-O4D .header-2bita {
	border-bottom: 1px dashed #bababa;

	color: #292929;
}

.service_active-2-O4D .header-2bita:after {
	position: absolute;
	right: 20px;

	display: block;
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiM1RkNBNDMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS41NTMxIDE2LjczMUwyMC4zOTE5IDcuODkyMTdMMTguNjI0MiA2LjEyNDRMOS43ODUzOCAxNC45NjMyTDUuNjYwOTggMTAuODM4OEwzLjg5MzIxIDEyLjYwNjVMOC4wMTc2MSAxNi43MzFMOC4wMTc1NiAxNi43MzFMOS43ODUzMiAxOC40OTg4TDkuNzg1MzggMTguNDk4N0w5Ljc4NTc3IDE4LjQ5OTFMMTEuNTUzNSAxNi43MzEzTDExLjU1MzEgMTYuNzMxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
	content: '';
}

.header__text-2r7Nf {
	display: flex;
	align-items: center;
	min-height: 30px;

	margin-right: 20px;

	font-weight: 600;
}

.list-20MzX {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

.list__item-1ZtbX {
	width: 100%;

	line-height: 26px;
}

.list__cities-1IgVg {
	display: inline-block;
	min-width: 100px;
	max-width: 86%;

	color: #292929;
	font-weight: 500;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.list__services-3Daeg {
	display: block;
	min-width: 100px;
	margin-bottom: 5px;

	color: #595959;
}

.icon-I4QdO {
	margin-right: 12px;
	width: 28px;
	height: 28px;

	color: #9a9a9a;
}

.header__price-1VWJY {
	display: flex;
	align-items: center;

	opacity: 0.7;
}

.service_active-2-O4D .header__price-1VWJY {
	padding-right: 32px;

	color: #9a9a9a;

	opacity: 1;
}

.main-1ToFd {
	z-index: 1;

	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 20px;
}

.main-1ToFd:not(.service_active-2-O4D) {
	/* padding-top: 0; */
	display: flex;
}

.content-2B4yu {
	flex: 1;

	font-weight: 500;
	font-size: 16px;
}

.content-2B4yu a {
	color: rgba(255, 255, 255, 0.8);

	transition: color 0.15s;
}

.content-2B4yu a:hover {
	color: var(--white);
}

.service_active-2-O4D .content-2B4yu {
	font-weight: 400;
	font-size: 14px;
	color: var(--text-base);
}

.service_active-2-O4D .content-2B4yu a {
	color: var(--brand-1-0);
}

.buttons-2lyfw {
	display: flex;
	padding-top: 15px;
}

.service_active-2-O4D .buttons-2lyfw {
	margin-left: -8px;
}

button.add-253ES {
	border: 2px solid rgba(255, 255, 255, 0.4);

	color: #ffffff;

	background: none;

	transition: border-color 0.2s;
}

button.add-253ES:hover {
	border-color: #ffffff;

	background: none;
}

@media screen and (max-width: 600px) {
	.service-2jnxW {
		max-width: 100%;
	}
}
