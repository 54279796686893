

.transitTooltip__title-AdQLf {
	text-align: center;
}

div.transitTooltip-3FpLI {
	pointer-events: all;
}

div.transitTooltip-3FpLI a {
	color: var(--warning-stroke-hover);
}

.transitTooltip__text-_MJ-h {
	display: flex;
	align-items: center;
	margin-bottom: 2px;

	color: var(--brand-1-0);
	font-size: 14px;
}

.transitTooltip__text-_MJ-h svg {
	flex-shrink: 0;
	margin-left: 4px;
	margin-bottom: 2px;
}
