div.paper-EEoHt {
	max-width: 420px;
	padding: 30px 40px 40px;

	border-radius: 12px;
}

.closeIcon-1zOwb {
	top: 8px;
	right: 8px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-pale);
	font-size: 14px;

	background: var(--bg-fill);
}

.closeIcon-1zOwb svg {
	transform: scale(0.55);
}

.title-1njSl {
	display: flex;
	align-items: center;
	margin-bottom: 28px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
}

.info-3zMSa {
	margin-bottom: 20px;

	color: var(--text-light);
	font-weight: 400;
	line-height: 1.3;
}

.title__icon-1bWC9 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 16px;
	padding: 3px;

	border-radius: 50%;

	color: var(--error-icon);

	background: var(--error-bg);
}

.description-vtj3S {
	font-size: 18px;
	color: var(--text-mid);
	line-height: 23px;
}

.buttons-1m041 {
	margin-top: 20px;
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.buttons-1m041 button {
	width: 50%;
	height: 53px;

	font-size: 18px;
}

/* stylelint-disable */
.button_normal-IhRa0 {
}
.button_accent-3WZIn {
}
.button_disabled-3qn_r {
}
/* stylelint-enable */
div.button_loader-23cfS {
	display: flex;
	justify-content: center;
	align-content: center;
	width: 50%;
	height: 53px;

	border-radius: 4px;

	background: var(--bg-elements);
}

div.button_loader-23cfS div {
	height: auto;
}

.input_wrapper-2T-jS .description-vtj3S {
	margin-top: 18px;

	font-size: 16px;
}

.resend_timer-244Mi {
	margin-top: 20px;

	color: var(--text-base);
}

.resend_timer-244Mi span {
	font-size: 14px;
}

.resend_timer__link-3AnBn {
	text-decoration: underline;
}

@media screen and (max-width: 600px) {
	div.paper-EEoHt {
		min-height: auto;
		max-width: none;
		padding: 30px 20px;

		border-radius: 12px 12px 0 0;
	}
}
