

.container-3Y9K0 {
	position: relative;

	display: flex;
	flex-direction: column;
	width: 114px;
}

.container_row-1eYnn {
	position: absolute;
	bottom: 100%;

	flex-direction: row;
	width: auto;
	padding-left: 112px;

	border: 1px solid #d9d9d9;
}

.container_col-V7qd6 {
	flex-direction: column;
}

.wrapper-15h6x {
	display: flex;
}

.container_col-V7qd6 .wrapper-15h6x {
	flex-direction: column;
}

.day-2FCgS {
	width: 114px;
	height: 32px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 13px;
	line-height: 32px;
	text-align: center;

	background: #f8f8f8;
}

.container_row-1eYnn .day-2FCgS {
	border: none;
	border-left: 1px solid #d9d9d9;
}

.container_col-V7qd6 .day-2FCgS {
	border: 1px solid #d9d9d9;
	border-top: none;
}

.day_dow-Cranl {
	padding-left: 6px;

	color: var(--light-gray);
	font-weight: 500;
}

.nav-3fD00 {
	position: absolute;

	display: flex;
	align-items: center;
}

.container_row-1eYnn .nav-3fD00 {
	bottom: 100%;

	margin-bottom: 9px;
}

.container_col-V7qd6 .nav-3fD00 {
	top: 50px;
	left: -73px;

	flex-direction: row-reverse;

	transform: rotate(-90deg);
}

.label-1i1cI {
	padding: 0 12px;

	font-size: 14px;
	font-weight: 500;
}

.container_col-V7qd6 .label-1i1cI {
	padding-right: 0;
}

.container_row-1eYnn .label-1i1cI {
	padding-left: 0;
}

.arrows-sxSht {
	display: flex;
}

.arrow_next-3-t0P,
.arrow_prev-3BqC4 {
	width: 8px;
	height: 12px;
	display: block;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQxIDBMMCAxLjQxTDQuNTggNkwwIDEwLjU5TDEuNDEgMTJMNy40MSA2TDEuNDEgMFoiIGZpbGw9IiNDRENEQ0QiLz4KPC9zdmc+Cg==);
	cursor: pointer;
}

.arrow_prev-3BqC4 {
	transform: rotate(-180deg);
}

.arrow_next-3-t0P {
	margin-left: 18px;
}
