.account__title-YbqsZ {
	margin-top: 0;
	margin-bottom: 24px;

	font-size: 28px;
	color: var(--dark-gray);
	font-weight: 500;
}

.account__field-2Voq4:not(:last-of-type) {
	margin-bottom: 25px;
}

div.card-2dY07 {
	box-sizing: border-box;
}

div.account__deleteModal-3qEeC {
	overflow: visible;

	min-width: 600px;
}

.account__content-3psc3 {
	border-radius: inherit;
}

.phone__input-3_Eph {
	margin-top: 24px;
}

.social__networks-2wR7T {
	display: flex;
	flex-direction: column;
	padding: 20px 40px 60px 40px;

	color: #9a9a9a;
}

.social__buttons-30cQf {
	display: flex;
}

.social__buttons-30cQf button {
	position: relative;

	display: flex;
	align-items: flex-end;
	width: 160px;
	height: 44px;

	font-size: 13px;
}

.social__buttons__logo-3qhQQ {
	margin-right: 8px;
	display: flex;
	align-items: center;
}

.social__buttons__wrapper-2ZmiS {
	position: relative;
}

.social__buttons__wrapper-2ZmiS:nth-child(2) {
	margin: 0 30px;
}

.social__buttons__control-CHwqi {
	position: absolute;
	right: -14px;
	top: 7px;

	z-index: 1000;

	height: 28px;

	border-radius: 50%;
	border: 4px solid #ffffff;

	cursor: pointer;

	background-color: #ffffff;
}

.social__buttons__control-CHwqi svg {
	height: 20px;
	width: 20px;
}

.social__buttons-30cQf button.facebook-1IAUt {
	border-color: #3c5b96;

	color: #ffffff;

	background-color: #3c5b96;
}

.social__buttons-30cQf button.twitter-175WG {
	border-color: #59adeb;

	color: #ffffff;

	background-color: #59adeb;
}

.hidden-1ETVw {
	display: none;
}

.account__offers-5dbh0 {
	color: var(--text-base);
}

.account__offers-5dbh0 p {
	margin: 0;
}

.account__offers-5dbh0 .checkbox-38289 {
	display: flex;
	padding: 0;
	margin-right: 11px;
}

.account__offers-5dbh0 label {
	margin-left: -2px;
}

span.colorSecondary-3BoFh:hover {
	background-color: var(--brand-1-5);
}

.account__offers-5dbh0 .checkbox-38289.checked-3wJa4 {
	color: var(--cabinet-action);
}

button.deleteButton-Rpyyo {
	font-size: 14px;
	font-weight: 400;
	color: var(--text-light);
}

.account__footer-yfocB .divider-zSgFR {
	height: 100%;
	width: 1px;
	margin: 0 12px;

	border-right: 1px solid #d9d9d9;
}

.terms__modal-3-JCW {
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #ffffff;
}

.loader__wrapper-1A23x {
	position: relative;
}

span.mui__label-1dcFc {
	font: var(--normal-desktop);
}

.account__subscription-oFQ6i {
	margin-bottom: 28px;
}

.subscription__title-1epx2 {
	margin-bottom: 6px;

	font-weight: 700;
}

.subscription__value-3F1on {
	color: var(--text-mid);
}

.subscription__loader-GZpd- {
	max-height: 24px;
}

@media (max-width: 1024px) {
	.account__field-2Voq4 {
		padding: 16px 20px;
	}

	.account__field-2Voq4:not(:last-of-type) {
		border-bottom: 1px solid var(--line-separator);
	}

	.account__offers-5dbh0 {
		padding: 4px 20px 16px;
	}

	.loader__wrapper-1A23x {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	.loader-czcbi {
		margin: 16px 20px 0;
	}

	.account__title-YbqsZ {
		font-size: 22px;
	}

	.social__networks-2wR7T {
		padding: 20px;
	}

	.social__buttons-30cQf {
		flex-direction: column;
	}

	.account__offers-5dbh0 .checkbox-38289 {
		margin-right: 12px;
	}

	.account__footer-yfocB {
		flex-direction: column;
		padding: 20px;
	}

	.account__subscription-oFQ6i {
		margin-bottom: 20px;
	}

	.subscription__title-1epx2 {
		margin-bottom: 12px;
	}

	.account__offers-5dbh0 .mui__label-1dcFc {
		font-size: 14px;
		line-height: 18px;
	}

	.account__footer-yfocB p {
		height: auto;
		margin: 0;
	}

	.account__footer-yfocB p:last-child {
		margin-top: 20px;
	}

	.account__footer-yfocB svg {
		margin-right: 18px;
	}
}

@media screen and (max-width: 380px) {
	.account__field-2Voq4,
	.account__offers-5dbh0 {
		padding: 16px;
	}
}
