.wrapper-2EuuD {
	position: static;

	margin: 30px 40px;
	padding: 0;

	border: 0;
}

@media (max-width: 1024px) {
	.monthsList-2RDDm {
		text-align: center;
	}
}

@media (max-width: 1024px) {
	.wrapper-2EuuD nav {
		display: none;
	}

	.wrapper-2EuuD > div {
		text-align: center;
	}
}

.nav_next-2ArH5,
.nav_prev-1Rf34 {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: -15px;

	width: 32px;
	height: 32px;
	margin: auto;

	border-radius: 50%;

	color: transparent;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI1IiByPSIxNiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNMjcuNTkgMTlMMjkgMjAuNDFMMjQuNDIgMjVMMjkgMjkuNTlMMjcuNTkgMzFMMjEuNTkgMjVMMjcuNTkgMTlaIiBmaWxsPSIjQTVBNUE1Ii8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHk9IjEiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=) center no-repeat;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.nav_next-2ArH5 {
	right: -15px;
	left: auto;

	transform: rotate(180deg);
}

.nav_next-2ArH5:hover,
.nav_prev-1Rf34:hover {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI1IiByPSIxNiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNMjcuNTkgMTlMMjkgMjAuNDFMMjQuNDIgMjVMMjkgMjkuNTlMMjcuNTkgMzFMMjEuNTkgMjVMMjcuNTkgMTlaIiBmaWxsPSIjMzI5MEZGIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHk9IjEiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=) center no-repeat;
}

.monthsList-2RDDm {
	display: flex;
}

@media (max-width: 1024px) {
	.monthsList-2RDDm {
		display: block;
	}

	.monthsList_withPrice-2eawm {
		flex-direction: column;
		grid-gap: 30px;
		gap: 30px;
	}
}
