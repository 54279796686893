.container-2OSyH {
	display: flex;
	align-items: center;
}

.barcode-7LBXi {
	display: none;
}

.barcode__disabled-167sQ {
	opacity: 0.3;

	filter: grayscale(1);

	pointer-events: none;
}

button.control-35wjz {
	margin-right: 8px;

	border-radius: 6px;
}

/* stylelint-disable */
.control-35wjz {
}
/* stylelint-enable */
button.controlDownload-2DvtT {
	width: 32px;
	min-width: 32px;
	height: 32px;
	padding: 0;

	border-radius: 50%;
}

button.controlDownload-2DvtT svg {
	width: 50%;
	height: 50%;
}

.controlImg-1tfdf {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 34px;

	cursor: pointer;

	transition: opacity 0.15s;
}

.controlImg-1tfdf img {
	height: 100%;
}

.controlImg__disabled-2scAr {
	opacity: 0.6;

	pointer-events: none;
}

.controlSegment-SuC4L {
	display: flex;
	align-items: center;
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.barcode-7LBXi {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
