

.rememberMe-2kdZn {
	margin: 20px 0;
	padding: 0;
	align-self: flex-start;
}

.rememberMe-2kdZn:before {
	opacity: unset;
}

.rememberMe-2kdZn:after {
	background: var(--line-separator);
}

.rememberMe-2kdZn.rememberMeIsActive-27_M3:after {
	background: var(--brand-1-0);
}

.rememberMeLabel-1jda3 {
	color: var(--text-mid);

	font-size: 14px;

	opacity: 1;
}
