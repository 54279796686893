

.wrapper-2tlls {
	position: relative;

	display: inline-flex;
	align-items: center;
	margin-right: 20px;

	color: var(--gray50);
	font-size: 13px;
	line-height: 22px;
}

.wrapper-2tlls svg {
	position: absolute;
	left: 0;
	top: 0;
}

.wrapper-2tlls span {
	padding-left: 24px;
}
