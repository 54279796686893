

.segment-3_slH {
	display: block;
}

div.aircraftModal__root-1vxAW {
	z-index: 1502 !important;
}

.dates-1AXlc {
	display: flex;
	align-items: center;
}

.date-1rPW8 {
	position: relative;

	display: flex;
	align-items: center;

	color: #1f1f1f;
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	font-family: 'Roboto Condensed', sans-serif;
}

.segment_filled-2xiP3 .date-1rPW8 {
	color: #ffffff;
}

.segment_filled-2xiP3 div.date-1rPW8:after {
	border: 1px solid #ffffff;

	background: transparent;
}

.date-1rPW8:first-of-type:after {
	margin-left: 10px;
}

.date-1rPW8:last-of-type {
	flex-direction: row-reverse;
}

.date-1rPW8:last-of-type:after {
	margin-right: 10px;
}

.date-1rPW8:first-of-type:after,
.date-1rPW8:last-of-type:after {
	display: flex;

	width: 7px;
	height: 7px;

	border: 1px solid #a5a5a5;
	border-radius: 50%;

	background: #ffffff;
	content: '';
}

.date_nextDayArrival-3bAeZ {
	position: absolute;
	top: -10px;
	right: -11px;

	color: var(--dark-red);
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
}

.timeEnRoute-3hYrT {
	position: relative;

	display: flex;
	align-items: center;

	flex-basis: 100%;

	font-size: 14px;
	line-height: 1.15;
	text-align: center;
}

.timeEnRoute-3hYrT:before,
.timeEnRoute-3hYrT:after {
	display: flex;
	flex: 1;
	height: 1px;

	background: #a5a5a5;
	content: '';
}

.segment_filled-2xiP3 .timeEnRoute-3hYrT:before,
.segment_filled-2xiP3 .timeEnRoute-3hYrT:after {
	background: #ffffff;
}

.timeEnRoute_text-3rHWB {
	position: relative;

	padding: 0 8px;

	font-weight: 500;

	background: #ffffff;
}

.segment_filled-2xiP3 .timeEnRoute_text-3rHWB {
	color: #ffffff;

	background: transparent;
}

.short-2RnMl {
	padding-top: 8px;

	color: #777777;
	font-size: 14px;
	text-align: center;
}

.segment_filled-2xiP3 .short-2RnMl {
	color: #ffffff;
}

.airports-BHXDw {
	display: flex;
	margin-top: 5px;
	justify-content: space-between;
}

.airport-DKSEB {
	max-width: 50%;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 14px;
}

.airport__city-1ex-a {
	line-height: 20px;
}

.airport__name-oRbtg,
.airport__terminal-3z8g0 {
	font-size: 13px;
	line-height: 16px;
	font-weight: 400;

	opacity: 0.7;
}

.airport-DKSEB:last-child {
	text-align: right;
}

.details-3xnVe {
	margin-top: 12px;

	border-top: 1px dashed #d9d9d9;
}

.details__summary-Yf9Ux {
	display: flex;
	align-items: center;

	color: var(--common-gray);
	font-size: 13px;
	line-height: 1.15;

	opacity: 1;
}

.details__logo-kdPgz,
.details__eco-2t4V6 {
	display: inline-flex;
	align-items: center;
}

.details__logo-kdPgz {
	width: 20px;
	height: 20px;
}

.details__logo-kdPgz img {
	width: 100%;
	height: 100%;
}

.details__summary-Yf9Ux span:not(:first-child) {
	padding-left: 5px;
}

.details__summary-Yf9Ux span:not(:first-child):before {
	content: '\2022';

	padding-right: 5px;
}

.details__short-boFzM {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 40px;
	margin-bottom: 10px;

	cursor: pointer;
}

.details__arrow-39dYG {
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMi42ODM2IDEuNDFMMTEuMTk5MSAwTDYuMzY2NzYgNC41OEwxLjUzNDM5IDBMMC4wNDk5MzMgMS40MUw2LjM2Njc2IDcuNDFMMTIuNjgzNiAxLjQxWiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K) center no-repeat;

	transition: transform 0.15s;
}

.opened-306O3 .details__arrow-39dYG {
	transform: rotate(180deg);
}

.details__full-3KCLX {
	display: block;
	margin-bottom: 20px;
}

.statistics-3mByP {
	margin: 0 0 16px;
	padding: 8px 12px;

	border-radius: 3px;

	background-color: #ededed;
}

.statistics__item-26aHv {
	display: flex;
	justify-content: space-between;

	font-size: 13px;
	line-height: 20px;
}

.statistics__item__title-nB_GH {
	color: var(--common-gray);
}

.statistics__item__value-2lB6k {
	color: #464646;
}

button.aircraft-n0m7Q {
	display: block;
	width: 100%;

	text-align: left;
}

.aircraft__name-1CPTw {
	color: #1f1f1f;
	font-size: 16px;
	font-weight: 500;
}

.aircraft__image-1O8B5 {
	height: auto;
	max-width: 85vw;
	margin: 10px auto 20px;
}

.aircraft__features-2zCDD {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.aircraft__features__moreInfo-25z84 {
	position: relative;

	padding-right: 30px;

	color: var(--common-gray);
	font-size: 14px;

	cursor: pointer;
}

.aircraft__features__moreInfo-25z84:after {
	position: absolute;
	top: -4px;
	right: 0;

	display: block;
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxjaXJjbGUgcj0iMTIiIHRyYW5zZm9ybT0ibWF0cml4KDAgLTEgLTEgMCAxMiAxMikiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xMC40MSA2TDkgNy40MUwxMy41OCAxMkw5IDE2LjU5TDEwLjQxIDE4TDE2LjQxIDEyTDEwLjQxIDZaIiBmaWxsPSIjNzc3Nzc3Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

.airport__info__name-P1W1T {
	margin-top: 25px;
	margin-bottom: 10px;

	color: #1f1f1f;
	font-weight: 500;
	font-size: 16px;
}

.airport__info-3WKTG {
	position: relative;

	width: 100%;
	min-width: 93px;

	display: flex;

	background-color: var(--common-blue);
}

button.airport__info__image-3seUt {
	position: relative;

	flex-grow: 1;

	color: #ffffff;

	background-image: url('https://cdn.websky.aero/content/frontend/images/mock-airport.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	cursor: pointer;
}

.airport__info__icon-3I_gP {
	position: absolute;
	right: 10px;
	bottom: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.weather-1g4Jx {
	position: relative;

	height: 100%;
	width: 102px;
}

.weather__value-l0hr2 {
	position: absolute;
	right: 8px;
	bottom: 5px;

	color: #ffffff;
	font-weight: 300;
	font-size: 40px;
}

.weather__icon-3ypn- {
	position: absolute;
	top: 6px;
	left: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: #ffffff;
}
