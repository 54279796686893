

.amenities-9fvq- {
	display: flex;
	align-items: center;
	min-height: 20px;
	margin-left: 12px;
}

.amenities__item-1LyLb {
	display: inline-flex;
	width: 16px;
	height: 16px;
	align-items: center;
	justify-content: center;

	color: var(--common-blue);

	cursor: help;
}

.amenities__item-1LyLb:not(:last-of-type) {
	margin-right: 5px;
}

.amenities__item_unavailable-3enMo {
	color: var(--light-gray);
}
