

.footer-XRV54 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
}

.footer__price-5TefD {
	color: var(--baggage-additional-money-color);
	font-size: 22px;
	font-weight: 600;
}

.price__from-3MYvQ {
	font-size: 15px;
}

button.footer__button-1zcBy {
	min-height: 48px;

	border-radius: 24px;

	color: #ffffff;

	font-weight: 600;
	font-size: 13px;
	line-height: 18px;

	background-color: var(--baggage-additional-money-color);
}

.footer_selected-fP6S8 .footer__price-5TefD {
	color: #686868;
}

.footer_selected-fP6S8 button.footer__button-1zcBy {
	color: var(--dark-gray);

	background-color: #ededed;
}
