

.wrapper-aDJrI {
	max-width: 600px;
	margin: 0 20px 8px 20px;
	padding: 13px 20px 16px 20px;

	border-radius: 8px;

	color: #414141;

	background: #ffffff;
}

.wrapper_disabled-3a0C7 {
	opacity: 0.6;
}

.flightInfo_mobile-hvxq2 {
	display: flex;
	flex-direction: column;
}

.flightInfo__iatas-2tKFm {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 40px;
	font-weight: 600;
	line-height: 150%;
}

.planeIcon-1kRaz {
	display: flex;
	align-items: center;
	margin: 0 8px;

	transform: rotate(180deg);

	overflow: hidden;
}

.planeIcon-1kRaz:after,
.planeIcon-1kRaz:before {
	max-width: 45%;

	color: #bababa;

	font-size: 18px;
	line-height: 24px;
	letter-spacing: 3px;

	transform: translateY(-6px);
	content: '...............................';
	overflow: hidden;
}

.planeIcon-1kRaz svg {
	min-width: 24px;
	margin: 0 4px;

	color: #bababa;

	transform: rotate(180deg) scale(1.2);
}

.flightInfo__cities-3dpqX,
.flightInfo__dates-2fYWs {
	display: flex;
	justify-content: space-between;

	font-size: 13px;
	line-height: 150%;
}

.flightInfo__cities-3dpqX {
	position: relative;
	top: -10px;

	margin-bottom: 4px;

	color: #9a9a9a;
	font-size: 13px;
}

.flightInfo__cities-3dpqX > span,
.flightInfo__dates-2fYWs > span {
	max-width: calc(50% - 8px);
}

.flightInfo__cities-3dpqX > span:last-child,
.flightInfo__dates-2fYWs > span:last-child {
	text-align: right;
}

.flightInfo__time-1Kw6o {
	display: flex;
	justify-content: space-between;

	line-height: 150%;
	font-weight: 500;
}

.flightInfo__weather-LgWmL {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}

.weather-13YLf {
	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
}

.weather__icon-23ISj {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.fareGroup-PkHAn {
	display: flex;
	align-items: center;
	padding: 10px 20px;

	border-top: 1px dashed rgba(255, 255, 255, 0.4);
	border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
}

.fareGroup__img-2Cmt4 {
	height: 36px;
	width: 36px;
	margin-right: 18px;

	border-radius: 2px;

	background-position: center;
	background-size: cover;
}

.fareGroup__info-1EEAE {
	display: flex;
	flex-direction: column;

	font-size: 12px;
	line-height: 150%;
}

.fareGroup__info-1EEAE .flightNumber-2wYti {
	margin-top: 2px;

	font-size: 16px;
	font-weight: 500;
}

.arrivalInfo-2NH1M {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 12px;
}

.arrivalInfo__place-3GdyG {
	display: flex;
}

.arrivalInfo__item-2NJ3t {
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 150%;
	font-weight: 500;
}

.arrivalInfo__title-2y6J1 {
	margin-bottom: 2px;

	font-size: 12px;
	line-height: 150%;
	font-weight: 400;
}

.arrivalInfo__place-3GdyG .arrivalInfo__item-2NJ3t:first-child {
	margin-right: 20px;
}

.mobileSegment__bottom-zz0TZ {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -15px;
	margin-right: -12px;

	color: #727272;
	font-size: 16px;
	line-height: 20px;
}

span.checkbox-3_W-z {
	position: relative;
	top: -1px;
}

span.checkbox_checked-3CCmw.checkbox-3_W-z {
	color: var(--common-blue);
}
