.wrapper-1jaMY {
	position: relative;

	min-height: 40px;
}

.mostPopular-3R82i {
	min-height: auto;
}

.bottomPrice-1GQLU {
	min-height: unset;
	margin-top: 20px;
}

.content-1IDfm {
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 10px;
	gap: 10px;
	width: 100%;
	min-height: 40px;
}

.contentFlex-3Dnww {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 10px;
	gap: 10px;
	width: 100%;
}

.contentFlex-3Dnww.priceOnly-1Xg62 {
	justify-content: flex-end;
}

.mostPopular-3R82i .content-1IDfm {
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-end;
	min-height: auto;
	grid-gap: 0;
	gap: 0;
}

.bold-FgklP {
	color: var(--text-mid);
	font: var(--medium--1-mobile-bold);
}

.regular-2L5t6 {
	color: #4d4d4d;
	color: var(--text-mid, #4d4d4d);
	font: var(--medium--1-desktop);
}

.light-3hfKT {
	color: #808080;
	color: var(--text-light, #808080);
	font: var(--small-desktop);
}

a.link-3RgyE {
	display: inline-flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;

	text-decoration: none;
	line-height: 18px;
}

a.link-3RgyE > svg {
	width: 18px;
	min-width: 18px;
	height: 18px;
}

button.button-2ZscD {
	min-width: auto;
	padding: 0 16px;
}

.price-2Vlf0 {
	display: flex;
	align-items: center;
}

.forAllPassengers-3w0Sh .price-2Vlf0 {
	flex: 1;
	flex-direction: column;
	align-items: normal;
}

.forAllPassengers-3w0Sh .money-3nO9f {
	padding-left: 0;
}

.label-59N34 {
	white-space: nowrap;
}

@media screen and (max-width: 600px) {
	.content-1IDfm {
		position: relative;
	}
}
