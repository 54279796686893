

.loyalty-2TI-b {
	border-radius: 4px;

	color: #727272;
	font-size: 14px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

.loyalty__header-JAF8e {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px 12px;

	border-bottom: 1px dashed #e4e4e4;
}

.loyalty__header-JAF8e:before {
	position: absolute;
	left: 12px;

	display: flex;
	width: 28px;
	height: 28px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQiIGN5PSIxNCIgcj0iMTQiIGZpbGw9IiNGRkIxM0MiLz4KPHBhdGggZD0iTTEzLjYxOTYgOC4xNzA4MkMxMy43MzkzIDcuODAyMyAxNC4yNjA3IDcuODAyMyAxNC4zODA0IDguMTcwODJMMTUuNDgxOCAxMS41NjA1QzE1LjUzNTMgMTEuNzI1MyAxNS42ODg5IDExLjgzNjkgMTUuODYyMiAxMS44MzY5SDE5LjQyNjNDMTkuODEzOCAxMS44MzY5IDE5Ljk3NDkgMTIuMzMyNyAxOS42NjE0IDEyLjU2MDVMMTYuNzc4IDE0LjY1NTRDMTYuNjM3OCAxNC43NTczIDE2LjU3OTIgMTQuOTM3OCAxNi42MzI3IDE1LjEwMjZMMTcuNzM0MSAxOC40OTIzQzE3Ljg1MzggMTguODYwOCAxNy40MzIgMTkuMTY3MyAxNy4xMTg1IDE4LjkzOTVMMTQuMjM1MSAxNi44NDQ2QzE0LjA5NDkgMTYuNzQyNyAxMy45MDUxIDE2Ljc0MjcgMTMuNzY0OSAxNi44NDQ2TDEwLjg4MTUgMTguOTM5NUMxMC41NjggMTkuMTY3MyAxMC4xNDYyIDE4Ljg2MDggMTAuMjY1OSAxOC40OTIzTDExLjM2NzMgMTUuMTAyNkMxMS40MjA4IDE0LjkzNzggMTEuMzYyMiAxNC43NTczIDExLjIyMiAxNC42NTU0TDguMzM4NTYgMTIuNTYwNUM4LjAyNTA4IDEyLjMzMjcgOC4xODYxOSAxMS44MzY5IDguNTczNjggMTEuODM2OUgxMi4xMzc4QzEyLjMxMTEgMTEuODM2OSAxMi40NjQ3IDExLjcyNTMgMTIuNTE4MiAxMS41NjA1TDEzLjYxOTYgOC4xNzA4MloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
	content: '';
}

.loyalty__name-BFwfc {
	padding-left: 40px;

	color: #414141;
	font-weight: 700;
	font-size: 16px;
}

.loyalty__miles-2ryBX {
	padding-right: 24px;
}

.loyalty__hint-3Z5zE {
	position: absolute;
	right: 12px;

	width: 20px;
	height: 20px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjUgOS45OTk4NEMxLjY2NjUgNS4zOTk4NCA1LjM5OTg0IDEuNjY2NSA5Ljk5OTg0IDEuNjY2NUMxNC41OTk4IDEuNjY2NSAxOC4zMzMyIDUuMzk5ODQgMTguMzMzMiA5Ljk5OTg0QzE4LjMzMzIgMTQuNTk5OCAxNC41OTk4IDE4LjMzMzIgOS45OTk4NCAxOC4zMzMyQzUuMzk5ODQgMTguMzMzMiAxLjY2NjUgMTQuNTk5OCAxLjY2NjUgOS45OTk4NFpNMTAuODMzMiA5LjE2NjUxVjE0LjE2NjVIOS4xNjY1VjkuMTY2NTFIMTAuODMzMlpNOS45OTk4MyAxNi42NjY1QzYuMzI0ODMgMTYuNjY2NSAzLjMzMzE2IDEzLjY3NDggMy4zMzMxNiA5Ljk5OTgzQzMuMzMzMTYgNi4zMjQ4MyA2LjMyNDgzIDMuMzMzMTcgOS45OTk4MyAzLjMzMzE3QzEzLjY3NDggMy4zMzMxNyAxNi42NjY1IDYuMzI0ODMgMTYuNjY2NSA5Ljk5OTgzQzE2LjY2NjUgMTMuNjc0OCAxMy42NzQ4IDE2LjY2NjUgOS45OTk4MyAxNi42NjY1Wk0xMC44MzMyIDUuODMzMTdWNy40OTk4M0g5LjE2NjVWNS44MzMxN0gxMC44MzMyWiIgZmlsbD0iI0JBQkFCQSIvPgo8L3N2Zz4K);
}

.loyalty__content-6Q1RE {
	padding: 12px;
}
