.calendar-pJbfq {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	border-radius: 4px;
}

.calendar-pJbfq:first-child {
	margin-top: 20px;
}

.header-d1OfY {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	padding: 0 calc((100% - 1100px) / 2);

	color: var(--gray80);
}

.title-3pUFQ {
	display: flex;
	align-items: center;
}

.title__text-184XY {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
}

.title__cities-18-lZ {
	display: flex;
	align-items: center;

	font-weight: 600;
	font-size: 24px;
}

.title__cities-18-lZ > svg {
	flex-shrink: 0;

	margin: 0 8px;

	color: var(--base);
}

.title__icon-3qy9h {
	display: flex;
	align-items: center;

	transform: rotate(180deg);
}

.priceGraph__content-vzYn1 {
	padding: 54px 40px;
}

.priceGraph__closeModal-38eQ2 {
	top: 15px;
	right: 10px;
	z-index: 3;

	display: flex;
	align-items: center;
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: #333333;

	background: var(--gray05);
}

.priceGraph__closeModal-38eQ2 svg {
	position: relative;

	width: 28px;
	height: 28px;
}

.priceGraph__closeModal-38eQ2 > svg {
	height: 20px;
}

.title__icon_reverse-1a3qS {
	transform: rotate(0deg);
}

.ffpInfo-2T350 {
	width: 100%;
	max-width: 1100px;
}

.content-3uBrc {
	padding: 16px 0;
	max-width: 1100px;
	width: 100%;
	min-height: 132px;
}

.tabs-Ut3k_ {
	display: flex;
	align-items: center;

	white-space: nowrap;
}

.tabs__flexContainer-1dJ4V {
	height: 100%;
}

.legNumber-5603J {
	flex-shrink: 0;

	width: 32px;
	height: 32px;
	margin-right: 15px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 17px;
	line-height: 32px;
	text-align: center;

	background: var(--base15);
}

button.diagram-34wrY {
	display: inline-flex;
	height: 37px;
	padding: 0 16px;

	border-radius: 100px;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 15px;
	line-height: 37px;
}

button.diagram-34wrY svg {
	margin-left: 6px;

	opacity: 0.5;
}

button.tab-O1TJC {
	min-width: 0;
	padding: 0 12px;

	border-bottom: 3px solid transparent;

	color: #9a9a9a;
	font-family: inherit;
	font-weight: 500;
	font-size: 13px;
	text-transform: none;

	opacity: 1;

	transition: background-color 0.15s, border-bottom-color 0.15s;
}

button.tab_active-6q-ZK {
	color: var(--tab-color-active);
}

span.tab__idicator-QT0__ {
	height: 3px;

	background-color: var(--tab-color-active);
}

.priceGraph-2yemN {
	padding: 0;
}

div.priceGraph__modal-2PbWB {
	z-index: 1600 !important;
}

@media screen and (max-width: 600px) {
	.ffpInfo-2T350 {
		margin-bottom: 20px;
	}
}

@media (max-width: 1024px) {
	.title__text-184XY {
		font-size: 20px;
	}
}
