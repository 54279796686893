.passengers-MaOUN {
	position: relative;

	flex-grow: 1;
}

.passengers_isMultiCity-2H6SA {
	flex-basis: 434px;
	flex-grow: 0;
}

.passengers_isMultiCity-2H6SA.passengers_withCurrency-1jaqC {
	border-right: 1px solid var(--line-separator);
}

@media screen and (max-width: 600px) {
	.passengers-MaOUN {
		width: 100%;
		margin-right: 0;
		margin-top: 4px;
	}

	.passengers_isMultiCity-2H6SA {
		margin-top: 4px;
	}
}

.selector-zSXNC {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;

	cursor: pointer;
	overflow: hidden;
}

.selector_focused-uWmCz {
}

@media (min-width: 1024px) {
	div.tooltip__popper-2jV9- {
		white-space: nowrap;
	}

	div.tooltip-tOqeu {
		max-width: -moz-fit-content;
		max-width: fit-content;
	}
}

.passengers_isMultiCity-2H6SA .selector-zSXNC {
	border-radius: 4px;
}

.passengers_withCurrency-1jaqC.passengers_isMultiCity-2H6SA .selector-zSXNC {
	border-radius: 4px 0 0 4px;
}

.arrowIcon-2mdQT {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: calc(12px * 2);
	height: calc(12px * 2);

	color: var(--text-pale);

	cursor: pointer;

	transition: transform 0.15s;

	pointer-events: none;
}

.arrowIcon_flipped-2apsX {
	transform: rotate(180deg);
}

.arrowIcon_flipped-2apsX svg path {
	fill: var(--light-gray);
}

@media screen and (max-width: 600px) {
	.arrowIcon-2mdQT {
		display: none;
	}
}

.title-16ZvU {
	white-space: nowrap;
	overflow: hidden;

	text-overflow: ellipsis;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.selector-zSXNC {
		width: 100%;
		padding-left: 8px;
	}

	.passengers_isMultiCity-2H6SA .selector-zSXNC {
		border-radius: 0;
	}
}

@media screen and (max-width: 600px) {
	.title-16ZvU {
		padding-left: 36px;
	}
}

.menu-1Gu5m {
	position: absolute;
	z-index: 3;
	right: 0;

	min-width: 230px;
	margin-top: 4px;

	padding: 16px;

	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	background: var(--bg-white);
}

.list-1riqb {
	padding: 0;
	margin: 0;

	background: red !important;
}

.item-mdmBN {
	display: flex;
	justify-content: space-between;

	list-style-type: none;
}

.item-mdmBN:not(:first-of-type) {
	padding-top: 12px;
}

.item-mdmBN:not(:last-of-type) {
	padding-bottom: 12px;

	border-bottom: 1px solid var(--line-separator);
}

.controls-2K90m {
	display: flex;
	align-items: center;
}

.info-3crTy {
	position: relative;

	display: block;
	margin-right: 10px;
}

.info__icon-1Ny0R {
	margin-left: 4px;
	display: flex;
	align-items: center;
}

.passengerType-3aCSM {
	color: var(--text-base);
	font: var(--medium--1-desktop-accent);
}

.age-3QTgN {
	display: inline-flex;
	align-items: center;

	color: var(--text-light);
	font: var(--small-mobile);

	transition: color 0.15s;
}

.age-3QTgN:hover {
	cursor: help;
}

.increase-TUYp1,
.decrease-3jq__ {
	display: flex;
	justify-content: center;
	align-items: center;

	width: calc(12px * 2);
	height: calc(12px * 2);
	padding: 0;

	border: none;

	border-radius: 50%;

	cursor: pointer;

	background-color: var(--common-blue);

	transition: background-color 0.15s;

	appearance: none;
}

.increase-TUYp1 svg,
.decrease-3jq__ svg {
	fill: #ffffff;
}

.increase-TUYp1:hover,
.decrease-3jq__:hover {
	background-color: var(--glassy-common-blue);
}

.increase-TUYp1:disabled,
.decrease-3jq__:disabled {
	background-color: #ededed;
	cursor: default;
}

.increase-TUYp1:disabled svg,
.decrease-3jq__:disabled svg {
	fill: #cdcdcd;
}

.counter-TFeYK {
	width: 38px;

	color: var(--brand-1-0);

	font-weight: 500;
	font-size: 18px;
	text-align: center;

	transition: color 0.15s;
}

.counter_disabled-3Uu0Y {
	color: var(--text-pale);
}

.mobileMenu-1Wy6L {
	font-size: 16px;
}

.mobileMenu-1Wy6L .passengerType-3aCSM {
	font-size: 16px;
}

.mobileMenu-1Wy6L .increase-TUYp1,
.mobileMenu-1Wy6L .decrease-3jq__ {
	width: 32px;
	height: 32px;
}

.mobileMenu-1Wy6L .info-3crTy {
	flex-basis: 45%;
}

.mobileMenu-1Wy6L .age-3QTgN:hover {
	color: var(--light-gray);

	cursor: text;
}

.mobileMenu-1Wy6L .age-3QTgN:hover .info__icon-1Ny0R path {
	cursor: text;

	fill: #cdcdcd;
}

.mobileMenu-1Wy6L .info__icon-1Ny0R {
	position: absolute;
	top: 50%;
	right: 0;

	margin-left: 0;

	transform: translateY(-50%);
}

.mobileTitle--LN4s {
	margin: 0 0 16px 0;
	padding-bottom: 16px;

	border-bottom: 1px solid var(--line-separator);

	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.mobileContent-2qFEz {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

.modal__Title-j2PsD {
	flex-grow: 1;
}

/* stylelint-disable */
button.modal__closeButton-3Jx2a,
.modal__swipeBlock-2R7Rp {
}

/* stylelint-enable */
.passengerIcon-1ZtoM {
	position: absolute;

	display: flex;
	align-items: center;
}

/* stylelint-disable */
.button-17aVq {
	width: 100%;
}

/* stylelint-enable */
@media screen and (max-width: 600px) {
	.alternative-xr69S .passengerIcon-1ZtoM {
		display: none;
	}

	.alternative-xr69S .title-16ZvU {
		padding: 0;
		width: 100%;

		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}

	.alternative-xr69S .selector-zSXNC {
		padding: 0;

		height: 56px;
	}
}

@media (max-width: 1024px) {
	.passengers_isMultiCity-2H6SA .selector-zSXNC {
		border-radius: 4px 0 0 4px;
	}

	.passengers_isMultiCity-2H6SA {
		flex-basis: auto;
	}
}
