

.wrapper-3lP4s {
	width: 100%;
	max-width: 300px;
	padding: 12px 0;

	border: 1px solid var(--line-separator);
	border-radius: 6px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	cursor: pointer;
	background: var(--bg-white);
}

.aircraftImage-1_C24 {
	width: 100%;
	padding-top: 12px;

	border-top: 1px dashed var(--line-dot-line);
}

.aircraftStatistics-14Q4U {
	display: flex;
	justify-content: space-between;
	padding: 0 12px 12px;
	margin-bottom: 12px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.aircraftName-3hWs_ {
	display: inline-block;
	padding-right: 24px;

	border-right: 1px solid var(--line-separator);

	color: var(--text-base);
	font-size: 13px;
	line-height: 17px;
	text-decoration: none;
}

.wrapper-3lP4s.active-1-NQW,
.wrapper-3lP4s:hover {
	border-color: var(--brand-1-3);

	background: var(--brand-1-5);
}

.content-2R7YZ {
	display: flex;
	flex-direction: column;

	color: var(--text-light);
	font-size: 13px;
}

.header-1sIHm {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 7px;
	padding: 0 12px;

	color: var(--brand-1-0);
}

.routeInfo-1ujkp {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	padding: 0 12px;
}

.airportsInfo-2gnib {
	display: flex;
	padding-top: 6px;

	border-top: 1px dashed var(--line-dot-line);
}

.controls-31GMk {
	display: flex;
	justify-content: space-between;
	padding: 0 12px;
	align-items: center;
}

.bookLink-1OHEu {
	text-decoration: none;
	color: var(--brand-1-0);
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
}

.flightNumber-STQj4 {
	margin-right: 16px;

	font-weight: 700;
	line-height: 18px;
}

.status-pf4CC {
	padding: 2px 4px;

	border: 1px solid transparent;
	border-radius: 4px;

	line-height: 17px;
}

.departed-JPoXe {
	border-color: var(--brand-1-0);

	color: var(--brand-1-0);
}

.scheduled-34bhD {
	border-color: var(--success-20);

	color: var(--success-20);
}

.arrived-7fTV_ {
	border-color: var(--text-mid);

	color: var(--text-mid);
}

.cancelled-1CDmo {
	border-color: var(--error-text);

	color: var(--error-text);
}

.dataExpected-2Pjjf {
	border-color: var(--brand-3-0);

	color: var(--brand-3-0);
}

/* stylelint-disable */
.date-Cf2OW {
}
/* stylelint-enable */
.airportInfo-1t4vJ {
	display: flex;
	margin-bottom: 5px;
	align-items: center;
}

.time-dQYyM {
	margin-right: 4px;
	margin-left: 8px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
}

.city-284Hr {
	flex: 1;

	font-weight: 500;
	line-height: 18px;
	color: var(--text-base);
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

button.buyTicket-1kbcO {
	padding: 10px;

	font-size: 16px;
}

.duration-2w7_8 {
	position: relative;

	flex: 2;

	text-align: center;
}

.departure-2gvh0,
.arrival-3_0v7 {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.departure-2gvh0 {
	align-items: flex-start;
	padding-left: 12px;

	border-right: 1px dashed var(--line-dot-line);
}

.arrival-3_0v7 {
	padding-right: 12px;
	align-items: flex-end;
}

.durationTime-28wuZ {
	position: inherit;
	z-index: 2;

	display: inline-block;
	padding: 0 3px;

	background: var(--bg-white);
}

.wrapper-3lP4s.active-1-NQW .durationTime-28wuZ,
.wrapper-3lP4s:hover .durationTime-28wuZ {
	background: var(--brand-1-5);
}

.durationLine-2Y825 {
	position: absolute;
	top: 50%;
	left: 5%;
	z-index: 1;

	width: 90%;
	height: 1px;

	transform: translateY(-50%);
	background: var(--line-separator);
	content: '';
}

.durationLine-2Y825:after {
	right: 0;
}

.durationLine-2Y825:after,
.durationLine-2Y825:before {
	position: absolute;
	top: 50%;
	z-index: 3;

	display: block;
	width: 10px;
	height: 10px;

	border: 1px solid var(--line-separator);
	border-radius: 50%;

	transform: translateY(-53%);
	background: var(--bg-white);
	content: '';
}

.wrapper-3lP4s:hover .durationLine-2Y825:after,
.wrapper-3lP4s:hover .durationLine-2Y825:before,
.wrapper-3lP4s.active-1-NQW .durationLine-2Y825:after,
.wrapper-3lP4s.active-1-NQW .durationLine-2Y825:before {
	background: var(--brand-1-5);
}
