

.wrapper-J2Fgw {
	padding: 20px;

	border-radius: 8px;

	color: var(--text);

	background: var(--white);
}

.flightInfo-1BOdj {
	position: relative;

	display: flex;
	flex-direction: column;
}

.flightInfo__iatas-d0FCD {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 40px;
	font-weight: 600;
	line-height: 150%;
}

.planeIcon-3e7B7 {
	display: flex;
	align-items: center;
	width: 36px;
	height: 36px;

	color: #b3b3b3;

	transform: translateY(50%) rotate(180deg);
}

.planeIcon-3e7B7 > svg {
	width: 100%;
	height: 100%;
}

.flightInfo__cities-3hLy7 {
	display: flex;
	justify-content: space-between;

	text-transform: uppercase;

	font-size: 13px;
	line-height: 1.3;
}

.flightInfo__cities-3hLy7 > span {
	flex-basis: 40%;

	color: var(--gray50);
}

.flightInfo__cities-3hLy7 > span:last-child {
	text-align: right;
}

.flightInfo__time-2E5fT {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	padding-bottom: 8px;

	border-bottom: 1px dashed #c2c2c2;

	font-weight: 700;

	font-size: 18px;
	line-height: 1.3;
}

.fareGroup-2oxYz {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
}

.fareGroup__img-1sqTq {
	height: 36px;
	width: 36px;
	margin-right: 8px;

	border-radius: 2px;

	background-position: center;
	background-size: cover;
}

.fareGroup__info-28FYr {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 700;
	line-height: 130%;
}

.fareGroup_flight-1yjI- {
	margin-top: 2px;

	font-size: 16px;
	font-weight: 500;
}

.arrivalInfo-1lLDe {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.arrivalInfo__place-dRwVv {
	display: flex;
}

.arrivalInfo__item-2Pdbo {
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 1.3;
	font-weight: 500;
}

.arrivalInfo__title-1upu- {
	color: var(--gray50);
	text-transform: uppercase;

	font-size: 13px;
	line-height: 1.3;
	font-weight: 400;
}

.arrivalInfo__place-dRwVv .arrivalInfo__item-2Pdbo:first-child {
	margin-right: 20px;
}
