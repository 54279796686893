

.fareGroup_white-OTgji .title-2eoTV {
	display: flex;
	align-items: center;

	color: var(--text-mid);
	font-weight: 700;
	font-size: 22px;
}

.fareGroup_white-OTgji .info-1S-CD {
	position: static;
	position: initial;
}

.fareGroup_white-OTgji .option__title-2FVfm {
	color: var(--text-mid);
}

.fareGroup_white-OTgji .option__value-2Eugw,
.fareGroup_white-OTgji .option__icon-2UmJ7,
.fareGroup_white-OTgji .option_unavailable-31Kvx .option__title-2FVfm,
.fareGroup_white-OTgji .option_unavailable-31Kvx .option__icon-2UmJ7,
.fareGroup_white-OTgji .option_unavailable-31Kvx .option__value-2Eugw {
	color: var(--text-pale);
}

.fareGroup_white-OTgji button.button-2Uaea:hover,
.fareGroup_white-OTgji.fareGroup_Business-1RIba button.button-2Uaea:hover {
	background: var(--brand-3-1);
}

.buttons-3C9MF button.fareGroup__button-1Sc7F:hover {
	background: var(--brand-1-1);
}

div.paper_dropdown-1TWtP {
	padding: 20px 16px;

	background: var(--brand-1-5);
}

.fareGroup_recommended-1wHHi {
	border: 2px solid var(--brand-3-0);
}

.fareGroup_white-OTgji .option-3R9Pv:not(:last-of-type) {
	border-bottom-style: dashed;
}

.fareGroup_white-OTgji .keyFeature-2oVPM,
.fareGroup_white-OTgji.fareGroup_Business-1RIba .keyFeature-2oVPM {
	background: var(--brand-1-0);
}

.fareGroup_white-OTgji .keyFeature-2oVPM.keyFeature_paid-2_68q {
	background: var(--bg-elements);
}

.fareGroup-3bl-l {
	padding: 20px;
}

.slider__wrp-1u8gN .fareGroup-3bl-l {
	max-width: 100%;
}

.keyFeature__value-lRF9t {
	font-size: 14px;
	font-weight: 400;
	text-align: center;
}

.upgradeOptionWrapper-1R4hr {
	display: none;
}

.fareGroup_unavailable-10Bml {
	opacity: 1;
}

div.slider_allOnce-2Toau .slick-track {
	margin: 0 auto;
}

div.slider_allOnce-2Toau .slick-slide > div > div {
	margin-right: 0;
}

@media screen and (max-width: 375px) {
	.fareGroup-3bl-l {
		padding: 16px;
	}

	.fareGroup_white-OTgji .option__title-2FVfm {
		font-size: 13px;
	}

	.fareGroup_white-OTgji .option__value-2Eugw {
		width: 18px;
		height: 18px;
	}
}
