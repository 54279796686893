

.wrapper-35XkP {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	justify-content: center;
	align-items: center;

	box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.03);
}

.inner-37mCL {
	max-width: 1180px;
}

.title-3n-h1 {
	min-width: 307px;

	color: #333333;
	font-weight: 700;
	font-size: 24px;
	line-height: 100%;
}

.segments-3E45E {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	grid-gap: 12px;
	gap: 12px;
}

.alignmentStub-14rkU {
	display: none;
}

button.segment-3poTD {
	padding: 12px 16px;

	border-radius: 100px;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 110%;

	background: var(--bg-elements);
}

button.segment-3poTD:hover {
	background: var(--bg-elements);
}

button.segment-3poTD.segment__active-2MN1c {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.checked-3yVuJ {
	display: none;
}
