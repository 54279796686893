

.message-cP6A_ {
	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.message-cP6A_:not(:last-child) {
	margin-bottom: 20px;
}

.message_context-2jz1L .message__title-21BmR {
	margin-bottom: 2px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.message_context-2jz1L .message__text-2Q-Kc {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.message_info-26GdD .message__icon-158Og {
	color: var(--text-pale);
}

.message_info-26GdD .message__title-21BmR {
	color: var(--text-base);
}

.message_info-26GdD .message__text-2Q-Kc {
	color: var(--text-mid);
}

.message_context-2jz1L.message_warning-d-HrU .message__icon-158Og {
	color: var(--error-icon);
}

.message_context-2jz1L.message_warning-d-HrU .message__title-21BmR,
.message_context-2jz1L.message_warning-d-HrU .message__text-2Q-Kc {
	color: var(--error-text);
}
