div.card-3xIpd {
	position: relative;

	width: 335px;
	height: 112px;

	border-radius: 0;

	box-shadow: none;
}

div.card_withoutPanorama-2CTw8 {
	background: linear-gradient(180deg, #57a4fe 0%, #3290ff 100%);
}

.card__focusHighlight-LeJ9m {
	color: var(--white);
}

.card__panorama-FFIM2 {
	width: 100%;
	height: 112px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

div.card__weather-1ToRD {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	min-width: 93px;
	min-height: 88px;

	border-radius: 0;

	box-shadow: none;
}

.card__content-3cvaP {
	position: absolute;
	left: 0;
	top: 0;

	width: 100%;
	height: 112px;

	display: flex;
	justify-content: space-between;
	padding: 12px;
}

.card__weatherIcon-3VRBe {
	margin: 15px 0 0 12px;
}

.card__temperature-3VV68 {
	padding-right: 8px;

	letter-spacing: -3px;

	font-weight: 300;
	font-size: 36px;
	line-height: 54px;
	text-align: right;
}

.card__airportName-KEwGf {
	align-self: flex-end;

	margin-left: auto;

	color: #ffffff;

	font-size: 15px;
	line-height: 22px;
}

.card__airportName_withIATA-2Dm0R {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-left: 12px;
}

.card__airportIATA-1ycS_ {
	font-weight: 200;
	font-size: 72px;
	line-height: 76px;

	opacity: 0.7;
}

.card__airportLink-c2NLX {
	margin-left: auto;
}

.card__chevronIcon-1DdSZ {
	position: relative;
	top: 7px;

	display: none;
}
