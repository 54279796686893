.main-2whfm:before {
	opacity: 1;
}

.label-2obBl {
	opacity: 1;
}

.primary-3MMgC.main-2whfm:after {
	background: var(--gray20);
}

.primary-3MMgC.active-2YvyX:after {
	background: var(--base);
}

.secondary-6aWAr.main-2whfm:after {
	background: var(--gray90);
	opacity: 0.2;
}

.secondary-6aWAr.active-2YvyX:after {
	background: var(--white);
	opacity: 1;
}

.secondary-6aWAr.active-2YvyX:before {
	background: var(--base);
}

@media (max-width: 1024px) {
	.main-2whfm {
		padding: 0;
	}
}
