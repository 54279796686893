

.services-1VMeA {
	display: flex;
	margin: 8px 0;
}

.service-3EtZe {
	flex: 1;
}

div.service-3EtZe:last-child {
	margin-right: 0;
}

.service-3EtZe.selected-3ZKX9 {
	background: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.selected-3ZKX9 .header-QBMk- {
	color: var(--common-blue);
	text-decoration: underline;
}
