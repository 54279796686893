.container-OwB7n {
	padding: 20px 40px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.header-3tOk3 {
	margin-bottom: 20px;

	display: flex;
	flex-direction: column;

	color: var(--text-base);
	font-size: 22px;
	line-height: 1.3;
	font-weight: 700;
}

.description-32WwL {
	margin-top: 4px;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.row-2pfCM {
	display: flex;
	align-items: center;
}

.row-2pfCM:not(:last-child) {
	margin-bottom: 26px;
}

/* stylelint-disable */
.main-2uhMq {
}
/* stylelint-enable */
.field-2SqSo {
	flex: 1;
	width: 100%;
}

.row-2pfCM .field-2SqSo:not(:last-child) {
	margin-right: 12px;
}

.controls-34qnj {
	display: flex;
	justify-content: space-between;

	grid-gap: 12px;

	gap: 12px;

	margin-top: 28px;
}

button.submit-3sM1b,
button.submit-3sM1b:hover {
	color: #ffffff;

	background: var(--active);
}

span.button-3yRot {
	color: #9a9a9a;
}

span.button-3yRot.button_checked-39DBF {
	color: var(--base);
}

.warningModal-nQey2 a {
	color: var(--text-link);
}

@media screen and (max-width: 600px) {
	.main-2uhMq {
		flex-direction: column;
	}

	.row-2pfCM .field-2SqSo:not(:last-child) {
		margin-right: 0;
		margin-bottom: 24px;
	}

	.container-OwB7n {
		padding: 20px;
	}

	.controls-34qnj {
		flex-direction: column;
	}

	.label-xR5As {
		font-size: 14px;
	}
}
