

.withoutLabel-2lUaw input {
	padding-top: var(--input-underline-withoutLabel-pd);
	padding-bottom: var(--input-underline-withoutLabel-pd);
}

/* LabelIn / LabelOut */
.labelIn-G5V44 .label-3De9_.shrink-2x2YF {
	transform: var(--label-labelIn-shrink);
}

.labelOut-M-3QY .label-3De9_.shrink-2x2YF {
	transform: var(--label-labelOut-shrink);
}

.labelIn-G5V44 .notchedOutline-3UfxU,
.labelOut-M-3QY .notchedOutline-3UfxU {
	top: 0;
}

.labelIn-G5V44 .notchedOutline-3UfxU legend,
.labelOut-M-3QY .notchedOutline-3UfxU legend {
	display: none;
}

.labelOnLine-3HFu3 label.label-3De9_.shrink-2x2YF:before {
	position: absolute;
	top: -2px;
	right: -3px;
	bottom: -2px;
	left: -3px;
	z-index: -1;

	background: var(--white);
	content: '';
}

/* Label */
label.label-3De9_ {
	font-size: 16px;
	font-weight: 400;
	color: var(--text-light);
}

label.label-3De9_.focused-3Y1Wt {
	color: var(--brand-1-0);
}

label.label-3De9_.disabled-VdgMZ {
	color: var(--text-light);
}

label.label-3De9_.error-2fZ3j {
	color: var(--text-light);
}

/* Input */
div.input-2Y76G {
	height: 56px;
}

div.input-2Y76G,
div.input-2Y76G:hover {
	background: transparent;
}

.labelIn-G5V44 div.input-2Y76G {
	padding: 0;
}

div.input-2Y76G.focused-3Y1Wt {
	background: var(--brand-1-5);
}

div.input-2Y76G.disabled-VdgMZ {
	background: var(--bg-fill);
}

div.input-2Y76G.error-2fZ3j {
	background: var(--error-bg);
}

div.input-2Y76G.valid-6dWd0 {
	background: var(--success-bg);
}

div.input-2Y76G input {
	height: inherit;

	color: var(--text-base);
	font-weight: 400;
	font-size: 16px;
}

div.labelIn-G5V44:not(.withoutLabel-2lUaw) input,
div.labelIn-G5V44:not(.withoutLabel-2lUaw) input[aria-autocomplete] {
	padding: var(--input-labelIn-pd);
}

div.input-2Y76G.focused-3Y1Wt input {
	color: var(--text-base);
}

div.input-2Y76G.disabled-VdgMZ input {
	color: var(--text-light);
}

div.input-2Y76G.error-2fZ3j input {
	color: var(--text-mid);
}

/* Outlined */
div.outlined-2pyks,
div.outlined-2pyks:hover {
	background: var(--brand-1-5);
}

div.outlined-2pyks.focused-3Y1Wt {
	background: transparent;
}

div.outlined-2pyks.disabled-VdgMZ {
	background: var(--bg-fill);
}

div.outlined-2pyks.error-2fZ3j {
	background: var(--error-bg);
}

div.outlined-2pyks.valid-6dWd0 {
	background: var(--success-bg);
}

div.outlined-2pyks .notchedOutline-3UfxU,
div.outlined-2pyks:hover .notchedOutline-3UfxU {
	border-color: var(--brand-1-3);
}

div.outlined-2pyks.focused-3Y1Wt .notchedOutline-3UfxU {
	border-color: var(--brand-1-1);
	border-width: 1px;
}

div.outlined-2pyks.disabled-VdgMZ .notchedOutline-3UfxU {
	border-color: var(--line-separator);
}

div.outlined-2pyks.error-2fZ3j .notchedOutline-3UfxU {
	border-color: var(--error-icon);
}

div.outlined-2pyks.valid-6dWd0 .notchedOutline-3UfxU {
	border-color: var(--success-stroke-hover);
}

/* Underline */
.underline-YGWa2 div.input-2Y76G {
	border-radius: var(--input-underline-brds);
}

.underline-YGWa2 div.input-2Y76G:before,
.underline-YGWa2 div.input-2Y76G:hover:before {
	border-color: var(--line-separator);
}

.underline-YGWa2 div.input-2Y76G:after {
	display: none;
}

.underline-YGWa2 div.input-2Y76G.focused-3Y1Wt:before {
	border-color: var(--brand-1-0);
}

.underline-YGWa2 div.input-2Y76G.error-2fZ3j:before {
	border-color: var(--input-error);
}

.underline-YGWa2 div.input-2Y76G.disabled-VdgMZ:before {
	border-color: transparent;
}

.underline-YGWa2 div.input-2Y76G.valid-6dWd0:before {
	border-color: var(--success-stroke-hover);
}

/* Helper Text */
p.helperText-1la4r {
	margin-top: 4px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.1;
}

p.helperText-1la4r.focused-3Y1Wt {
	color: var(--text-light);
}

p.helperText-1la4r.error-2fZ3j {
	color: var(--error-icon);
}

p.helperText-1la4r.disabled-VdgMZ {
	color: var(--text-light);
}

/* Valid Icon */
.valid_icon-3iQm1 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 4px;

	color: var(--seat-hover-choosed);
}
