button.selector-2yoqY {
	width: 100%;
	min-height: 64px;
	padding: 0 20px;

	border: 1px solid var(--line-separator);
	border-radius: 4px;

	color: var(--brand1);
}

button.selector-2yoqY:not(:last-child) {
	margin-bottom: 12px;
}

.header-3418J {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	color: var(--text-light);
}

.header__arrow-1EcBY {
	display: flex;
	align-items: center;

	color: var(--common-gray);
	font-size: 14px;
}

.header__arrow-1EcBY > span {
	display: flex;
}

.header__arrow-1EcBY svg {
	margin-left: 12px;
}

.description-3OBjU {
	margin: 20px 0 0;

	color: var(--common-gray);
	font-size: 15px;
}

@media screen and (max-width: 600px) {
	.header-3418J {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.header__arrow-1EcBY {
		width: 100%;
		justify-content: space-between;
	}

	button.selector-2yoqY {
		position: relative;

		padding: 20px;
	}

	.arrow-1Efj6 {
		position: absolute;
		right: 20px;
		top: 0;
		bottom: 0;

		margin: auto;
		height: 20px;
		width: 20px;
	}

	.arrow-1Efj6 svg {
		margin: 0;
	}
}
