

button.root-2A5ck {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 47px;
	padding: 8px 12px;

	border: 1px solid transparent;
	border-radius: 4px;

	font: var(--medium-desktop-accent);

	background: var(--brand-3-0);
}

button.root-2A5ck:hover {
	background: var(--brand-3--1);
}

button.root-2A5ck:active {
	background: var(--brand-3--2);
}

button.disabled-1dvfW {
	color: var(--text-pale);

	background: var(--bg-elements);
}

button.disabled-1dvfW .endIcon-wr43w svg {
	color: var(--text-pale);
}

button.selected-3p_al,
button.selected-3p_al:hover,
button.selected-3p_al.disabled-1dvfW {
	border-color: var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--white);

	cursor: default;
}

button.subscription-3xUbx {
	background: var(--subscription-0);
}

button.subscription-3xUbx:hover {
	background: var(--subscription--1);
}

.startIcon-Mot6M {
	margin-top: -2px;
}

.title-PaJVr {
	margin-right: 8px;
}

.miles-1noGj,
.money_wrapper-2lFI9 {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.money-1f3IU,
.miles_amount-1S9Uv {
	font: var(--big--1-desktop-bold);
}

.money_original-v38BR {
	margin-right: 4px;
	overflow: hidden;

	text-decoration: line-through;
	text-overflow: ellipsis;

	opacity: 0.75;
}

.money_wrapper_center-ohT0U {
	margin-left: unset;
	width: 100%;
	justify-content: center;
}

.money_wrapper_center-ohT0U .money_original-v38BR {
	font: var(--small-desktop);
}

.subscription-3xUbx .money_original-v38BR {
	color: var(--subscription-3);

	opacity: 1;
}
