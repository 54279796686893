.sortings-1s_uS {
	display: flex;
	align-items: center;
}

button.flightType-1jSzz {
	min-height: 44px;
	padding-top: 0;
	padding-bottom: 0;

	margin-left: 10px;

	border-radius: 100px;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 44px;

	background: var(--white);
}

button.flightType-1jSzz:hover {
	color: var(--text-base);

	background: var(--bg-fill);
}

button.flightType-1jSzz.flightType__active-azqBq {
	position: relative;

	color: var(--white);

	background: var(--brand-1-0);
}

button.flightType-1jSzz.flightType__active-azqBq:hover {
	background: var(--brand-1-1);
}

button.flightType-1jSzz.flightType__active-azqBq:before {
	width: 18px;
	height: 18px;
	margin-right: 12px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyMCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjU1MzIgMTIuNzMwOUwxOS44NzQgMi40MTAxNEwxOC4xMDYyIDAuNjQyMzc0TDcuNzg1NDQgMTAuOTYzMkwyLjczNTM1IDUuOTEzMDlMMC45Njc1ODMgNy42ODA4NUw2LjAxNzY3IDEyLjczMDlMNi4wMTc1NCAxMi43MzExTDcuNzg1MyAxNC40OTg4TDcuNzg1NDQgMTQuNDk4N0w3Ljc4NTkgMTQuNDk5Mkw5LjU1MzY2IDEyLjczMTRMOS41NTMyIDEyLjczMDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) no-repeat 50% 50% / contain;
	content: '';
}

@media screen and (max-width: 600px) {
	.sortings-1s_uS {
		height: auto;

		line-height: 24px;
	}
}

.title-1Hq58 {
	position: relative;

	display: inline-block;
	padding-right: 15px;

	font-weight: 500;
	font-size: 14px;

	cursor: pointer;
}

.title-1Hq58:after {
	position: absolute;
	top: 50%;

	width: 10px;
	height: 5px;

	transform: translate(5px, -50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cgk8cGF0aCBkPSJNMCAwTDUgNUwxMCAwTDAgMFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) right;

	transition: transform 0.15s;
	content: '';
}

div.slideBottom-3mPOl {
	padding: 0;
}

@media screen and (max-width: 600px) {
	.title-1Hq58 {
		padding-left: 32px;

		color: var(--common-blue);
	}

	.title-1Hq58:before {
		position: absolute;
		top: 50%;
		left: 0;

		width: 24px;
		height: 24px;

		transform: translate(0, -50%);
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjAgOS45OUwxNiA2TDEyIDkuOTlMMTUgOS45OUwxNSAxN0wxNyAxN0wxNyA5Ljk5TDIwIDkuOTlaTTggNkw4IDEzLjAxTDUgMTMuMDFMOSAxN0wxMyAxMy4wMUwxMCAxMy4wMUwxMCA2TDggNloiIGZpbGw9IiNhNWE1YTUiLz4KPC9zdmc+Cg==);

		transition: transform 0.15s;
		content: '';
	}

	.title-1Hq58:after {
		display: none;
	}

	.sortBy-1VeIq {
		display: none;
	}
}

.title_open-2MYHR:after {
	transform: translate(5px, -50%) rotate(180deg);
}

.currentSorting-1ykyf {
	color: var(--common-blue);
}

div.paper-15U-E {
	min-width: 185px;
	margin-top: 40px;

	border-radius: 8px;
}

li.listItem-3HHmr {
	padding: 4px 16px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 14px;
	font-family: inherit;
}

li.listItem-3HHmr.listItem_active-1hrRx {
	position: relative;

	color: var(--common-blue);

	background: none;
}

li.listItem-3HHmr:hover,
li.listItem_active-1hrRx {
	background: #ededed;
}

.listItem_active-1hrRx:after {
	position: absolute;
	top: 0;
	right: 8px;
	bottom: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNTk5OTggMTQuNEw1LjM5OTk4IDEwLjJMMyAxMi44TDkuNTk5OTggMTkuNEwyMS42IDcuMzk5OTlMMTkuMiA1TDkuNTk5OTggMTQuNFoiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

.mobile-BPjYN {
	display: flex;
	flex-direction: column;
}

.mobile__actions-kBta_ {
	display: flex;
}

button.mobile__closeButton-2qPeI {
	margin-right: 18px;
	margin-bottom: 20px;
	margin-left: auto;

	font-weight: 600;
	font-size: 19px;
	line-height: 20px;
}

.mobile__list-1_3Aq {
	margin: 0;
	padding: 0;
	width: 100%;
}

.mobile__list_sorting-NqmcO {
	display: inline;
	display: initial;
}

.mobile__list_filter-2adzE {
	display: inline;
	display: initial;
}

.mobile__list-1_3Aq:not(:last-of-type) {
	margin-right: 20px;
}

.mobile__list__title-1LMbN {
	position: relative;

	padding: 10px 20px;

	border-radius: 12px 12px 0 0;

	color: #414141;
	font-weight: 500;
	font-size: 20px;
	line-height: 34px;

	background: #f0f0f0;
}

button.mobile__list__close-2C8h8 {
	position: absolute;
	top: 50%;
	right: 20px;

	display: inline-block;

	transform: translate(0, -50%);
	cursor: pointer;
	content: '';
}

button.mobile__list__close-2C8h8 svg {
	width: 24px;
	height: 24px;
}

.mobile__list__item-2tFsm {
	list-style-type: none;
}

.mobile__list__item-2tFsm:not(:first-of-type) {
	border-top: 1px solid #e4e4e4;
}

button.mobile__list__button-1aWS8 {
	position: relative;

	justify-content: flex-start;
	width: 100%;
	height: auto;
	padding: 16px 20px;

	border-radius: 0;

	color: #ffffff;
	font-weight: 500;
	line-height: 24px;
	font-size: 16px;
}

button.mobile__list__button-1aWS8:not(.mobile__list__button_inactive-1Qee9):before {
	position: absolute;
	right: 20px;

	display: inline-block;
	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS40NjQxIDE3LjY3NzNMMjIuMDcwNyA3LjA3MDY2TDE5Ljk0OTMgNC45NDkzNEw5LjM0Mjc0IDE1LjU1NTlMNC4zOTM0IDEwLjYwNjZMMi4yNzIwOCAxMi43Mjc5TDcuMjIxNDIgMTcuNjc3M0w5LjM0Mjc0IDE5Ljc5ODZMOS4zNDMxNSAxOS43OTlMMTEuNDY0NSAxNy42Nzc3TDExLjQ2NDEgMTcuNjc3M1oiIGZpbGw9IiNDNEM0QzQiLz4KPC9zdmc+Cg==) no-repeat center;
	content: '';
}

button.mobile__list__button_inactive-1Qee9 {
	color: #414141;

	background-color: #ffffff;
}

button.mobile__list__button_inactive-1Qee9:hover {
	background-color: #ffffff;
}

.switchWrp-lNvpC {
	padding: 28px 20px 32px 20px;

	border-top: 1px solid #e4e4e4;
}

.switch__component-2iEFB {
	width: 100%;
	padding-left: 0;

	color: #727272;
	line-height: 24px;
}

.switch__label-23MyK {
	font-size: 16px;
}

.switch__component-2iEFB:after {
	background: #dadada;
}

.switch_active-2eTmH:after {
	background: var(--switch-default-background);
}
