

.prices__wrp-3I3yV {
	display: flex;
	align-items: center;
}

.selectFrom_best-1BLQe {
	margin-bottom: 8px;

	color: var(--text-light);
	font-size: 13px;
}

.fare_family-sgFTz {
	color: var(--text-light);
}

.subsidies-33jpV {
	display: flex;
	align-items: center;
	margin-bottom: 2px;

	color: var(--brand-1-0);
	font-size: 14px;
}

.price_subsidies-1AJhn .fare_family-sgFTz {
	margin-bottom: 4px;
}

.infoMessage-ArbSw {
	padding-top: 8px;
}

div.infoMessage__htmlMessage-2UI5O {
	width: 100%;
	max-width: 140px;
	height: max-content;
}

.subsidies-33jpV svg {
	flex-shrink: 0;
	margin-left: 4px;
	margin-bottom: 2px;
}

.clear-3W7OJ {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;
	margin-top: 8px;

	border-radius: 50%;

	color: var(--brand-1-1);

	background: var(--brand-1-5);
}

.clear-3W7OJ svg {
	width: 18px;
	height: 18px;
}

.price-238cm {
	position: relative;

	display: flex;
	align-items: center;
	min-width: auto;
	justify-content: center;
	flex: 1;

	border-left: 1px dashed var(--gray20);

	color: var(--base-30);
	font-size: 14px;
}

.price-238cm.price_selected-3Koy0 {
	font-size: 14px;
	color: var(--base);
}

.price_original-16j4a {
	margin-bottom: 8px;

	color: var(--active);
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	text-align: right;
	text-decoration: line-through;
}

.price__container-1UN2g {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 20px 16px;
}

.price__money-2aUrq {
	font-weight: 600;
	font-size: 22px;
}

.price__money-2aUrq.price__money_tooBig-1MQDM {
	font-size: 17px;
}

.price__money_withPromoCode-ii1rn {
	color: #ffffff;
}

.price__money-2aUrq.price_miles-1Dubo {
	font-size: 17px;
}

.price-238cm.price_selected-3Koy0 .price__money-2aUrq {
	color: var(--text-base);
	font-size: 22px;
}

.price_miles-1Dubo .currency-2nxmg {
	padding-left: 6px;

	font-weight: 400;
	font-size: 14px;
}

.miles__taxes-3ZsZC {
	margin-top: 4px;

	color: var(--gray50);
	font-size: 14px;
}

div.seats-21__K {
	margin-top: 8px;

	color: var(--error-icon);
	font-weight: 400;
	text-align: center;

	font-size: 14px;
	line-height: 1.2;
}

.best-35IQm {
	position: absolute;
	top: 0;
	bottom: 0;
	left: -14px;

	width: 28px;
	height: 28px;
	margin: auto;

	color: var(--active);
}

/* stylelint-disable-next-line block-no-empty */
button.button-2mYvV {
}

.canceled-1IKMj {
	margin-top: 8px;

	color: var(--error);
	font-size: 14px;
}

.subsidies__tooltip-2vwVq {
	text-align: center;
}
