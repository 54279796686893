

.container-XzykO {
	width: -moz-fit-content;
	width: fit-content;
	padding: 12px;

	color: var(--bright-violet);
	font-weight: 500;
	font-size: 14px;

	background: var(--very-pale-violet);
}

.bottom-8cYHn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 9px;
}

.link-1-7Az {
	display: flex;
	align-items: center;

	color: var(--bright-violet);
	text-decoration: none;
}

.link-1-7Az svg {
	width: 15px;
	height: 15px;
}

.arrowWrapper-2eV6L {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: 8px;

	border-radius: 100px;

	background: var(--bg-white);
}

.servicesIcons-2FZZG {
	display: flex;
}

.servicesIcons-2FZZG svg {
	height: 21px;
}
