

button.passenger-Dg-uu {
	padding: 20px 27px;

	color: var(--base);

	background: var(--gray05);
}

button.passenger-Dg-uu.active-mYXtM {
	background: var(--base80);
}

.label-28Cmo {
	color: var(--gray80);
	font-size: 16px;
	line-height: 24px;
}

.label-28Cmo.active-mYXtM {
	color: var(--base);
}

span.checkbox-2VXwC.active-mYXtM {
	color: var(--base);
}
