

.root-1cZ8w {
	margin-bottom: 20px;
}

.ul-50KDP {
	display: flex;
	justify-content: center;
}

.icon-1Ird9 {
	border-radius: 4px;

	color: var(--text-light);

	cursor: pointer;
	opacity: 1;

	pointer-events: all;
}

.icon-1Ird9.icon-1Ird9:disabled,
.icon-1Ird9.icon-1Ird9:disabled svg {
	cursor: not-allowed;
}

button.button-2aQ_r {
	display: flex;

	border-radius: 4px;

	color: var(--text-mid);

	opacity: 1;
	background: var(--bg-elements);

	transition: all 0.3s;
}

button.button-2aQ_r:not(:last-child) {
	margin-right: 10px;
}

button.button-2aQ_r:hover:not(:disabled) {
	background: var(--brand-1-3);
}

div.page-3ECZe {
	border-radius: 4px;

	color: var(--text-mid);
	font-size: 14px;
	line-height: 2rem;

	background: var(--bg-white);
}

button.button-2aQ_r.selected-28apb,
button.button-2aQ_r.selected-28apb:hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}
