.stepTitle-2LvlS {
	max-width: 550px;
	margin: 0 auto 38px;
}

.buttons-2_EIK {
	margin-top: 28px;
}

div.prices-1luCe {
	margin-bottom: 12px;
}

div.wide-3lEsU {
	max-width: 1100px;
}

button.backButton-1cAj_ {
	position: relative;
}

.nasServicesNotice-29RVY {
	text-align: left;
}

.warningModalContent-3rVDI a {
	color: var(--text-link);
}

div.scrollBody-3vZiM div.paper-12jQJ {
	border-radius: 4px;
}

@media screen and (max-width: 600px) {
	.wrapper-1VMyZ .mobileStep-1k7qy:not(:first-of-type):not(.passed-2zRbQ) {
		margin-top: 8px;
	}

	div.withoutTopBorderRadius-1V_go {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	div.withoutBottomBorderRadius-2U7Q- {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	div.withTopBorder-20lYL {
		border-top: 1px solid rgba(255, 255, 255, 0.3);
	}

	.reasonDescr-3_TTJ {
		margin: 0;

		font-size: 14px;
		line-height: 19px;
		font-weight: 400;
	}

	div.prices-1luCe {
		padding: 8px 12px 0;

		border-radius: 0 0 8px 8px;
	}
}
