.container-2ozdm {
	padding: 32px;
}

.header-1rBvE {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__text-1rPbG {
	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

button.closeButton-zpQKG {
	width: 28px;
	height: 28px;
	padding: 0;
}

.flight-2_Xpc {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
	padding: 12px;

	border-radius: 4px;

	color: var(--text-base);
	font-size: 16px;

	background: var(--brand-1-5);
}

.flight__direction-3WIDg {
	display: flex;
	flex: 1;
	align-items: center;

	font-weight: 700;
}

.flight__direction-3WIDg svg {
	flex-shrink: 0;
	margin-right: 12px;

	color: var(--text-pale);
}

.passenger-2eWGx {
	display: flex;
	align-items: center;
	margin: 20px 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
}

.passenger-2eWGx svg {
	flex-shrink: 0;
	margin-right: 12px;

	color: var(--text-pale);
}

button.closeButton-zpQKG svg {
	width: 16px;
	height: 16px;
}

.notification-2oMJx {
	border: none;
}

.notification__title-4BJVm {
	font-size: 16px;
	font-weight: 700;
	color: var(--error-text);
}

.buttons-zE3Zi {
	width: 100%;
	margin-top: 16px;
	display: flex;
}

.buttons-zE3Zi button:not(:last-child) {
	margin-right: 16px;
}

button.newSearch-29yPz,
button.newSearch-29yPz:hover {
	width: 100%;

	color: var(--brand-1-0);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-1-5);
}

button.continueButton-1UBHH,
button.continueButton-1UBHH:hover {
	width: 100%;

	color: var(--bg-white);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-3-0);
}

@media screen and (max-width: 600px) {
	.buttons-zE3Zi {
		display: block;
	}

	.buttons-zE3Zi button:not(:last-child) {
		margin-right: 0;
		margin-bottom: 16px;
	}
}
