.root-2aVJS {
	min-height: 44px;
	display: flex;
	align-items: center;
	padding: 11px 16px;
}

.payload-1v4-i {
	display: flex;
	flex: 1;
	align-items: center;

	line-height: normal;
}

.icon-2qqZY {
	width: 21px;
	height: 21px;
	margin-right: 15px;

	color: #b3b3b3;
}

.icon-2qqZY svg {
	width: 21px;
	height: 21px;
}

.root-2aVJS:not(:first-child) {
	border-top: 1px solid #e3e3e3;
}

@media (max-width: 768px) {
	.root-2aVJS {
		flex-direction: column;
		align-items: flex-start;
		padding: 12px 16px;
	}

	.payload-1v4-i {
		align-items: flex-start;
	}

	.root-2aVJS.review-3AsXN:first-of-type {
		padding-top: 0;
	}

	.buttons-2lR7V {
		margin-left: 36px;
		margin-top: 12px;
	}

	.root-2aVJS.account-1iXut:last-child {
		border-radius: 0 0 8px 8px;
	}
}
