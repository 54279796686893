button.container-2hSE2 {
	display: inline-flex;
	height: 34px;
	padding: 0 12px 0 8px;

	border-radius: 4px;

	color: var(--text-light);
	font-size: 18px;

	background-color: var(--bg-fill);
}

button.container-2hSE2:hover {
	background-color: var(--bg-fill);
}

.icon-1Ck2m {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	min-width: 18px;
	height: 18px;
	margin-left: 10px;
}

.icon-1Ck2m svg {
	width: 100%;
	height: 100%;
}

button.success-2kBTs,
button.success-2kBTs:hover {
	color: var(--success-icon);

	background: var(--success-bg);
}

button.success-2kBTs .icon-1Ck2m {
	color: var(--success-icon);
}

@media screen and (max-width: 600px) {
	.container-2hSE2 {
		margin-top: 6px;
		margin-left: 0;
	}
}
