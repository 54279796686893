div div.paper-b7sQs,
.header-mjfSQ,
.title-2Vdn1,
.contentWrapper-1BNCH,
.footer-2RdH6,
.buttons-AUPa6,
.closeButton-1AU5-,
.cancelButton-1h2lx,
.confirmButton-3vTsy,
.dialogContent_root-3uUvF {
	padding: 0;
}
