

.wrapper-15Usc {
	width: 32px;
	height: 32px;

	border: none;

	color: var(--text-pale);

	background-color: var(--bg-elements);
}

.wrapper-15Usc svg {
	width: 20px;
	height: 20px;
}
