

button.button-KSWoi {
	border-radius: 6px;

	background: var(--brand-3-0);
}

button.button-KSWoi:hover {
	background: var(--brand-3--1);
}
