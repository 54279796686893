.container-3PBrF {
	position: relative;

	text-align: center;
	overflow: hidden;
}

.container_small-1NGVJ {
	min-height: 1100px;
}

.map__overlay-1hxXr {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;

	background: rgba(0, 0, 0, 0.5);
}

.seatMap__wrapper-PX2tc {
	position: relative;

	display: inline-block;
	margin-bottom: 10px;

	border-bottom: 9px solid #ededed;
}

.seatMap__wrapper_withoutWings-290Aa {
	padding-bottom: 340px;
}

.airplane__start-3vVt1 {
	position: absolute;
	top: 1px;
	right: 0;

	left: 0;

	display: none;

	width: 208px;
	height: 190px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjA4IiBoZWlnaHQ9IjE5MCIgdmlld0JveD0iMCAwIDIwOCAxOTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjA3Ljc0IDE5MEMyMDcuMTA1IDE3MS4wNDMgMjAzLjkzMyAxNDIuNDc0IDE5My44NyAxMTUuODQ4QzE4Mi4zNyA4NS40MTc3IDE0OC44NyAwIDEwMy44NyAwQzU4Ljg3MDEgMCAyNS4zNzAxIDg1LjQxNzcgMTMuODcwMSAxMTUuODQ4QzMuODA3NTYgMTQyLjQ3NCAwLjYzNTcxNSAxNzEuMDQzIDAgMTkwSDguODcwMjhDOC45MjgwNCAxNzEuNzA2IDI2LjA0OTEgMTI4LjIzNCAyNi4wNDkxIDEyOC4yMzRDMjYuMDQ5MSAxMjguMjM0IDYyLjczNTIgODguNjQzNyAxMDMuODcgODguMDA3N1Y4OEMxMDQuMDM3IDg4IDEwNC4yMDQgODguMDAwNiAxMDQuMzcgODguMDAxOUMxMDQuNDQ1IDg4LjAwMTQgMTA0LjUyIDg4LjAwMDkgMTA0LjU5NSA4OC4wMDA2QzEwNC42ODcgODguMDAwMiAxMDQuNzc4IDg4IDEwNC44NyA4OFY4OC4wMDc3QzE0Ni4wMDUgODguNjQzNyAxODIuNjkxIDEyOC4yMzQgMTgyLjY5MSAxMjguMjM0QzE4Mi42OTEgMTI4LjIzNCAxOTkuODEyIDE3MS43MDYgMTk5Ljg3IDE5MEgyMDcuNzRaIiBmaWxsPSIjRURFREVEIi8+Cjwvc3ZnPgo=) center no-repeat;
	background-size: contain;
}

.airplane__final-lrNMQ {
	position: absolute;
	right: 0;
	bottom: 0;

	left: 0;

	display: none;

	width: 208px;
	height: 123px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjA4IiBoZWlnaHQ9IjEyMyIgdmlld0JveD0iMCAwIDIwOCAxMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOS40MzUyNSAwSDBWMTQuMzQ5MkMwIDM2LjQ1NDIgNSA4Ni44NzQ1IDIyLjUgMTQ4Ljc0OUMyNy40NjggMTY2LjMxNCAzNi4yNjQgMTg1LjkyMyA0Ni4zMTQzIDIwNUgxNjEuNjg2QzE3MS43MzYgMTg1LjkyMyAxODAuNTMyIDE2Ni4zMTQgMTg1LjUgMTQ4Ljc0OUMyMDMgODYuODc0NSAyMDggMzYuNDU0MiAyMDggMTQuMzQ5MlYwSDE5OS41NjVDMTk2LjI2OSAxOC4xNzc3IDE3NS4zNzIgMjQgMTc1LjM3MiAyNEgxMDVIMTA0SDMzLjYyNzZDMzMuNjI3NiAyNCAxMi43MzA4IDE4LjE3NzcgOS40MzUyNSAwWiIgZmlsbD0iI0VERURFRCIvPgo8L3N2Zz4K) center no-repeat;
	background-size: contain;
}

.airplane__final_hide-2YoZe {
	display: none;
}

.seatMap-2A-yi {
	position: relative;

	display: inline-block;
	min-width: 208px;
	width: 394px;
	padding: 0 17px;

	background: #ffffff;
}

.row-3HVo9 {
	position: relative;

	display: flex;
	justify-content: center;

	width: 100%;
	padding: 0 6px;

	background: #ffffff;
}

.row_last-1_Zp1 {
	position: relative;
}

.row__seatWrapper-2XCgS {
	position: absolute;

	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

.row__seatWrapper_newClass-2KUXA {
	padding-top: 40px;
}

.row__serviceHeader-dY5iT {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	height: 21px;

	border-bottom: 1px solid var(--seat-standart);
}

.row__serviceHeader__wrapper-1pQVx {
	display: inline-flex;
	grid-gap: 6px;
	gap: 6px;
	min-width: 0;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 10px;

	border-radius: 4px 4px 0 0;

	color: var(--gray70);
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;

	white-space: nowrap;

	text-transform: uppercase;
	text-overflow: ellipsis;

	background: var(--seat-standart);
	overflow: hidden;
}

.row__serviceHeader__serviceName-2GRHh {
	min-width: 0;
	overflow: hidden;

	text-overflow: ellipsis;
}

.row_serviceHeader_seattype-3hdcL {
	display: flex;
}

.row_serviceHeader_from-3gTeC {
	text-transform: none;
}

/* stylelint-disable */
.row__serviceHeader__price-2ztEP {
}

.row_serviceHeader_from_after-3Y9QQ {
}

.row_withExit-K88Yy {
}

.row__serviceHeader_free-1IfFU {
}
/* stylelint-enable */
.row__serviceHeader-dY5iT.row__serviceHeader_comfort-J1EEB {
	border-color: var(--seat-business);
}

.row__serviceHeader-dY5iT.row__serviceHeader_comfort-J1EEB .row__serviceHeader__wrapper-1pQVx {
	background: var(--seat-business);
}

.row__serviceHeader-dY5iT.row__serviceHeader_preferred-1p1uU {
	border-color: var(--seat-extra);
}

.row__serviceHeader-dY5iT.row__serviceHeader_preferred-1p1uU .row__serviceHeader__wrapper-1pQVx {
	background: var(--seat-extra);
}

.row_serviceHeader_price_wrapper-2okkS {
	display: flex;
}

.row_serviceHeader_delimetr-3IPgx {
	display: flex;
}

.row__rowPartWrp-3tv2V {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-bottom: 12px;

	width: 100%;
	flex: 1;
}

.row__rowPartWrp_aisle-da9Fr {
	flex: 0;
}

.row__rowPartWrp-3tv2V.row__rowPartWrp_static-1v0CJ {
	position: static;
}

.row__seatWrapper_boundaryRight-q_ZYv {
	border-radius: 0;
}

.row__seatWrapper_boundaryLeft-3EUWz {
	border-radius: 0;
}

.row_nextRowHasOtherClass-2V90R .row__rowPartWrp-3tv2V:first-child .row__seatWrapper-2XCgS {
	border-bottom-left-radius: 5px;
}

.row_nextRowHasOtherClass-2V90R .row__rowPartWrp-3tv2V:last-child .row__seatWrapper-2XCgS {
	border-bottom-right-radius: 5px;
}

.row_otherClass-2ucoo .row__rowPartWrp-3tv2V:first-child .row__seatWrapper-2XCgS {
	border-top-left-radius: 5px;
}

.row_otherClass-2ucoo .row__rowPartWrp-3tv2V:last-child .row__seatWrapper-2XCgS {
	border-top-right-radius: 5px;
}

.row_otherClass-2ucoo .row__seatWrapper_boundaryLeft-3EUWz {
	border-top-left-radius: 5px;
}

.row_otherClass-2ucoo .row__seatWrapper_boundaryRight-q_ZYv {
	border-top-right-radius: 5px;
}

.row_nextRowHasOtherClass-2V90R .row__seatWrapper_boundaryRight-q_ZYv {
	border-bottom-right-radius: 5px;
}

.row_nextRowHasOtherClass-2V90R .row__seatWrapper_boundaryLeft-3EUWz {
	border-bottom-left-radius: 5px;
}

.row_service-2EPIQ {
	padding: 0 0 15px 0;
	justify-content: space-between;
}

.row__seatWrapper_service_business_wide-Y7BLp,
.row__seatWrapper_service_preferred_wide-1RH-U,
.row__seatWrapper_service_standard_wide-gj1gw,
.row__seatWrapper_service_business-1P4iR,
.row__seatWrapper_service_preferred-3hzZ_,
.row__seatWrapper_service_standard-3ki9r {
	opacity: 0;
}

.row__seatWrapper_service_business-1P4iR {
	background-color: #e6eaf9;
}

.row__seatWrapper_service_preferred-3hzZ_ {
	background-color: #dcecff;
}

.row__seatWrapper_service_standard-3ki9r {
	background-color: #f9f5f4;
}

.passenger-prWzE.passenger_recommend-8D54Q .passenger__payload-3DvVG,
.passenger_recommend-8D54Q .passenger__payload-3DvVG svg,
.passenger-prWzE.passenger_recommend-8D54Q .passenger__price-2JdAv {
	color: #ffffff;
}

div.row__seatWrapper-2XCgS[data-highlight='true'] {
	opacity: 1;
}

.row__seatWrapper_service_business_wide-Y7BLp {
	background-color: #e8f3df;
}

.row__seatWrapper_service_preferred_wide-1RH-U {
	background-color: #fef4e9;
}

.row__seatWrapper_service_standard_wide-gj1gw {
	background-color: #ebf6fe;
}

.exit-ON4BF {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;

	height: 40px;

	background: #ffffff;
}

.exit__label-2KJta {
	position: relative;

	font-size: 14px;
	color: #9a9a9a;
}

.exit__label-2KJta:before {
	content: '';

	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;

	margin: auto;
	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.exit__label-2KJta:first-child {
	padding-left: 25px;
}

.exit__label-2KJta:last-child {
	padding-right: 25px;
}

.exit__label-2KJta:last-child:before {
	right: 0;
	left: auto;

	transform: rotate(180deg);
}

.wings-2Fbgk {
	position: absolute;
	left: 0;
	right: 0;
}

.wings_atBottom-kKOAf {
	bottom: 20px;
}

.wings_center-3y3ND {
	top: 45%; /* because we didn't start from top */

	transform: translate(0, -50%);
}

.wings_atBottom-kKOAf:before {
	left: 94.01408450704226%;
}

.wings_atBottom-kKOAf:after {
	left: 17px;
}

.wings-2Fbgk:after {
	position: absolute;
	left: 0;

	width: 1171px;
	height: 778px;

	transform: translateX(-100%);

	background: #e4e4e4;
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMDAxLjUgMTM0Ljk0QzEwMDEuNjIgMTI3LjEzMyAxMDAxLjY4IDExOS4yNjQgMTAwMS42OCAxMTEuMzQyQzEwMDEuNjggNzguNzE5NiAxMDAwLjYzIDQ3LjAxODYgOTk4LjYzOCAxNi42ODk3Qzk5OC4wMTkgNy4yNjQ3MyA5OTAuMTQ5IDAgOTgwLjcwMyAwSDgxMC42OTRDODAxLjI0OCAwIDc5My4zNzggNy4yNjQ3MyA3OTIuNzYgMTYuNjg5N0M3OTAuNzcgNDcuMDE4NiA3ODkuNzE0IDc4LjcxOTYgNzg5LjcxNCAxMTEuMzQyQzc4OS43MTQgMTUyLjIxNCA3OTEuMzcxIDE5MS42NCA3OTQuNDQ3IDIyOC43MzRMMCA1ODguNjE2Vjc3OEwxMTcxIDQ3Ni40NTZWNTguMTU2MUwxMDAxLjUgMTM0Ljk0WiIgZmlsbD0iI0U0RTRFNCIvPgo8L3N2Zz4K);
	        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMDAxLjUgMTM0Ljk0QzEwMDEuNjIgMTI3LjEzMyAxMDAxLjY4IDExOS4yNjQgMTAwMS42OCAxMTEuMzQyQzEwMDEuNjggNzguNzE5NiAxMDAwLjYzIDQ3LjAxODYgOTk4LjYzOCAxNi42ODk3Qzk5OC4wMTkgNy4yNjQ3MyA5OTAuMTQ5IDAgOTgwLjcwMyAwSDgxMC42OTRDODAxLjI0OCAwIDc5My4zNzggNy4yNjQ3MyA3OTIuNzYgMTYuNjg5N0M3OTAuNzcgNDcuMDE4NiA3ODkuNzE0IDc4LjcxOTYgNzg5LjcxNCAxMTEuMzQyQzc4OS43MTQgMTUyLjIxNCA3OTEuMzcxIDE5MS42NCA3OTQuNDQ3IDIyOC43MzRMMCA1ODguNjE2Vjc3OEwxMTcxIDQ3Ni40NTZWNTguMTU2MUwxMDAxLjUgMTM0Ljk0WiIgZmlsbD0iI0U0RTRFNCIvPgo8L3N2Zz4K);
	content: '';
}

.wings-2Fbgk:before {
	position: absolute;
	left: 100%;

	width: 1171px;
	height: 778px;

	background: #e4e4e4;
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNjkuNTAyIDEzNC45NEMxNjkuMzc5IDEyNy4xMzMgMTY5LjMxNyAxMTkuMjY0IDE2OS4zMTcgMTExLjM0MkMxNjkuMzE3IDc4LjcxOTYgMTcwLjM3MiA0Ny4wMTg2IDE3Mi4zNjIgMTYuNjg5N0MxNzIuOTgxIDcuMjY0NzMgMTgwLjg1MSAwIDE5MC4yOTcgMEgzNjAuMzA2QzM2OS43NTIgMCAzNzcuNjIyIDcuMjY0NzMgMzc4LjI0IDE2LjY4OTdDMzgwLjIzIDQ3LjAxODYgMzgxLjI4NiA3OC43MTk2IDM4MS4yODYgMTExLjM0MkMzODEuMjg2IDE1Mi4yMTQgMzc5LjYyOSAxOTEuNjQgMzc2LjU1MyAyMjguNzM0TDExNzEgNTg4LjYxNlY3NzhMMCA0NzYuNDU2VjU4LjE1NjFMMTY5LjUwMiAxMzQuOTRaIiBmaWxsPSIjRTRFNEU0Ii8+Cjwvc3ZnPgo=);
	        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MSIgaGVpZ2h0PSI3NzgiIHZpZXdCb3g9IjAgMCAxMTcxIDc3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNjkuNTAyIDEzNC45NEMxNjkuMzc5IDEyNy4xMzMgMTY5LjMxNyAxMTkuMjY0IDE2OS4zMTcgMTExLjM0MkMxNjkuMzE3IDc4LjcxOTYgMTcwLjM3MiA0Ny4wMTg2IDE3Mi4zNjIgMTYuNjg5N0MxNzIuOTgxIDcuMjY0NzMgMTgwLjg1MSAwIDE5MC4yOTcgMEgzNjAuMzA2QzM2OS43NTIgMCAzNzcuNjIyIDcuMjY0NzMgMzc4LjI0IDE2LjY4OTdDMzgwLjIzIDQ3LjAxODYgMzgxLjI4NiA3OC43MTk2IDM4MS4yODYgMTExLjM0MkMzODEuMjg2IDE1Mi4yMTQgMzc5LjYyOSAxOTEuNjQgMzc2LjU1MyAyMjguNzM0TDExNzEgNTg4LjYxNlY3NzhMMCA0NzYuNDU2VjU4LjE1NjFMMTY5LjUwMiAxMzQuOTRaIiBmaWxsPSIjRTRFNEU0Ii8+Cjwvc3ZnPgo=);
	content: '';
}

.service__block-uyW0a {
	width: 76px;
	height: 40px;

	border: 1px solid #d9d9d9;

	background-color: #ededed;
	background-repeat: no-repeat;
	background-position: center;
}

.row__PartWrpWithTechFacilities-35_gQ {
	display: flex;
	flex-direction: column;
	flex: auto;
}

.row__toilet-1T9XD {
	/* display: flex; */
	display: none;

	height: 48px;
	flex-direction: row;
}

.row__toiletPart-L_6t1 {
	height: 100%;
	flex: 1;
}

.row__toiletPart_leftSide-2jroH {
	position: relative;

	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.row__toiletPart_rightSide-2I62Z {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.row__toiletPart_icon-3NRQe {
	position: absolute;
	top: calc(50% - 12px);
	left: calc(50% - 12px);

	color: var(--text-light);
}

.row__toiletPart_innerSide-WjW9i {
	background: var(--seat-unavailable);
}

.row__toiletPart_small-2Bg1F.row__toiletPart_innerSide-WjW9i {
	min-width: 30px;
}

@media (min-width: 1024px), (max-width: 1024px) and (min-width: 601px) {
	.seatMap_wide-3OvBP {
		width: 450px;
	}
}

@media (max-width: 1024px) {
	.container-3PBrF {
		display: flex;
		justify-content: flex-end;
	}

	.seatMap__wrapper-PX2tc {
		margin-right: 5%;
	}
}

@media (min-width: 601px) and (max-width: 819px) {
	.seatMap__wrapper-PX2tc {
		margin-right: 20px;
	}
}

@media (max-width: 768px) {
	.container-3PBrF {
		justify-content: center;
	}

	.seatMap__wrapper-PX2tc {
		margin-right: 0;
	}
}

@media screen and (max-width: 600px) {
	.seatMap__wrapper-PX2tc {
		top: 0;

		padding: 0;

		margin: 0;

		width: 100%;
	}

	.seatMap-2A-yi {
		width: 100%;
	}

	.wings_atBottom-kKOAf {
		bottom: calc(50% + 200px);
	}

	.airplane__final_hide-2YoZe {
		bottom: -123px;

		display: unset;
	}

	.seatMap_wide-3OvBP .seat-2O66F {
		width: 23px;

		background-size: 80%;
	}

	.airplane__final-lrNMQ {
		display: none;
	}
}
