

.wrapper-gtKC6 {
	padding: 40px;
	display: flex;
	flex-direction: column;
}

.text-15EwQ {
	margin: 0;
	padding-bottom: 30px;
}

button.button-G_y6o {
	margin-left: auto;
}
