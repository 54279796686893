.wrapper-2tb8e {
	position: relative;

	display: flex;
	align-items: center;
	min-width: 168px;
	padding: 14px 20px 14px 14px;

	border-radius: 4px;

	color: var(--white);
	font-weight: 500;
	white-space: nowrap;

	background-color: var(--brand-1-1);
}

.wrapper-2tb8e > svg {
	margin-right: 10px;
}

.picker-1UQEJ {
	position: absolute;
	bottom: 0;
	left: 50%;
	z-index: 10;

	color: var(--text-base);

	transform: translate(-50%, calc(100% + 10px));
}

.header-3OYHB {
	display: flex;
	align-items: center;
	height: 90px;
	padding-top: 20px;
	padding-left: 32px;

	border-radius: 20px 20px 0 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;

	background: var(--bg-fill);
}

.header__title-2GGvY {
	line-height: 130%;
}

.header__backIcon-1QLzE {
	display: none;

	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.wrapper-2tb8e {
		min-width: 120px;
		padding: 12px 14px;
	}

	.picker-1UQEJ {
		left: auto;
		right: 0;

		transform: translateY(calc(100% + 10px));
	}

	.header-3OYHB {
		height: 56px;
		padding: 0 0 0 12px;

		border-radius: 0;

		box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.05);
		background: var(--bg-white);
	}

	.header__backIcon-1QLzE {
		display: block;
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}
}

.monthsWrapperStyles_wrapper-3ZCpi {
	margin: 0;
	padding: 20px 30px 40px;
}

.dayStyles_day-rLfud {
	max-width: 36px;
	margin: 2px 0;
}

.day_end-2lzHX.day_start-1Bhs2:after {
	left: 2px;

	width: 36px;
	height: 36px;
}

/* stylelint-disable-next-line block-no-empty */
.day__inside-3he_s {
}

/* stylelint-disable-next-line block-no-empty */
.day_selected-D44w2 {
}

div.day_highlight-2TX3a {
	border-radius: 50%;

	background: var(--brand-1-4);
}

div.day_highlight-2TX3a > div {
	color: var(--text-base);
	font-weight: 500;
}

@media (min-width: 601px) and (max-width: 1164px) {
	.monthsWrapperStyles_wrapper-3ZCpi {
		min-height: 760px;
	}
}
