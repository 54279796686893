.slider-dm-AF {
	max-width: 100%;
	width: 100%;
	min-width: 0;
	overflow: hidden;
	height: 100%;
}

.container-MxLbh {
	width: 100%;
	height: 100%;
	padding: 0;
	padding-left: 76px;
}

@media (max-width: 1024px) {
	.container-MxLbh {
		padding-left: 0;
	}
}

.inside-s9C-u {
	position: relative;

	display: inline-flex;
	min-width: 100%;
	height: 100%;
}

.elementsWrapper-3hYTm {
	display: flex;
	min-width: 100%;
}

.arrow_next-2vBwt,
.arrow_prev-wrccS {
	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: transparent;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
	transform: translateY(-100%) rotate(180deg);
	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI1IiByPSIxNiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNMjcuNTkgMTlMMjkgMjAuNDFMMjQuNDIgMjVMMjkgMjkuNTlMMjcuNTkgMzFMMjEuNTkgMjVMMjcuNTkgMTlaIiBmaWxsPSIjQTVBNUE1Ii8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHk9IjEiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=) center no-repeat;
}

.arrow_next-2vBwt {
	position: absolute;
	right: -55px;
	bottom: 0;
	top: 50%;
}

.arrow_prev-wrccS {
	position: absolute;
	top: 50%;
	bottom: 0;
	left: -55px;

	transform: translateY(-100%) rotate(0);
}

.arrow_next-2vBwt:hover,
.arrow_prev-wrccS:hover {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI1IiByPSIxNiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNMjcuNTkgMTlMMjkgMjAuNDFMMjQuNDIgMjVMMjkgMjkuNTlMMjcuNTkgMzFMMjEuNTkgMjVMMjcuNTkgMTlaIiBmaWxsPSIjMzI5MEZGIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHk9IjEiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=);
}

@media (max-width: 1024px) {
	.arrow_next-2vBwt,
	.arrow_prev-wrccS {
		display: none;
	}
}

.disabled-3bS2M {
	opacity: 0.5;
	cursor: default;
}

@media screen and (max-width: 600px) {
	.container-MxLbh {
		position: absolute;
		bottom: 0;
		top: 0;
	}

	.slider-dm-AF {
		height: 100%;
	}

	.inside-s9C-u {
		height: 100%;
	}
}
