.buttons-2wk-m {
	display: flex;
	margin-top: 22px;
}

div.modal-2BvXL {
	max-width: 780px;
}

.buttons-2wk-m > button {
	margin-right: 8px;
}

.buttons-2wk-m > button:last-child {
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	.buttons-2wk-m {
		flex-direction: column;
	}

	.modal-2BvXL > div:first-of-type {
		font-size: 18px;
	}

	.buttons-2wk-m > button {
		margin-right: 0;
		margin-bottom: 8px;
	}

	.buttons-2wk-m > button:last-child {
		margin-bottom: 0;
	}
}

button.button-uj15L,
button.button-uj15L:hover,
button.button-uj15L:focus,
button.button-uj15L:active {
	padding: 14px 53px;

	border-radius: 4px !important;

	color: var(--gray70);
	font-weight: 500;

	background: var(--gray05);
}

button.active-grrhN,
button.active-grrhN:focus,
button.active-grrhN:hover {
	color: #ffffff;

	background: var(--active);
}
