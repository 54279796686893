.root-2Dq2h {
	width: 660px;
	padding: 40px 40px 47px;
}

.title-1InVC {
	font-size: 30px;
	font-weight: 500;
	color: var(--text-base);
}

.message-1sh74 {
	margin-top: 43px;

	color: var(--text-base);
	font-size: 16px;
	line-height: 1.4;
}

.row-3fF3D {
	display: flex;
	align-items: flex-start;

	margin-top: 31px;
}

.input_wrapper-vpmIM {
	flex-grow: 1;
}

.button_wrapper-1pEwk {
	margin-left: 20px;
	padding-top: 4px;
}

.resend-1EOdp {
	margin-top: 21px;

	font-size: 14px;
	line-height: 20px;
	color: var(--text-mid);
}

.resend__label-24CYP {
	justify-content: flex-start;
}

.resend__label-24CYP a {
	text-decoration: none;
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.root-2Dq2h {
		width: auto;

		/* padding: 47px 28px; */
		padding: 0;
	}

	.message-1sh74 {
		margin-top: 38px;
	}

	.row-3fF3D {
		flex-direction: column;
		align-items: normal;
		margin-top: 25px;
	}

	.button_wrapper-1pEwk {
		margin-left: 0;
		margin-top: 20px;
		padding-top: 0;
	}

	.resend-1EOdp {
		font-size: 16px;
		line-height: 24px;
	}
}
