.segments-2_MOj {
	flex-basis: 0;
	flex-grow: 1;
}

.segments_multiCity-2Ef_w {
	flex-basis: 100%;
	margin-right: 0;
}

@media (max-width: 1024px) {
	.segments_multiCity-2Ef_w {
		margin-bottom: 68px;
	}
}

@media screen and (max-width: 600px) {
	.segments-2_MOj {
		margin-right: 0;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 600px) {
	.actions-tmprx {
		display: flex;
		margin-top: 6px;
	}

	button.addSegment-pF-4c,
	button.removeSegment-2qfRF {
		width: 100%;
		flex-grow: 1;
	}

	button.removeSegment-2qfRF {
		display: block;
		margin-left: 4px;
	}

	.alternative-2JxWI .actions-tmprx {
		margin-top: 0;
	}

	.alternative-2JxWI button.addSegment-pF-4c,
	.alternative-2JxWI button.removeSegment-2qfRF {
		border-radius: 0;
	}
}
