

span.toggle-2nce8 {
	height: auto;
	align-items: center;
	padding: 0;
	width: auto;
}

span.thumb-1JelR {
	width: 14px;
	height: 14px;

	box-shadow: none;
	background: var(--white);
}

span.track-1aIyT {
	width: 32px;
	height: 20px;

	border-radius: 10px;

	opacity: 1;
}

span.toggle-2nce8.normal-3-t7C .track-1aIyT {
	background: var(--line-separator);
}

span.toggle-2nce8.invert-2dprL .track-1aIyT {
	background: var(--brand-1-1);
}

span.switchBase-3lUqg {
	top: auto;

	padding: 0;

	transform: translateX(2px);
}

span.switchBase-3lUqg:hover {
	background: none;
}

span.switchBase-3lUqg.checked-qxTos {
	color: var(--white);

	transform: translateX(16px);
}

span.switchBase-3lUqg.disabled-LgrFN + .track-1aIyT,
span.switchBase-3lUqg.checked-qxTos + .track-1aIyT {
	opacity: inherit;
}

.normal-3-t7C .switchBase-3lUqg.checked-qxTos + .track-1aIyT {
	background: var(--brand-1-0);
}

.invert-2dprL .switchBase-3lUqg.checked-qxTos + .track-1aIyT {
	background: var(--success-icon);
}

.toggle-2nce8.required-TGYqY :not(.checked-qxTos) + .track-1aIyT {
	background: var(--error-stroke-hover);
}
