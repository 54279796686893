.container-3XmRR {
	display: flex;
	flex-direction: column;
	padding: 16px;

	background: var(--white);
}

.row-G5Gci {
	display: flex;
	align-items: center;
}

.row-G5Gci:not(:last-child) {
	margin-bottom: 14px;
}

.col-3mJe_:not(:first-child) {
	margin-left: 16px;
	padding-left: 16px;

	border-left: 1px solid var(--line-dot-line);
}

.request-2RlDl {
	color: var(--text-base);
	font-size: 20px;
	font-weight: 700;
	line-height: 1.3;
}

.date-3MCNR {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.col-3mJe_.status-x2vQG {
	margin-left: auto;

	border-left: none;
}

@media screen and (max-width: 600px) {
	.row-G5Gci {
		flex-direction: column;
		align-items: flex-start;
	}

	.col-3mJe_:not(:first-child) {
		margin-top: 2px;
		margin-left: 0;
		padding-left: 0;

		border-left: none;
	}

	.col-3mJe_.status-x2vQG {
		margin-top: 10px;
		margin-left: 0;
		padding-left: 0;
	}
}
