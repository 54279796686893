div.wrapper-57Jfk {
	background-color: var(--white);
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

div.wrapper-57Jfk:first-child,
div.wrapper-57Jfk:last-child {
	border-radius: 8px;
}

div.wrapper-57Jfk.inline-2Blsl {
	width: 100%;

	border-radius: 0;

	box-shadow: none;
}

div.header-1k3Rz {
	display: flex;
	align-items: center;
	padding: 0 20px;
}

div.header_content-1YIiH {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 20px 0;
}

.header-1k3Rz,
.footer-yQL2G {
	padding: 20px;
}

.avatar-2lYWE {
	position: relative;

	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 52px;
	height: 52px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);

	background-color: var(--base);
}

.avatar-2lYWE span {
	font-size: 16px;
	line-height: 1.2;
	font-weight: 700;
	text-transform: uppercase;
}

.info-1X7l0 {
	display: flex;
	flex-direction: column;
}

.name-1mmCp {
	color: var(--gray80);
	font-weight: 500;
	font-size: 18px;
	line-height: 1.2;
}

.name-1mmCp:not(:only-child) {
	margin-bottom: 4px;
}

.notification-3xQrV {
	color: var(--base);
	font-size: 16px;
	line-height: 1.2;
}

.edit-3jAdv {
	display: flex;
	align-items: center;
	margin-left: auto;

	font-size: 16px;
	line-height: 16px;
	color: var(--base);
}

.edit-3jAdv > svg {
	color: var(--gray30);
}

.companion-1gluk .avatar-2lYWE {
	width: 38px;
	height: 38px;
}

.companion-1gluk.empty-CEfOw .name-1mmCp {
	color: var(--base);
}

.companion-1gluk.empty-CEfOw .avatar-2lYWE {
	color: var(--base);

	background-color: var(--brand1-5);
}

@media screen and (max-width: 600px) {
	button.button-vkO1c {
		width: 100%;
		margin-left: 0;

		border-radius: 4px;
	}
}
