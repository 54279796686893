.info-2nzxT {
	position: relative;
	z-index: 1;

	width: 100%;
}

.classInfo-1AhU_ {
	margin-bottom: 12px;

	border-radius: 4px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.classInfo-1AhU_ img {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.classInfo__payload-1QaLa {
	padding: 0 12px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: var(--gray80);
	font-weight: 700;
	font-size: 20px;
}

.seat-3nGGR {
	margin-bottom: 12px;

	border-radius: 4px;

	color: var(--common-gray);
	font-size: 14px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	background: #ffffff;
}

.seat-3nGGR img {
	border-radius: 4px 4px 0 0;
}

.info_stacked-3WvtZ .seat-3nGGR {
	margin-bottom: 0;

	border-radius: 0;

	box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.15);
}

.info_stacked-3WvtZ .seat-3nGGR:first-child {
	border-radius: 4px 4px 0 0;
}

.info_stacked-3WvtZ .seat-3nGGR:last-child {
	border-radius: 0 0 4px 4px;
}

.header-1I3ev {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 5em;

	padding: 12px;

	color: var(--gray80);
}

.title-3l4LX {
	position: relative;

	display: flex;
	flex-direction: column;
	min-height: 44px;

	padding-left: 60px;

	justify-content: center;

	color: var(--text-base);
	font-weight: 700;

	font-size: 16px;
}

.title_withDescription-2c3h3 {
	justify-content: flex-start;
}

.freeSeat__container-2qpO6 {
	display: flex;
	align-items: center;
	padding: 20px;

	border-radius: 4px;

	color: var(--text-base);
	font-weight: 700;

	background: var(--white);
	box-shadow: 2px 2px 3px rgb(0 0 0 / 4%);
}

.freeSeat__seat-1aa9K {
	width: 36px;
	height: 36px;

	margin-right: 12px;

	border-radius: 4px;

	background: var(--seat-free);
}

.title-3l4LX span {
	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;

	font-size: 14px;
}

.description-1TuP5 {
	color: var(--gray70);
	font-weight: 400;
	font-size: 15px;
	line-height: 130%;
}

.description-1TuP5:not(:empty) {
	margin-top: 5px;
}

.seat__info-2rIar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 12px;

	border-top: 1px dashed var(--gray20);
}

.price__wrapper-2CBRB {
	display: flex;
	align-items: baseline;

	color: var(--base);
	font-size: 14px;
}

.price-3eMWh {
	font-weight: 700;
	font-size: 24px;
}

.freeSeat-30DTW {
	color: #55d590;
	font-weight: 500;
}

.seat_free-lsaU8 .title-3l4LX {
	padding-top: 20px;
	padding-bottom: 20px;
}

.seat_free-lsaU8 .title-3l4LX:before,
.seat_business-wlm-k .title-3l4LX:before,
.seat_preferred-Ll5K2 .title-3l4LX:before,
.seat_economy-1xzIT .title-3l4LX:before {
	position: absolute;
	left: 0;
	right: 0;

	margin-right: 15px;

	width: 44px;
	height: 44px;

	border-radius: 4px;

	content: '';
}

.seat_free-lsaU8 .title-3l4LX:before {
	background: var(--seat-free);
	transform: translateY(-10px);
}

.seat_business-wlm-k .title-3l4LX:before {
	background: var(--seat-business);
}

.seat_preferred-Ll5K2 .title-3l4LX:before {
	background: var(--seat-extra);
}

.seat_economy-1xzIT .title-3l4LX:before {
	background: var(--seat-standart);
}

.note-99cxK {
	padding: 12px;

	border-top: 1px dashed #d1d1d1;
	border-radius: 0 0 4px 4px;

	background: var(--warning20);
}

.note__header-2cham {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	color: var(--gray80);
	font-weight: 600;
	font-size: 16px;
}

.note__header-2cham svg {
	margin-right: 8px;

	color: #9a9a9a;
}

.image-3IBVs {
	width: 100%;
}

.features-1td3I {
	font-size: 15px;
	font-weight: 400;
	color: var(--gray50);
}

.feature-1SfyH {
	display: flex;
	justify-content: space-between;
}

.feature-1SfyH:not(:last-child) {
	padding-bottom: 12px;
}

.seats__info_slider-2ifGp {
	display: block;
}

.seats__info_slider-2ifGp .slick-track {
	display: flex;
	padding-bottom: 20px;
}

.seats__info_slider-2ifGp .slick-track .slick-slide {
	display: flex;
	padding-top: 20px;
	padding-left: 20px;
	width: 75vw;
	height: 418px;
}

.serviceClassList-35fpB {
	margin: 0 0 15px;
	padding: 0;
	display: flex;

	list-style-type: none;
}

.serviceClassList__item-SaqhV {
	flex: 1;
	padding: 10px 20px;

	border-radius: 2px;

	text-align: center;
	color: #ffffff;

	background: #506dd2;

	cursor: pointer;
}

.serviceClassList__item-SaqhV:not(:last-of-type) {
	margin-right: 10px;
}

.serviceClassList__item_active-3-2t8 {
	position: relative;

	background: var(--common-blue);
}

.serviceClassList__item_active-3-2t8:after {
	position: absolute;

	bottom: -8px;
	left: 50%;

	display: inline-block;

	width: 0;
	height: 0;

	border-style: solid;
	border-width: 9px 8px 0 8px;
	border-color: var(--common-blue) transparent transparent transparent;

	transform: translate(-50%, 0);

	content: '';
}

@media (max-width: 1024px) {
	.info-2nzxT {
		width: 100%;
	}

	.classInfo-1AhU_ {
		max-width: 370px;
		margin: 0 auto;
		margin-bottom: 12px;
	}

	.seat-3nGGR {
		width: 70%;
		height: 99%;
		margin: 0 auto;
		max-width: 370px;
		margin-bottom: 12px;
	}

	.info_stacked-3WvtZ .seat-3nGGR {
		box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
	}

	.info_stacked-3WvtZ .seat-3nGGR:first-of-type,
	.info_stacked-3WvtZ .seat-3nGGR:first-of-type img {
		border-radius: 4px 4px 0 0;
	}

	.info_stacked-3WvtZ .header-1I3ev {
		background: #f4f4f4;
	}

	.info_stacked-3WvtZ .title-3l4LX {
		color: #414141;
		font-weight: 500;
		line-height: 24px;
	}

	.info_stacked-3WvtZ .seat-3nGGR:not(:first-of-type) .header-1I3ev {
		border-top: 1px dashed #d9d9d9;
	}

	.serviceClassList-35fpB {
		width: 70%;
		margin: 0 auto 20px;
	}
}

.additionalInfo-Wn-OD {
	color: var(--text-base);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.2;
}

.additionalInfo__title-3WEyg {
	position: relative;
}

.additionalInfo-Wn-OD ul {
	margin: 10px 0;
	padding-left: 16px;

	font-weight: 400;
}

.additionalInfo-Wn-OD ul:last-child {
	margin-bottom: 4px;
}

/* stylelint-disable */
.seatPrice-3i8JF {
}
/* stylelint-enable */
@media screen and (max-width: 600px) {
	.seat-3nGGR {
		width: 100%;
	}

	.serviceClassList-35fpB {
		width: 100%;
		margin: 0 auto 20px;
	}

	.title_withDescription-2c3h3 {
		justify-content: center;
	}
}
