.wrapper-fbltd {
	position: relative;

	padding-bottom: 30px;

	border-radius: 12px;

	background: var(--white);
}

button.tabs__tab-2V8ZY.tabs__selectedTab-1a4bE {
	border-bottom: 3px solid var(--base);
}

button.tabs__tab-2V8ZY {
	max-width: none;

	border-bottom: 3px solid transparent;

	opacity: inherit;

	transition: 0.2s;
}

button.tabs__tab-2V8ZY .tabs__tab__label-3mEy4 {
	margin-bottom: 6px;
}

button.tabs__tab-2V8ZY .tabs__tab__header-1BQP4 {
	font-weight: 700;
	font-size: 22px;
	text-transform: none;
	text-transform: initial;
	line-height: normal;
}

button.tabs__tab-2V8ZY:not(.tabs__selectedTab-1a4bE) .tabs__tab__header-1BQP4 {
	color: var(--text-base);
}

div.stickyTabs-pdkOE {
	top: 0;
	right: 0;
	left: 0;
	z-index: 4;

	padding-top: 12px;

	box-shadow: 4px 4px 29px rgba(0, 0, 0, 0.09);
	background: #ffffff;
}

.tabs__container-11sDh {
	padding: 0 24px;
}

div.stickyTabs-pdkOE .tabs__container-11sDh {
	margin: auto;
	max-width: 1100px;

	border-bottom: none;
}

@media screen and (max-width: 600px) {
	.wrapper-fbltd {
		padding-bottom: 0;

		border-radius: 0;

		background: none;
	}

	.tabs__container-11sDh {
		padding: 0;
	}

	.tabs__tab-2V8ZY {
		margin-left: -4px;
	}

	button.tabs__tab-2V8ZY span {
		margin-bottom: -22px;
	}

	button.tabs__tab-2V8ZY .tabs__tab__header-1BQP4 {
		font-size: 16px;
	}

	div.flexTabsContainer-z5_RF:after {
		content: '';

		min-width: 1px;
		height: 1px;
		display: block;
	}
}
