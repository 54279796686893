.wrapper-pXzJO {
	max-width: 1100px;
	margin: 0 auto;

	border-radius: 4px;

	color: var(--text-base);

	background: var(--white);
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

.row-zzAo0 {
	display: flex;
	align-items: center;
	grid-gap: 40px;
	gap: 40px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.row-zzAo0:last-child {
	border-bottom: none;
}

.col-TO4fL {
	flex: 1;
	display: flex;
	align-items: center;
}

.col-TO4fL:last-child {
	margin-right: 0;
	margin-bottom: 0;
}

.header-WzGDl {
	position: relative;

	padding: 20px 20px 8px;
}

.plane__icon-3BKvs {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;
	margin: 0 26px;

	color: var(--text-pale);

	transform: rotate(180deg) translateY(-8px);
}

.plane__icon-3BKvs svg {
	width: 100%;
	height: 100%;
}

.city-Gwoqf {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.city__airport-1x730 {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.city__info-2X8LI {
	color: var(--text-base);
	font-size: 42px;
	line-height: 1.3;
}

.city__iata-opPl1 {
	font-weight: 600;
}

.city__time-2SYM6 {
	margin-left: 8px;

	font-weight: 300;
}

.city__weather-1SDiU {
	position: absolute;
	bottom: 10px;

	display: flex;
	align-items: center;

	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}

.weather__icon-2YRV1 {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.flightInfo-qwsYQ {
	display: flex;
	flex-direction: column;
}

.flightInfo-qwsYQ:not(:last-child) {
	margin-right: 40px;
}

.flightInfo__title-i9P5f {
	color: var(--text-light);
	font-size: 14px;
	line-height: 1.3;
	font-weight: 400;

	text-transform: uppercase;
}

.flightInfo__value-3st95 {
	font-size: 42px;
	font-weight: 700;
	line-height: 1.3;
	text-transform: uppercase;
}

.flightInfo-qwsYQ.flightInfo__departure-1oxYQ .flightInfo__value-3st95 {
	font-weight: 300;
}

.footer-J1VEi {
	position: relative;

	padding: 20px;
}

.airline__logo-cLOUz {
	display: inline-block;
	height: 28px;
	width: 28px;
	margin-right: 12px;

	background-position: center;
	background-size: cover;
}

.airline__name-3ibVn {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.footer__airline-1fCBp > span:first-child {
	display: flex;
	align-items: center;
}

.footer__item-3fE6H {
	display: flex;
	flex-direction: column;
	margin-right: 28px;
}

.footer__item-3fE6H:last-child {
	margin-right: 0;
}

.footer__item-3fE6H:not(:only-child):last-child {
	align-items: flex-end;
}

.footer__itemLabel-33Gn2 {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.footer__itemValue-2ro2M {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

button.button-1Xfdh {
	margin-top: auto;
	margin-left: auto;

	color: var(--text-light);
	font-weight: 500;
	font-size: 13px;
}

@media screen and (max-width: 820px) {
	.row-zzAo0 {
		flex-direction: column;
		align-items: inherit;
		grid-gap: 20px;
		gap: 20px;
	}
}
