.wrapper-1HxiP {
	display: flex;
	align-items: center;
	padding: 18px;

	border: 1px solid #efea6c;
	border-radius: 4px;

	background-color: #fffdcc;
}

.icon-2riC2 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 16px;

	color: #b3b3b3;
}

.text-27M9r {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.accent-2oFQK {
	color: #cd202c;
}

.status-1fW3D {
	text-transform: capitalize;
}

.actions-1fKvX {
	display: flex;
	align-items: center;
	margin-left: auto;
}

button.button-3bKE1 {
	position: relative;

	margin-right: 24px;

	color: #cd202c;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	cursor: pointer;
}

button.button-3bKE1:after {
	content: '';

	position: absolute;
	top: 0;
	right: -13px;

	height: 100%;
	width: 1px;

	background-color: var(--gray30);

	pointer-events: none;
}

button.button-3bKE1:last-child {
	margin-right: 0;
}

button.button-3bKE1:last-child:after {
	display: none;
}

div.modal_paper-22A9V {
	max-width: 480px;
}

button.modal_button-37U3W {
	margin-right: 12px;
}

button.modal_button-37U3W:last-child {
	margin-right: 0;
}

button.modal_button-37U3W.modal_button_cancel-2ATms {
	color: var(--sureModal-text-color);

	background-color: var(--sureModal-cancel-bg);
}

button.modal_button-37U3W.modal_button_cancel-2ATms:hover {
	color: var(--sureModal-text-color);

	background-color: var(--gray10);
}

@media screen and (max-width: 600px) {
	.wrapper-1HxiP {
		flex-wrap: wrap;
	}

	.text-27M9r {
		width: 86%;
	}

	.actions-1fKvX {
		flex-wrap: wrap;
		margin-left: 0;
		margin-top: 16px;
	}

	.button-3bKE1 {
		margin-right: 20px;
	}

	.button-3bKE1:after {
		right: -11px;
	}

	div.modal_paper-22A9V {
		width: 100%;
		min-width: auto;
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}

	div.modal_body-1agDE {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}
