.form-1LDSH {
	margin-bottom: 12px;
	padding: 28px;

	background: #ffffff;

	transition: box-shadow 0.15s;
}

.form-1LDSH:hover {
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07), 0 0 25px rgba(0, 0, 0, 0.12);
}

/* stylelint-disable */
.label-y8iU_ {
}

.label_lastName-3TuXs {
}

.label_firstName-3ti15 {
}

.label_middleName-3KDYW {
}

/* stylelint-enable */
@media screen and (max-width: 600px) {
	.form-1LDSH {
		padding: 0 20px;

		background: none;
	}

	.form-1LDSH:hover {
		box-shadow: none;
	}
}
