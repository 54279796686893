div.paper-2MHPE {
	max-width: 420px;

	border-radius: 20px;

	background: var(--bg-white);
}

.closeIcon-1lzTY {
	position: absolute;
	top: 8px;
	right: 8px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	border-radius: 30px;

	color: var(--text-pale);
	line-height: 1;
}

.closeIcon-1lzTY:hover {
	background: var(--bg-fill);
}

.closeIcon-1lzTY svg {
	width: 20px;
	height: 20px;
}

.header-3A0_F {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
	align-items: center;
	padding: 24px 50px 16px 32px;

	border-radius: 20px 20px 0 0;

	background: var(--bg-white);
}

.header__icon-1t024 {
	width: 36px;
	height: 36px;
	padding: 3px;

	border-radius: 30px;

	background-color: var(--error-bg);
	background-size: 30px 30px;
	background-position: center;
	background-repeat: no-repeat;
}

.header__icon-1t024 svg {
	width: 30px;
	height: 30px;

	color: var(--error-icon);
}

.header__text-3jcO9 {
	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	font-style: normal;
	line-height: 130%;
}

.content-SHov_ {
	padding: 12px 40px 10px 40px;

	background: var(--bg-white);
}

.content__text-1Ni79,
.message__text-1PmPa {
	color: var(--text-mid);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
}

.content__text_hidden-3YnBf {
	display: none;
}

.footer-3NheD {
	padding: 10px 40px 40px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	grid-gap: 12px;

	gap: 12px;
}

.footer-3NheD > button.button__confirm-3sdAg,
.footer-3NheD > button.button__cancel-3UeGB {
	flex-grow: 1;
	min-width: 100px;
	width: 50%;
}

/* stylelint-disable-next-line block-no-empty */
button.delete_order-1PKME {
}

@media screen and (max-width: 600px) {
	div.paper-2MHPE {
		max-width: 100%;

		border-radius: 20px 20px 0 0;
	}

	.header-3A0_F {
		flex-direction: column;
		padding: 20px 20px 16px 20px;

		text-align: center;
	}

	.closeIcon-1lzTY {
		display: none;
	}

	.header__icon-1t024 {
		width: 44px;
		height: 44px;
		padding: 7px;
	}

	.header__text-3jcO9 {
		font-weight: 500;
		font-size: 18px;
	}

	.content__text-1Ni79,
	.message__text-1PmPa {
		text-align: center;
		font-size: 16px;
	}

	button.delete_order-1PKME {
		flex-grow: 1;
	}
}
