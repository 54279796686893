.toggle-NuCS_:before {
	opacity: 1;
}

.toggle-NuCS_:after {
	background: var(--line-separator);
}

.toggle_active-3KPCr:after {
	background: var(--brand-1-0);
}

.switch__label-2wTzZ {
	color: var(--text-light);
	font-size: 16px;

	opacity: 1;
}

@media (max-width: 1024px) {
	.toggle-NuCS_ {
		margin-bottom: 12px;
	}
}

@media screen and (max-width: 600px) {
	.switch__label-2wTzZ {
		padding-left: 28px;
	}
}
