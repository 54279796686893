

.flight__header-1D81v {
	display: flex;
}

.flightInfo-3tzCc {
	position: relative;

	flex-grow: 1;

	color: var(--results-flight-info-header-primary-color);
	overflow: hidden;
}

.flightInfo__inner-ANpD4 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 20px;
}

.flightInfo-3tzCc:after {
	width: 100%;
}

.flightInfo__col-2Wpjl {
	flex-basis: 100px;
}

.flightInfo__col-2Wpjl:first-of-type {
	flex-basis: var(--results-flight-left-col-flex-basis);
}

.flightInfo__col-2Wpjl.flightInfo__col_time-29Ah8.flightInfo__col_singleFare-3bHP3 {
	flex-basis: 300px;
}

.flightInfo__col-2Wpjl:last-of-type {
	flex-basis: 170px;
}

.flightInfo__col__head-1-s_I {
	line-height: 25px;
	font-size: 20px;
	white-space: nowrap;
}

.flightInfo__col_head_duration-33uuk {
	flex-basis: 100px;
}

.flightInfo__col__sub-GMQhT {
	color: var(--results-flight-info-header-secondary-color);
	line-height: 21px;
	font-size: 14px;
	white-space: nowrap;
}

.badge-1yDtV {
	position: absolute;
	top: 0;
	left: 20px;
}

.col-1on5n {
	cursor: pointer;

	transition: background-color 0.15s;
}

.col-1on5n:after {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 4px;

	opacity: 0;
	background-color: var(--results-flight-header-underline-background);

	transition: opacity 0.15s;

	pointer-events: none;
	content: '';
}

.col_active-2KLVM:after,
.col-1on5n:hover:after {
	opacity: 1;
}

.date-2b1gj {
	position: relative;

	font-weight: 600;
	font-size: 22px;
}

.date-2b1gj.date_singleFare-1FIAJ {
	font-size: 20px;
}

.dates__separator-bcbGO {
	margin: 0 5px;

	font-weight: 600;
	line-height: 28px;
	font-size: 22px;
}

.date__nextDayHint-2dlWO {
	position: absolute;
	top: 0;

	color: var(--dark-red);
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: right;

	transform: translate(3px, -25%);
}

.detailsLink-1QfMz {
	position: relative;

	display: inline;
}

.detailsLink-1QfMz:after {
	position: absolute;
	top: 50%;
	right: 0;

	display: block;

	width: 10px;
	height: 20px;

	transform: translate(15px, -50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cgk8cGF0aCBkPSJNMCAwTDUgNUwxMCAwTDAgMFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) center no-repeat;

	transition: transform 0.15s;
	content: '';
}

.detailsLink_active-1zqE6:after {
	transform: translate(15px, -50%) rotate(-180deg);
}

.prices-2v5Ls {
	display: flex;
}

.flightInfo__inner_singleFare-CUdjX {
	height: 100%;
}

.flightInfo__col_singleFare-3bHP3 {
	display: flex;
	align-items: flex-end;
}

.flightInfo__col_singleFare-3bHP3 > div:first-child {
	font-weight: 600;
	line-height: 25px;
}

.flightInfo__col_time-29Ah8 > div:last-child {
	margin-left: 30px;
}

.flightInfo__col_duration-1YGJj {
	flex-basis: 200px;
}

.flightInfo__col_duration-1YGJj > div:last-child {
	margin-left: 12px;
}

.flightInfo__col_singleFare-3bHP3.flightInfo__col_details-hkfGp {
	flex-basis: 300px;
	display: flex;
	justify-content: space-between;
	margin-right: 20px;
}

.flightInfo__col_singleFare-3bHP3.flightInfo__col_details-hkfGp > div:last-child {
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 12px;
}
