.input-r-YUx:focus {
	position: relative;
	z-index: 1;

	background: transparent;
}

.input-r-YUx:focus:-ms-input-placeholder, .input_withoutPlaceholder-1tzoW:-ms-input-placeholder {
	opacity: 0;
}

.input-r-YUx:focus::placeholder,
.input_withoutPlaceholder-1tzoW::placeholder {
	opacity: 0;
}

.input_withoutPlaceholder-1tzoW {
	color: transparent;
}

.input_departure-3olTr {
	padding-right: 42px;

	text-overflow: ellipsis;
}

.input_arrival-PS34V {
	text-overflow: ellipsis;
}

@media screen and (max-width: 600px) {
	.input-r-YUx {
		display: flex;
		flex-direction: column;
		padding-left: 44px;
		justify-content: space-evenly;
	}
}

.switcher-343Vq {
	position: absolute;
	top: 50%;
	right: 12px;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	padding: 0;

	border: none;

	color: var(--text-pale);

	outline: none;

	transform: translateY(-50%);
	cursor: pointer;
	background: var(--bg-fill);

	transition: all 0.15s;
}

.switcher-343Vq:hover {
	opacity: 1;
}

.switcher_highlighted-2Nkpe {
	opacity: 1;
}

@media screen and (max-width: 600px) {
	.switcher-343Vq.switcher_alternative-3Oehp {
		right: 0;

		width: 32px;
		height: 32px;

		border-radius: 50%;

		color: var(--white);

		background: var(--common-blue);
		transform: translate(61%, -50%);
		opacity: 1;
	}
}

.switcher_highlighted-2Nkpe svg path {
	fill: var(--common-blue);
}

.input_icon-3AZFT {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 600px) {
	.autocomplete_alternative-1p4d8 {
		cursor: pointer;
	}

	.autocomplete_alternative-1p4d8 .input-r-YUx {
		padding: 0;

		font-size: 21px;
		font-weight: 600;
		color: var(--text-light);
	}

	.autocomplete_alternative-1p4d8 .input_withoutPlaceholder-1tzoW {
		color: transparent;
	}

	.autocomplete-kxwgB:not(.autocomplete_alternative-1p4d8):before {
		content: '';

		position: absolute;
		top: 50%;
		left: 8px;

		width: 24px;
		height: 24px;

		transform: translateY(-50%);
	}
}
