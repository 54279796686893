

div.root-17Tsj {
	top: 30vh;
}

div.content-61Kxj {
	min-width: 100px;
}

/* stylelint-disable */

.message-dLVOz {
}
