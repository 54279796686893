

.context-3SXCy .text-2bwQZ {
	padding-right: 4px;

	font-size: 15px;
}

.icon-2-NBv {
	margin-right: 10px;
}
