
/* end mixins */
div.serviceInfo__slider-2j7ye {
	overflow-y: auto;

	background: var(--gray05);
}

button.single-2CpnX {
	width: 100%;
	min-height: 49px;

	font-size: 15px;
}

button.single-2CpnX:hover {
	background: var(--active);
}

button.single_cancel-2pA20 {
	border: 1px solid var(--active);

	color: var(--active);

	background: #ffffff;
}

button.clear-385Uv {
	border: none;

	background: none;
}

.clearContainer-3SO73 {
	text-align: right;
}

.serviceInfo__seat-kpAmR {
	margin-bottom: 20px;
}

.serviceInfo__seat-kpAmR:last-child {
	margin-bottom: 0;
}

.serviceInfo__selectable-25pv5 {
	display: flex;
	align-items: center;
}

.serviceInfo__passenger-DqxGj {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.seat-LwGsd {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;

	margin-left: auto;

	border-radius: 4px;

	color: var(--gray70);
	font-weight: 500;
	font-size: 14px;

	background: var(--seat-standart);
}

.seat-LwGsd.seat_double-2vxPd {
	width: 88px;
}
