.wrapper-3mZYO {
	display: flex;
	align-items: center;
	max-width: 1100px;

	min-height: 67px;
	margin: 0 auto 12px;
	padding: 16px;

	border-radius: 4px;

	color: var(--brand1);

	font-size: 24px;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);

	background: #ffffff;
}

h1.title-dgSkx {
	margin: 0;

	font-weight: 700;
	font-size: var(--thanks-banner-font-size);
}

.title-dgSkx span {
	padding-right: 16px;
}

@media screen and (max-width: 600px) {
	.wrapper-3mZYO {
		margin: 0;

		box-shadow: none;
	}

	h1.title-dgSkx {
		font-size: 16px;
		font-weight: 500;
	}
}
