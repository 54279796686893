

.inner-1-hUU {
	justify-content: flex-end;
}

.period-QE4Yo {
	font-size: 18px;
}

.city-mM3nK.main_city-ZF8ii {
	font-size: 31px;
}
