.container-3SRG2 {
	margin-top: 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 15px;
	color: var(--gray50);
}

button.refuse-2z_Qx,
button.refuse-2z_Qx:hover {
	border-color: #ffffff;

	color: var(--error);

	background: #ffffff;
}

button.button-1w49w,
button.button-1w49w:hover {
	border-color: var(--active);

	color: #ffffff;

	background: var(--active);
}

.container-3SRG2 button:not(:last-of-type) {
	margin-left: auto;
	margin-right: 15px;
}

div.baggage-XFQdu {
	background-color: red;
}

.dialog__deleteIcon-19LRW {
	position: relative;
}

@media screen and (max-width: 600px) {
	.container-3SRG2 {
		flex-direction: column;
	}

	.text-s9tYr {
		margin-bottom: 20px;
	}

	button.button-1w49w,
	button.refuse-2z_Qx {
		width: 100%;
	}
}
