

.container-1CEcb {
	width: -moz-fit-content;
	width: fit-content;
	padding: 12px;

	border: 1px solid var(--light-violet);

	color: var(--text-mid);
	font-weight: 500;
	font-size: 16px;

	background: var(--very-pale-violet);
}

.link-dAInJ {
	display: flex;
	align-items: center;
	margin-top: 8px;

	color: var(--bright-violet);
	font-weight: 700;
	font-size: 16px;
	text-decoration: none;
}

.link-dAInJ svg {
	margin-left: 12px;
	width: 15px;
	height: 15px;
}
