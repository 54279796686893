.toggle_active-3WC7z:after {
	background: var(--success-icon);
}

.toggle__label-1JtQ2,
.toggle_active-3WC7z .toggle__label-1JtQ2 {
	color: #ffffff;

	opacity: 0.7;
}

@media screen and (max-width: 600px) {
	.switchItem-2zMox {
		padding: 0;
	}
}
