

.badge-3beoC {
	position: absolute;
	top: -18px;
	right: 10px;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

svg.badge-3beoC {
	color: var(--active);
}

div.tooltip-31muY {
	margin-top: -7px;
}

.inner-1H9wv {
	color: inherit;
}

.shadow-3Zd4a {
	color: inherit;
}

.wrapper-38lzD {
	color: inherit;
}
