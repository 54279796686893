.wrapper-ReGlr {
	display: flex;
	height: 100vh;
	width: 100vw;

	background-image: url('https://cdn.websky.aero/content/frontend/images/stub-404.jpeg');
	background-position: center 70%;
	background-size: cover;
	transform: matrix(-1, 0, 0, 1, 0, 0);
}

.content-20sl4 {
	height: 100vh;
	width: 100vw;
	padding: 170px;

	color: #ffffff;

	transform: matrix(-1, 0, 0, 1, 0, 0);
}

.error__description-2jD4u {
	height: 52px;
	display: flex;
	align-items: center;
	margin: 0;

	font-size: 37px;

	text-shadow: 3px 1px 20px rgba(0, 0, 0, 0.14);
}

.error__code-gubzC {
	margin: 0;
	display: flex;
	align-items: center;

	text-transform: uppercase;
	font-weight: 600;
	font-size: 80px;

	text-shadow: 3px 1px 20px rgba(0, 0, 0, 0.14);
}

@media screen and (max-width: 600px) {
	.content-20sl4 {
		padding: 50px;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	.error__description-2jD4u {
		height: auto;
		justify-content: center;

		text-align: center;
	}

	.error__code-gubzC {
		font-size: 50px;

		text-align: center;
	}

	.content-20sl4 .button-3Yokg {
		margin-top: 0;
	}
}
