

.fields-1aVKf {
	flex: 1;
	overflow: auto;
}

.controls-fPpvf {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr 1fr;
	height: 65px;
	padding: 12px;

	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

/* stylelint-disable */
.cancel-1ytwK {
}
.confirm-24mjy {
}
.disabled-3px-m {
}
/* stylelint-enable */
