.meal-1Po0s {
	border-radius: 12px;

	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.04);
	background: var(--bg-white);
	overflow: hidden;
}

.meal-1Po0s:after {
	border-radius: 12px;
}

.image-1QXQ1 {
	height: 220px;

	border-radius: 0;
}

.name-2_dmv {
	color: var(--text-mid);
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	line-height: 110%;
	text-transform: lowercase;
}

.name-2_dmv:first-letter {
	text-transform: uppercase;
}

.price-1O91t {
	padding: 8px 16px;

	border: 1px solid var(--brand-1-3);
	border-radius: 100px;

	color: var(--text-link);
	font-weight: 500;
	font-size: 22px;
	line-height: 110%;

	background: var(--brand-1-5);
}

.meal-1Po0s:hover .price-1O91t {
	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: var(--brand-1-5);
}

.meal-1Po0s:hover:after {
	z-index: 1;

	border-color: var(--brand-1-2);
}

.count-1UMnd {
	font-size: 15px;
}

div.popup-F_SoO {
	width: 520px;
}

.details__wrapper-2eUqQ {
	border-radius: 20px;
	overflow: hidden;
}

.details__content-_hs4f {
	padding: 20px 32px 12px 32px;

	background: var(--bg-fill);
}

.details__name-2T4fQ {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 130%; /* 28.6px */
	text-transform: lowercase;
}

.details__name-2T4fQ:first-letter {
	text-transform: uppercase;
}

.details__trigger-3dPga {
	color: var(--text-mid);
	font-size: 16px;
	font-weight: 700;
	line-height: 130%;
}

.details__trigger__icon-3jib3 {
	color: var(--text-pale);
}

.details__price-ZCrh3 {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 130%;
}

.details__description-2FfzX {
	border-top: 1px dashed var(--line-dot-line);
}

.details__row-2Eu9h:first-child {
	border-bottom: 0;
}

.details__description__row-Hwhde {
	margin-top: 16px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
}

.details__selector-3vTtR {
	display: flex;
	padding: 8px 32px 32px 32px;
	flex-direction: column;
	align-items: stretch;
	grid-gap: 24px;
	gap: 24px;
	width: 100%;
	align-self: stretch;
}

.details__counter-3FqJ4 {
	border-bottom: 1px dashed var(--line-separator);
}

.details__passenger-vxLTg {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 130%;
}

.details__passenger__index-222mB {
	color: var(--text-light);
	font-weight: 500;
	font-size: 14px;
	line-height: 130%;
	text-align: center;

	background: var(--bg-fill);
}

.details__total__text-1BU6R {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 130%;
}

.details__clear-3eUKL,
.details__add_for_all-2Dvka {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 130%;
}

.details__clear-3eUKL {
	border-left: 1px solid var(--line-separator);
}

button.details_button_empty-S2xsP {
	opacity: 0;

	pointer-events: none;
}

.categories__item-lS7ZM {
	display: flex;
	grid-gap: 0;
	gap: 0;
	align-items: center;
	flex-direction: row-reverse;
	padding: 12px 14px;

	border-radius: 100px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 110%;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);
}

.categories__item_selected-Im2II {
	padding: 12px 8px 12px 14px;

	color: var(--bg-white);

	background: var(--brand-1-0);
}

.categories__item__icon-38Rxz {
	width: 21px;
	height: 21px;
	margin: 0 0 0 2px;

	color: var(--brand-1-0);

	background: var(--brand-1-0);
}

.details__image-2PB2q {
	background-color: var(--bg-white);
}

.meal_fullSize-K35Wu {
	width: 800px;
	height: 340px;
	margin: 0 auto;
}

.meal_fullSize-K35Wu .image-1QXQ1 {
	width: 470px;

	border-radius: 12px 0 0 12px;
}

.meal_fullSize-K35Wu .name-2_dmv {
	text-align: left;
	font-size: 24px;
	font-weight: 700;
	line-height: 110%;
}

.meal_fullSize-K35Wu .footer-2BmOz {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.meal_fullSize-K35Wu .footer__icons-311ga {
	width: 100%;
}

.meal_fullSize-K35Wu .title-2ZTXr {
	margin-top: 0;
}

@media screen and (max-width: 600px) {
	div.popup-F_SoO {
		width: 100%;
	}

	.meals-3O1Cd:not(._singleMeal-2Kh8e) {
		grid-gap: 12px;
		gap: 12px;
	}

	.categories__wrapper-3SQan {
		margin-top: 0;
		height: auto;
		padding: 12px;

		border-radius: 4px;

		background: var(--brand-1-0);
	}

	.details__wrapper-2eUqQ {
		border-radius: 12px 12px 0 0;

		background: unset;
	}

	.categories__mobile__header-3Mkj6 {
		display: none;
	}

	.list__wrapper-3ueP2 {
		display: flex;
		flex-direction: column;
	}

	.categories__item-lS7ZM {
		flex-direction: row;

		background: transparent;
		box-shadow: none;
	}

	.categories__item_selected-Im2II {
		padding: 12px;

		border-radius: 4px;

		color: var(--text-accent);

		background: var(--bg-fill);
	}

	.categories__item__icon-38Rxz {
		display: none;
	}

	.details__name-2T4fQ,
	.name-2_dmv {
		text-transform: uppercase;
	}

	.details__counters-gumfy {
		max-height: 186px;
	}
}
