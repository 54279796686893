

.timer-3veSP {
	font-size: 15px;
	color: var(--common-gray);
}

.sliding-3SPQ_ {
	font-size: 13px;
	line-height: 24px;

	animation: slideUp-1NDUy 0.3s ease;
}

.link-1JW0L {
	margin-left: 3px;

	color: var(--cabinet-action);

	cursor: pointer;
}

.sent-fX2Bc {
	display: flex;
	align-items: center;

	color: var(--common-green);
}

.sent-fX2Bc svg {
	margin-right: 8px;
}

.wait-1U5P_ {
	display: flex;
	align-items: center;
}

.wait-1U5P_ svg {
	margin-right: 8px;
}

.wait-1U5P_ span {
	margin: 0 4px;
}

@keyframes slideUp-1NDUy {
	from {
		transform: translateY(10px);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}
