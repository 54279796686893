.ticket-1Cba6 {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 1100px;

	color: var(--gray80);
}

@media screen and (max-width: 600px) {
	div.header-14Ugi,
	div.ticket-1Cba6 {
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
	}
}

.transparent-2SMTa {
	background: transparent;
}

.header-14Ugi {
	display: flex;
	flex-direction: column;
}

.info-1xxZi {
	display: flex;
	align-items: baseline;
	padding: 16px;
}

@media screen and (max-width: 600px) {
	div.info-1xxZi {
		flex-direction: column;
		align-items: flex-start;
		padding: 10px;
	}

	.notes-3uA8I {
		padding: 0 20px;
	}
}

.title-ROFBf {
	margin: 0;
	padding: 0;

	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	.title-ROFBf {
		flex: 0 1 50%;

		font-size: 16px;
		font-weight: 500;
	}
}

@media screen and (max-width: 600px) {
	.createPrefix-3DwoW {
		display: none;
	}
}

.time-2G65U {
	margin: 0 0 0 10px;
	padding: 0 0 0 10px;

	border-left: 1px solid var(--gray20);

	color: var(--gray50);
	font-size: 14px;
	line-height: 150%;
}

@media screen and (max-width: 600px) {
	.time-2G65U {
		flex: 0 1 50%;
		align-self: flex-end;
		margin: -21px 0 0;
		padding: 0;

		border-left: 0;
	}
}

.status-30s_G {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.status-30s_G {
		margin-left: 0;
		margin-top: 6px;
		margin-bottom: 6px;
	}
}

.comments-7ayv3 {
	padding: 16px;

	border-top: 1px solid var(--gray05);
	border-bottom: 1px solid var(--gray05);
}

@media screen and (max-width: 600px) {
	.comments-7ayv3 {
		padding: 10px;
	}
}

.commentsTitle-eYiEq,
.filesTitle-3Zbb1 {
	margin: 0;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.commentsText-1asvI,
.filesContent-1uFRj {
	margin-top: 8px;
	margin-bottom: 0;

	font-size: 15px;
	line-height: 19px;
}

@media screen and (max-width: 600px) {
	div.breadcrumbs-22fai {
		display: none;
	}
}

.files-2T8zT {
	padding: 16px;
}

@media screen and (max-width: 600px) {
	.files-2T8zT {
		padding: 10px;
	}
}

.prices-1aIon {
	margin: 12px auto;
	max-width: 1100px;
}

@media screen and (max-width: 600px) {
	div.prices-1aIon {
		padding: 10px;
		margin-top: 0;

		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}
}

div.price-3nikw {
	padding: 25px 16px 16px 20px;
	margin: 12px 0;

	box-shadow: none;
}

@media screen and (max-width: 600px) {
	div.price-3nikw {
		margin: 0;
	}
}

.file-4r719 {
	display: flex;
	align-items: center;

	font-size: 16px;

	color: var(--base10);
}

@media screen and (max-width: 600px) {
	.file-4r719 {
		line-break: anywhere;
	}
}

.fileIcon-3m3ZB {
	margin-right: 7px;
}

.buttons-1iqta {
	max-width: 1100px;
	margin: 12px auto 0 auto;
}

@media screen and (max-width: 600px) {
	.buttons-1iqta > div {
		display: flex;
		flex-direction: column;
	}

	.buttons-1iqta > div button:last-of-type {
		margin-top: 8px;
		margin-left: 0;
	}
}

button.button-1BRhG {
	min-width: 250px;
}

button.confirm-38dXU {
	margin-left: 12px;
}

button.reject-3fK2R {
	color: var(--error);
	font-weight: 400;

	background: #ffffff;
}

/* stylelint-disable */
.refund__request-3S3an {
}
/* stylelint-enable */
@media screen and (max-width: 600px) {
	div.step-erRYH {
		display: block;
		padding-right: 15px;

		border-radius: 0;
	}

	div.step-erRYH:not(:last-of-type) {
		border: 1px solid rgba(255, 255, 255, 0.3);
	}

	.reasonHeader-1Py-j {
		max-height: 115px;
		display: block;
		overflow: hidden;

		text-overflow: ellipsis;
	}

	.reasonDescr-1MhLg {
		margin: 0;

		font-size: 14px;
		line-height: 19px;
		font-weight: 400;
	}
}
