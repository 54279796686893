.item-28508 {
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

.avatar-1a1sP {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;

	background: var(--bg-fill);
}

.payload-nXpTE > div:last-of-type {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 8px;
}

.passengerName-3Z2jR,
.payload-nXpTE {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.2;
}

.freeSeat__text-NTQ9S {
	color: var(--brand-1-0);
}

.select-NTjqe {
	display: none;

	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.clear-1zif3 {
	transform: scale(0.9);
}

.clear-1zif3 svg {
	color: var(--error-icon);
}

.item-28508 .passengerName-3Z2jR {
	padding-right: 5px;
}

.item-28508.item_active-2s-90 svg,
.item-28508.item_recommend-fwADI svg {
	color: var(--text-pale);
}

.item-28508.item_active-2s-90 .clear-1zif3 svg {
	color: var(--error-icon);
}

.item-28508.item_active-2s-90,
.item-28508.item_withSeat-3KjM_ {
	background: var(--white);
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05), 0 0 0 1px var(--brand-1-2);
}

.item-28508.item_active-2s-90 .select-NTjqe {
	display: inline;

	color: var(--brand-1-0);
}

.item-28508.item_recommend-fwADI,
.item-28508.item_recommend-fwADI.item_active-2s-90 {
	background: var(--white);
	box-shadow: none;
}

.item-28508.item_recommend-fwADI .avatar-1a1sP {
	color: var(--text-light);

	background: var(--bg-fill);
}

.item-28508.item_active-2s-90 .avatar-1a1sP,
.item-28508.item_recommend-fwADI.item_active-2s-90 .avatar-1a1sP {
	color: var(--white);

	background: var(--brand-1-0);
}

.item-28508.item_active-2s-90 .passengerName-3Z2jR,
.item-28508.item_active-2s-90 .numbers-T7dtP,
.item-28508.item_recommend-fwADI .passengerName-3Z2jR,
.item-28508.item_recommend-fwADI .numbers-T7dtP {
	color: var(--text-base);
}

.item-28508.item_active-2s-90 .money-1wiy-,
.item-28508.item_recommend-fwADI .money-1wiy- {
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.item-28508.item_withSeat-3KjM_ {
		box-shadow: none;
	}
}
