.wrapper-ZrD6l {
	border-radius: 8px;

	background: #f0f0f0;
}

.header-3R6U_ {
	margin-bottom: 20px;
	padding: 28px 40px;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;

	color: #ffffff;
	font-weight: 700;
	font-size: 24px;

	background: #5ba6ff;
}

.buttons-2Bkep {
	margin: 20px 0;
	padding: 0 40px;
	display: flex;
	width: 100%;
	justify-content: space-between;
}

div.modal__paper-2sk6v {
	max-width: 760px;

	border-radius: 8px;

	background: #f0f0f0;
}

/* stylelint-disable */
button.skipVisaButton-2_mhK {
}

button.saveButton-1_6eB {
}

button.saveButton-1_6eB[disabled] {
	opacity: 0.6;
}

@media screen and (max-width: 600px) {
	.header-3R6U_ {
		padding: 20px;

		border-radius: 0;

		font-size: 18px;
	}

	div.modal__paper-2sk6v {
		width: 100%;
	}

	.button-29Q8- {
		padding: 0 20px;
	}
}
