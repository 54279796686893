

.icons-18ehP {
	padding: 20px 24px;
	display: flex;
	align-items: center;
}

.icon-3Qy1P {
	display: inline-flex;
	align-items: center;
	height: 48px;

	color: var(--offer-social-buttons-color);
}

.icon-3Qy1P:not(:last-of-type) {
	margin-right: 12px;
}
