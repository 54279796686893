

.text_normal-yAksa {
	color: var(--text-base);
	line-height: 22px;
	font-size: 18px;
	font-weight: 500;
}

.text_light-1FRM2 {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
}

.segment-15SBc {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	padding: 12px 23px 12px 20px;

	border-radius: 8px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

.segment_disabled-2M4bD {
	opacity: 0.6;
	cursor: not-allowed;
}

.dateWrp-2XX_f,
.flightInfo-1JqHA {
	display: flex;
	flex-direction: column;
}

.airplane-Dyis0 {
	position: relative;
	top: 3px;

	display: inline-block;
	width: 16px;
	height: 16px;
	margin: 0 8px;

	color: var(--brand-3-0);
}

.dateWrp-2XX_f,
.selectWrp-29-9a {
	flex-grow: 1;
}

.dateWrp-2XX_f {
	max-width: 130px;

	padding-right: 17px;

	white-space: nowrap;
}

.selectWrp-29-9a {
	display: flex;
	justify-content: flex-end;
	margin-top: 1px;
	max-width: 121px;
	padding-left: 17px;
	margin-right: -11px;
}

.flightInfo-1JqHA {
	position: relative;

	flex: 1;
	padding-left: 20px;
}

.flightInfo-1JqHA:before,
.flightInfo-1JqHA:after {
	position: absolute;
	top: 50%;

	width: 1px;
	height: 46px;

	transform: translate(0, -50%);
	background: var(--bg-fill);
	content: '';
}

.flightInfo-1JqHA:before {
	left: -1px;
}

.flightInfo-1JqHA:after {
	right: -1px;
}

.route-2Y5bF {
	display: flex;
}

.time-32D_V,
.flight-2j23G,
.selectLabel-3vEOC,
.iata-2W4Gu {
}

.iata-2W4Gu {
	font-size: 18px;
	font-weight: 500;
}

.selectLabel-3vEOC {
	font-size: 14px;
	line-height: 17px;

	cursor: pointer;
}

span.checkbox-3Tlp1 {
	position: relative;
	top: -1px;
}

span.checkbox_checked-3cZiv.checkbox-3Tlp1 {
	color: var(--brand-3-0);
}
