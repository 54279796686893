
/* stylelint-disable */
.block-1LKe3 {
	display: flex;
	justify-content: center;
}

.popupWrapper_focused-39FmL {
}

/* stylelint-enable */
.popupWrapper-1bOat {
	z-index: 1;

	width: 100%;
}

.input__wrapper-2iCBu {
	position: relative;
}
