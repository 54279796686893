

button.container-tqvar {
	height: 48px;
	min-height: 48px;
	padding: 6px 16px;

	border-radius: 4px;

	background: var(--white);

	transition: background-color 0.3s, opacity 0.3s;
}

button.container-tqvar:hover {
	background: var(--brand-1-0);
}

button.container-tqvar.disabled-35m02 {
	opacity: 0.6;
}

.wrapper-3Tjd6 {
	display: flex;
	align-items: center;
	width: 100%;
}

.icon-3V66m {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 14px;

	color: var(--line-separator);
}

.content-19dL_ {
	display: flex;
	flex-direction: column;
}

.row-3Z8Oo {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.city-2nryF {
	color: var(--text-link);
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.4;
}

.country-2MeOq {
	color: var(--text-pale);
	font-size: 11px;
	font-weight: 400;
	line-height: 1.4;
}

.time-1KFM0 {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.4;
}

.distance-2BWYH {
	color: var(--text-pale);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.4;
}

button.container-tqvar:hover .icon-3V66m {
	color: var(--brand-1-3);
}

button.container-tqvar:hover .city-2nryF,
button.container-tqvar:hover .country-2MeOq {
	color: var(--white);
}
