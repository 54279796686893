.title-YGT5k {
	font-weight: 700;
	font-size: 21px;
	line-height: 30px;
	color: var(--gray80);
}

.description-3jL69 {
	margin-top: 12px;

	font-size: 13px;
	line-height: 1.5;
	color: #727272;
}

.info-2GmZs {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 33%;
}

.info-2GmZs.equipment-pgk3J {
	min-width: -moz-fit-content;
	min-width: fit-content;
	margin-left: auto;

	text-align: right;
}

.info_price-1vb3o {
	margin-top: auto;
}

.titleContainer-3Vh1K {
	flex: 1;
}

.baggage_wrapper-3cvEQ {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;

	padding: 16px 0;

	border-radius: 4px;

	background: #f6f6f6;
}

.baggage_wrapper-3cvEQ:not(:last-child) {
	margin-right: 12px;
}

.label-3M_qy {
	font-weight: 400;
	font-size: 13px;
	line-height: 1.4;
	color: #808080;
}

.price_wrapper-1me-m {
	margin-top: 6px;

	font-weight: 700;
	font-size: 13px;
	line-height: 1.4;
	color: var(--text);
}

.price_wrapper-1me-m.perFlight-Kaf74 {
	color: var(--success);
}

.price_label-2mXlN {
	display: flex;

	color: #262626;
	font-weight: 600;
	font-size: 13px;
	line-height: 130%;
}

.price_label-2mXlN.price_label_withPrice-JYcFC {
	justify-content: flex-end;
}

.price_amount-3Lnvy {
	margin-left: 12px;
	padding-left: 8px;

	border-left: 1px solid #d9d9d9;

	font-size: 16px;
}

.row-3DrTC {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 10px;
	gap: 10px;
}

.baggage__container-2_74y {
	display: flex;
	align-items: center;
	flex: 1;
}

.segmentNotice-16TkV {
	margin-top: 8px;
	padding: 12px 10px;

	border: 1px solid var(--warning);
	border-radius: 4px;

	color: var(--text-mid);
	font-size: 11px;

	background: var(--warning20);
}

.categories-N5yF4 {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	justify-content: space-between;
	justify-items: center;
	grid-gap: 10px 16px;
	gap: 10px 16px;

	width: 100%;

	margin-bottom: 24px;
}

.categories-N5yF4 .categories__item-1U1qq > svg {
	width: 70%;
	height: 70%;
}

.category__wrapper-GwHnd {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.category__price-1za-t {
	font-size: 16px;
	font-weight: 700;
	color: var(--text);
}

.category_info-PA_Rs {
	display: flex;
	flex-direction: column;
}

.category_title-3VpWX {
	margin-bottom: 4px;

	color: var(--base);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.category_description-O473d {
	color: #4d4d4d;
	font-size: 13px;
	line-height: 1.3;
}

.category_description-O473d a {
	text-decoration: underline;
	color: var(--base);
}

.promoMessage-_BWT7 {
	display: none;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.promoMessage-_BWT7 {
		font-size: 12px;
	}
}

@media screen and (max-width: 600px) {
	.included_baggage-zFMpG {
		display: none;
	}

	.row-3DrTC {
		display: block;
	}

	.baggage_wrapper-3cvEQ {
		margin-bottom: 12px;
		padding-bottom: 12px;

		border-bottom: 1px solid #d9d9d9;

		background: none;
	}

	.toggle__wrapper-25EKd {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.descriptionContainer-T1Qvl {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.description-3jL69 {
		margin-left: 20px;

		color: var(--text-light);
		font-size: 14px;
	}

	.baggageInfo-20CDE {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		color: var(--brand1);
		font-weight: 500;
		font-size: 14px;
	}

	.price_wrapper-1me-m {
		margin-top: 0;

		color: var(--text);
		font-size: 16px;
	}

	.price_label-2mXlN {
		margin-bottom: 8px;
	}

	.categories-N5yF4 {
		display: block;
		justify-content: unset;
		justify-items: unset;

		width: calc(100% + 24px);
		margin-left: -12px;
	}

	.categories-N5yF4 .slick-slide {
		padding-left: 12px;
	}

	.category__wrapper-GwHnd {
		margin-bottom: 12px;
		padding-bottom: 14px;

		border-bottom: 1px solid #d9d9d9;
	}

	.category_description-O473d {
		display: none;
	}
}
