

.root-3nY2M.additional_class-1QO6f {
	padding: 0;

	color: var(--radio-color);
}

.root-3nY2M.additional_class-1QO6f.checked-2vDd0 {
	color: var(--radio-checked-color);
}

.root-3nY2M.additional_class-1QO6f.disabled-2gGxs {
	color: var(--radio-disabled-color);
}

.root-3nY2M.additional_class-1QO6f:hover,
.root-3nY2M.additional_class-1QO6f.checked-2vDd0:hover {
	background: none;
}
