.airplane-3UYWS {
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

.airplane__image-1aqi1 {
	display: block;
}

.airplane__info-1xfY2 {
	padding: 12px;
}

.airplane__name-3SqFH a {
	display: flex;
	align-items: center;

	font-size: 13px;
	font-weight: 400;
	line-height: 1;
	text-decoration: none;
}

.airplane__name-3SqFH a:after {
	margin-left: 4px;

	content: '';

	width: 18px;
	height: 18px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDExVjE3SDExVjExSDEzWk0xMiAyMEM3LjU4OTk5IDIwIDMuOTk5OTkgMTYuNDEgMy45OTk5OSAxMkMzLjk5OTk5IDcuNTkgNy41ODk5OSA0IDEyIDRDMTYuNDEgNCAyMCA3LjU5IDIwIDEyQzIwIDE2LjQxIDE2LjQxIDIwIDEyIDIwWk0xMyA3VjlIMTFWN0gxM1oiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+Cg==) no-repeat center / contain;

	transform: translateY(-1px);
}

.details-2aKmc {
	justify-content: center;
	height: 44px;

	padding: 12px;

	border-color: var(--line-separator);

	color: var(--text-base);
}

.segment-xlWTq {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;
}

.sunInfo-a3aQ_ {
	line-height: 1;
}

.sunInfo-a3aQ_:not(:last-child) {
	margin-right: 20px;
}

.sunInfo-a3aQ_ svg {
	width: 20px;
	height: 20px;

	transform: translateY(-1px);
}

@media screen and (max-width: 600px) {
	div.airplane-3UYWS {
		padding: 0 28px;
	}

	.airplane__image-1aqi1 {
		display: block;
	}

	.seatsDetails-FrBji {
		border: none;
	}

	.seatsDetails_icon-2dWUs {
		display: none;
	}
}
