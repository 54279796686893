

.disclaimer-28sZ8 {
	display: flex;
}

.disclaimer__inner-1tEER {
	display: flex;
}

.disclaimer__icon-VAthV {
	display: block;
	height: 20px;
	width: 20px;
	margin-bottom: 12px;
}

.disclaimer__paragraph-293Fy {
	display: block;
}
