.root-2USIv {
	font-size: 16px;
	font-weight: 500;
}

.root-2USIv.type_review-1Y5YU.online_check_in-1m_KV {
	background: var(--warning);
}

.root-2USIv.type_review-1Y5YU.wait_payment-36srk {
	color: var(--white);

	background: var(--error);
}

.root-2USIv.type_review-1Y5YU.confirmed-2O00q,
.confirmed-2O00q .icon-1v8-V {
	color: var(--success);
}

.root-2USIv.type_account-jsHGh.online_check_in-1m_KV {
	background: var(--warning);
}

.root-2USIv.type_account-jsHGh.wait_payment-36srk {
	color: #ffffff;

	background: var(--error);
}

.root-2USIv.type_account-jsHGh.confirmed-2O00q {
	color: var(--success);
}

.root-2USIv svg {
	display: block;
}

.content-2RnGM {
	display: flex;
	align-items: center;
	width: 100%;
}

.text-TcUq1 {
	margin-left: 8px;

	font-weight: 500;
}

.transactions_wrapper-2guB9 {
	margin-left: auto;
}

.transactions-2_NGF {
	display: none;

	color: var(--base);
	font-size: 15px;

	cursor: pointer;
}

.canceled-3rAto {
	color: var(--error-text);
}

.canceled-3rAto svg {
	color: var(--error-icon);
}

@media (max-width: 768px) {
	.transactions-2_NGF {
		font-weight: 400;
	}
}
