.container-1bo9w {
	position: relative;

	display: flex;
	flex-direction: column;
}

.text-MreSZ {
	width: 65%;
	margin-bottom: 20px;

	color: var(--text-light);
	font-size: 16px;
	line-height: 1.3;

	white-space: break-spaces;
}

.footer-3aSvm {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

button.button-2NVys {
	flex: 1;

	border-radius: 4px;

	color: var(--brand-1-0);
	font: var(--medium--1-desktop);
	white-space: nowrap;

	background: var(--brand-1-4);
}

button.button-2NVys:hover {
	background: var(--brand-1-4);
}

button.button-2NVys:not(:last-child) {
	margin-right: 12px;
}

button.button-2NVys.button_gray-BYOBi {
	color: var(--text-light);
	font-weight: 400;

	background: var(--bg-fill);
}

button.button-2NVys.button_gray-BYOBi:hover {
	background: var(--grey-line);
}

.header-1aw7w {
	display: flex;
	align-items: center;
}

.miles-2Y-0U {
	padding-left: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 30px;
}

button.menu-2Kz9M {
	margin-left: auto;
}

.miles_currency-1XycA {
	margin-left: 8px;

	font-weight: 300;
}

.progress_status-U9Afp {
	margin-top: 16px;
	margin-bottom: 12px;
}

.progress_fares-2JLAj {
	margin-bottom: 20px;
}

.progress_status-U9Afp,
.progress_fares-2JLAj {
	color: var(--text-light);
	font-size: 16px;
	line-height: 1.3;
}

div.progress-W91WC {
	height: 8px;
	margin-bottom: 8px;

	border-radius: 20px;
}

div.progress_bg-2ZGfh {
	background: var(--bg-fill);
}

div.progress_color-1qIyM {
	border-radius: 20px;

	background: var(--brand-1-0);
}

.links-3W_Dt {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: auto;
}

.links_item-CvCRR {
	position: relative;

	margin-right: 25px;
	margin-bottom: 4px;

	color: var(--brand-1-0);

	cursor: pointer;
}

.links_item-CvCRR:before {
	content: '';

	position: absolute;
	right: -13px;

	width: 1px;
	height: 100%;

	background: var(--line-separator);
}

.links_item-CvCRR:last-child {
	margin-right: 0;
}

.links_item-CvCRR:last-child:before {
	display: none;
}

.loader-3DM9o {
	display: flex;
	align-items: center;
	margin-top: 12px;

	color: var(--text-light);
	font-weight: 500;
	font-size: 14px;
}

.loader-3DM9o .loader__indicator-3gaRE {
	margin: 0 12px;
}

.addToAppleWallet-cEk1g {
	width: 96px;
	margin-top: 20px;
	margin-left: auto;

	cursor: pointer;
}

.progress_text-37kiu {
	position: static;
}

@media (max-width: 1024px) {
	.text-MreSZ {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.footer-3aSvm {
		flex-direction: column;
		align-items: inherit;
	}

	.links-3W_Dt {
		margin-top: 20px;
	}

	button.button-2NVys {
		margin-right: 0;
		margin-bottom: 10px;
	}

	button.button-2NVys:last-child {
		margin-bottom: 0;
	}

	button.menu-2Kz9M {
		position: absolute;
		right: 20px;
		top: 0;

		transform: translateY(-50%);
	}

	.addToAppleWallet-cEk1g {
		order: 1;
	}
}
