

.price_selected-1kYEu .fare_family-1qP-L {
	margin-bottom: 8px;
}

.price_selected-1kYEu .prices__wrp-3Wjx8 {
	display: flex;
	justify-content: center;
	align-items: center;

	min-height: 46px;

	padding: 4px 16px 0;

	border-radius: 40px;

	color: var(--white);

	background: var(--brand-3-0);
}

.price-37z-y.price_selected-1kYEu .price__money-bRhYz {
	padding: 0;

	color: var(--white);
}
