div.paper-28mZE {
	margin-top: 150px;
}

.header-DfotD {
	height: 100px;
	padding: 0 40px;

	border-radius: 8px 8px 0 0;
	border-bottom: 1px solid #d9d9d9;

	color: var(--common-gray);
	font-size: 28px;
	line-height: 100px;

	background-color: #f9f9f9;
}

.header-DfotD:empty {
	height: 0;
	margin-bottom: 55px;

	border: none;
}

.content-3GsvF {
	padding: 32px 40px 40px;

	color: var(--common-gray);
	font-size: 16px;
	line-height: 25px;
	white-space: pre-wrap;
}

.content__text-12A00 {
	margin-bottom: 15px;
}

.content__text-12A00 a {
	color: var(--text-link);
}

.content__text_hidden-YQxq_ {
	display: none;
}

.content__error-P150Z {
	font-style: italic;
}

.buttons-XOY5b {
	margin-top: 32px;

	text-align: right;
}

@media screen and (max-width: 600px) {
	div.scrollBody-1B9lZ {
		display: flex;
		justify-content: flex-end;
	}

	div.scrollBody-1B9lZ div.paper-28mZE {
		width: 100%;
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
		overflow: auto;
	}
}
