

.autocomplete-2Z3Tm {
	position: relative;
}

.autocomplete-2Z3Tm > div {
	min-width: 100%;
	width: auto;
}

.input-3FPp5 {
	display: inline-block;
}

.options-2xRoJ {
	position: absolute;
	z-index: 1000;

	width: 100%;
	max-height: 200px;
	overflow-y: auto;
}

.group-owcyV:not(:last-of-type) {
	padding-bottom: 8px;
	margin-bottom: 5px;

	border-bottom: 1px solid var(--line-separator);
}

.group__label-1fkXO {
	padding: 6px 16px 0;
	width: 100%;

	color: var(--autocomplete-group-label);
	font-weight: 500;
	font-size: 12px;
	font-size: var(--autocomplete-group-label-fz, 12px);
	line-height: 24px;
	text-transform: uppercase;
}

.group__options-2v6OZ {
	display: block;
}

li.option-j8Mva {
	display: flex;
	align-items: center;

	font-family: inherit;
}

label.autocomplete__root-2deNu {
	z-index: 2;

	width: 100%;

	color: #9a9a9a;
	font-family: inherit;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	transform: translate(10px, 30px) scale(1);

	pointer-events: none;
}

label.label_shrink-T-rJn {
	transform: translate(0, -1.5px) scale(0.75);
}

label.autocomplete__root-2deNu.focused-uaqQJ:not(.label_error-kYWgr) {
	color: var(--passengers-input-bg);
}

div.input-3FPp5 {
	z-index: 1;

	font-family: inherit;
}

div.inner_input-1i_cD:before {
	content: '';

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	display: block;
}

.loader-amceF {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;

	margin: auto;
}

p.hint_error-OW-n3 {
	position: absolute;
	bottom: 0;

	width: 100%;

	font-family: inherit;
	white-space: nowrap;
	text-overflow: ellipsis;

	transform: translateY(140%);
	overflow: hidden;
}

.input_focused-GKcSF.inner_input-1i_cD:before {
	background: var(--input-background-active);
}

.input_focused-GKcSF input {
	border-color: var(--base) !important;
}
