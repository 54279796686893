.container-1Hrdm {
	padding: 28px 32px 32px;

	border-radius: 20px;

	background: var(--white);
}

.title-2I4Lo {
	margin-bottom: 22px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.icon-1VDEG {
	width: 48px;
	height: 58px;
	margin-bottom: 22px;

	color: var(--text-pale);
}

.icon-1VDEG svg {
	width: 100%;
	height: 100%;
}

ul.conditions-1_9Pi {
	margin: 0;
	padding-left: 26px;

	list-style: none;
}

ul.conditions-1_9Pi li {
	position: relative;

	margin-bottom: 20px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 15px;
	line-height: 1.3;
}

ul.conditions-1_9Pi li:before {
	content: '';

	position: absolute;
	top: 6px;
	left: -21px;

	width: 8px;
	height: 8px;

	border-radius: 50%;

	background: var(--line-separator);
}

ul.conditions-1_9Pi li:last-of-type {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	.container-1Hrdm {
		padding: 20px;

		border-radius: 12px;
	}
}
