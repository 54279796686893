.root-2yLVF {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 98px;
	height: 146px;
}

.wrapper-2L7qw {
	position: relative;
}

.wrapper-2L7qw svg {
	display: block;
}

.size-2GALH {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.prefix-1addJ {
	display: inline;

	font-weight: 900;
}

.value-2SBOp {
	font-weight: 900;
	font-size: 24px;
	line-height: 1;
}

.prefix-1addJ,
.measurement-2JIX1 {
	font-size: 13px;
	line-height: 1;
}

.root-2yLVF.hand_luggage_excess-xzAtN .wrapper-2L7qw,
.root-2yLVF.carry_on-ddScK .wrapper-2L7qw {
	padding-bottom: 6px;
}

.root-2yLVF.hand_luggage_excess-xzAtN .size-2GALH,
.root-2yLVF.carry_on-ddScK .size-2GALH {
	display: flex;
	align-items: center;
	justify-content: center;
}

.root-2yLVF.hand_luggage_excess-xzAtN .measurement-2JIX1,
.root-2yLVF.carry_on-ddScK .measurement-2JIX1 {
	margin-left: 4px;
}

.root-2yLVF.checked_baggage-UEgWR .size-2GALH {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.root-2yLVF.carry_on-ddScK:not(.small-1YrDN) .size-2GALH {
	bottom: 0;
	top: 0;

	margin: auto;
}

.root-2yLVF.hand_luggage_excess-xzAtN.small-1YrDN .size-2GALH,
.root-2yLVF.carry_on-ddScK.small-1YrDN .size-2GALH {
	bottom: 22px;
}

.root-2yLVF.checked_baggage-UEgWR.small-1YrDN .size-2GALH,
.root-2yLVF.checked_baggage-UEgWR.middle-XDRPM .size-2GALH {
	bottom: 24px;
}

.root-2yLVF.checked_baggage-UEgWR.large-1szr_ .size-2GALH {
	bottom: 28px;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.root-2yLVF.real_size-R8MOc {
	width: auto;
	height: auto;
}

.root-2yLVF.BaggageExcess-3PG1J {
	height: auto;
}

.root-2yLVF.BaggageExcess-3PG1J .wrapper-2L7qw .size-2GALH {
	position: relative;
	left: auto;
	right: auto;
	bottom: auto;

	margin-top: 10px;
}

.root-2yLVF.BaggageExcess-3PG1J .wrapper-2L7qw .size-2GALH .value-2SBOp {
	position: relative;

	display: inline;

	font-size: 22px;
}

.root-2yLVF.BaggageExcess-3PG1J .wrapper-2L7qw .size-2GALH .value-2SBOp:before {
	content: '+';
}

.root-2yLVF.BaggageExcess-3PG1J .wrapper-2L7qw .size-2GALH .measurement-2JIX1 {
	display: inline;
	margin-left: 3px;
}

@media screen and (max-width: 600px) {
	.root-2yLVF.BaggageExcess-3PG1J {
		width: 58px;
	}

	.root-2yLVF.BaggageExcess-3PG1J .wrapper-2L7qw > svg {
		width: 58px;
	}

	.root-2yLVF.BaggageExcess-3PG1J .wrapper-2L7qw .size-2GALH {
		display: none;
	}
}

.carry_on-ddScK.hand_luggage_excess-xzAtN:not(.real_size-R8MOc) .size-2GALH,
.carry_on-ddScK.large-1szr_:not(.real_size-R8MOc) .size-2GALH {
	top: 0;
}
