

.modal__content-3I9ms {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	grid-gap: 20px;
	gap: 20px;
	align-self: stretch;

	font: var(--medium--1-desktop);
}

.modal__header-2X98e {
	color: var(--text-Base);
	font: var(--medium-1-mobile-bold);
}

div.modal__paper-S89be {
	max-width: 520px;
}

.status-2C8sA {
	color: var(--text-mid);
	font: var(--medium-desktop-accent);
}

.status_accent-1pkb4 .passenger__segment-3MlvG {
	color: var(--error-text);
}

.services-1DWtq {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	grid-gap: 12px;
	gap: 12px;
	align-self: stretch;
}

.passenger-2Ds_F {
	display: flex;
	align-items: flex-start;
	grid-gap: 13px;
	gap: 13px;
	align-self: stretch;
}

.passenger__name-2wNgA {
	min-width: 140px;
}

.notification__text-3C0TI {
	font: var(--medium--1-desktop-accent);
}

.loader-1zo5t {
	margin: 0 auto;
}

button.removeServices-3Y648 {
	height: 47px;
	align-self: stretch;

	border-radius: 12px;
}
