.wrapper-3CYRh {
	padding: 44px 48px;
}

.header-1AEqt {
	margin-bottom: 28px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 20px;
	line-height: 1.6;
}

.text-39wh7 {
	margin-bottom: 40px;

	color: var(--sureModal-text-color);
	font-size: 17px;
	line-height: 1.7;
}

.footer-28n1q {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.button-xzd8I {
	margin-right: 12px;
}

button.button-xzd8I:last-child {
	margin-right: 0;
}

button.button_cancel-3oPgF {
	color: var(--sureModal-cancel-color);

	background-color: var(--sureModal-cancel-bg);
}

button.button_cancel-3oPgF:hover {
	background-color: var(--sureModal-cancel-bg);
}

@media screen and (max-width: 600px) {
	.wrapper-3CYRh {
		padding: 24px 28px;
	}
}
