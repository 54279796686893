

label.groupTitle-1Rc-p {
	font-weight: 500;
	color: var(--gray80);
}

fieldset.groupWrapper-1nty1 {
	display: block;
}

label.groupTitle-1Rc-p.active-3jr2F {
	color: var(--gray80);
}

.reasons-3l2lq .reason-1Iewk {
	padding: 20px 0;

	border-bottom: 1px solid var(--gray10);
}

.reasons-3l2lq .reason-1Iewk:last-of-type {
	border-bottom: none;
}
