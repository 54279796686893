.members-1i6r5 {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.members__header-2sO37 {
	display: flex;
	align-items: baseline;
	margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
	.members__header-2sO37 {
		margin-bottom: 10px;
	}
}

.members__subHeader-x6O3e {
	margin-bottom: 16px;

	font-size: 18px;
	font-weight: 400;
	line-height: 1.3;

	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.members__subHeader-x6O3e {
		display: none;
	}
}

.members__title-2VZVj {
	margin: 0;

	font-size: 24px;
	font-weight: 700;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	.members__title-2VZVj {
		font-size: 22px;
	}
}

.members__counter-1em2O {
	margin-left: 8px;

	font-size: 18px;
	font-weight: 400;

	color: var(--text-mid);
}

.members__items-1krBn {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 20px;
	gap: 20px;
}

@media screen and (max-width: 600px) {
	.members__items-1krBn {
		justify-content: center;
		grid-gap: 12px;
		gap: 12px;
	}
}

.addMemberForm__actions-28wFX {
	margin-top: 24px;

	display: flex;
	justify-content: flex-end;
}
