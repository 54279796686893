.list_reset-_3VQx {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

button.seat-fXydX {
	position: relative;

	z-index: 1;

	flex: 1;

	box-sizing: content-box !important;
	min-width: 29px;
	max-width: 47px;
	height: 44px;

	margin-right: 8px;

	border-right: 2px;

	border-radius: 4px;

	color: #ffffff;

	background: var(--seat-standart);
}

/* unavailable */
button.seat-fXydX.seat_unavailable-1yHse {
	background: var(--seat-busy);

	pointer-events: none;
}

.seat-fXydX.seat_unexistent-3bBo4 {
	max-width: 48px;

	opacity: 0;

	pointer-events: none;
}

/* regular */
.seat-fXydX {
	background: var(--seat-standart);
}

.seat-fXydX.seat_recommend-3bZpY {
	z-index: 1002;

	background: var(--selected);
}

.seat-fXydX.seat_recommend-3bZpY.seat_recommendCurrent-2iaaK {
	z-index: 1002;

	background: var(--selected2);
}

.seat-fXydX.seat_free-2dLyU {
	background: var(--seat-free);
}

/* business */
button.seat_business-t4gS0 {
	background: var(--seat-business);
}

button.seat_preferred-2MIkT {
	background: var(--seat-extra);
}

button.seat-fXydX.seat_occupied-FjVc3,
button.seat_preferred-2MIkT.seat_occupied-FjVc3,
button.seat_preferred-2MIkT.seat_occupied-FjVc3.seat_unavailable-1yHse,
button.seat_business-t4gS0.seat_occupied-FjVc3,
button.seat_business-t4gS0.seat_occupied-FjVc3.seat_unavailable-1yHse {
	background: var(--seat-occupaied);
}

.seat-fXydX:hover,
.seat_business-t4gS0:hover {
	background: var(--seat-hover);
}

.seat-fXydX.seat_customColor-lY8MB:hover {
	background: var(--seat-hover) !important;
}

.seat-fXydX.seat_occupied-FjVc3:hover {
	background: var(--seat-occupaied-hover);
}

.seat-fXydX.seat_customColor-lY8MB.seat_occupied-FjVc3,
.seat-fXydX.seat_customColor-lY8MB.seat_occupied-FjVc3:hover {
	background: var(--seat-occupaied-hover) !important;
}

.span-uJWT7.ripple-D4LIy {
	background-color: #ffffff;
}

.seat-fXydX:last-child {
	margin-right: 0;
}

.number-3k3RS {
	position: absolute;
	left: 50%;

	font-weight: 500;
	font-size: 14px;
	line-height: 14px;

	transform: translate(-50%, 0);

	transition: opacity 0.15s;
}

.seat-fXydX .number-3k3RS {
	color: var(--gray70);
}

.seat_occupied-FjVc3 .number-3k3RS {
	color: #ffffff;
	font-size: 22px;
	font-weight: 700;
}

.seat_occupied-FjVc3 .number-3k3RS.number_name-3X_QH {
	font-size: 14px;
	font-weight: 500;
}

.seat-fXydX.seat_recommend-3bZpY .number-3k3RS {
	color: #ffffff;
}

button.seat-fXydX.seat_combinedLeft-3r8-p .number-3k3RS,
button.seat-fXydX.seat_combinedRight-1XT1D .number-3k3RS {
	color: var(--white);
}

.seat_occupied-FjVc3.seat-fXydX:hover .number-3k3RS {
	opacity: 0;
}

.seat-fXydX:not(.seat_unexistent-3bBo4):not(.seat_unavailable-1yHse):not(.seat_occupied-FjVc3):hover .number-3k3RS {
	color: #ffffff;
}

.seat_occupied-FjVc3.seat-fXydX:not(.seat_confirmed-3vzbS):hover:after {
	position: absolute;
	top: -7px;
	right: 0;
	bottom: 0;
	left: 0;

	width: 16px;
	height: 16px;

	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjY2NjMgNC43NzMyNUwxMS43MjYzIDMuODMzMjVMNy45OTk2NyA3LjU1OTkyTDQuMjczMDEgMy44MzMyNUwzLjMzMzAxIDQuNzczMjVMNy4wNTk2NyA4LjQ5OTkyTDMuMzMzMDEgMTIuMjI2Nkw0LjI3MzAxIDEzLjE2NjZMNy45OTk2NyA5LjQzOTkyTDExLjcyNjMgMTMuMTY2NkwxMi42NjYzIDEyLjIyNjZMOC45Mzk2NyA4LjQ5OTkyTDEyLjY2NjMgNC43NzMyNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.male-1yvF0.seat-fXydX.seat_unavailable-1yHse:not(.seat_unexistent-3bBo4):after,
.female-3TSza.seat-fXydX.seat_unavailable-1yHse:not(.seat_unexistent-3bBo4):after {
	position: absolute;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjk5OTcgMTkuMzU3MUMxNS43ODk5IDE5LjM1NzEgMTguODU2OSAxNi4yODY4IDE4Ljg1NjkgMTIuNUMxOC44NTY5IDguNzEzMTYgMTUuNzg5OSA1LjY0MjgyIDExLjk5OTcgNS42NDI4MkM4LjIxMjg2IDUuNjQyODIgNS4xNDI1OCA4LjcxMzE2IDUuMTQyNTggMTIuNUM1LjE0MjU4IDE2LjI4NjggOC4yMTI5MiAxOS4zNTcxIDExLjk5OTcgMTkuMzU3MVpNMTAuMjg1NCA5LjA3MTM5QzExLjI3MTUgMTAuMDU5OSAxMS45OTk3IDEyLjUgMTcuMTQyNiAxMi41QzE3LjE0MjYgMTUuMzM1OSAxNC44MzU3IDE3LjY0MjggMTEuOTk5NyAxNy42NDI4QzkuMTYzODEgMTcuNjQyOCA2Ljg1Njg2IDE1LjMzNTkgNi44NTY4NiAxMi41QzYuODU2ODYgMTIuNSA4Ljg1MjQgMTEuMjkyOSAxMC4yODU0IDkuMDcxMzlaIiBmaWxsPSIjQTVBNUE1Ii8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.female-3TSza.seat-fXydX.seat_unavailable-1yHse:not(.seat_unexistent-3bBo4):after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuODU2NzYgMTkuMzU3MUgxNy4xNDI3QzE4LjA5MDEgMTkuMzU3MSAxOC44NTY3IDE4LjU4OTUgMTguODU2NyAxNy42NDI5VjEyLjVDMTguODU2NyA4LjcxMzIgMTUuNzg2NyA1LjY0MjgyIDExLjk5OTcgNS42NDI4MkM4LjIxMjg0IDUuNjQyODIgNS4xNDI1OCA4LjcxMzIgNS4xNDI1OCAxMi41VjE3LjY0MjlDNS4xNDI1OCAxOC41ODk1IDUuOTA5MjggMTkuMzU3MSA2Ljg1Njc2IDE5LjM1NzFaTTExLjk5OTcgNy4zNTcxOEMxMS45OTk3IDcuMzU3MTggMTEuOTk5NyAxMi41NTM2IDE3LjE0MjYgMTIuNTAwMUMxNy4xNDI2IDE1LjM0MDIgMTQuODM5MiAxNy42NDMgMTEuOTk5NyAxNy42NDNDOS4xNjAzMiAxNy42NDMgNi44NTY3NiAxNS4zNDAyIDYuODU2NzYgMTIuNTAwMUM2Ljg1Njc2IDEyLjUgMTEuOTk5NyAxMi41NTM2IDExLjk5OTcgNy4zNTcxOFoiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==);
}

button.seat-fXydX.seat_combinedLeft-3r8-p {
	padding-left: 4px;

	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	background: var(--seat-hover);
}

button.seat-fXydX.seat_combinedRight-1XT1D {
	margin-right: 0;
	padding-right: 4px;

	border-top-right-radius: 0;
	border-bottom-right-radius: 0;

	background: var(--seat-hover);
}

button.seat_small-2L7zV .number-3k3RS span {
	display: block;
}

button.seat_small-2L7zV .number-3k3RS span:last-child {
	margin-top: 5px;
}

button.seat-fXydX.seat_small-2L7zV:not(.seat_business-t4gS0) {
	max-width: 30px;
}

@media (min-width: 1024px) {
	button.seat-fXydX.seat_standart-2Xwfj:not(.seat_small-2L7zV) {
		max-width: 48px;
	}
}

@media only screen and (max-width: 420px) {
	button.seat_small-2L7zV {
		margin: 1px;

		width: 25px;
		min-width: 25px;
	}
}
