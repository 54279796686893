.promoCode-1agOv {
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-gap: 16px;
	gap: 16px;
	align-items: center;
	width: 100%;
}

.promoCode__title-2I7iG {
	margin-bottom: 12px;

	font-weight: 700;
	font-size: 22px;
	line-height: 28px;
}

button.promoCode__value-2qCwR {
	height: 20px;
	min-width: auto;
	padding-right: 0;
	padding-left: 0;

	color: var(--text-mid);
	font-size: 14px;

	background-color: transparent;
}

button.promoCode__value-2qCwR > div {
	width: 16px;
	min-width: 16px;
	height: 16px;
	margin-left: 5px;

	color: var(--text-light);
}

button.promoCode__value-2qCwR:hover {
	color: var(--text-link);

	background-color: transparent;
}

button.promoCode__value-2qCwR:hover > div {
	color: var(--text-link);
}

button.promoCode__btn-1egCF {
	margin-top: 8px;

	background-color: var(--brand-3-0);
}

button.promoCode__btn-1egCF:hover {
	background-color: var(--active-30);
}

.promoCode__info-1fxI3 {
	color: var(--text-mid);
	font-size: 14px;
	line-height: 18px;
}

.promoCode__info_flex-2Mis9 {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
}

.promoCode__info_expired-2HjIv {
	color: var(--brand-3-0);
}

.promoCode__info-1fxI3 svg {
	width: 16px;
	height: 16px;
}

.promoCode__row-29p0n {
	display: flex;
	align-items: center;
}

.promoCode__row_code-3OiZ8 {
	grid-gap: 16px;
	gap: 16px;
}

.promoCode__row_mb-3E-me {
	margin-bottom: 8px;
}

.promoCode__row_params-1Itjq > span:not(:last-child) {
	margin-right: 7px;
	padding-right: 7px;

	border-right: 1px solid var(--line-separator);
}

.promoCode__icon-3id9j {
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.promoCode__title-2I7iG {
		font-size: 18px;
	}

	.promoCode__icon-3id9j svg {
		width: 40px;
		height: 40px;
	}
}
