div.modal-3-jtZ {
	position: relative;
	z-index: 1301;

	display: flex;
	flex-direction: column;
	max-height: none;
	margin: 70px auto;

	border-radius: 8px;
	overflow: visible;
}

div.modal-3-jtZ.modal_bottom-3ctU6 {
	position: fixed;
}

div.modal_mobileFullScreen-1yrG0 {
	border-radius: 0;
}

div.modal_rounded-1mIWL {
	border-radius: 16px;

	background: var(--gray);
}

.extraTitle-1omNX {
	padding: 30px 60px 20px 30px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 18px;
	text-align: left;
}
@media screen and (max-width: 600px) {
	div.modal_rounded-1mIWL:not(.modal_bottom-3ctU6) {
		border-radius: 0 !important;
	}

	div.modal_rounded-1mIWL.modal_bottom-3ctU6 {
		border-radius: 16px 16px 0 0;
	}

	.modal_rounded-1mIWL .closeIcon-3-Yiq {
		z-index: 11;

		width: 28px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal_rounded-1mIWL .closeIcon-3-Yiq svg {
		position: relative;

		width: 28px;
		height: 28px;
	}
}

div.scrollBody-3XXu- {
	padding: 20px;
}

div.scrollBody-3XXu-:after {
	display: none;
}

.closeIcon-3-Yiq {
	position: absolute;

	top: -40px;
	right: -40px;
	z-index: 11;

	display: inline;

	color: #ffffff;

	cursor: pointer;
}

.modal_rounded-1mIWL .closeIcon-3-Yiq {
	top: 20px;
	right: 20px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--gray80);

	background: var(--gray05);
}

.modal_rounded-1mIWL .closeIcon-3-Yiq svg {
	color: var(--text-pale);

	transform: scale(0.6);
}

@media screen and (max-width: 600px) {
	div.modal-3-jtZ:not(.modal_bottom-3ctU6) {
		margin: 0 !important;
		height: auto;
		min-height: 100%;
		max-width: unset !important;
	}

	div.modal-3-jtZ.modal_bottom-3ctU6 {
		margin: auto;
	}

	div.scrollBody-3XXu- {
		padding: 0;
	}

	.title-3Vq7L {
		position: relative !important;

		padding: 12px 40px 12px 24px !important;

		background: #ededed;
	}

	.titleText-N178c {
		font-weight: 500;

		font-size: 20px;
		line-height: 23px;
		color: var(--common-gray);
	}

	.closeIcon-3-Yiq {
		top: 11px;
		right: 10px;
		z-index: 11;

		color: var(--common-gray);
	}

	.closeIcon_mobileFullScreen-2Rtht {
		top: 0;
		right: 0;

		padding: 3px 5px 1px 5px;

		color: #727272;

		background: #f0f0f0;
	}
}
