

.container-16juF {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;

	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

.money-2_R3C {
	font-size: 18px;
	font-weight: 700;
	color: var(--text-base);
}

.from-3zVXo {
	padding-right: 3px;
}

/* stylelint-disable */
.close-a1Lpn {
}
/* stylelint-enable */
