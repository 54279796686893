

.recommend-33HNg {
	justify-content: flex-start;

	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.together-2UmfQ {
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.recommend__wrapper-1t439 {
	border-radius: 4px;

	background: var(--brand-1-0);
}

.recommend__label-2gkdC {
	margin-bottom: 6px;

	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.recommend__label-2gkdC svg {
	margin-right: 6px;
}

.recommend__label-2gkdC,
.recommend__label-2gkdC svg,
.recommend__price-2dv5K,
.together-2UmfQ {
	color: var(--white);
}

.recommend__icon-UqMOq {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 6px;

	color: var(--brand-1-0);
}

button.passengers__button-B-kKb {
	border-radius: 6px;
}

button.passengers__button-B-kKb.passengers__button_clear-2gRKh {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.passengers__button-B-kKb.passengers__button_clear-2gRKh:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}
