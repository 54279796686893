

.root-3PptW {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/* stylelint-disable */
button.control-p6Ziw {
	margin-left: 12px;
}
.control__edit-3FBPP {
}
/* stylelint-enable */
.price-25vRr {
	font-weight: 700;
	font-size: 18px;
	line-height: 1;
	color: var(--base);
}

.price__selected-2eqQH {
	color: var(--gray80);
}
