

.container-_9Czl {
	display: flex;
	flex-direction: column;

	background: var(--white);
}

.summary-vAdJr {
	display: flex;
	align-items: center;

	padding: 20px;
}

.summary_collapsible-3dRQO {
	cursor: pointer;
}

.summary__title-336pb {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.2;
}

.summary__money-14hck {
	font-size: 31px;
}

.summary__arrow-3TA3F {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 24px;
	height: 24px;

	margin-left: 12px;

	transition: transform 0.15s;
}

.summary_expand-2ko4h .summary__arrow-3TA3F {
	transform: rotate(180deg);
}

.summary__arrow-3TA3F svg {
	width: 100%;
	height: 100%;
}

.details-3UdAr {
	display: flex;
	flex-direction: column;

	padding: 20px;

	border-top: 1px dashed var(--line-separator);
}

.details__row-3wq4r {
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.details__row-3wq4r:not(:last-child) {
	margin-bottom: 12px;
}

.details__money-3zV5y {
	font-weight: 500;
}
