

.wrapper-eT0os {
	padding: 30px;
}

.title-2ePZS {
	margin: 0;

	font-size: 28px;
	line-height: 35px;
}

.sub-1v09q {
	margin-top: 15px;
	padding-right: 30px;

	color: var(--light-gray);
	font-size: 18px;
	line-height: 130%;
}

.social-2FNxB {
	margin-top: 35px;
}

.email-2VruM {
	margin-top: 20px;
	margin-bottom: 0;

	color: var(--link-color);
	font-size: 15px;
	line-height: 22px;

	cursor: pointer;
}

button.button-1AeEp {
	margin-top: 40px;
	height: 64px;
	width: 100%;
}
