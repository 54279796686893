/* stylelint-disable */
div.root-24pp8 {
}

div.root_transfer-3oK3x .root__scrollBody-1SDd5 .root__paper-3ut_c {
	max-width: 840px;

	border-radius: 20px;

	background: transparent;
}

.root_animal-2IKDW {
}

.root_businessLounge-2thcM .header-ZikDP,
.root_priorityBoarding-3ZblF .header-ZikDP {
	height: 124px;
	padding-top: 0;

	color: var(--white);
	font-size: 28px;
	line-height: 1.3;

	border-radius: 8px 8px 0 0;
}

.root_businessLounge-2thcM .header-ZikDP {
	background: var(--additional-business-lounge);
}

.root_priorityBoarding-3ZblF .header-ZikDP {
	background: var(--additional-priority-boarding);
}

.root_loyalty-1UQE2 {
}

.header__info-1mGVZ {
}

.header__icon-UAutJ {
}

@media screen and (max-width: 600px) {
	.root_businessLounge-2thcM .header-ZikDP,
	.root_priorityBoarding-3ZblF .header-ZikDP {
		font-size: 22px;
		line-height: 1.2;
	}

	/* transfer */
	div.root_transfer-3oK3x .root__paper-3ut_c {
		border-radius: 0;
	}

	/* insurance */
	div.root_insurance-39Af3 .root__scrollBody-1SDd5 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.root_insurance-39Af3 .root__paper-3ut_c {
		min-height: auto !important;

		border-radius: 8px 8px 0 0;
	}

	div.root_insurance-39Af3 .root__scrollBody-1SDd5 .root__paper-3ut_c {
		overflow: auto;
	}

	/* baggage-delivery */
	div.root_baggageDelivery-iKwC7 .root__paper-3ut_c {
		padding: 12px;

		border-radius: 12px 12px 0 0;
	}

	/* capsule-hotel */
	div.root_capsuleHotel-aXcL0 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.root_capsuleHotel-aXcL0 .root__scrollBody-1SDd5 {
		height: auto;
	}

	/* common */
	.root_businessLounge-2thcM .root__scrollBody-1SDd5,
	.root_priorityBoarding-3ZblF .root__scrollBody-1SDd5,
	.root_transfer-3oK3x .root__scrollBody-1SDd5,
	.root_loyalty-1UQE2 .root__scrollBody-1SDd5 {
		display: flex;
		justify-content: flex-end;
	}

	.root_businessLounge-2thcM div.root__scrollBody-1SDd5 .root__paper-3ut_c,
	.root_priorityBoarding-3ZblF div.root__scrollBody-1SDd5 .root__paper-3ut_c,
	.root_transfer-3oK3x div.root__scrollBody-1SDd5 .root__paper-3ut_c,
	.root_loyalty-1UQE2 div.root__scrollBody-1SDd5 .root__paper-3ut_c {
		width: 100%;
		min-height: auto;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
	}
}
