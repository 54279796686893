.inputWrapper-1ClQM {
	position: relative;

	width: 100%;
}

div.progress__root-2tTL8 {
	z-index: 10;
}

div.progress__color-gfEVp {
	position: absolute;

	width: 100%;
	height: 4px;

	background-color: var(--results-loader-progress-background);

	transform: none;
}

div.progress__bar__color-2FGL0 {
	background-color: var(--results-loader-progress-color);
}

.legengs-rZrb3 {
	position: relative;

	margin-bottom: 15px;
}

.popup-15edQ {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;

	border-radius: 2px;

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);

	transform: translateY(calc(100% + 4px));

	background: #ffffff;
}

.day-2bVCm {
}

.day-2bVCm > div {
	font-size: 15px;
}

.datepicker__disclaimer-3mt-l {
	margin: 18px 28px 18px;

	font-size: 13px;
	color: var(--common-gray);
}

div.overlay-2k3JE {
	z-index: 1;

	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 40px;

	color: #ffffff;
}

.overlay__title-21HhJ {
	margin: 0;

	font-weight: 500;
	font-size: 57px;
	line-height: 85px;
}

.overlay__msg-2AKkC {
	margin: 36px 0;

	font-size: 17px;
	line-height: 25px;
	text-align: center;
}

button.overlay__button-hc2nZ {
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	div.dialog-r4_mV {
		z-index: 1501 !important;

		padding-right: 0 !important; /* for .mui-fixes */
	}

	.popup-15edQ {
		position: relative;

		display: flex;
		height: 100%;
		max-height: 100%;
		padding-top: 99px;
		padding-bottom: 50px;

		box-shadow: none;
		transform: none;
	}

	.popup_withoutTabs-arvWe {
		padding-top: 56px;
	}
}

.footer__buttons-1EwSv {
	display: flex;
	grid-gap: 10px;
	gap: 10px;

	padding: 0 28px;
}

.footer__buttons-1EwSv:only-child {
	width: 100%;
	justify-content: flex-end;

	padding-top: 12px;
	padding-bottom: 12px;
}

@media (max-width: 1024px) {
	div.footer__buttons-1EwSv {
		z-index: 5;
		position: fixed;
		bottom: 31px;

		justify-content: space-between;
		align-items: center;

		width: 100%;
		height: 60px;

		margin: 0;
		padding: 0 20px;

		background: var(--bg-white);
		box-shadow: 0 -7px 20px 0 rgba(0, 0, 0, 0.03);
	}

	div.footer__buttons-1EwSv:only-child {
		margin: 0;
		justify-content: space-between;
	}

	button.footer__clear-2UX1- {
		padding-left: 20px;

		color: var(--common-gray);
		font-weight: 400;
	}
}

.legengs__cheap-26nYV,
.legends__medium-1kn9M,
.legends__expensive-Uk9AB {
	position: relative;

	display: inline-block;
	margin-right: 27px;
	padding-left: 14px;

	color: var(--light-gray);
	font-size: 11px;
}

.legengs__cheap-26nYV:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;

	width: 10px;
	height: 10px;
	margin: auto;

	border-radius: 50%;

	opacity: 0.5;
	background: var(--common-emerald);
	content: '';
}

.legends__medium-1kn9M:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;

	width: 10px;
	height: 10px;

	border-radius: 50%;

	opacity: 0.5;
	background: #1e77ca;
	content: '';
}

.legends__expensive-Uk9AB:before {
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;

	width: 10px;
	height: 10px;

	border-radius: 50%;

	opacity: 0.5;
	background: var(--dark-red);
	content: '';
}

.calendar-3sQ46:before {
	width: 18px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiAySDE1VjBIMTNWMkg1VjBIM1YySDJDMC44OSAyIDAuMDEgMi45IDAuMDEgNEwwIDE4QzAgMTkuMSAwLjg5IDIwIDIgMjBIMTZDMTcuMSAyMCAxOCAxOS4xIDE4IDE4VjRDMTggMi45IDE3LjEgMiAxNiAyWk00IDlWMTFINlY5SDRaTTEwIDlIOFYxMUgxMFY5Wk0xNCA5VjExSDEyVjlIMTRaTTIgMThIMTZWN0gyVjE4WiIgZmlsbD0iI0E1QTVBNSIvPgo8L3N2Zz4K) center center;
}

.pricegraph-2yauy:before {
	width: 16px;
	height: 16px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IDE2SDEwVjBINlYxNlpNMCAxNkg0VjhIMFYxNlpNMTIgMTZWNUgxNlYxNkgxMloiIGZpbGw9IiNBNUE1QTUiLz4KPC9zdmc+Cg==) center center;
}

.matrix-2Jgmy:before {
	width: 24px;
	height: 22px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgc3R5bGU9Im1peC1ibGVuZC1tb2RlOm11bHRpcGx5Ij4KPHBhdGggZD0iTTguNiAxNEg5LjhMMTIuOCA5LjM2ODQySDE2LjFDMTYuNTk4IDkuMzY4NDIgMTcgOC45ODA1MyAxNyA4LjVDMTcgOC4wMTk0NyAxNi41OTggNy42MzE1OCAxNi4xIDcuNjMxNThIMTIuOEw5LjggM0g4LjZMMTAuMSA3LjYzMTU4TDYuOCA3LjYzMTU4TDUuOSA2LjQ3MzY4TDUgNi40NzM2OEw1LjYgOC41TDUgMTAuNTI2M0g1LjlMNi44IDkuMzY4NDJIMTAuMUw4LjYgMTRaIiBmaWxsPSIjQTVBNUE1Ii8+CjxwYXRoIGQ9Ik0xNS40IDIySDE0LjJMMTEuMiAxNy4zNjg0SDcuOUM3LjQwMiAxNy4zNjg0IDcgMTYuOTgwNSA3IDE2LjVDNyAxNi4wMTk1IDcuNDAyIDE1LjYzMTYgNy45IDE1LjYzMTZIMTEuMkwxNC4yIDExSDE1LjRMMTMuOSAxNS42MzE2SDE3LjJMMTguMSAxNC40NzM3SDE5TDE4LjQgMTYuNUwxOSAxOC41MjYzSDE4LjFMMTcuMiAxNy4zNjg0SDEzLjlMMTUuNCAyMloiIGZpbGw9IiNBNUE1QTUiLz4KPC9nPgo8L3N2Zz4K) center center;
}

.content-23l0u {
	position: relative;
}

/* stylelint-disable */
.footer__clear-2UX1- {
}

.footer__done-iXB30 {
}
/* stylelint-enable */

@media (max-width: 1024px) {
	div.footer__buttons-1EwSv,
	.content-23l0u {
		width: 100%;
		max-width: 450px;
	}

	.content-23l0u {
		min-width: 0;
		overflow: auto;
	}
}

@media screen and (max-width: 600px) {
	.content-23l0u {
		max-width: 100%;
	}

	div.footer__buttons-1EwSv {
		bottom: 0;

		max-width: none;
	}
}

.footer-IBTuW {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;

	border-top: 1px solid #ededed;
}

.tabs-33te3 {
	display: inline-block;
	margin-left: 14px;
}

span.tab__indicator-3no1y {
	top: 0;
	bottom: auto;

	height: 3px;

	background-color: var(--common-blue);
}

button.tab-1ob_K {
	min-width: auto;
	margin: 0 16px;
	height: 43px;
	min-height: 43px;

	font-size: 13px;
	font-weight: 500;
	text-transform: none;
	color: #9a9a9a;
}

@media (min-width: 960px) {
	button.tab-1ob_K {
		padding-left: 0;
		padding-right: 0;

		font-family: inherit;
	}
}

button.tab_selected-o54YR {
	color: var(--common-blue);
}

@media (max-width: 1024px) {
	.footer-IBTuW {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.tabs-33te3 {
		margin-left: 0;
		width: 100%;
	}

	span.tab__wrapper-gnO4O {
		display: flex;
		flex-direction: row;
	}

	span.tab__wrapper-gnO4O svg {
		margin-right: 10px;
	}

	button.tab-1ob_K {
		flex-grow: 1;
		max-width: 100%;
		width: auto;
		margin: 0;

		font-family: inherit;
		color: #777777;
		font-size: 12px;
		font-weight: 500;

		background: #ededed;
	}

	.tab-1ob_K.tab_matrix-3ThGm {
		display: none;
	}

	.tab_selected-o54YR.tab-1ob_K {
		background: #ffffff;
	}

	button.tab_selected-o54YR {
		color: #777777;
	}

	.tab__indicator-3no1y {
		display: none;
	}
}

.dialog__bar-xxOoz {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;

	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 56px;
	padding: 0 14px;

	color: #313131;

	box-shadow: none;
	background-color: #ededed;
}

.dialog__dates-10mEW {
	font-size: 16px;
	font-weight: 500;

	pointer-events: none;
}

.dialog__dates-10mEW span {
	pointer-events: all;
}

.dialog__dates-10mEW > span {
	display: inline-flex;
	align-items: center;
}

.dialog__dates-10mEW:before {
	pointer-events: all;
}

.dialog__dateBack-3or4t:before {
	content: ' \2013   ';

	padding: 0 4px;

	color: currentColor;
}

.dialog__activeDate-xjoC3 {
	color: var(--datepicker-day-selected-background);
}

.dialog__close-3-yRG {
	position: absolute;
	right: 10px;

	width: 24px;
	height: 24px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNUwxMiAxMC41OUw2LjQxIDVMNSA2LjQxTDEwLjU5IDEyTDUgMTcuNTlMNi40MSAxOUwxMiAxMy40MUwxNy41OSAxOUwxOSAxNy41OUwxMy40MSAxMkwxOSA2LjQxWiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K) no-repeat center;
}

@media (max-width: 1024px) {
	div.wrapper-2NsOS nav {
		position: absolute;
		top: 20px;
		left: 0;

		width: 100%;
		display: flex;
		padding: 0 20px;
	}

	div.nav_prev-2ifCp {
		top: 20px;
		left: 20px;
	}

	div.nav_next-1MDPZ {
		top: 20px;
		right: 20px;
	}
}
