

.stepbar-3jOW9 {
	width: 100%;
	padding-bottom: 5px;

	background-color: var(--mobileStepbar-background);
}

div.stepbar__cartWrapper-34KlB {
	max-width: 100%;
	min-width: auto;
}
