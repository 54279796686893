

.segment-S94ts {
	display: flex;
	flex-direction: column;
}

label.label__root-28Nvv {
	padding: 8px 16px 8px 2px;
	margin: 0;

	border-radius: 4px;

	background: #f6f6f6;
}

span.label__label-3wDjK {
	display: flex;
	align-items: center;
	flex: 1;
}

span.checkbox-2fCxU {
	margin-right: 4px;
}

span.checkbox-2fCxU.checkbox__checked-2cFQZ {
	color: var(--base);
}

.collapse-3AB7S span.checkbox-2fCxU {
	display: none;
}

.collapse-3AB7S label.label__root-28Nvv {
	padding: 16px;
}

.date-2Yxa4 {
	margin-right: 8px;
	padding-right: 8px;

	border-right: 1px solid #d9d9d9;

	white-space: nowrap;
}

.date-2Yxa4,
.info-3sE7u {
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.arrow-F2onj {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;

	color: var(--gray30);

	transform: rotate(90deg);

	transition: transform 0.15s;
}

.arrow-F2onj.arrow__collapse-2KQVs {
	transform: rotate(-90deg);
}

.wrapperInner-24hCA {
	padding-top: 12px;
}
