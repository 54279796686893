

button.button-uFhQh {
	color: var(--gray50);

	background: #ffffff;
}

button.button-uFhQh:hover,
button.button-uFhQh:focus {
	background-color: #ffffff;
}
