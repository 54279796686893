

button.button-1aWzF {
	width: 38px;
	height: 38px;
	padding: 6px;

	color: var(--text-pale);

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
}

button.button-1aWzF:before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;

	border-radius: 50%;

	background-color: var(--white);
	content: '';
}

div.paper-2ycwG {
	max-width: 200px;

	border-radius: 12px;

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);
}

div.paper-2ycwG > ul {
	padding: 12px;
}

div.paper-2ycwG > ul > li {
	padding: 12px;

	border-radius: 4px;

	color: var(--gray80);
	font-weight: 500;
	line-height: 1.3;
	white-space: normal;

	transition: color 0.15s, background 0.15s;
}

div.paper-2ycwG > ul > li:hover {
	color: var(--base);

	background: var(--bg-fill);
}
