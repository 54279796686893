

.button-2OAVn {
	width: 26px;
	min-width: 26px;
	max-width: 26px;
	height: 26px;
	min-height: 26px;
	max-height: 26px;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.button-2OAVn svg {
	width: 18px;
	height: 18px;
}

.button-2OAVn:not(.button_disabled-3Ybax):hover {
	color: white;

	background: var(--brand-1-1);
}

.button-2OAVn:not(.button_readonly-SRfL2).button_disabled-3Ybax {
	opacity: 0.6;
}

.value-4jeJY {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 130%;
}
