

.popup-3Nfx3 {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;

	display: none;

	background: #ffffff;
}

.visible-284Le.popup-3Nfx3 {
	display: flex;
	flex-direction: column;
}
