.wrapper-6p0T2 {
	display: flex;
}

.left-239ZY {
	justify-content: flex-start;
}

.center-3D9tH {
	justify-content: center;
}

.right-3eogQ {
	justify-content: flex-end;
}

button.button-2MCMz,
button.backButton-3tzqO {
	min-width: 160px;
}

button.backButton-3tzqO:not(:last-child) {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	button.button-2MCMz {
		width: 100%;
	}
}
