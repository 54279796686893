

.checkbox-2xJoE {
	display: flex;
	align-items: center;
	padding: 8px;
}

span.checkbox__label-1usI0 {
	color: var(--text-base);
	text-transform: capitalize;
	font-size: 15px;
	line-height: 1.3;
}

span.checkbox__root-3A1R-.checkbox__checked-3odei {
	color: var(--base);
}

.checkbox__price-3Smpn {
	margin-left: auto;

	color: var(--text-light);
	font-size: 15px;
	line-height: 1.3;
}

.select-3FkAg {
	width: 100%;
	margin-bottom: 16px;
}

.select-3FkAg > svg {
	margin-right: 6px;
}

div.select_root-3j5DH {
	padding: 18px 20px 18px 10px;

	border-radius: 4px;

	border: 1px solid var(--gray20);
}

.passenger-2Fau- {
	padding: 6px 0;

	display: flex;
	align-items: center;

	border-bottom: 1px solid var(--line-separator);
}

.passenger-2Fau-:first-child {
	border-top: 1px solid var(--line-separator);
}

.passenger_vipService-2afJS {
	border-bottom: 1px dashed var(--line-separator);
}

.passenger_vipService-2afJS:first-child {
	border-top: 1px dashed var(--line-separator);
}

span.label-3x_fT {
	color: var(--text-base);
	font-weight: 400;
	line-height: 1.3;
	text-transform: capitalize;
}

.price-1q_1f {
	margin-left: auto;

	color: var(--text-light);
	font-weight: 400;
	font-size: 16px;
	line-height: 1;
}
