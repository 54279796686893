.root-37YN7.type_review-lfZcs.confirmed-sUJIr {
	color: var(--success-text);
}

.icon-24mFF {
	width: 21px;
	height: 21px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkluZm8gLyBJdGVtIEljbHVkZWQiPgo8cGF0aCBpZD0iU3VidHJhY3QiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjEgMTFDMjEgMTYuNzk5IDE2LjI5OSAyMS41IDEwLjUgMjEuNUM0LjcwMTAxIDIxLjUgMCAxNi43OTkgMCAxMUMwIDUuMjAxMDEgNC43MDEwMSAwLjUgMTAuNSAwLjVDMTYuMjk5IDAuNSAyMSA1LjIwMTAxIDIxIDExWk0xNS40OTM3IDguOTkzNzJDMTUuODM1NCA4LjY1MjAxIDE1LjgzNTQgOC4wOTc5OSAxNS40OTM3IDcuNzU2MjhDMTUuMTUyIDcuNDE0NTcgMTQuNTk4IDcuNDE0NTcgMTQuMjU2MyA3Ljc1NjI4TDkuMzQ4NjggMTIuNjYzOUw2Ljc0MzcyIDEwLjA1ODlDNi40MDIwMSA5LjcxNzIgNS44NDc5OSA5LjcxNzIgNS41MDYyOCAxMC4wNTg5QzUuMTY0NTcgMTAuNDAwNiA1LjE2NDU3IDEwLjk1NDYgNS41MDYyOCAxMS4yOTY0TDkuMzQ4NjggMTUuMTM4OEwxNS40OTM3IDguOTkzNzJaIiBmaWxsPSIjNUZDQTQzIi8+CjwvZz4KPC9zdmc+Cg==) no-repeat center;
}

.icon-24mFF svg {
	display: none;
}

@media screen and (max-width: 600px) {
	.root-37YN7.type_review-lfZcs.confirmed-sUJIr:first-of-type {
		padding-top: 2px;
	}

	.root-37YN7.type_review-lfZcs.confirmed-sUJIr:first-of-type > div {
		align-items: center;
	}
}
