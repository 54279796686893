

label.formLabel-3OG2h {
	font-size: 24px;
	line-height: 24px;
	font-weight: 700;

	color: var(--gray80);
}

.notes-cHxIw {
	max-width: 1100px;
	margin: 0 auto;
}

.wide-3F5l1 {
	max-width: 1100px;
}

.selectedServices-3lmOg {
	max-width: 1100px;

	margin: 0 auto 20px;
}

.selectedServices-3lmOg .note-2qEOJ {
	margin-left: 0;
	margin-right: 0;
}

.selectedServices-3lmOg .note-2qEOJ:first-child {
	margin-top: 0;
}

div.control-1TWeL {
	margin: 10px 0;
}

/* error text */
.control-1TWeL > p {
	bottom: 12px;
}

label.agree-1sAQZ {
	max-width: 1100px;
	margin: 21px auto;
	display: flex;
}

span.checkbox-3rLSm.active-2sIxI {
	color: var(--base);
}

.terms-3DtNZ {
	color: var(--common-blue);
}

div.form-333xc {
	max-width: 1100px;
}

/* stylelint-disable */
.wrapper-1Hn7W {
}

.formName-NtfRt {
}

.formEmail-1qg1r {
}

.formTel-1vPh4 {
}

.formComment-Bex1W {
}
/* stylelint-enable */
