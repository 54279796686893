.airplane-QpRXp {
	position: relative;

	min-width: 320px;
	max-width: 320px;

	margin-bottom: 16px;

	border-radius: 4px;

	text-align: center;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);

	background-color: #ffffff;

	transition: all 0.15s;

	overflow: hidden;
}

.airplane_noInfo-1GRkQ {
	display: none;
}

.airplane__info-3jzSG {
	padding: 12px 20px 20px;

	text-align: center;
}

.airplane__info_wrapper-Q9Wgt {
	display: flex;
	justify-content: center;
	align-items: center;
}

.airplane__name-3Vlde {
	display: flex;
	justify-content: center;
	align-items: center;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 16px;
}

.airplane__amenities-1q7am {
	display: none;
	padding-left: 12px;

	border-left: 1px solid var(--line-separator);
}

.info-3HtSd {
	width: 20px;
	height: 20px;
	margin-bottom: 2px;
	margin-left: 5px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC43Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2Njk5IDkuOTk5OTZDMS42NjY5OSA1LjM5OTk2IDUuNDAwMzMgMS42NjY2MyAxMC4wMDAzIDEuNjY2NjNDMTQuNjAwMyAxLjY2NjYzIDE4LjMzMzcgNS4zOTk5NiAxOC4zMzM3IDkuOTk5OTZDMTguMzMzNyAxNC42IDE0LjYwMDMgMTguMzMzMyAxMC4wMDAzIDE4LjMzMzNDNS40MDAzMyAxOC4zMzMzIDEuNjY2OTkgMTQuNiAxLjY2Njk5IDkuOTk5OTZaTTEwLjgzMzcgOS4xNjY2M1YxNC4xNjY2SDkuMTY2OTlWOS4xNjY2M0gxMC44MzM3Wk0xMC4wMDAzIDE2LjY2NjZDNi4zMjUzMiAxNi42NjY2IDMuMzMzNjUgMTMuNjc1IDMuMzMzNjUgOS45OTk5NkMzLjMzMzY1IDYuMzI0OTYgNi4zMjUzMiAzLjMzMzI5IDEwLjAwMDMgMy4zMzMyOUMxMy42NzUzIDMuMzMzMjkgMTYuNjY3IDYuMzI0OTYgMTYuNjY3IDkuOTk5OTZDMTYuNjY3IDEzLjY3NSAxMy42NzUzIDE2LjY2NjYgMTAuMDAwMyAxNi42NjY2Wk0xMC44MzM3IDUuODMzMjlWNy40OTk5Nkg5LjE2Njk5VjUuODMzMjlIMTAuODMzN1oiIAogICAgZmlsbD0iY3VycmVudENvbG9yIi8+CjwvZz4KPC9zdmc+Cg==) center no-repeat;
}

.airplane__info-3jzSG .airplane__image-1UA88 img {
	width: 220px;
	min-height: 71px;
	margin-bottom: 20px;
}

.details-3PLaX {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 52px;
	padding: 0 28px;

	border-top: 1px dashed #d1d1d1;

	color: var(--gray80);
	font-size: 14px;
}

.details_one-H2e88 {
	justify-content: center;
}

.seatsDetails-sNRwU {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 44px;

	border: 1px solid var(--brand-1-2);

	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 500;

	font-size: 14px;
	line-height: 1.3;

	background: var(--brand-1-5);
}

.seatsDetails_icon-NfoUA {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 18px;
	min-width: 18px;
	height: 18px;

	margin-right: 6px;

	color: var(--brand-1-2);
}

.seatsDetails_icon-NfoUA svg {
	width: 100%;
	height: 100%;
}

.modal-18A2k {
	z-index: 1501 !important;
}

@media (max-width: 768px) {
	div.airplane-QpRXp {
		margin: 20px auto;
		max-width: 394px;
	}
}

@media screen and (max-width: 600px) {
	div.airplane-QpRXp {
		margin-bottom: 0;
		padding: 0 20px;

		box-shadow: none;
	}

	.segment-14mzl {
		margin: 17px 20px;

		color: var(--gray70);
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}
}
