.root-29VV8 {
	padding: 6px 12px;

	border-radius: 100px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.2;
	color: var(--success-20);

	background: var(--success90);
}
