

.wrapper-34zwO {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 12px 18px 20px;

	background: inherit;
}

.title-18DpZ {
	font-size: 24px;
	color: var(--text-base);
	font-weight: 700;
}

.button-3vs4y {
	width: 32px;
	height: 32px;
	margin-right: 12px;

	border-radius: 50%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBvcGFjaXR5PSIwLjMiIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgZmlsbD0ibm9uZSIvPgo8cGF0aCBkPSJNMTYuNTkgOC41ODk4NEwxMiAxMy4xNjk4TDcuNDEgOC41ODk4NEw2IDkuOTk5ODRMMTIgMTUuOTk5OEwxOCA5Ljk5OTg0TDE2LjU5IDguNTg5ODRaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat var(--brand-1-0);

	transition: all 0.15s;
}

.button_opened-3zmUa {
	transform: rotate(-180deg);
}

.root-2bLzT .content-RlsSV {
	min-height: 100%;
	width: 94%;
	margin: 0 auto;
	padding: 20px;

	border-radius: 4px;

	background: var(--account-sidebar-background);
}

.root-2bLzT {
	position: absolute;
	top: 56px;
	right: 0;
	left: 0;
	z-index: 10;

	margin-right: auto;
	margin-left: auto;

	text-align: center;
}

.user-1CBcc {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
}

.user__info-26N1i {
	display: flex;
	flex-direction: column;

	color: var(--bg-white);
	font-weight: 600;
	font-size: 18px;
}

.content-RlsSV .menu__item-1Ey-Z {
	padding: 15px;
}

.content-RlsSV div:not(.user__info-26N1i):not(.user-1CBcc):hover,
.content-RlsSV div.menu__item_selected-1ac7c,
.content-RlsSV div.menu__item_selected-1ac7c:hover {
	border-radius: 4px;

	background-color: var(--brand-1-1);
}

.menu__item-1Ey-Z span {
	color: #ffffff;
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;
	font-family: inherit;
}

.content-RlsSV .menu__item-1Ey-Z .icon-s3JSu > svg {
	width: 20px;
	height: 24px;
}

.menu__item-1Ey-Z .icon-s3JSu {
	margin-right: 0;
	min-width: 42px;

	font-size: 20px;
	color: #ffffff;

	opacity: 0.7;
}

.menu__item-1Ey-Z .item__text-21P5- {
	padding: 0 24px 0 0;
	overflow: hidden;

	text-overflow: ellipsis;
	color: #ffffff;
}

.menu__item-1Ey-Z .badge-8na1f {
	padding-right: 6px;
	padding-left: 6px;

	color: var(--brand-1-0);

	background-color: var(--bg-white);
}
