

.list-16vIY {
	margin: 0;
	padding-left: 15px;

	font-weight: 400;
	font-size: 16px;

	list-style-type: none;
}

.list-16vIY li {
	position: relative;
}

.list-16vIY a {
	color: var(--common-blue);
}

.list-16vIY li:before {
	position: absolute;
	top: 50%;
	left: -15px;

	width: 5px;
	height: 5px;

	border-radius: 50%;

	transform: translate(0, -50%);
	background: var(--text-light);
	content: '';
}
