

.wrapper-29zUj {
	display: flex;
	max-width: 1100px;
	margin: 28px auto 0;
}

.right-2M7rg {
	margin-left: auto;
	display: flex;
}
