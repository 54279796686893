

button.root-XpTA_ {
	width: 40px;
	min-width: unset;
	height: 40px;
	min-height: unset;
	padding: 8px;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-3-0);
}

button.root-XpTA_:hover {
	background: var(--brand-3--1);
}

button.root-XpTA_.success-2bFc2,
button.root-XpTA_.success-2bFc2:hover {
	background: var(--success-icon);
}

button.root-XpTA_.loading-2ZEN9 {
	background: var(--line-dot-line);
}

.root-XpTA_ span.colorPrimary-2cyi4 {
	color: var(--white);
}
