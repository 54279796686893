

.passengersForm-1kx66 {
	max-width: 1100px;
	display: flex;
	flex-direction: column;
	margin: 50px auto 0;
}

button.submit-89kNR {
	margin-top: 24px;
	margin-left: auto;
}

div.passenger-20Eqh {
	max-width: 100%;
}
