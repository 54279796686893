.fareGroup-YqfNr {
	position: relative;

	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: flex-start;
	min-width: 256px;
	max-width: 380px;
	padding: 22px 16px 16px 16px;
	overflow: visible;

	border-radius: 4px;
}

.fareGroup_unavailable-3vJAG {
	opacity: 0.6;
	cursor: not-allowed;
}

.fareGroup_recommended-2owC8 {
	border: none;
}

.fareGroup_Economy-1iMe1 {
	background: var(--results-faregroup-economy);
}

.fareGroup_Business-2taXD {
	background: var(--results-faregroup-business);
}

.fareGroup_white-3HHUz {
	border-radius: 8px;

	background-color: var(--white);
}

.title-2Kwtx {
	color: var(--results-faregroup-economy-title);
	font-size: 21px;
	line-height: 28px;
	font-weight: 600;
	white-space: nowrap;
}

.fareGroup_white-3HHUz .title-2Kwtx {
	font-size: 19px;
	color: var(--gray80);
}

.info-1lOnT {
	position: absolute;

	display: inline-flex;
	margin-left: 5px;

	cursor: pointer;
}

.fareGroup_white-3HHUz .info-1lOnT {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.fareGroup_white-3HHUz .info-1lOnT svg {
		color: var(--gray80);
	}
}

.keyFeatures-32OVK {
	margin: 12px 0 16px;
	min-height: 60px;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	grid-gap: 4px;

	gap: 4px;
}

.fareGroup_white-3HHUz .keyFeatures-32OVK {
	margin-bottom: 0;
	margin-top: 7px;
}

.keyFeature-7x_WV {
	position: relative;

	min-height: 68px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 2px;

	cursor: help;

	background-color: rgba(255, 255, 255, 0.3);
}

.fareGroup_white-3HHUz .keyFeature-7x_WV {
	background-color: var(--base);
}

.fareGroup_white-3HHUz.fareGroup_Business-2taXD .keyFeature-7x_WV {
	background: #61579f;
}

.fareGroup-YqfNr .chevron {
	position: relative;
	top: 8px;
	left: -4px;

	color: var(--base);

	transform: scale(0.9);
}

.keyFeature_unavailable-qu2Ob {
	opacity: 0.6;
}

.fareGroup_white-3HHUz .keyFeature_unavailable-qu2Ob {
	background: var(--gray30);
	opacity: 1;
}

.keyFeature_paid-1_JPE {
	opacity: 1;
}

.keyFeature__icon-A4CL0 {
	margin-bottom: 16px;

	color: var(--white);

	transform: scale(0.9);
}

.keyFeature__icon_external-EzYGK {
	max-width: 33px;
	max-height: 33px;
	object-fit: contain;
}

.keyFeature__value-ydnHd {
	position: absolute;
	bottom: 0;

	display: flex;
	align-items: center;
	margin-bottom: 5px;

	color: #ffffff;
	font-weight: 600;
	font-size: 11px;
	line-height: 16px;
}

.keyFeature__value-ydnHd svg {
	transform: scale(0.9);
}

.fareGroup_white-3HHUz > svg {
	color: var(--white);
}

.fareGroup_white-3HHUz .keyFeature_paid-1_JPE {
	color: var(--gray30);

	background: #f0f0f0;
}

.fareGroup_white-3HHUz .keyFeature__icon-A4CL0 {
	color: var(--white);
}

.fareGroup_white-3HHUz .keyFeature__value-ydnHd {
	color: var(--white);
}

.fareGroup_white-3HHUz .keyFeature_paid-1_JPE .keyFeature__icon-A4CL0,
.fareGroup_white-3HHUz .keyFeature_paid-1_JPE .keyFeature__value-ydnHd {
	color: var(--gray30);
}

.options-frmJk {
	margin: 0 -20px;
	padding: 16px 20px 0;

	border-top: 1px dashed rgba(255, 255, 255, 0.5);
}

.fareGroup_white-3HHUz .options-frmJk {
	padding-top: 0;

	border-top: none;
}

.option-33rad {
	display: flex;
	align-items: center;

	line-height: 20px;

	list-style-type: none;
}

.fareGroup_white-3HHUz .option-33rad {
	margin: 0;
	padding: 13px 0;

	color: var(--gray70);
}

.option-33rad:not(:first-of-type) {
	margin-top: 3px;
}

.fareGroup_white-3HHUz .option-33rad:not(:first-of-type) {
	margin: 0;
}

.fareGroup_white-3HHUz .option-33rad:not(:last-of-type) {
	border-bottom: 1px solid #e4e4e4;
}

.miles_notification-3FF2Z {
	margin-top: 12px;
}

div.miles_notification-3FF2Z svg {
	color: var(--gray50);
}

.option_free-2deHu {
	opacity: 1;
}

.option__title-2A76D {
	color: var(--results-faregroup-option-color);
	font: var(--small-desktop-Caps);
}

.fareGroup_white-3HHUz .option__title-2A76D {
	font: var(--normal-desktop);
	color: var(--base);
}

.option__value-LMs4T {
	display: flex;
	align-items: center;
	margin-left: auto;

	color: var(--results-faregroup-option-color);
	font: var(--small-desktop-accent);
	text-align: right;

	cursor: help;
}

.fareGroup_white-3HHUz .option__icon-btY_S,
.fareGroup_white-3HHUz .option__value-LMs4T {
	color: var(--base);
}

.fareGroup_white-3HHUz .option_unavailable-2kgsi .option__title-2A76D,
.fareGroup_white-3HHUz .option_unavailable-2kgsi .option__icon-btY_S,
.fareGroup_white-3HHUz .option_unavailable-2kgsi .option__value-LMs4T {
	color: var(--gray50);
}

.option__tooltip-I6wdE {
	position: relative;
	top: -5px;
}

.upgradeOffer-rv9L8 {
	padding: 8px 12px;

	border-radius: 2px;

	color: var(--gray70);
	font: var(--normal-desktop);

	cursor: pointer;

	background: var(--success90);
}

.upgradeOffer__link-3zGXR {
	display: block;
	margin-top: 5px;

	color: var(--base);
	font: var(--normal-desktop-bold);
	line-height: 14px;
	text-decoration: none;
}

div.dialog__header-2WmzO {
	height: 55px;
	padding-left: 0;

	font-size: 20px;
	font-weight: 500;
	line-height: 150%;
}

.dialog__header-2WmzO > div > div {
	display: flex !important;
	align-items: center;
}

div.dialog__content-1r_IA {
	margin-top: 55px;
}

.taxes-2y7f4 {
	display: flex;
	justify-content: flex-end;
	margin-top: 12px;

	color: var(--gray50);
	font: var(--normal-desktop);
}

/* stylelint-disable block-no-empty */
button.button-UFve4 {
	margin-top: auto;
}

.upgradeOptionWrapper-NbgN3 {
}

@media only screen and (max-width: 360px) {
	.fareGroup-YqfNr {
		width: 278px;
	}

	.fareGroup__keyFeature-1-1L7 {
		width: 80px;
	}
}
