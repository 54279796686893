

.transfer-2PmEV {
	display: flex;
	flex-direction: column;
	padding: 21px 27px;

	border-radius: 2px;

	background-color: #ededed;
}

.transfer__layover-rrTAu {
	color: var(--gray80);
}

.warning-3Shlh {
	display: flex;
	align-items: center;
	margin-top: 4px;

	color: var(--error-icon);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.warning__icon-3Ig1A {
	width: 16px;
	height: 16px;
	margin: 0 4px;
}

.warning__icon-3Ig1A > svg {
	width: 100%;
	height: 100%;
}

.warning-3Shlh:only-child {
	margin-left: 0;
}

.warning_red-3-EVN {
	color: var(--error-20);
}
