

.container-2FgxC {
	width: 100%;
}

div.tabs-Y5wUT {
	margin-top: 0;
	padding-top: 16px;

	background: var(--common-blue);
}

div.tabLabel-iMnm9 {
	color: #ffffff;
	text-transform: unset;
}

button.tabSelected-1KiXr div.tabLabel-iMnm9 {
	color: #ffffff;
}

div.tabs-Y5wUT span.indicator-1_wxW {
	display: block;

	background: #ffffff;
}

div.tabs__container-3I-kQ {
	max-width: 1100px;
	margin: 0 auto;
}
