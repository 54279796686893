.wrapper-28De- {
	padding: 40px 28px 28px;
}

.header-3hjez {
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;

	color: var(--dark-gray);
}

.desc-12nwm {
	font-size: 15px;
	line-height: 28px;
	color: var(--common-gray);
}

@media screen and (max-width: 600px) {
	.header-3hjez {
		font-size: 18px;
	}

	.desc-12nwm {
		margin-top: 16px;

		font-size: 14px;
	}
}

.buttons-RTIA0 {
	display: flex;
	justify-content: flex-end;
	margin-top: 28px;
}

@media screen and (max-width: 600px) {
	.wrapper-28De- {
		padding: 20px;
	}

	.buttons-RTIA0 button {
		font-size: 13px;
	}
}
