.wrapper-BeFqJ {
	display: flex;
	flex-direction: column;
	margin-top: 28px;
	padding-top: 28px;

	border-top: 1px dashed var(--line-dot-line);
}

.wrapper__auth-3w5fN {
	display: flex;
	flex-direction: column;
}

.wrapper__auth_offset-2gkqv {
	margin-bottom: 30px;

	padding-bottom: 30px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.wrapper__title-3Y8b5 {
	margin-bottom: 28px;

	color: var(--text-base);
	font-weight: 500;
}

.social-3RYqg {
	display: flex;
	align-items: center;
}

.social-3RYqg:not(:nth-last-of-type(2)) {
	margin-bottom: 25px;
}

.social__icon-3Kmej {
	position: relative;

	margin-right: 16px;
}

.social__name-3Y5i4 {
	display: flex;

	color: var(--text-base);
	font-weight: 700;
}

.social__name-3Y5i4 svg {
	margin-left: 8px;
	width: 16px;
	height: 16px;

	color: var(--success-icon);
}

.social__info-1_oWC {
	display: flex;
	flex-direction: column;
}

.social__user-3_mmQ {
	margin-top: 4px;

	color: var(--text-mid);
}

.social__user-3_mmQ span {
	margin-left: 16px;

	color: var(--text-light);
}

.social__delete-2u0Zo {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-left: auto;

	color: var(--text-pale);

	cursor: pointer;
}

.login-2cFvu {
	display: flex;
	flex-direction: column;
}

.login__subtitle-1DYIt {
	margin: 28px 0 16px;

	color: var(--text-light);
}

.login__auth-a8Gwd {
	display: flex;
	align-items: center;
}

.login__auth_icon-CkWN6 {
	width: 44px;
	height: 44px;

	cursor: pointer;
}

.login__auth_icon-CkWN6:not(:last-of-type) {
	margin-right: 16px;
}

@media (max-width: 1024px) {
	.wrapper__auth-3w5fN {
		padding: 0 20px 16px;
		grid-gap: 20px;
		gap: 20px;
	}

	.wrapper__auth-3w5fN ~ .login-2cFvu {
		margin-top: 20px;
	}

	.wrapper-BeFqJ {
		margin-top: 20px;
	}

	.wrapper__title-3Y8b5 {
		margin-bottom: 8px;
	}

	.login__title-3aIbs,
	.login__subtitle-1DYIt {
		color: var(--text-light);
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
	}
}
