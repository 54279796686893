.container-3uX9C {
	position: relative;

	margin-top: 16px;

	border-bottom: 1px solid #d9d9d9;
}

.container-3uX9C.container_rounded-1BthT {
	border-bottom: none;
}

.tabs__container_center-3KPjb {
	justify-content: center;
}

.tabs__container_right-2zn3w {
	justify-content: right;
}

span.tab__indicator-34OjJ {
	height: 3px;

	background-color: var(--selected-tab-color);
}

.tab-3MEYq span.tab__indicator-34OjJ {
	display: none;
}

button.tab-3MEYq {
	min-width: auto;
	margin: 0 16px;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;

	font-family: inherit;
	font-size: 13px;
	font-weight: 500;
	text-transform: none;
	color: #9a9a9a;
}

button.tab-3MEYq.tab_rounded-3zl-5 {
	height: 44px;
	padding: 0 16px;

	border-radius: 100px;

	color: var(--gray70);
	font-size: 17px;

	font-family: 'Roboto', sans-serif;
	line-height: 44px;
	letter-spacing: normal;

	background: var(--gray05);
}

button.tab_selected-3jvuX {
	color: var(--selected-tab-color);
}

button.tab_rounded-3zl-5.tab_selected-3jvuX {
	color: #ffffff;

	background: var(--base);
}

button.tab_disabled-2Irkp[disabled] {
	margin-bottom: -22px;
}

.tab__content-IuK80 {
	max-width: 100%;
	overflow: hidden;
}

.route-LVFCt {
	max-width: 100%;
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 15px;
	font-weight: var(--tabs-route-font-weight);
	text-transform: uppercase;
	line-height: 21px;
	color: var(--common-gray);
}

.tab_rounded-3zl-5 .route-LVFCt {
	color: var(--gray70);
	font-weight: 500;
	font-size: 17px;
	line-height: 44px;
	text-transform: none;
}

.tab_selected-3jvuX .route-LVFCt {
	color: var(--selected-tab-color);
}

.tab_rounded-3zl-5.tab_selected-3jvuX .route-LVFCt {
	color: #ffffff;
}

.desc-2bR4R {
	margin-bottom: 12px;

	font-size: 14px;
}

span.seats__count-3fHNV:not(:last-child):after {
	content: ',';
}

@media (max-width: 1024px) {
	.scroller-2czgZ::-webkit-scrollbar {
		display: none;
		width: 0;
	}
}
