.container-3nZ2s {
	padding: 24px 48px;

	background: var(--common-blue);
}

.inner-1DWCP {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
}

button.submit-1Q0jl {
	background: var(--start-search-button);
}

button.submit-1Q0jl:hover {
	background: var(--start-search-button-hover);
}

@media screen and (max-width: 600px) {
	.inner-1DWCP {
		flex-direction: column;
	}

	.container-3nZ2s {
		padding: 16px 20px;
	}
}
