

div.root-1T2ee {
	position: relative;
}

label.textField__root-2GGvB {
	z-index: 2;

	width: 100%;

	color: var(--text-light);
	font: var(--medium--1-desktop);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	transform: translate(10px, 20px) scale(0.9);

	pointer-events: none;
}

label.label_shrink-2yW-N {
	font: var(--small-desktop);

	transform: translate(0, -1.5px) scale(0.75);
}

label.textField__root-2GGvB.textField-2IPjB.textField_outlined-1UC4_:not(.label_error-2-ZL8) {
	color: var(--cabinet-action);
}

div.input-2sko3.disabled-ivmbk:before,
div.real_input-YdtJm.disabled-ivmbk:before {
	background: var(--input-background-disabled);
}

div.real_input-YdtJm.disabled-ivmbk {
	color: var(--gray90);
}

div.input-2sko3 {
	z-index: 1;

	position: relative;

	font-family: inherit;
}

div.input-2sko3:before {
	content: '';

	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	display: block;
	height: 56px;

	background: var(--input-background);
}

div.input-2sko3.input_filled-1QV-1:before {
	border-radius: var(--input-border-radius);
}

input.real_input-YdtJm,
div.real_input-YdtJm {
	z-index: 1;

	box-sizing: border-box;
	height: 56px;
	padding: 12px 9px 9px;

	border: 1px solid transparent;

	font-size: 16px;
	line-height: 20px;
	color: var(--gray90);
}

.loader-2Qvg8 {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;

	margin: auto;
}

p.hint-2b0fP {
	margin: 4px 8px 0;

	font: var(--small-desktop-mini);
}

p.hint_error-3UX5m {
	position: absolute;
	bottom: 0;

	margin-top: 0;
	width: 100%;

	font-family: inherit;
	white-space: nowrap;
	text-overflow: ellipsis;
	letter-spacing: normal;
	line-height: inherit;

	transform: translateY(140%);
	overflow: hidden;
}

p.hint_error-3UX5m.Mui-error {
	color: var(--error-icon);
}

.input_focused-QrI4b.input-2sko3:before {
	background: transparent;
}

.input_focused-QrI4b:not(.input_outline-rpXwN) input.real_input-YdtJm,
.input_focused-QrI4b:not(.input_outline-rpXwN) div.real_input-YdtJm {
	background: var(--input-background-active);
}

div.textField_outlined-1UC4_ {
	border-radius: var(--input-border-radius);
}

div.input-2sko3.input_filled-1QV-1 {
	border-radius: var(--input-border-radius);

	background: var(--input-background);
}

div.input-2sko3.input_filled-1QV-1 .real_input-YdtJm,
div.input-2sko3.input_standard-1t33j .real_input-YdtJm {
	padding: 26px 12px 9px;
}

fieldset.input_outline-rpXwN,
div.input-2sko3.input_filled-1QV-1.disabled-ivmbk,
div.input-2sko3.input_filled-1QV-1 .real_input-YdtJm,
div.input-2sko3.disabled-ivmbk .input_outline-rpXwN {
	border: 1px solid var(--input-outline);
	border-radius: var(--input-border-radius);
}

div.input-2sko3.input_filled-1QV-1.disabled-ivmbk .real_input-YdtJm {
	border: none;
}

div.input-2sko3.input_focused-QrI4b .input_outline-rpXwN,
div.input-2sko3.input_focused-QrI4b.input_filled-1QV-1 .real_input-YdtJm {
	border: 1px solid var(--input-outline-active);
	border-radius: var(--input-border-radius);
}

label.textField_filled-IO-E6.textField_focused-BWum7:not(.label_error-2-ZL8),
label.textField_filled-IO-E6.textField_focused-BWum7.label_error-2-ZL8,
label.textField_standard-1xNCl.textField_focused-BWum7 {
	color: var(--input-color);
}

div.input-2sko3.input_error-1PeYc.input_filled-1QV-1 .real_input-YdtJm,
div.input-2sko3.input_error-1PeYc.input_filled-1QV-1.input_focused-QrI4b .real_input-YdtJm {
	border-color: var(--input-error-border);

	background: var(--input-error-background-filled);
}

div.input-2sko3.input_outline-rpXwN.input_error-1PeYc fieldset.input_outline-rpXwN {
	border-color: var(--input-error-border);
}

div.underline-1JdOI:before {
	border-color: var(--input-outline);
}

div.underline-1JdOI:after {
	border-color: var(--input-outline-active);
}

div.input-2sko3.underline-1JdOI {
	margin-top: 0;
}

label.textField__root-2GGvB.textField_focused-BWum7.textField_standard-1xNCl,
label.textField_standard-1xNCl.label_shrink-2yW-N {
	z-index: 2;

	transform: translate(12px, 10px) scale(0.75);
}

div.input-2sko3.input_standard-1t33j input.real_input-YdtJm,
div.input-2sko3.input_focused-QrI4b.input_standard-1t33j input.real_input-YdtJm {
	border-radius: var(--mobile-input-border-radius);

	appearance: none;
}

.valueFilled-2BVqQ {
	font-family: inherit;
}

.nationality-3AYzN label.MuiInputLabel-animated {
	transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 100ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 100ms;
}

.nationality-3AYzN .optionLabel-1CmtC {
	font-size: 16px;
}
