

.root-3-Ozj {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-width: 0;
	min-height: 26px;
}

.wrapper-2nbxN {
	display: flex;
	align-items: center;
}

.minus-2J4t6,
.plus-1ZgMI {
	min-width: 22px;
	max-width: 22px;
	min-height: 22px;
	max-height: 22px;

	cursor: pointer;

	transition: opacity 0.1s linear;
}

.minus-2J4t6 svg,
.plus-1ZgMI svg {
	display: block;
	width: 22px;
	height: 22px;
}

.minus-2J4t6:hover,
.plus-1ZgMI:hover {
	opacity: 0.8;
}

.value-xGOXt {
	position: static;

	margin: 0 13px;

	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	color: var(--gray80);

	transform: none;
}

.hidden-2FmIv {
	display: none;
}

.disabled-3ghV9.root-3-Ozj {
	opacity: 0.6;
}

.disabled-3ghV9 .minus-2J4t6,
.disabled-3ghV9 .plus-1ZgMI {
	cursor: pointer;

	pointer-events: none;
}
