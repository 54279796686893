.container-1JGYF {
	display: flex;
	flex-direction: column;
}

.passenger-rtrPT {
	position: relative;

	display: flex;
	align-items: flex-end;
	grid-gap: 16px;
	gap: 16px;

	padding: 24px 20px 20px 64px;

	border-bottom: 1px dashed var(--line-dot-line);

	background: var(--white);
}

.passengerContent-19XjL {
	flex: 1;
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.icon-1kolc {
	position: absolute;
	top: 20px;
	left: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-1-0);
}

.icon-1kolc svg {
	width: 58%;
	height: 58%;
}

.passengerName-9Q92N {
	color: var(--text-base);
	font-weight: 700;

	font-size: 22px;
	line-height: 1.3;
	text-transform: capitalize;
}

.passengerInfo-1TmVo {
	flex: 1;
	display: flex;
	flex-direction: column;
	grid-gap: 6px;
	gap: 6px;
}

.passengerInfo__item-3VYF0 {
	color: var(--text-base);
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
}

.passengerInfo__item-3VYF0:last-child {
	margin-bottom: 0;
}

button.button-14Uay {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 500;
}

.services-3AVqy {
	padding: 12px;

	background: var(--white);
}

@media screen and (max-width: 600px) {
	.passenger-rtrPT {
		flex-direction: column;
	}
}
