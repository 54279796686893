.modal-2ijdW {
	min-width: 620px;
}

.container-X4L5f {
	padding: 28px 32px;
}

.header-2q_2C {
	margin-bottom: 20px;

	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.table-2BXZ0 {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;

	font-size: 16px;
	color: var(--text-base);
}

.tableHeader-3-Q0m {
	font-weight: 500;

	background: var(--brand-1-5);
}

.tableHeader-3-Q0m,
.tableRow-TdhRv {
	display: grid;

	grid-template-columns: 36% 22% 20% 20%;
	grid-gap: 4px;
	gap: 4px;
	align-items: center;
	padding: 16px 12px;

	border-bottom: dashed 1px var(--gray-line);

	list-style: none;
}

.tableHeader-3-Q0m > span {
	display: flex;
}

.tableRow-TdhRv.error-1cWih {
	color: var(--error-icon);

	background: var(--errorBG);
}

.promoCodeIcon-22q7U {
	display: flex;
	align-items: center;

	color: var(--text-pale);
}

.promoCodeIcon-22q7U:hover {
	cursor: pointer;
}

.passengerColumnName-HvSOP {
	justify-self: start;
}

.promoCodeColumnName-Mtbou {
	display: flex;
	align-items: center;
	justify-self: start;

	line-height: 1;
}

.priceColumnName-d1gci {
	justify-self: flex-end;
}

.passengerColumn-2E7GV,
.promoCodeColumn-2u1vQ {
	display: flex;
	justify-self: start;

	flex-wrap: wrap;
}

.priceColumn-2ie7d {
	justify-self: flex-end;
}

.errorText-3BriM {
	margin: 0 0.3em;
}

.total-2LX7w {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 28px;
}

.totalPriceContainer-3Dxvh {
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-template-rows: repeat(3, auto);
}

.totalPrice-1vr6B {
	grid-area: 1 / 1 / 2 / 2;
	padding-right: 12px;

	border-right: 1px solid var(--gray-line);

	color: var(--error-icon);
	font-weight: 700;
	font-size: 21px;
	text-decoration: line-through;
}

.totalPriceWithDiscountLabel-YDdSy {
	grid-area: 1 / 2 / 2 / 3;
	margin-left: 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.totalPriceWithDiscount-xJMOu {
	grid-area: 1 / 3 / 2 / 4;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.flightPriceLabel-1lytk {
	grid-area: 2 / 2 / 3 / 3;
	margin-left: 12px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	text-transform: lowercase;
	justify-self: start;
}

.flightPrice-38wml {
	grid-area: 2 / 3 / 3 / 4;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	justify-self: flex-end;
}

.addServicesPriceLabel-EnDLr {
	grid-area: 3 / 2 / 4 / 3;
	margin-left: 12px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	text-transform: lowercase;
	justify-self: start;
}

.addServicesPrice-2mffj {
	grid-area: 3 / 3 / 4 / 4;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 17px;
	justify-self: flex-end;
}

.container-X4L5f div.notification-3T_wS {
	margin: 12px 0;

	border: 1px solid var(--error-stroke-hover);
	border-radius: var(--warning-border-radius);

	background: var(--error-bg);
}

div.notification-3T_wS .notificationTitle-3tbHm {
	font-size: 16px;
	font-weight: 700;

	color: var(--error-text);
}

.buttons-3tVI1 {
	justify-self: right;

	align-self: flex-end;
	margin-left: auto;
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	flex-wrap: wrap;
}

.buttons-3tVI1 button {
	padding: 10px 24px;
}

.buttons-3tVI1 button.submit-3FJMy {
	justify-self: right;

	margin-left: auto;
}

.buttons-3tVI1 button.cancel-12aoo {
	color: var(--text-light);

	background: var(--bg-fill);
}

.buttons-3tVI1 button.cancel-12aoo:hover,
.buttons-3tVI1 button.cancel-12aoo:focus {
	color: var(--text-light);

	background: var(--bg-elements);
}

@media screen and (max-width: 600px) {
	.modal-2ijdW {
		min-width: auto;
		width: 100%;
	}

	.modal-2ijdW .close-3T7IX {
		top: 12px;
		right: 12px;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.promoCodeIcon-22q7U svg {
		/* pointer-events: none; */
	}

	.modal-2ijdW .close-3T7IX svg {
		height: 20px;
		width: 20px;
	}

	.container-X4L5f {
		padding: 0;
	}

	div.header-2q_2C {
		margin: 0;
		padding: 12px;

		font-size: 18px;
	}

	.table-2BXZ0 {
		margin: 0 20px;
	}

	.tableRow-TdhRv {
		display: flex;
		flex-direction: column;
		grid-gap: 8px;
		gap: 8px;
		align-items: stretch;
		padding: 16px 0;

		line-height: 1.3;

		background: unset;
	}

	.tableRow-TdhRv.error-1cWih {
		background: unset;
	}

	.passengerColumn-2E7GV {
		font-size: 16px;
		font-weight: 700;
	}

	.promoCodeColumn-2u1vQ,
	.errorText-3BriM,
	.priceColumn-2ie7d {
		display: flex;

		font-size: 16px;
	}

	.errorText-3BriM,
	.priceColumn-2ie7d {
		justify-content: space-between;
	}

	.promoCodeColumn-2u1vQ {
		justify-content: start;
	}

	.promoCodeValue-2UsJm {
		margin-left: auto;
	}

	.total-2LX7w {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: stretch;
		margin: 16px 20px;
		grid-gap: 8px;
		gap: 8px;
	}

	.totalPrice-1vr6B {
		grid-area: unset;

		padding-right: 0;

		border-right: unset;

		font-size: 20px;
	}

	.totalPriceWithDiscountWrapper-N_DRR {
		display: flex;
		justify-content: flex-end;
		align-self: stretch;
		padding-bottom: 16px;

		border-bottom: 1px dashed var(--gray-line);

		color: var(--text-base);
		font-weight: 700;
		font-size: 22px;
	}

	.flightPriceWrapper-2Dx6Y,
	.addServicesPriceWrapper-38Qg6 {
		display: flex;
	}

	.flightPrice-38wml,
	.flightPriceLabel-1lytk,
	.addServicesPrice-2mffj,
	.addServicesPriceLabel-EnDLr {
		font-size: 18px;
		font-weight: 400;
		color: var(--text-light);
	}

	.toolbar-39-7j {
		padding: 12px;

		background: var(--white);
	}

	button.toolbarContinueButton-18LB_ {
		padding: 10px 24px;

		font-weight: 700;
		font-size: 14px;
	}
}
