.months__wrapper-3cySC {
	position: relative;

	border: none;
	border-radius: 4px;

	box-shadow: 0 6px 30px rgba(0, 0, 0, 0.05);
}

.months__wrapper-3cySC nav {
	position: absolute;
	top: 50%;
	left: 50%;

	width: calc(100% + 30px);

	transform: translateX(-50%);
}

.months__prev-2UbCR {
	left: 0;
}

.months__next-3b1ET {
	right: 0;
}

.months__prev-2UbCR,
.months__next-3b1ET {
	top: 50%;

	width: 30px;
	height: 30px;

	border-radius: 50%;

	color: transparent;

	box-shadow: 0 2px 14px rgba(0, 0, 0, 0.08);
	background: var(--white);
}

.months__prev-2UbCR:before,
.months__next-3b1ET:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 70%;
	height: 70%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxTDEzLjE3IDEyTDguNTkgMTYuNTlMMTAgMThMMTYgMTJMMTAgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;

	transform: translate(-50%, -50%);
}

.months__prev-2UbCR:before {
	transform: translate(-50%, -50%) rotate(180deg);
}

.month__header-1OWDr {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
}

.month__week-Z6xNS:not(:last-child) {
	margin-bottom: 4px;
}

.header__day-eipv_ {
	line-height: 40px;
}

.header__day-eipv_ span {
	border-bottom: none;

	color: var(--text-light);
	font-weight: 400;
}

.header__day-eipv_,
.day__day-1ZIW9 {
	width: 40px;
	height: 40px;

	font-size: 14px;
}

.day__day-1ZIW9 {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text-pale);
	line-height: 1.2;
}

.highlightDate-2L2Bj {
	color: var(--text-base);
}

/* stylelint-disable */
.hoverDate-2lUo0 {
}

.day__inside-1lACC {
}

/* stylelint-enable */
.day__today-3Zrkr {
	background: var(--brand-1-5);
}

.day__today-3Zrkr:not(.day__selected-2jPtc):not(.day__inPeriod-1s3bs) {
	border-radius: 30px;
}

.day__day-1ZIW9.day__selected-2jPtc {
	background: var(--brand-1-0);
}

.day__inPeriod-1s3bs {
	color: var(--white);

	background: var(--brand-1-0);
}

.day__weekend-3lLHT {
	color: var(--text-pale);
}

.day__weekend-3lLHT.day__selected-2jPtc {
	background: var(--brand-1-0);
}

.day__weekend-3lLHT.highlightDate-2L2Bj {
	color: var(--text-base);
}

.day__weekend-3lLHT.day__inPeriod-1s3bs {
	color: var(--white);
}

.day__start-1etrj,
.day__end-25r9v {
	color: var(--white);

	background: var(--brand-1-0);
}

.day__start-1etrj {
	border-radius: 30px 0 0 30px;
}

.day__end-25r9v {
	border-radius: 0 30px 30px 0;
}

@media screen and (max-width: 600px) {
	.months__wrapper-3cySC {
		box-shadow: none;
	}

	.months__wrapper-3cySC nav {
		position: relative;
		top: 0;
		left: 0;

		width: 100%;

		transform: none;
	}

	.months__list-1q_zM {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 20px;
		gap: 20px;
	}

	.month-3L267 {
		padding-right: 0;
	}
}
