.promoCode-eqqdy {
	color: var(--gray);
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
}

.promoCode-eqqdy a.trigger-3_NQK {
	color: inherit;
	text-decoration: none;
}

.content-1a9GW,
.contentWrp-26_Z- {
	display: flex;
	align-items: baseline;
}

.promoCode_empty-2dycw a.trigger-3_NQK,
.promoCode_notApplied-jad-X a.trigger-3_NQK {
	opacity: 0.8;
}

.promoCode-eqqdy .icon-1Iv5o {
	position: relative;
}

.content-1a9GW {
	position: relative;
}

.promoCode_applied-1zUoK .icon-1Iv5o {
	position: absolute;
	left: -30px;
	top: 50%;

	margin-right: 10px;

	color: var(--selected2);

	transform: scale(0.9) translate(0, -50%);
}

.clearWrapper-2ehZf {
	margin-right: 20px;
	margin-left: 12px;

	opacity: 0.8;
}

div.modal__root-2FLB7 {
	z-index: 1600 !important;
}

div.modal-O4g9o {
	border-radius: 16px;
}

div.modal-O4g9o > span {
	transform: scale(0.7);
}

.closeIcon-1t_0R {
	width: 32px;
	height: 32px;
}

a.clear-1Xj5q {
	position: relative;

	display: inline-flex;
	align-items: center;

	color: #ffffff;
	font-weight: 500;
	font-size: 14px;
	text-decoration: none;
}

.icon_notApplied-1OIeZ {
	position: relative;
	top: 7px;

	color: var(--active);
}

.icon_notApplied-1OIeZ svg {
	transform: scale(0.7);
}

.clearIcon-1GlpF {
	position: absolute;
	left: 100%;
	top: 50%;

	transform: scale(0.8) translate(0, -53%);
}

button.closeButton-1mB9H {
	position: absolute;
	top: 16px;
	right: 16px;

	padding: 6px;

	color: var(--text-mid);

	background: var(--bg-fill);
}

button.closeButton-1mB9H:hover {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	div.scrollBody-3V4xW {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.scrollBody-3V4xW div.modal-O4g9o {
		min-height: auto;

		border-radius: 16px 16px 0 0;
	}
}

@media screen and (max-width: 600px) {
	div.modal__scrollBody-2pkPH {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.modal__root-2FLB7 div.modal-O4g9o {
		min-height: auto;
	}

	button.closeButton-1mB9H {
		top: 14px;
		right: 20px;
	}
}
