.container-1l-ZN {
	display: flex;
	flex: 1;
	padding: 8px;
}

.day-3_za2 {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;

	border-radius: 8px;

	color: var(--text-pale);
	font-size: 13px;
	line-height: 1;
	text-align: center;

	background: var(--bg-fill);

	pointer-events: none;
}

.day-3_za2:not(:last-child) {
	margin-right: 4px;
}

.active-3znuK {
	color: var(--text-link);

	cursor: pointer;

	background: var(--brand-1-4);

	pointer-events: auto;
}

.weekday-2wzTF {
	margin-bottom: 4px;

	font-weight: 700;
	text-transform: capitalize;
}

.date-N2oNm {
	font-weight: 400;
}

@media screen and (max-width: 600px) {
	.container-1l-ZN {
		padding: 12px;
	}

	.day-3_za2 {
		min-height: 48px;
		padding: 10px;
	}

	.weekday-2wzTF {
		margin: 0;
	}

	.date-N2oNm {
		display: none;
	}
}
