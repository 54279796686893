

.tag-1F1K8 {
	display: flex;
	align-items: center;

	color: var(--light-violet);
	font-weight: 400;
	font-size: 13px;
}

.percent-2E4g7 {
	display: flex;
	align-items: center;
	margin-right: 8px;
	padding: 4px 8px;

	border-radius: 4px;

	color: var(--bg-fill);
	font-size: 14px;

	background-color: var(--light-violet);
}

.percent-2E4g7 svg {
	margin-right: 10px;
	width: 18px;
	height: 18px;
}
