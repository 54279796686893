.title-B3G9T {
	color: var(--text-base);
}

.groups_itemIcon-3nfcW {
	color: var(--text-pale);
}

.groups_itemTitle-z_IXi,
.groups_itemDescription-3NjrV {
	color: var(--text-light);
}

button.close-1vwu_ {
	background: var(--bg-fill);
}

@media screen and (max-width: 600px) {
	div.paper-3-0Fd.paper-3-0Fd {
		border-radius: 0;
	}
}
