.segment-1oAMS:not(:last-of-type) {
	margin-bottom: 16px;
}

@media (max-width: 768px) {
	.segment-1oAMS {
		max-width: 420px;
		margin: 0 auto;
	}
}

.segment__info-34Mnh {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 768px) {
	.segment__info-34Mnh {
		flex-direction: column;
	}
}

.segment__location-10Rnu {
	max-width: 280px;
}

@media (max-width: 768px) {
	.segment__location-10Rnu {
		max-width: 100%;
	}
}

.segment__flight-13Mih {
	flex-grow: 1;
	margin: 0 12px;
}

@media (max-width: 768px) {
	.segment__flight-13Mih {
		margin: 13px 0;
	}
}

.segment__transfer-27Iuz {
	margin-top: 16px;

	border-radius: 4px;

	font-weight: 500;
	font-size: 16px;

	background-color: #ffffff;
}
