.wrapper-1jjhc {
	margin: 12px auto 0;
	max-width: 1100px;
	display: flex;
}

.wrapper-1jjhc > div:not(:last-of-type) {
	margin-right: 13px;
}

.footer-1l_Ea {
	padding: 20px 24px;
}

button.footer__button-gUMgA {
	padding: 0 25px;
}

@media screen and (max-width: 600px) {
	.wrapper-1jjhc {
		margin: 0;
		flex-direction: column;

		background: #ffffff;
	}

	.wrapper-1jjhc > div:not(:last-of-type) {
		margin-right: 0;
	}
}
