@media screen and (max-width: 600px) {
	.main-tXdOe {
		color: var(--text-base);
	}

	.main__icon--aFJd {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 26px;
		height: 26px;

		margin-right: 6px;

		color: var(--brand-1-0);

		transform: translateY(-1px);
	}

	.price__wrapper-3a_ue,
	.money-1BeX3 {
		font-size: 20px;
		font-weight: 500;
		line-height: 1;
	}

	.content-3sJWO {
		background: var(--white);
	}

	.control-2UrZJ {
		width: 38px;
		height: 38px;

		margin-right: 6px;

		font-size: 16px;
	}
}
