

.head-1WTRM {
	background: linear-gradient(180deg, #ff9255 0%, #6c75c4 100%);
}

div.header-2Y0S6 {
	padding: 24px 34px 0 34px;
}

.head-1WTRM svg {
	position: relative;
	right: -20px;

	width: 150px;
	height: 113px;

	opacity: 0.8;
}

.description-RrVay {
	white-space: pre;
}
