

.flight-1gcv- {
	width: 100%;
	max-width: 1100px;
	margin: 0 auto 12px;

	border-radius: 4px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;

	transition: box-shadow 0.15s;
	overflow: hidden;
}

.flight-1gcv-:hover,
.flight_open-1oiFm {
	box-shadow: 0 2px 13px rgba(0, 0, 0, 0.12);
}

.flight__content-QZRZc {
	border-top: 1px solid #d9d9d9;
}

.fareGroups-3qv_j {
	display: flex;
	overflow-x: auto;

	padding: 28px 40px;
}
