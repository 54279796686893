

.wrapper-2zeky {
	color: var(--text-base);

	background: var(--white);
}

.wrapper_business-2k28s {
	background: linear-gradient(180deg, #6a86e6 0%, #3b56b2 100%);
}

.row-185Gj {
	display: flex;
	align-items: center;
	margin: 0 20px;
}

.header-16tKu {
	padding: 20px 0 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
	text-transform: uppercase;
}

.header__logo-3wbgS {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	margin-right: 8px;
}

.header__logo-3wbgS img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.info-1UQvN {
	justify-content: space-between;
	padding-bottom: 8px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.info__col-2lZum {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.info__col-2lZum:last-child {
	align-items: flex-end;

	text-align: right;
}

.info__iata-1XI_u {
	color: var(--text-base);
	font-size: 44px;
	font-weight: 700;
	line-height: 1.3;
}

.info__cities-2QFgc {
	display: flex;
	flex-direction: column;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.info__time-38xY6 {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.disabled-f_lbw .info__iata-1XI_u,
.disabled-f_lbw .info__time-38xY6 {
	color: var(--text-pale);
}

.info__plane-2KpJ7 {
	width: 44px;
	min-width: 44px;
	height: 44px;

	color: var(--text-pale);
}

.info__plane-2KpJ7 svg {
	width: 100%;
	height: 100%;
}

.arrivalInfo-sDeW8 {
	padding: 8px 0 12px;
}

.disabled-f_lbw .arrivalInfo-sDeW8 {
	display: none;
}

.arrivalInfo__item-3wg2o {
	display: flex;
	flex-direction: column;
}

.arrivalInfo__itemLabel-1w4ti {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.arrivalInfo__itemValue-1t39J {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}
