.addMember-3gE57 {
	display: flex;
	flex-direction: column;
	padding: 60px;
}

@media screen and (max-width: 600px) {
	.addMember-3gE57 {
		margin-top: 24px;
		padding: 0;
	}
}

.addMember__header-sr6Rz {
	display: flex;
	padding-bottom: 20px;

	border-bottom: 1px solid var(--line-separator);
}

@media screen and (max-width: 600px) {
	.addMember__header-sr6Rz {
		padding: 0;

		border: none;
	}
}

.addMember__title-2pr6x {
	display: flex;

	font-size: 31px;
	font-weight: 700;
	line-height: 1.3;

	color: var(--light-violet);
}

@media screen and (max-width: 600px) {
	.addMember__title-2pr6x {
		font-size: 22px;

		color: var(--text);
	}
}

.addMember__addSelectedMemberForm-3tW2D {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
	.addMember__addSelectedMemberForm-3tW2D {
		margin-bottom: 10px;
	}
}

.addMember__actions-2a8v8 {
	display: flex;
	justify-content: flex-end;
	grid-gap: 12px;
	gap: 12px;
	margin-top: 24px;
}

@media screen and (max-width: 600px) {
	.addMember__actions-2a8v8 {
		flex-direction: column;
		margin-top: 10px;
	}
}

button.addMember__cancelButton-2bEDA {
	padding: 12px 24px;
	height: 48px;

	border-radius: 6px;

	font-size: 18px;
	font-weight: 400;

	color: var(--text-light);

	background-color: var(--gray-bg);
}

button.addMember__cancelButton-2bEDA:hover {
	background-color: var(--gray10);
}

.addMember__addButton-3P8M8 {
	display: flex;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.addMember__addButton-3P8M8 {
		padding: 20px;

		border-radius: 8px;

		background-color: var(--white);
	}
}

button.addMember__addButtonIcon-2YSRZ {
	width: 30px;
	height: 30px;

	border-radius: 50%;

	color: var(--bright-violet);

	background-color: var(--very-pale-violet);
}

.addMember__addButtonIcon-2YSRZ svg {
	width: 18px;
	height: 18px;
}

.addMember__addButtonText-1CVSV {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;

	color: var(--light-violet);
}

.addMember__newTravelCompanion-ooMW9 {
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 600px) {
	.addMember__newTravelCompanion-ooMW9 {
		margin-bottom: 30px;
		padding: 20px;

		border-radius: 8px;

		background-color: var(--white);
	}
}

.addMember__newTravelCompanionActions-32S7_ {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	margin-top: 25px;
}

@media screen and (max-width: 600px) {
	.addMember__newTravelCompanionActions-32S7_ {
		flex-direction: column-reverse;
		margin-top: 40px;
	}
}

.addMember__newTravelCompanionActions-32S7_ button.addMember__newTravelCompanionSubmit-2wXFj,
.addMember__newTravelCompanionActions-32S7_ button.addMember__newTravelCompanionCancel-3L9-V {
	padding: 10px 24px;
	height: 39px;

	font-size: 14px;
}
