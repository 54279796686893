.header-1ShuM {
	padding: 22px 0 22px 20px;

	background: var(--white);
}

.passenger-2Eode {
	padding: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.seats-3xFUT {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.seats-3xFUT:not(:last-child) {
	margin-right: 20px;
	padding-right: 14px;

	border-right: 1px solid var(--line-separator);
}

.seats__icon-el5yT {
	margin-right: 10px;

	color: var(--brand-1-0);
}

.footerActions-3jqj2 {
	padding: 20px;

	border-top: 1px dashed var(--line-dot-line);
}

.footerActions__cancel-Y5RTU {
	order: -1;

	margin-right: 20px;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 13px;
	line-height: 1.4;
}

.footerActions__cancel-Y5RTU:hover {
	text-decoration: none;
}

.sendToEmail__wrapper-1Ir6p {
	margin: 0;
}

.sendToEmail__text-25C0A {
	color: var(--brand-1-0);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.4;
}

.actions__buttons-1xiek {
	margin-left: 0;
}

.appleWaller-1EcIv {
	height: 34px;
	margin-left: 10px;
}

button.downloadButton-yIPTh {
	min-width: auto;
	height: 34px;

	padding: 0 16px;

	border: 1px solid var(--brand-1-3);
	border-radius: 6px;

	color: var(--brand-1-0);
	font-weight: 700;
	font-size: 14px;

	background: transparent;
}

button.downloadButton-yIPTh:hover {
	border-color: var(--brand-1-4);

	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

.downloadIcon-2VsUm {
	display: none;
}

.actions__barcode-6hEj9 {
	margin: 0;

	color: var(--text-base);
}

.actions__barcode-6hEj9 svg path {
	fill: var(--brand-1-0);
}

.actions__barcode_disabled-2fqBW svg path {
	fill: var(--brand-1-2);
}

.services-3JPo7 {
	padding: 20px;

	border-color: var(--line-separator);
}

@media screen and (max-width: 600px) {
	.wrapper-3OrsF {
		border-radius: 4px;
	}

	.header-1ShuM {
		padding: 22px 20px;

		border-radius: 16px 16px 0 0;
	}

	button.downloadButton-yIPTh {
		flex: unset;
		max-width: unset;
	}

	.actions__buttons-1xiek {
		margin-top: 0;

		border-top: none;
	}

	.footerActions__cancel-Y5RTU {
		margin: 0;
	}
}
