

.wrapper-4MR8R {
	display: flex;
	flex-direction: column;
	height: 100%;

	border-radius: 8px;

	border: 1px solid transparent;

	box-shadow: 4px 10px 38px rgba(0, 0, 0, 0.1);

	transition: border-color 0.3s;
	overflow: hidden;
}

.wrapper-4MR8R.active-BvdxW {
	border: 1px solid var(--active-30);
}

/* stylelint-disable */
.confirmed-3Bj8p {
}
/* stylelint-enable */
.header-1YGKh {
	display: flex;
	align-items: center;
	padding: 12px 16px;
	min-height: 76px;

	color: var(--white);

	background-color: var(--additional-insurance);

	transition: background-color 0.3s;
}

.wrapper-4MR8R.active-BvdxW .header-1YGKh {
	background-color: var(--active-30);
}

.header_rate-uIwq4 {
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
}

.header_description-6l01g {
	margin-top: 4px;

	font-size: 12px;
	line-height: 1;
}

.header_info-3fHN2 {
	display: flex;
	flex-direction: column;
}

.header_price-2zqfp {
	display: flex;
	align-items: center;
	margin-left: auto;

	font-weight: 700;
	font-size: 18px;
}

.header_price-2zqfp > svg {
	margin-left: 8px;
}

.content-3aF0z {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	padding: 20px 16px;
}

.content-3aF0z > ul {
	width: 100%;
	margin-top: 0;
	margin-bottom: 14px;
	padding-left: 0;

	list-style: none;
}

.content-3aF0z > ul > li {
	position: relative;

	margin-bottom: 14px;
	padding-bottom: 14px;
	padding-left: 14px;

	border-bottom: 1px solid var(--gray10);

	color: var(--text-base);

	font-size: 14px;
	line-height: 1.4;
}

.content-3aF0z > ul > li a {
	color: var(--brand-1-0);
}

.content-3aF0z > ul > li:before {
	position: absolute;
	top: 6px;
	left: 0;

	width: 5px;
	height: 5px;

	border-radius: 50%;

	background-color: var(--line-separator);
	content: '';
}

.content-3aF0z > ul > li:last-of-type {
	margin-bottom: 0;

	border-bottom: none;
}

button.button-3HmTz,
button.button_reject-1crF2 {
	margin-top: auto;

	border-radius: 4px;
}

button.button-3HmTz {
	font-weight: 500;
}

button.button_reject-1crF2 {
	font-weight: 400;
}
