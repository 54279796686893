.header-1zUP7 {
	padding: 16px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
}

div.tabHeader-te4ST {
	font-size: 14px;
	text-transform: unset;
}

div.tabs-33G9U span.indicator-22fMv {
	display: inline-block;
}

.downloadContainer-2xhpe {
	display: block;
	padding: 32px 16px;
}

.downloadContainer-2xhpe > div {
	width: 100%;
}

.buttons-1cWRK {
	display: flex;
	justify-content: flex-end;
}

button.button-2BEgy,
button.button-2BEgy:hover {
	background: var(--brand-3-0);
}

div.select-3oNIs {
	margin-bottom: 16px;
}

div.resend_wrapper-2FSrN {
	min-width: 0;
	width: 100%;
}

@media screen and (max-width: 600px) {
	div.slideUpWrapper-3qa2n {
		z-index: 1000;
	}

	div.slideUp-2dfV2 {
		padding: 0;
	}

	div.slideUp__header-1A6P4 {
		padding-left: 16px;
	}

	.sendTicketsContainer-mt1V3,
	.downloadContainer-2xhpe {
		padding: 32px 16px;
	}

	.header-1zUP7 {
		padding: 16px 0;
	}
}
