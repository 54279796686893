.container-3t686 {
	margin-top: 40px;
}

.request__main-3NyqF {
	padding: 18px 16px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background: #ffffff;
}

.header__wrapper-2_i2Y {
	display: flex;
	align-items: center;
}

.header-1EZAg {
	padding-right: 16px;

	border-right: 1px solid var(--gray20);

	color: var(--gray80);
	font-weight: 700;
	font-size: 20px;
}

.created-iQm8D {
	padding-left: 16px;

	color: var(--gray50);
	font-size: 14px;
}

.createdTime-2xmOc {
	display: none;
	margin-left: 4px;
}

.order-56WRx {
	padding: 0 18px 16px;

	border-bottom: 1px solid #f4f4f4;

	background: #ffffff;
}

.status-3iaPk {
	position: relative;

	display: inline-flex;
	align-items: center;
	height: 29px;
	padding: 0 10px 0 4px;

	border-radius: 100px;

	color: var(--gray80);
	font-weight: 500;
	font-size: 14px;

	background: var(--warning);
}

.status-3iaPk:before {
	display: block;
	width: 20px;
	height: 20px;
	margin-right: 4px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05Ljk5MTAyIDIuMTY2OTlDNS4zOTEwMiAyLjE2Njk5IDEuNjY2MDIgNS45MDAzMyAxLjY2NjAyIDEwLjUwMDNDMS42NjYwMiAxNS4xMDAzIDUuMzkxMDIgMTguODMzNyA5Ljk5MTAyIDE4LjgzMzdDMTQuNTk5MyAxOC44MzM3IDE4LjMzMjcgMTUuMTAwMyAxOC4zMzI3IDEwLjUwMDNDMTguMzMyNyA1LjkwMDMzIDE0LjU5OTMgMi4xNjY5OSA5Ljk5MTAyIDIuMTY2OTlaTTkuOTk5MzUgMTcuMTY3QzYuMzE2MDIgMTcuMTY3IDMuMzMyNjggMTQuMTgzNyAzLjMzMjY4IDEwLjUwMDNDMy4zMzI2OCA2LjgxNjk5IDYuMzE2MDIgMy44MzM2NiA5Ljk5OTM1IDMuODMzNjZDMTMuNjgyNyAzLjgzMzY2IDE2LjY2NiA2LjgxNjk5IDE2LjY2NiAxMC41MDAzQzE2LjY2NiAxNC4xODM3IDEzLjY4MjcgMTcuMTY3IDkuOTk5MzUgMTcuMTY3Wk05LjE2NjAyIDYuMzMzNjZIMTAuNDE2VjEwLjcwODdMMTQuMTY2IDEyLjkzMzdMMTMuNTQxIDEzLjk1ODdMOS4xNjYwMiAxMS4zMzM3VjYuMzMzNjZaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

.status_reject-2m9ik,
.status_error-1THUk {
	color: #ffffff;

	background: var(--error);
}

.status_reject-2m9ik:before {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi42NjYgMTAuNTAwM0MxNi42NjYgMTQuMTgyMiAxMy42ODEyIDE3LjE2NyA5Ljk5OTM1IDE3LjE2N0M4LjU0NzI4IDE3LjE2NyA3LjIwMzY1IDE2LjcwMjggNi4xMDg3NiAxNS45MTQ2TDE1LjQxMzYgNi42MDk3NEMxNi4yMDE4IDcuNzA0NjIgMTYuNjY2IDkuMDQ4MjYgMTYuNjY2IDEwLjUwMDNaTTQuODg2OTggMTQuNzc5NEwxNC4yNzg0IDUuMzg3OTVDMTMuMTIwNSA0LjQxNzc3IDExLjYyODEgMy44MzM2NiA5Ljk5OTM1IDMuODMzNjZDNi4zMTc0NSAzLjgzMzY2IDMuMzMyNjggNi44MTg0MyAzLjMzMjY4IDEwLjUwMDNDMy4zMzI2OCAxMi4xMjkxIDMuOTE2OCAxMy42MjE1IDQuODg2OTggMTQuNzc5NFpNMTguMzMyNyAxMC41MDAzQzE4LjMzMjcgMTUuMTAyNyAxNC42MDE3IDE4LjgzMzcgOS45OTkzNSAxOC44MzM3QzUuMzk2OTggMTguODMzNyAxLjY2NjAyIDE1LjEwMjcgMS42NjYwMiAxMC41MDAzQzEuNjY2MDIgNS44OTc5NSA1LjM5Njk4IDIuMTY2OTkgOS45OTkzNSAyLjE2Njk5QzE0LjYwMTcgMi4xNjY5OSAxOC4zMzI3IDUuODk3OTUgMTguMzMyNyAxMC41MDAzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
}

.status_error-1THUk:before {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjAyIDEwLjUwMDNDMS42NjYwMiAxNS4xMDAzIDUuMzk5MzUgMTguODMzNyA5Ljk5OTM1IDE4LjgzMzdDMTQuNTk5NCAxOC44MzM3IDE4LjMzMjcgMTUuMTAwMyAxOC4zMzI3IDEwLjUwMDNDMTguMzMyNyA1LjkwMDMzIDE0LjU5OTQgMi4xNjY5OSA5Ljk5OTM1IDIuMTY2OTlDNS4zOTkzNSAyLjE2Njk5IDEuNjY2MDIgNS45MDAzMyAxLjY2NjAyIDEwLjUwMDNaTTEwLjgzMjcgNi4zMzM2Nkg5LjE2NjAxVjExLjMzMzdIMTAuODMyN1Y2LjMzMzY2Wk05Ljk5OTM0IDMuODMzNjZDNi4zMjQzNCAzLjgzMzY2IDMuMzMyNjcgNi44MjUzMyAzLjMzMjY3IDEwLjUwMDNDMy4zMzI2NyAxNC4xNzUzIDYuMzI0MzQgMTcuMTY3IDkuOTk5MzQgMTcuMTY3QzEzLjY3NDMgMTcuMTY3IDE2LjY2NiAxNC4xNzUzIDE2LjY2NiAxMC41MDAzQzE2LjY2NiA2LjgyNTMzIDEzLjY3NDMgMy44MzM2NiA5Ljk5OTM0IDMuODMzNjZaTTEwLjgzMjcgMTMuMDAwM0g5LjE2NjAxVjE0LjY2N0gxMC44MzI3VjEzLjAwMDNaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
}

/* stylelint-disable */

.status_awaitingPayment-1d926 {
}

/* stylelint-enable */
.comment-2BR0O {
	padding: 18px 16px;

	border-bottom: 1px solid #f4f4f4;

	font-size: 15px;
	line-height: 130%;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);

	background: #ffffff;
}

.comment__header-1WVs_ {
	margin-bottom: 8px;

	color: var(--gray80);
	font-weight: 600;
	font-size: 16px;
}

.comment__text-2FQ7D {
	white-space: pre-wrap;
}

@media screen and (max-width: 600px) {
	.container-3t686 {
		margin-top: 0;
		margin-bottom: 8px;

		border-radius: 8px;
	}

	.request__main-3NyqF {
		flex-direction: column;
		align-items: flex-start;

		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.header__wrapper-2_i2Y {
		justify-content: space-between;
		margin-bottom: 6px;
		min-height: 24px;
		width: 100%;
	}

	.header-1EZAg {
		border-right: none;

		font-size: 16px;
	}

	.created-iQm8D {
		text-align: right;
	}

	.order-56WRx {
		padding: 0;
	}

	div.chip-p09_W:last-child,
	div.chip-p09_W:first-child {
		border-radius: 0;
	}
}
