div.checkbox_notActive-1dArr:after {
	background: #d9d9d9;
}

.wrapper-2hBLS {
	flex: 1;
	padding: 18px 18px 2px 18px;

	border-radius: var(--baggage-additional-wrapped-border-radius);

	transition: background-color 0.15s;
}

.wrapper-2hBLS:only-child {
	max-width: 50%;
}

.wrapper-2hBLS.wrapper_inDialog-1o-1r {
	flex-wrap: wrap;
}

.wrapper-2hBLS.wrapper_inDialog-1o-1r:only-child {
	max-width: unset;
}

.wrapper-2hBLS:not(:last-child) {
	margin-right: 12px;
}

.wrapper-2hBLS.wrapper_inDialog-1o-1r:not(:last-of-type) {
	margin-right: 0;
}

.wrapper_solid-13Vdt {
	background-color: #ffffff;
}

.wrapper_outline-5aNYi {
	border: 1px solid #d9d9d9;

	background-color: transparent;
}

.wrapper_active-BgdcB.wrapper_outline-5aNYi {
	border: 1px solid rgba(50, 144, 255, 0.2);

	background-color: rgba(50, 144, 255, 0.1);
}

.head-d5Bri {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-1cGZL {
	padding-left: 70px;

	color: var(--dark-gray);
	font-weight: 600;
	font-size: var(--baggage-additional-header-font-size);
	text-transform: var(--baggage-service-segment-direction-text-transform);
}

.switcher-2zTXR {
	margin-right: 6px;
}

.header-1cGZL:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	display: flex;
	width: 56px;
	height: 56px;
	margin: auto;

	opacity: var(--baggage-additional-service-icon-opacity);

	background-color: var(--common-blue);
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMzIuNjY2NyAxOC42NjY3QzMzLjk1NTQgMTguNjY2NyAzNS4wMDAxIDE5LjcxMTQgMzUuMDAwMSAyMVYyNS42NjY3SDMyLjY2NjdMMzIuNjY2NyAyMy4zMzM0QzMyLjY2NjcgMjIuMDQ0NyAzMS42MjIxIDIxIDMwLjMzMzQgMjFIMjUuNjY2N0MyNC4zNzgxIDIxIDIzLjMzMzQgMjIuMDQ0NyAyMy4zMzM0IDIzLjMzMzRMMjMuMzMzNCAyNS42NjY3SDIxLjAwMDFWMjFDMjEuMDAwMSAxOS43MTE0IDIyLjA0NDggMTguNjY2NyAyMy4zMzM0IDE4LjY2NjdIMzIuNjY2N1pNMTguNjY2NyAyNS42NjY3VjIxQzE4LjY2NjcgMTguNDIyNyAyMC43NTYxIDE2LjMzMzQgMjMuMzMzNCAxNi4zMzM0SDMyLjY2NjdDMzUuMjQ0MSAxNi4zMzM0IDM3LjMzMzQgMTguNDIyNyAzNy4zMzM0IDIxVjI1LjY2NjdINDIuMDAwMVY0MkgxNC4wMDAxVjI1LjY2NjdIMTguNjY2N1pNMzIuNjY2NyAzMC4zMzM0SDMwLjMzMzRIMjUuNjY2N0gyMy4zMzM0SDE2LjMzMzRWMzIuNjY2N0gzNS4wMDAxVjM1SDM3LjMzMzRWMzIuNjY2N0gzOS42NjY3VjMwLjMzMzRIMzIuNjY2N1pNMzAuMzMzNCAyNS42NjY3SDI1LjY2NjdWMjMuMzMzNEgzMC4zMzM0VjI1LjY2NjdaTTQuNjY2NzUgMjhDNC42NjY3NSAyNi43MTE0IDUuNzExNDIgMjUuNjY2NyA3LjAwMDA4IDI1LjY2NjdIMTEuNjY2N1Y0Mkg3LjAwMDA4QzUuNzExNDIgNDIgNC42NjY3NSA0MC45NTU0IDQuNjY2NzUgMzkuNjY2N1YyOFpNNDQuMzMzNCA0MlYyNS42NjY3SDQ5LjAwMDFDNTAuMjg4OCAyNS42NjY3IDUxLjMzMzQgMjYuNzExNCA1MS4zMzM0IDI4VjM5LjY2NjdDNTEuMzMzNCA0MC45NTU0IDUwLjI4ODggNDIgNDkuMDAwMSA0Mkg0NC4zMzM0WiIgZmlsbD0iY3VycmVudENvbG9yIi8+Cjwvc3ZnPgo=);
	        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMzIuNjY2NyAxOC42NjY3QzMzLjk1NTQgMTguNjY2NyAzNS4wMDAxIDE5LjcxMTQgMzUuMDAwMSAyMVYyNS42NjY3SDMyLjY2NjdMMzIuNjY2NyAyMy4zMzM0QzMyLjY2NjcgMjIuMDQ0NyAzMS42MjIxIDIxIDMwLjMzMzQgMjFIMjUuNjY2N0MyNC4zNzgxIDIxIDIzLjMzMzQgMjIuMDQ0NyAyMy4zMzM0IDIzLjMzMzRMMjMuMzMzNCAyNS42NjY3SDIxLjAwMDFWMjFDMjEuMDAwMSAxOS43MTE0IDIyLjA0NDggMTguNjY2NyAyMy4zMzM0IDE4LjY2NjdIMzIuNjY2N1pNMTguNjY2NyAyNS42NjY3VjIxQzE4LjY2NjcgMTguNDIyNyAyMC43NTYxIDE2LjMzMzQgMjMuMzMzNCAxNi4zMzM0SDMyLjY2NjdDMzUuMjQ0MSAxNi4zMzM0IDM3LjMzMzQgMTguNDIyNyAzNy4zMzM0IDIxVjI1LjY2NjdINDIuMDAwMVY0MkgxNC4wMDAxVjI1LjY2NjdIMTguNjY2N1pNMzIuNjY2NyAzMC4zMzM0SDMwLjMzMzRIMjUuNjY2N0gyMy4zMzM0SDE2LjMzMzRWMzIuNjY2N0gzNS4wMDAxVjM1SDM3LjMzMzRWMzIuNjY2N0gzOS42NjY3VjMwLjMzMzRIMzIuNjY2N1pNMzAuMzMzNCAyNS42NjY3SDI1LjY2NjdWMjMuMzMzNEgzMC4zMzM0VjI1LjY2NjdaTTQuNjY2NzUgMjhDNC42NjY3NSAyNi43MTE0IDUuNzExNDIgMjUuNjY2NyA3LjAwMDA4IDI1LjY2NjdIMTEuNjY2N1Y0Mkg3LjAwMDA4QzUuNzExNDIgNDIgNC42NjY3NSA0MC45NTU0IDQuNjY2NzUgMzkuNjY2N1YyOFpNNDQuMzMzNCA0MlYyNS42NjY3SDQ5LjAwMDFDNTAuMjg4OCAyNS42NjY3IDUxLjMzMzQgMjYuNzExNCA1MS4zMzM0IDI4VjM5LjY2NjdDNTEuMzMzNCA0MC45NTU0IDUwLjI4ODggNDIgNDkuMDAwMSA0Mkg0NC4zMzM0WiIgZmlsbD0iY3VycmVudENvbG9yIi8+Cjwvc3ZnPgo=);
	content: '';
}

.money-2WhhU {
	font-weight: 600;
	color: var(--baggage-additional-money-color);
	font-size: 18px;
}

.money-2WhhU.money_opened-txb5f,
.item_money-1p1na.money_opened-txb5f {
	color: var(--baggage-additional-selected-money-color);
}

.desc-2-S6S {
	margin-top: 15px;
	margin-bottom: 15px;

	color: var(--common-gray);
	font-size: 14px;
}

.item-34Xw4 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-top: 1px dashed #cdcdcd;

	color: var(--dark-gray);
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
}

.itemprice-3lq-- {
	display: flex;
	align-items: center;
}

.item_money-1p1na {
	font-size: 16px;
	font-weight: 600;
	color: var(--common-blue);
}

span.checkbox_checked-3iOIK.checkbox-1sP2e {
	color: var(--common-blue);
}

.checkbox__label-1xhgp {
	opacity: 1;
}

.label__from-qG-n1 {
	margin-right: 5px;

	color: var(--baggage-additional-money-color);

	font-weight: 600;

	font-size: 18px;
}

.not_selected-3BSOF:before {
	opacity: 0.5;
}

@media screen and (max-width: 600px) {
	.header-1cGZL,
	.desc-2-S6S {
		font-size: 12px;
	}

	.header-1cGZL {
		padding-left: 52px;

		text-transform: none;

		text-transform: initial;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
	}

	.wrapper-2hBLS {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;

		border-radius: 4px;
	}

	.head-d5Bri {
		width: 100%;
		padding: 0 12px;
	}

	.header-1cGZL:before {
		left: 12px;

		height: 40px;
		width: 40px;

		background-size: contain;
	}

	.not_selected-3BSOF:before {
		opacity: 1;
	}
}
