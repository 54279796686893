.gdsTotalPrice-3n4MJ {
	border-color: var(--line-separator);

	color: var(--text-base);
	font-weight: 400;
	line-height: 1.1;
}

.summaryFlight-3wG2N {
	display: none;
}

.form-3rgOv form {
	display: flex;
	flex-direction: column;
}

.form-3rgOv .passengers-1yw9s {
	margin-top: 0;
}

.passengersToolbar__label-2yWuD a {
	text-decoration: none;
}

@media screen and (max-width: 600px) {
	.toolbarCheckbox__wrapper-3WQJW {
		order: 2;
		margin-bottom: 30px;
	}

	.orderMessages-1XTGa {
		margin-bottom: 45px;
	}
}
