
/* stylelint-disable */
.day__inside-2XZgV {
}

.day_highlighted-2ckzh {
}

/* stylelint-enable */
.day-12oh8 {
	display: inline-block;
	width: 30px;
	height: 30px;

	line-height: 30px;
	text-align: center;

	cursor: pointer;
}

.day-12oh8.day_selected-2KLJ0 {
	color: #ffffff;

	background: #84d2ff;
}

.day_notAvailable-2NQqE {
	opacity: 0.6;
	cursor: default;
}

.day_startPeriod-1tn32,
.day_endPeriod-1yMQP {
	color: #ffffff;

	background: #84d2ff;
}

.day_startHover-3FkcW > div,
.day_endHover-1D_QB > div {
	position: relative !important;

	z-index: 2;

	border-radius: 50%;

	background: var(--datepicker-day-period-background);
}

.day_startHover-3FkcW,
.day_endHover-1D_QB {
	position: relative !important;
	z-index: 1;
}

.day_startHover-3FkcW:after {
	position: absolute;
	top: 0;
	right: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_endHover-1D_QB:before {
	position: absolute;
	top: 0;
	left: 0;

	width: calc(40px / 2);
	height: 36px;

	background: var(--datepicker-day-period-background);
	content: '';
}

.day_inPeriod-FNgIr {
	color: #ffffff;

	background: #5bc3ff;
}

.day_fromOtherMonth-2hYQd {
	color: #cccccc;
}

.day_weekend-Tqu8w {
	color: #84d2ff;
}

.day_today-H_RJr {
	color: #84d2ff;
}

.day_weekend-Tqu8w.day_selected-2KLJ0,
.day_weekend-Tqu8w.day_inPeriod-FNgIr,
.day_weekend-Tqu8w.day_today-H_RJr,
.day_selected-2KLJ0.day_today-H_RJr {
	color: #ffffff;
}
