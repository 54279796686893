.container-XnyNd {

	padding: 60px 0;
}

@media screen and (max-width: 600px) {
	.container-XnyNd {
		position: relative;

		padding-top: 40px;
	}
}

.header-3Bnok {
	width: 200px;
	height: 24px;
	margin-left: 15px;

	border-radius: 4px;

	background: rgba(205, 205, 205, 0.5);
}

.calendar-1GrdP {

	padding-right: 28px;
}

@media screen and (max-width: 600px) {
	.calendar-1GrdP {
		padding: 0;
	}
}

.daysWrapper-9Qzda {
	position: relative;

	height: 100%;
	overflow: hidden;
}

.daysWrapper-9Qzda:after {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;

	width: 100%;

	transform: translateX(100%);
	opacity: 0.7;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(128, 186, 232, 0) 99%,
		rgba(125, 185, 232, 0) 100%
	);

	animation: slide-1pW3j 1s infinite 0.5s;
	content: '';
}

.days-20YNQ {

	margin-top: 15px;
	margin-left: 100px;
}

@media screen and (max-width: 600px) {
	.days-20YNQ {
		height: 100%;
	}
}

.day-6ANR8 {

	cursor: default;
}

.bar-1NA63 {
	height: 100px;

	cursor: default;
}

@media screen and (max-width: 600px) {
	.bar-1NA63 {
		height: 100%;
	}
}

.progress-rVVc8 {

	height: 90%;

	opacity: 1;
	background: rgba(205, 205, 205, 0.5);
}

.day-6ANR8:hover .progress-rVVc8 {
	opacity: 1;
	background: rgba(205, 205, 205, 0.5);
}

.day__header-1cq-_ {
	right: 0;
	bottom: -23px;
	left: 0;

	width: 14px;
	height: 14px;
	margin: auto;

	border-radius: 4px;

	opacity: 0.5;
	background: rgba(205, 205, 205, 0.5);
}

.legends-16JYe {

	left: 70px;
}

.line-2VEj5 {
}

.label-1HUo9 {
	top: -8px;

	width: 50px;
	height: 15px;

	border-radius: 15px;

	background: rgba(205, 205, 205, 0.5);
}

@keyframes slide-1pW3j {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
