.ticketExample-12zd4 {
	display: grid;
	grid-template-columns: 1fr 1fr;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.ticketExample__leftSide-irhdP {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

.ticketExample__rightSide-1zzSo {
	display: flex;
	flex-direction: column;
}

.ticketExample__header-1F9Id {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.4rem 0.6rem;
	height: 36px;

	font-size: 0.8rem;
	line-height: 1;

	color: var(--white);

	background-color: var(--brand-1-0);
}

.allocated-2lynQ {
	position: relative;

	background-color: var(--error-stroke--hover);
}

.ticketExample__headerAgent-2Hl6P {
	font-size: 0.4rem;
	font-weight: 400;
	line-height: 1.2;
}

body[data-demo-wx='true'] .ticketExample__headerAgent-2Hl6P {
	display: none;
}

.ticketExample__headerAgent-2Hl6P span {
	display: block;
	margin-top: 0.1rem;

	font-size: 0.5rem;
}

.ticketExample__headerContacts-YdTwR {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	grid-gap: 0.1rem;
	gap: 0.1rem;
}

.ticketExample__headerContacts-YdTwR span {
	font-size: 0.4rem;
}

.ticketExample__leftSide-irhdP .ticketExample__header-1F9Id {
	display: flex;
	justify-content: flex-end;

	border-right: 1px dashed var(--gray20);

	font-weight: 700;

	text-transform: uppercase;
}

.ticketExample__rightSide-1zzSo .ticketExample__header-1F9Id {
	display: flex;
	justify-content: space-between;
}

body[data-demo-wx='true'] .ticketExample__rightSide-1zzSo .ticketExample__header-1F9Id {
	justify-content: flex-end;
}

.ticketExample__flightInfo-15ZcK {
	display: flex;
	flex-direction: column;
}

.ticketExample__flightInfo-15ZcK:after {
	content: '';

	height: 3px;
	margin: 0 0.5rem;
	box-sizing: content-box;

	background-color: var(--gray20);
}

.ticketExample__content-1msHx {
	display: flex;
	justify-content: space-between;
	padding: 0.2rem 0.4rem;
	box-sizing: content-box;
	height: 26px;
}

@media screen and (max-width: 600px) {
	.ticketExample__content-1msHx {
		flex-direction: column;
		height: auto;
	}
}

.ticketExample__leftSide-irhdP .ticketExample__content-1msHx {
	border-right: 1px dashed var(--gray20);
}

.ticketExample__passengerName-qmyp7 {
	display: flex;
	flex-direction: column;
	width: max-content;
	padding: 0.2rem;

	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5;
	text-transform: uppercase;

	color: var(--text);
}

@media screen and (max-width: 600px) {
	.ticketExample__passengerName-qmyp7 {
		flex-direction: row;
	}
}

.ticketExample__passengerName-qmyp7 div {
	padding: 0.2rem 0.3rem;
}

@media screen and (max-width: 600px) {
	.ticketExample__passengerName-qmyp7 div:first-child {
		padding-left: 0;
	}
}

.ticketExample__contentGroupItems-GHZSc {
	display: flex;
	grid-gap: 0.2rem;
	gap: 0.2rem;
}

@media screen and (max-width: 600px) {
	.ticketExample__contentGroupItems-GHZSc {
		flex-direction: column;
	}
}

.ticketExample__contentItem-RB20e {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.2rem;
	grid-gap: 0.2rem;
	gap: 0.2rem;
	width: max-content;
}

.ticketExample__contentItemLabel-35RMk {
	font-size: 0.45rem;
	line-height: 1;

	color: var(--gray50);
}

.ticketExample__contentItemValue-1sAT_ {
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;

	color: var(--text);
}

.ticketExample__rightSide-1zzSo .ticketExample__contentItemValue-1sAT_ {
	font-size: 0.4rem;
}

.ticketExample__paymentInfo-3z5B3 {
	display: flex;
	justify-content: space-between;
	padding: 0.3rem 0.5rem;
	grid-gap: 1rem;
	gap: 1rem;

	font-size: 0.4rem;

	opacity: 0.15;
}

.icon-3kc30 {
	position: absolute;
	left: 50%;
	bottom: -30px;

	transform: translate(-50%, 0);
}

@media screen and (max-width: 600px) {
	.icon-3kc30 {
		left: 100%;
		bottom: -9px;

		transform: translate(0, 0);
	}
}

.icon-3kc30 svg {
	width: 18px;

	color: var(--error);

	transform: rotate(90deg);
}

@media screen and (max-width: 600px) {
	.icon-3kc30 svg {
		transform: rotate(0);
	}
}
