.wrapper-3moVs {
	display: flex;
	align-items: center;
}

.wrapper-3moVs:not(.wrapper_departure-2oaBb) {
	flex-direction: row-reverse;
}

.airport-3whe4 {
	margin: 0 16px;

	color: var(--text-mid);
	font-size: 14px;
}

.airport-3whe4.airport_departure-1ICEi {
	text-align: right;
}

.airport-3whe4 div:first-of-type {
	margin-bottom: 3px;
}

.airport_terminal-1cHx- {
	white-space: nowrap;
}

.time-1idjk {
	font-size: 42px;
	font-weight: 700;
	color: var(--text-base);
}

@media (max-width: 1024px) {
	.wrapper_mobile-3nkQ1 {
		display: flex;
		align-items: center;
	}

	.wrapper_mobile-3nkQ1:first-of-type {
		margin-bottom: 7px;
	}

	.wrapper_mobile-3nkQ1 .airport-3whe4 {
		margin: 0 0 0 12px;

		font-size: 13px;
	}

	.wrapper_mobile-3nkQ1 .time-1idjk {
		font-size: 31px;
	}

	.wrapper_mobile-3nkQ1 .plane-2L0AW {
		margin-right: 14px;
		margin-left: 2px;
		margin-top: 3px;
	}

	.wrapper_mobile-3nkQ1 .plane-2L0AW svg {
		transform: scale(1.35);
	}

	.wrapper_mobile-3nkQ1 .plane-2L0AW svg path {
		fill: var(--text-pale);
	}
}
