.root-3N9_c {
	display: flex;
	align-items: center;
	padding: 17px 13px 14px;

	border-radius: 4px;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	background-color: rgba(50, 144, 255, 0.1);
}

.root-3N9_c:not(:first-child) {
	margin-top: 20px;
}

.disabled-2VQfr {
	opacity: 0.7;
}

.name-3qUs0 {
	display: flex;
	align-items: center;
	flex: 1;
}

.name_price-2DNU5 {
	margin-left: auto;
}

.root-3N9_c + .passenger-2fL94 {
	border-top: none;
}

.name-3qUs0.name_withPrices-3vKD2 {
	max-width: 33%;
}

.prices-Oy-J- {
	display: flex;
	align-items: center;
	flex: 1;
}

.price-3vPol {
	flex: 1;

	text-align: center;
}

.price__empty-3aJR_ {
	flex: 1;
}

@media screen and (max-width: 600px) {
	.root-3N9_c:not(:first-child) {
		margin-top: 25px;
	}

	.prices-Oy-J- {
		justify-content: flex-end;
	}

	.price-3vPol {
		text-align: right;
	}
}
