

div.container-2lHM0 {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 32px 40px;

	color: var(--text);
}

.container-2lHM0 h1 {
	margin: 12px 0;

	font-size: 24px;
}

.container-2lHM0 h2 {
	font-size: 18px;
}

.table-1SMfE {
	border-spacing: 20px 10px;
}

.table-1SMfE thead {
	font-size: 13px;
	color: var(--text-light);
}

.table-1SMfE th {
	font-weight: 400;
}

.table-1SMfE th:nth-child(2) {
	color: var(--brand-3-0);
}

.table-1SMfE th:nth-child(3) {
	color: var(--light-violet);
}

.table-1SMfE th:nth-child(1) {
	text-align: left;
}

.table-1SMfE th:nth-child(2),
.table-1SMfE th:nth-child(3) {
	text-align: center;
}

.buttons-3p-Ir {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
	margin-top: 36px;
}

.buttons-3p-Ir button {
	height: 48px;
	padding: 0 24px;

	border-radius: 6px;
}

.buttons-3p-Ir button:hover {
	opacity: 0.9;
}

button.buttons__continue-17AkO,
button.buttons__continue-17AkO:hover {
	background-color: var(--brand-3-0);
}

button.buttons__add-28V1O,
button.buttons__add-28V1O:hover {
	background-color: var(--light-violet);
}

button.buttons__reselect-2AetY,
button.buttons__reselect-2AetY:hover {
	color: var(--text-light);

	background-color: var(--bg-fill);
}

.description-3mZ0O {
	margin-bottom: 36px;

	color: var(--text-light);
	font-size: 18px;
	text-align: center;
}
