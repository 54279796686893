

.pagination-1jnjY {
	display: flex;
	justify-content: space-between;
}

.item-1xFso {
	width: 8px;
	height: 8px;

	border-radius: 50%;

	cursor: pointer;
	background: #b3b3b3;
}

.item_active-1VWbX {
	background: #4d4d4d;
}
