.wrapper_stub-3QI_r {
	background: var(--order-header-longTrip-background);
}

.gradient-1dVmI {
	background: var(--order-header-background);
}

.longTrip-3wThr .order__info-Qt6sk div {
	display: flex;
}

.button__download-3YGUL > span:first-child {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	font-size: 10px;
	text-transform: capitalize;
	font-weight: 400;
}

.longTrip-3wThr .order__info-Qt6sk div span {
	display: flex;
	flex: 1;

	font-weight: 500;
	font-size: 20px;
	text-transform: uppercase;
}

.longTrip-3wThr .order__info-Qt6sk div span:last-child {
	display: flex;
	flex: 2;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 400;
}

div.card__content-3vEBM {
	display: flex;
	padding: 0;
	min-height: 150px;
	width: 100%;

	border-radius: var(--order-header-border-radius);
}

div.card__content-3vEBM:last-child {
	padding-bottom: 0;
}

.city__stub-dH5rR {
	background: url('https://cdn.websky.aero/content/frontend/images/order-summary-header-stub.svg') left center
		no-repeat;
	background-size: cover;
}

.card__content-3vEBM .ids-2Wcp5 {
	display: flex;
	flex: 1;
	padding: 36px 20px;
	flex-direction: column;

	border-right: 1px dashed rgba(255, 255, 255, 0.5);
}

.ids__row-13Mnl {
	font-size: 15px;
	color: rgba(255, 255, 255, 0.8);
}

.ids__row-13Mnl:not(:first-of-type) {
	margin-top: 12px;
}

.ids__row__title-CaJzj {
	display: inline-flex;
	align-items: center;

	white-space: nowrap;
	line-height: 20px;
}

.ids__row__value-1vjUM {
	margin-top: 4px;

	line-height: 20px;
	font-weight: 500;
	font-size: 18px;
	color: #ffffff;
}

.order__info-Qt6sk {
	display: flex;
	padding: 36px 20px 22px 20px;
	flex-direction: column;
	flex: 4;

	color: #ffffff;
}

.departure-3YpNL {
	font-size: 18px;
}

.arrival-1lw0A {
	margin-top: 10px;

	font-size: 63px;
	text-transform: uppercase;
	font-weight: 500;
}

.arrival_mediumFont-1d7fc {
	font-size: 55px;
}

.arrival_smallFont-3TJVU {
	margin-top: 20px;

	font-size: 38px;
}

.manage-1nOex {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 20px;
	flex: 1;
}

.manage-1nOex .button-1KRx- {
	color: var(--common-blue);

	background-color: #ffffff;
}

.manage-1nOex .button__download-3YGUL {
	height: 83px;
	width: 83px;

	margin-right: 10px;
}

.manage-1nOex .button__download-3YGUL svg {
	color: var(--cabinet-action);
}

.manage-1nOex .button-1KRx-:hover {
	background-color: #ffffff;
}

.manage-1nOex .button__view-1RhpS {
	min-width: 100px;
	height: 40px;
	padding: 0 20px;

	border-radius: var(--button-border-radius);

	font-weight: 500;
	font-size: 15px;
	line-height: 40px;
	white-space: nowrap;
	text-align: center;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 600px) {
	.gradient-1dVmI {
		background: var(--order-header-background);
	}

	div.card__content-3vEBM {
		flex-direction: column;
	}

	.card__content-3vEBM.card__content_paid-3xuV3,
	.card__content-3vEBM.card__content_paid-3xuV3 .gradient-1dVmI {
		border-radius: 0;
	}

	.card__content-3vEBM .ids-2Wcp5 {
		padding: 60px 12px 20px;

		border-right: none;
	}

	.ids__row-13Mnl {
		font-size: 16px;
	}

	.ids__row__value-1vjUM {
		margin-top: 4px;
		height: 20px;

		font-size: 20px;
	}

	.order__info-Qt6sk {
		padding: 0 12px 20px;
	}

	.card__content_paid-3xuV3 .order__info-Qt6sk {
		padding: 42px 20px 46px;
	}

	.order__info-Qt6sk .departure-3YpNL {
		height: auto;

		font-size: 16px;
		color: rgba(255, 255, 255, 0.8);
	}

	.card__content_paid-3xuV3 .departure-3YpNL {
		margin-bottom: 31px;

		color: #ffffff;
		line-height: 150%;
	}

	.arrival-1lw0A {
		height: auto;
		margin-top: 4px;

		font-size: 35px;
	}

	.card__content_paid-3xuV3 .arrival-1lw0A {
		margin-top: 0;

		font-size: 30px;
		line-height: 110%;
	}

	.arrival_mediumFont-1d7fc {
		font-size: 35px;
	}

	.arrival_smallFont-3TJVU {
		font-size: 28px;
	}

	.longTrip-3wThr .order__info-Qt6sk div span {
		font-size: 16px;
	}
}
