.statusMessage-eBXEd {
	position: relative;

	margin-bottom: 12px;
	padding: 16px 16px 16px 86px;

	color: var(--text-base);

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
	background: var(--white);
}

.statusMessage-eBXEd:before {
	position: absolute;
	top: 50%;
	left: 16px;

	width: 48px;
	height: 48px;

	border-radius: 50%;

	color: var(--white);

	transform: translate(0, -50%);

	content: '';
}

.statusMessage_success-3miPN:before {
	background: var(--success-icon) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMDAwMzkgMTEuMkwxLjgwMDM5IDYuOTk5OThMMC40MDAzOTEgOC4zOTk5OEw2LjAwMDM5IDE0TDE4LjAwMDQgMS45OTk5OEwxNi42MDA0IDAuNTk5OTc2TDYuMDAwMzkgMTEuMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.statusMessage_fail-2p6V9:before {
	display: none;
}

.statusMessage__title-3zrVz {
	margin: 0 0 8px;

	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
}

.statusMessage__text-3ClKz {
	margin: 0;

	font-size: 18px;
	line-height: 21px;
	white-space: pre-line;
}

@media screen and (max-width: 600px) {
	.statusMessage-eBXEd {
		padding: 0;
		margin: 0 0 20px 0;
	}

	.statusMessage_success-3miPN:before {
		top: 16px;

		transform: translate(0, -25%);
	}

	.statusMessage__title-3zrVz {
		display: inline-flex;
		align-items: center;
		min-height: 80px;
		padding-left: 76px;
		margin-bottom: 12px;
	}

	.statusMessage__text-3ClKz {
		padding: 0 16px 16px;

		font-size: 16px;
		line-height: 1.3;
	}
}

@media screen and (max-width: 463px) {
	.statusMessage-eBXEd:before {
		transform: translate(0, 0);
	}
}
