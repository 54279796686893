.wings-3mNpJ:before,
.wings-3mNpJ:after {
	background: var(--bg-elements);
}

.row__serviceHeader-22OYW {
	border-color: var(--seat-economy-base);
}

.row__serviceHeader__wrapper-3wAqn {
	padding: 4px 10px 2px;

	color: var(--text-mid);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.1;

	background: var(--seat-economy-base);
}

.row__serviceHeader__serviceName-YQKw2 {
	text-transform: none;
}

.wrapper_threeServices-1mYsE,
.wrapper_fourServices-1HsKz,
.header_passengers-2RtiS {
	min-height: auto;
}

.exit__label-kr_mq {
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	div.header_mobile-PUR6f,
	div.seatsInfo-3n9zz {
		background: var(--white);
	}

	.airplane-4ld8j {
		background: transparent;
	}
}
