

.legNumber-2m6zW {
	background: var(--brand-1-0);
}

.title__cities-ffMPP {
	color: var(--brand-1--2);
}

.title__cities-ffMPP span:first-of-type {
	position: relative;
}

.title__cities-ffMPP span:first-of-type:after {
	position: absolute;
	content: '\2014';

	right: -30px;
}

.title__cities-ffMPP svg * {
	display: none;
}

button.diagram-JzkLq {
	font-size: 16px;
}

.priceGraph__content-23lJi {
	padding: 54px 80px;
}
