.visaWrapper-cYLth {
	margin: 12px 40px;
	padding: 28px;

	border-radius: 4px;

	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.07);
	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.visaWrapper-cYLth {
		margin: 0;
	}
}

.passenger-q4_9n {
	display: flex;
	justify-content: space-between;
}

.passenger__name-19ahE {
	font-size: 20px;
	color: #292929;
	text-transform: capitalize;
	font-weight: 700;
}

.passenger__birth-3UeaL {
	font-size: 20px;
	font-weight: 500;
	color: #bababa;
}

.group-2VvCV {
	display: flex;
	margin-top: 32px;
}

@media screen and (max-width: 600px) {
	.group-2VvCV {
		display: block;
		margin: 0;
	}
}

.group__field-2LqZw {
	display: flex;
}

.group__field-2LqZw > div {
	flex: 1;
}

.group__field-2LqZw:first-child {
	flex: 1;
	margin-right: 28px;
}

.group__field-2LqZw:only-child {
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	.visaWrapper-cYLth {
		margin: 12px 20px;
	}

	.group__field-2LqZw:first-child {
		margin: 0;
	}

	.group__field-2LqZw > div {
		margin-top: 18px;
	}
}
