.container-1Q6UQ.oldVersion-2Ysml {
	border-radius: 4px;
}

.oldVersion-2Ysml .content-Dvf4Y a {
	color: var(--gray);
	text-decoration: underline;
}

.oldVersion-2Ysml .optionsList-1kJ3N li:before {
	background: var(--gray);
}

.petPopup__header-3yXYy {
	padding: 32px 150px 32px 40px;
}

@media screen and (max-width: 600px) {
	.petPopup__header-3yXYy {
		padding: 32px 150px 20px 20px;
	}
}
