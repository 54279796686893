.title-2QcXx {
	margin-left: 64px;

	color: var(--fg-default);
	font-size: 36px;
}

@media (max-width: 1024px) {
	.title-2QcXx {
		margin-left: 0;
	}
}

@media screen and (max-width: 600px) {
	.title-2QcXx {
		font-size: 28px;
	}
}
