.container-3ctCf {
	margin-top: 24px;
}

.disabilities-7q9Mt {
	margin-top: 11px;
	padding: 28px;

	border-radius: 4px;

	color: var(--text-light);

	background: var(--bg-fill);
}

.disabilities_error-Ghc5d {
	border: 1px solid var(--error-stroke--hover);

	background: var(--error-bg);
}

.checkboxes-3XZNd {
	margin-top: 13px;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.disabilities__text-16XQ9 {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
}

.disabilities__text-16XQ9 svg {
	color: var(--text-pale);
}

.disabilities__text-16XQ9 > div:nth-child(2) {
	padding-top: 2px;
}

span.checkbox-3vyLt.checkbox_checked-1hwpS {
	color: var(--brand-1-0);
}

span.checkbox-3vyLt:not(.checkbox_checked-1hwpS) svg {
	color: var(--text-pale);
}

span.checkbox-3vyLt:not(.checkbox_checked-1hwpS):before {
	position: absolute;

	width: 14px;
	height: 14px;

	background-color: var(--white);
	content: '';
}

span.checkbox_root-1MbeA.checkbox-3vyLt.checkbox_disabled-KLomD + span {
	opacity: 0.8;
}

span.checkbox_label-gn95Q {
	font: var(--medium--1-normal);
	font-family: inherit;
}

.switchWrapper-36WI3 {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	align-items: baseline;
}

.switchWrapper-36WI3 .icon-21K1J {
	width: 40px;
}

.switchControl__errorText-1IUNo {
	color: var(--error);
	font-size: 12px;
}

@media screen and (max-width: 600px) {
	.checkboxes-3XZNd {
		grid-template-columns: 1fr;
	}

	.switchWrapper-36WI3 {
		flex-direction: column;
		grid-gap: 18px;
		gap: 18px;
	}

	div.switchControl-33miF {
		margin-left: 12px;
		margin-bottom: 0;
	}

	.disabilities-7q9Mt {
		width: calc(100% - 24px);

		margin: 12px auto 0;
		padding: 12px;
	}

	span.checkbox-3vyLt:not(.checkbox_checked-1hwpS):before {
		background-color: inherit;
	}

	.switchControl__errorText-1IUNo {
		display: flex;
		align-items: center;
		margin-left: 12px;
	}
}
