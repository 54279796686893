.FullscreenDialog__Content-nxozP {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 100px;
}

.wrapper-1Pqfq {
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

@media screen and (max-width: 600px) {
	.wrapper-1Pqfq {
		padding-bottom: 60px;
	}
}

.flights__wrapper-2Awha {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	width: 100%;
	padding: 20px;

	border-radius: 4px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);
}

@media screen and (max-width: 600px) {
	.flights__wrapper-2Awha {
		padding: 0 0 0 10px;

		background: transparent;

		box-shadow: none;
	}
}

.flights__title-3f2tt {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.flights-ENAFD {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}
