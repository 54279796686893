.container-3-tDn {
	margin: 0 auto;
}

.container-3-tDn > div {
	margin-bottom: 12px;
}

.inner-1m7tV {
	max-width: 866px;
	margin: 0 auto;
	width: 100%;
	padding-bottom: 82px;
}

.header-1yx2C {
	width: 100%;
	margin: 0 auto;
	padding: 28px 0;

	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.segments-3NjSP {
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

ul.services-KurZW {
	margin: 0;
	padding: 0;

	color: var(--text-light);
	font-size: 16px;
	line-height: 130%;

	text-transform: capitalize;

	list-style: none;
}

.notice-3q_mG {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	padding: 12px;

	border-radius: 12px;

	color: var(--text-mid);
	font-size: 14px;
	line-height: 130%;

	background: var(--bg-fill);

	cursor: pointer;
}

.notice__icon-1IOme {
	flex: 0;
	margin-right: 8px;
	height: 24px;
	width: 24px;

	display: flex;
	align-items: center;

	color: var(--text-pale);
}

.notice__icon-1IOme svg {
	margin-bottom: 2px;
}

.notice__link-3hZ7_ {
	display: flex;
	flex: 0;
	align-items: center;

	color: var(--text-light);
	white-space: nowrap;
}

.notice__notes-1AzzM {
	margin-right: auto;
}

.notice__link-3hZ7_ svg {
	margin-left: 9px;

	color: var(--text-pale);
}

.details-Desy5 {
	margin-top: 12px;
	padding-top: 12px;

	border-top: 1px dashed var(--line-dot-line);
}

.details-Desy5 a {
	margin-top: 4px;

	color: var(--text-link);
}

button.details__button-1EgFV {
	padding: 4px 0;

	font-size: 16px;
	font-weight: 400;
}

.travellers-2FUOM {
	margin-top: 20px;

	border-top: 1px dashed var(--line-dot-line);
}

.traveller-3_KzE {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 14px 0;

	border-bottom: 1px dashed var(--line-dot-line);

	font-size: 18px;
}

.traveller__name-2cXye {
	font-weight: 700;
	text-transform: capitalize;
}

.button-GlBaw.button_passenger-3xtjr {
	margin-left: 12px;
	min-width: 110px;
}

button.button-GlBaw.button_selected-Tx2gb {
	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: none;
}

button.button_passenger-3xtjr.button_disabled-3o8c4 {
	opacity: 0.7;
	cursor: not-allowed;
}

.traveller__controls-6JYWF {
	display: flex;
	align-items: center;
	min-height: 40px;
}

.rules__content-31Qc9 {
	max-width: 866px;
	margin: 0 auto;
	padding: 25px;

	font-size: 14px;
}

button.close-394iX {
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	margin-right: 12px;

	border-radius: 50%;

	color: var(--text-light);

	background: var(--bg-fill);
}

.refunded-3JjMu {
	color: var(--error-text);
	text-transform: lowercase;
}

@media (max-width: 1024px) {
	.inner-1m7tV {
		padding: 20px;
	}
}

@media screen and (max-width: 600px) {
	.inner-1m7tV {
		padding: 12px 12px 72px;
	}

	.notice-3q_mG {
		align-items: flex-start;
	}
}
