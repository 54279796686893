div.price-2A8Pr {
	display: flex;
	flex-direction: column;

	max-width: 1100px;
}

@media screen and (max-width: 600px) {
	div.price-2A8Pr {
		padding: 0;
	}
}

.total-14q4p {
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid var(--gray10);

	color: var(--gray80);
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
}

.totalTitle-bRzqy {
	margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
	.totalTitle-bRzqy {
		margin: 0;

		font-size: 20px;
	}
}

.money-11KkH {
	font-size: 24px;
	line-height: 38px;
}

.row-1D5Jh {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 8px;

	color: var(--gray70);
	font-size: 15px;
	line-height: 27px;
}
