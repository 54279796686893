

.wrapper-2T0Kw {
	display: flex;
	flex-direction: column;

	border-radius: 12px;

	box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
	background: var(--white);
}

.flightInfo-32KzI {
	position: relative;

	order: 1;
	padding: 10px 20px 20px;
}

.flightInfo__iatas-21UGw {
	color: var(--text-base);
	font-size: 44px;
	font-weight: 700;
	line-height: 1.3;
}

.flightInfo__weather-1b2E5,
.flightInfo__dates-3OCa3,
.arrivalInfo-QWbhr {
	display: none;
}

.flightInfo__cities-34ibi {
	margin-bottom: 0;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
	text-transform: uppercase;
	text-decoration: none;
}

.flightInfo__cities-34ibi > span {
	max-width: 36%;
	display: block;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.flightInfo__time-2og6u {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.fareGroup-CpWyH {
	grid-gap: 8px;
	gap: 8px;
	padding: 20px 20px 0;

	border-bottom: none;
}

.fareGroup__img-1SMJo {
	width: 28px;
	height: 28px;

	background-size: contain;
}

.fareGroup__info-v9dZV {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.planeInfo-jK951 {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 42px;
	height: 42px;
	margin: 0;

	color: var(--text-pale);

	transform: translate(-50%, -50%) rotate(180deg);
}

.planeInfo-jK951 > svg {
	width: 100%;
	height: 100%;
}

.planeInfo-jK951:before,
.planeInfo-jK951:after {
	display: none;
}
