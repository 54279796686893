.subscriptions-2o5_m {
	display: flex;
	flex-direction: column;
	grid-gap: 50px;
	gap: 50px;
}

@media screen and (max-width: 600px) {
	.subscriptions-2o5_m {
		grid-gap: 24px;
		gap: 24px;
	}
}
