.preferences-UbO2o {
	margin-top: 28px;
	padding: 20px;

	border-radius: 4px;

	background-color: #f9f9f9;
}

.title-18Ihf {
	margin: 0;

	font-size: 14px;
	color: var(--dark-gray);
}

.selects-2S8Sh {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

.selects-2S8Sh > div {
	flex: 1;
}

.selects-2S8Sh > div:first-child {
	margin-right: 10px;
}

.selects-2S8Sh > div:last-child {
	margin-left: 10px;
}

.selects-2S8Sh > div > div:after {
	border-bottom: 2px solid var(--common-blue);
}

.selects-2S8Sh .label-37KRH.focused-2BMjQ {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.selects-2S8Sh {
		flex-direction: column;
	}

	.selects-2S8Sh > div:first-child {
		margin-right: 0;
	}

	.selects-2S8Sh > div:last-child {
		margin-left: 0;
		margin-top: 10px;
	}
}
