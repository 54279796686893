

.header-1AxHn {
	display: flex;
	align-items: center;

	height: 56px;
	width: 100%;

	padding: 12px;

	box-shadow: var(--shdw-header);
}

.backBatton-bFAYj {
	display: flex;
	align-items: center;
	margin: 0 12px 2px 0;

	color: var(--text-light);

	cursor: pointer;
}

.backBatton-bFAYj:hover {
	color: var(--text-mid);
}

.title-2f2lc {
	font-size: 18px;
	font-weight: 700;

	color: var(--text-base);
}
