.wrapper-3YhRI,
.details-3i5Xk {
	border-radius: 4px;
}

.cards_header-35DWX {
	display: none;
}

.content-1miOD {
	justify-content: flex-start;
	padding-top: 10px;
}

.oneMethod-2G3en .content__title-2fcoK {
	font-size: 22px;
	line-height: 24px;
}

@media screen and (max-width: 600px) {
	.wrapper-3YhRI {
		padding: 12px;

		background: var(--white);
	}

	.content-1miOD {
		padding: 0;
	}

	.details-3i5Xk {
		order: 1;
		padding: 12px;

		border-radius: 4px;

		background: var(--bg-fill);
	}
}
