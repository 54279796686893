

.suggestions-3puC9 {
	display: flex;
	margin: 20px 0 20px;
	max-width: 1100px;
}

.container-2hjkp {
	display: flex;
	align-items: center;
	max-height: 37px;

	padding-left: 16px;

	border-radius: 18px;

	color: var(--white);

	cursor: pointer;
	background-color: var(--success);
}

.text-24HW0 {
	display: flex;
	align-items: center;
	padding: 10px 16px 10px 8px;

	font-size: 14px;
	color: #ffffff;
	text-decoration: underline;
}

.text-24HW0 > svg {
	margin-right: 8px;
}

.money-e_N7S {
	padding-left: 0;
}
