label.root-3tVy5 {
	padding: 12px 0;

	margin: 0;
}

label.root-3tVy5:not(:first-child) {
	border-top: 1px dashed var(--line-dot-line);
}

span.labelContainer-dKXAh {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.label-199gs {
	display: flex;
	justify-content: space-between;
	width: 100%;

	color: var(--text-mid);

	font: var(--medium--1-normal);
}

label:has(.checked-2sDWh) .label-199gs {
	color: var(--text-base);

	font: var(--medium--1-desktop-accent);
}

span.radio-1kyiH {
	padding: 9px 9px 9px 0;

	color: var(--line-dot-line);
}

span.radio-1kyiH.checked-2sDWh {
	color: var(--brand-1-0);
}

div.tooltip-2JFC9 {
	padding: 10px;

	border-radius: 2px;

	font: var(--small-desktop);

	color: var(--bg-white);

	background: var(--brand-1-0);
}

span.tooltip_arrow-1sc9r {
	left: 50% !important;

	color: var(--brand-1-0);
	font-size: 10px;

	transform: translateX(-50%) !important;
}

div.form-uQVxw {
	padding: 8px 20px;

	border-radius: 8px;

	background: var(--brand-1-5);
}

.form-uQVxw.form_error-1AGQ6:before {
	content: '';

	position: absolute;

	z-index: 1;

	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	border: var(--brand-1-0) 2px solid;
	border-radius: 8px;

	background: unset;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.label-199gs {
		flex-direction: column;
	}
}

.price-443iI {
	min-width: 150px;
	margin-left: auto;

	transform: translateY(-40%);
}

.singleTravellerInOrder-3CeRg .price-443iI {
	transform: none;
}

@media screen and (max-width: 600px) {
	.price-443iI {
		min-width: auto;
		margin-left: 0;

		transform: none;
	}

	.price-443iI .priceContent-IAnuX {
		flex-direction: row;
	}
}

/* stylelint-disable */
label.done-3f0jE {
}

.labelMute-31SoO {
}
