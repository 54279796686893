

.container-o4C8T {
	background: var(--white);
}

.icon-DDcM1 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: -12px;

	color: inherit;
}
