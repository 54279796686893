

.wrapper-2IMiG {
	width: 100%;
	max-width: 351px;
	margin: 0 auto;
}

.wrapper-2IMiG:not(:last-child) {
	padding-bottom: 16px;
}

.wrapper__icon-1zZwF {
	padding: 12px 12px 12px 40px;
}

.header-3bSHl {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 6px;

	font-size: 14px;
}

.header-3bSHl > svg {
	position: absolute;
	top: 0;
	left: -2px;

	max-height: 17px;

	color: var(--cart-icon-color);
}

.header__title-2msNI {
	flex: 1;
	display: flex;
	align-items: center;

	color: var(--text-base);
	font-weight: 700;
}

.unconfirmedServices-ZWPFa .header-3bSHl {
	min-height: 24px;

	padding-right: 32px;
}

.header__icon-1XvAD {
	position: absolute;
	top: 0;
	right: 0;

	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.unconfirmedServices-ZWPFa .header__icon-1XvAD {
	color: var(--error-icon);
}

.header-3bSHl > svg ~ .header__title-2msNI {
	padding-left: 28px;
}

.header__direction-2oMec {
	color: var(--text-base);
	font-weight: 700;
}

.notification-1JU0Z {
	margin: 16px 0 5px 0;

	color: var(--text-base);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.unconfirmedServices-ZWPFa .notification_unconfirmed-1NWE2 {
	color: var(--error-text);
}

.options-YMXXy {
	display: flex;
	flex-direction: column;
}

.option-2BVgz {
	position: relative;

	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.option-2BVgz:not(:last-child) {
	margin-bottom: 6px;
}

.option_requested-1Xgdo {
	opacity: 0.4;
}

.option_booked-FM7aa,
.option_included-3-0XV {
	justify-content: space-between;
}

.option__name-SjZ2l {
	max-width: 85%;

	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.option__description-cn8kF {
	margin-left: auto;
}

.option_rejected-1Vdcw .option__name-SjZ2l,
.option_canceled-29acG .option__name-SjZ2l,
.option_problematic-1p9HF .option__name-SjZ2l {
	color: var(--error-icon);
	font-weight: 700;
}

.option_icon-1MRlN .option__name-SjZ2l {
	padding-left: 28px;
}

.option__price-L2MSj {
	display: flex;
	align-items: center;
	margin-left: auto;

	color: var(--text-base);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.option__price_money-3iQFy {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 2px;
	gap: 2px;
}

.option__money-3fsFz {
	padding-left: 0;
}

.option_rejected-1Vdcw .option__money-3fsFz,
.option_canceled-29acG .option__money-3fsFz,
.option_problematic-1p9HF .option__money-3fsFz {
	display: none;
}

.option__price_icon-2hkLs {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--error-icon);

	cursor: pointer;
}

.option__price_icon-2hkLs > svg {
	width: 16px;
	height: 16px;
}

.option__icon-21Oyl {
	position: absolute;
	left: 4px;

	width: 20px;
	height: 20px;

	color: var(--cart-icon-color);
}

.option__icon-21Oyl svg {
	width: 20px;
	height: 20px;
}

.option__controls-2n9og {
	display: flex;
	align-items: center;
	grid-gap: 6px;
	gap: 6px;
}

.option__download-iFPOk,
.option__iconIncluded-1usQg {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;

	color: var(--text-pale);
}

.option__download-iFPOk > svg,
.option__iconIncluded-1usQg > svg {
	width: 100%;
	height: 100%;
}

.unconfirmedServices-ZWPFa .header-3bSHl > svg {
	top: 2px;
}
