

.container-33lsG {
	padding: 20px;

	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.wrapper-2K20v {
	max-width: 1100px;
	margin: 0 auto;
}

.item-23U1m {
	display: inline-flex;
	margin-right: 8px;
	padding: 10px;

	border-radius: 2px;

	color: #ffffff;
	font-weight: 700;
	font-size: 15px;

	cursor: pointer;
	background: rgba(255, 255, 255, 0.2);
}
