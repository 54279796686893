div.wrapper-2933h {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 0;

	display: flex;
	align-items: center;

	width: 100%;
	padding-left: 16px;
	padding-right: 44px;

	color: var(--white);
	line-height: var(--input-height);
	text-overflow: ellipsis;
	white-space: nowrap;

	cursor: text;

	pointer-events: none;

	overflow: hidden;
}

.wrapper_focused-2H78q {
	background: var(--white);
}

.wrapper_padded--9DkZ {
	padding-right: 42px;
}

@media screen and (max-width: 600px) {
	.wrapper-2933h {
		padding-left: 44px;
	}

	div.wrapper_alternative-I6jju {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
		padding: 0;

		line-height: normal;
	}

	.value-3Nki8 {
		margin-top: 4px;
	}

	.wrapper_alternative-I6jju .code-h9jh3 {
		margin: 0;

		font-size: 20px;
		font-weight: 600;
		line-height: 1.3;

		opacity: 1;
	}

	.wrapper_alternative-I6jju .value-3Nki8 {
		max-width: 90%;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		font-size: 12px;
		font-weight: 400;
	}

	.withMultiCity-3XBMv.wrapper_alternative-I6jju .code-h9jh3 {
		font-size: 16px;
	}
}

.placeholder-2aVq- {
	font-size: 15px;
	font-weight: 500;
	color: var(--light-gray);
}

.loader-cy31M {
	position: absolute;
	right: 18px;
	top: 50%;

	display: flex;
	margin-top: -10px;
	width: 20px;
	height: 20px;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJcSURBVHgBrVRdq2FhFF627RyzxcShOeUjQxlx4STJpXLnZ/glfoLiQrhyJ7kWpdy50wxmGDTKoZAZoTG+m7XeOJ05n47mqdfeve9ej7We91lLBE8gGAzyMplMv1qtrpbLpWK32wnb7RZwTTebzR+RSDQIh8O9p2JFDzdCodAHDLxZr9cSJAIkhftPIqbngfh7IpHoPUsYiUTsGPCRAtRqNRgMBjCZTCCXy9n5cDiEfr8PhUIBBoMBI0Y0k8lk8xFhLBaz4AcWenc4HGC1WuElFItFyGazsFgsAKv5kU6nv9G+mH7i8bgOH/aLiwvw+Xyg1+vhNRiNRrDZbFAqlah8JSYxrdVqvzk6lEgkn3ieB7vdDkqlEk6FVqsFv9/PSsdMb7xeLy/OZDK6/X6vFwQBXC4XvBVmsxmq1SqMRiOxVCpdcVj/NWX3mmYvwel0sttHi11xqJtA2ikUCjgXbrebLoZI3/OoH2M6WuMckMUoQ8Q7XiwWw//AwZPAoX4L0pBSPhetVuvYSVPScEYazmYzOBedTueuHTms/RdlSI4/F9gYjBDXgEMj36KOG0p5Pp/DW5FKpVh/o5cXlUqlx6GZN5jhF8pyMpnciXsKGo0GRKNRpj8uNiBY66GPBkjUoffxeHxS+ThhIBAIsCQQzXa7zcbYP+OrXq/TtLGQlS4vL4HakXC0FpWWz+chl8tBuVyGw3edbrf79cjxaMCiBXQ4OC1oeIECSApygUqlYn9Ce7TwnKT6jD08vB8veq6kXq+n4zjuGl8F6iaNRkPkC7IZXsBPj8dzizNx+zDuL+21NUIz0btFAAAAAElFTkSuQmCC) center no-repeat;

	animation: rotation-23fJa 0.9s infinite linear;

	pointer-events: none;
}

.value-3Nki8 {
	flex-wrap: nowrap;
	max-width: 80%;

	color: var(--text-mid);
	font-weight: 500;

	font-size: 16px;
	line-height: 1.3;

	text-transform: capitalize;

	text-overflow: ellipsis;

	overflow: hidden;
}

.code-h9jh3 {
	margin-left: 6px;

	color: var(--text-base);
	font-weight: 500;

	font-size: 16px;
	line-height: 1.3;

	text-transform: capitalize;
}

.advanced-2scjT .code-h9jh3 {
	position: absolute;
	right: 12px;

	font-weight: 500;

	opacity: 1;
}

@keyframes rotation-23fJa {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
