.controls-2b-jA {
	display: flex;
	flex-basis: 30%;
}

@media (max-width: 1024px) {
	.controls-2b-jA {
		position: absolute;
		top: 52px;
		right: 0;

		width: 50%;
	}

	.controls_advanced-2FZhd.controls-2b-jA {
		position: relative;

		align-items: flex-start;
		flex-direction: column;
	}

	.controls_advanced-2FZhd.controls-2b-jA > div {
		width: 100%;
	}

	.controls_advanced-2FZhd.controls-2b-jA > div:not(:last-child) {
		margin-bottom: 12px;
	}
}

@media screen and (max-width: 600px) {
	.controls-2b-jA {
		position: static;

		flex-direction: column;

		flex-basis: 100%;
		width: 100%;
		padding-right: 0;
	}

	.controls-2b-jA.controls_advanced-2FZhd {
		margin-top: 12px;
	}

	.controls-2b-jA.controls_withCurrency-3KMFu {
		flex-direction: column;
	}
}

.controls_multiCity-Mso1z {
	margin-top: 20px;
	flex-basis: 100%;
	justify-content: flex-end;
}

.controls_advanced-2FZhd {
	justify-content: space-between;
	align-items: flex-end;
}

@media (min-width: 1024px) {
	.controls_multiCity-Mso1z {
		justify-content: space-between;
	}
}

@media (max-width: 1024px) {
	.controls_multiCity-Mso1z {
		top: auto;
		bottom: 0;

		margin-top: 0;
		padding-right: 0;
	}
}
