.content-iQ-qj {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title-3QXgQ {
	text-align: center;
}

.text-3EKAz {
	display: flex;
	margin-bottom: 20px;

	font: var(--medium-desktop);
	text-align: center;

	color: var(--text-base);
}

.text-3EKAz.linked-e0u5q {
	flex-direction: column;
	align-items: center;

	font-size: 16px;
	font-weight: 400;
}

.text-3EKAz.linked-e0u5q ul {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding-left: 0;
}

.text-3EKAz.linked-e0u5q li {
	text-transform: capitalize;
}

@media screen and (max-width: 600px) {
	.title-3QXgQ {
		padding-top: 16px;
	}
}
