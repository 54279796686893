.bottom-3iOMm {
	margin-top: 15px;
	justify-content: space-between;
	align-items: center;
}

.promoCode__wrp-1U1PG {
	display: flex;
}

.controls-7sh7n {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.controls-7sh7n > div:not(:last-child) {
	position: relative;

	margin-right: 26px;
}

.controls-7sh7n > div:not(:last-child):after {
	position: absolute;
	top: 0;
	right: -13px;

	height: 100%;

	border-right: 1px solid var(--brand-1-2);
	content: '';
}

@media screen and (max-width: 600px) {
	.bottom-3iOMm {
		position: relative;
	}

	.controls-7sh7n {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.controls-7sh7n > div {
		width: 100%;
	}

	.controls-7sh7n > div:not(:last-child) {
		margin-bottom: 12px;
		margin-right: 0;
		padding-bottom: 12px;

		border-bottom: 1px solid var(--brand-1-2);
	}

	.controls-7sh7n > div:not(:last-child):after {
		display: none;
	}

	.bottom-3iOMm > div:not(:last-of-type) {
		margin-bottom: 12px;
		padding-bottom: 12px;

		border-bottom: 1px solid var(--brand-1-2);
	}
}
