

.container-1-Y2b {
	max-width: 1100px;
	margin: 0 auto;
}

div.container-1-Y2b.context-1DxWc:not(.info-1fR68, .warning-1memu) {
	border-color: var(--success-stroke-hover);

	background-color: var(--success-bg);
}

.container-1-Y2b:not(.info-1fR68, .warning-1memu) .icon-aah68 {
	color: var(--success);
}

.container-1-Y2b .title-mTqq0 {
	margin-bottom: 0;

	color: var(--text-base);
	font-weight: 700;

	font-size: 16px;
	line-height: 1.3;
}

.container-1-Y2b .text-1mXKp {
	color: var(--text-mid);

	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.container-1-Y2b.warning-1memu .title-mTqq0 {
	color: var(--error-text);
}

.container-1-Y2b button.action-1k12l {
	height: 36px;

	padding: 0 16px;

	border: none;

	color: var(--white);

	background-color: var(--brand-1-0);
}

.container-1-Y2b button.action-1k12l:hover {
	background-color: var(--brand-1--1);
}

.container-1-Y2b button.action-1k12l svg {
	display: none;
}
