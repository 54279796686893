

button.wrapper-2gbi2 {
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-width: 260px;
	padding: 16px 20px;

	border-left: 1px solid #d9d9d9;
}

button.wrapper_arrowed-eUNez {
	position: relative;

	border-left: none;

	transition: background-color 0.15s;
}

.wrapper_arrowed-eUNez:before {
	content: '';

	position: absolute;
	left: 0;

	width: 1px;
	height: var(--results-flight-price-separator-height);

	background-color: var(--results-flight-price-separator-background);
}

.wrapper_arrowed-eUNez:after {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 4px;

	opacity: 0;
	background-color: var(--results-flight-price-underline-background);

	transition: opacity 0.15s;

	pointer-events: none;
	content: '';
}

.wrapper_arrowed-eUNez.wrapper_business-2jC5M:after {
	background-color: var(--results-flight-price-business-underline-background);
}

.wrapper_active-Y43AD.wrapper_arrowed-eUNez:after,
.wrapper_arrowed-eUNez:hover:after {
	opacity: 1;
}

button.wrapper_economy-R-oaS {
	color: var(--results-flight-price-economy-group);

	background: var(--results-flight-price-economy-group-color);
}

button.wrapper_business-2jC5M {
	color: var(--results-flight-price-business-group);

	background: var(--results-flight-price-business-group-color);
}

button.wrapper_economy-R-oaS.wrapper_active-Y43AD {
	background: var(--results-flight-price-economy-group-opened);
}

button.wrapper_business-2jC5M.wrapper_active-Y43AD {
	color: var(--results-flight-price-business-group-color-opened);

	background: var(--results-flight-price-business-group-opened);
}

button.wrapper_flat-1MKtI {
	border-left: none;

	color: #ffffff;
}

.wrapper_flat-1MKtI.wrapper_economy-R-oaS {
	background-color: var(--common-blue);
}

.wrapper_flat-1MKtI.wrapper_business-2jC5M {
	background-color: var(--dark-violet);
}

.wrapper_arrowed-eUNez.wrapper_economy-R-oaS:hover {
	background-color: rgba(50, 144, 255, 0.1);
}

.wrapper_arrowed-eUNez.wrapper_business-2jC5M:hover {
	background-color: rgba(80, 109, 210, 0.1);
}

button.wrapper_singleFare-C53Qx {
	min-width: 210px;
	max-width: 210px;
}

.titles-hd1FW {
	display: flex;
	justify-content: center;
	flex-direction: column;

	text-align: left;
}

.title-1ReIF {
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	text-transform: capitalize;
}

.title_singleFare-Sgk3P {
	display: none;
}

.prefix-CA0Sw {
	margin-top: 8px;

	color: var(--results-flight-price-prefix);
	font-size: 14px;
	line-height: 16px;

	opacity: 0.7;
}

.prefix_singleFare-2GMLF {
	margin-top: 0;
}

.prefix_noSeats-1envR {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
	color: inherit;
}

.wrapper_flat-1MKtI .prefix-CA0Sw {
	color: rgba(255, 255, 255, 0.7);
}

.price-3WMN7 {
	position: relative;
	top: 5px;

	display: flex;
	align-items: center;

	align-self: flex-end;

	font-weight: 600;
	font-size: 34px;
	white-space: nowrap;
}

.price_singleFare-2zKOy {
	position: static;

	align-self: center;
}

.price__icon-3rUxb {
	display: flex;
	align-items: center;
	margin-left: 10px;

	color: #ffffff;

	transition: transform 0.15s;
}

.wrapper_active-Y43AD .price__icon-3rUxb {
	transform: rotate(-180deg);
}

.price_arrowed-13Lej {
	font-size: 32px;
}

.price_RUB-2aH-O {
	font-size: 25px;
}

button.wrapper_disabled-13lmA {
	cursor: not-allowed;
}

button.wrapper_arrowed-eUNez.wrapper_disabled-13lmA:hover {
	background-color: inherit;
}

button.wrapper_disabled-13lmA:after {
	display: none;
}

button.wrapper_disabled-13lmA .titles-hd1FW {
	opacity: 0.4;
}
