

.status-3GdlC {
	margin-left: 4px;

	text-transform: lowercase;
}

.status__solved-iJhxC {
	color: var(--text-light);
}

.status__new-2_nyO {
	color: var(--brand-1-0);
}

.status__progress-3cIOi {
	color: var(--seat-choosed-hover);
}

.status__declined-3uBAv {
	color: var(--text-base);
}

.status__awaiting-2zMoc {
	color: var(--brand-3-0);
}
