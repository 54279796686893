.container-Vb25i {
	display: flex;
	flex-direction: column;
	padding: 12px;

	border: 1px solid var(--warning-icon);
	border-radius: 4px;

	background: var(--white);
}

.switch__wrapper-16cQ0 {
	display: flex;
	align-items: center;
}

.switch__icon-1_XMO {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	min-width: 20px;
	height: 20px;
	margin-right: 8px;
}

.switch__control-1HPO5 {
	flex: 1;
	margin: 0;
}

.switch__control-1HPO5:before {
	opacity: 1;
}

.switch__control-1HPO5:after {
	background: var(--line-separator);
}

.switch__control-1HPO5.switch__active-WKVNL:after {
	background: var(--brand-1-1);
}

.switch__label-2o1NQ {
	color: var(--brand-1-0);

	opacity: 1;
}

.description-1ZMnX {
	margin-top: 16px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

.switch__infoIcon-1lywZ {
	display: inline-flex;
	justify-content: center;
	align-content: center;
	width: 16px;
	min-width: 16px;
	height: 16px;
	margin-left: 2px;

	color: var(--text-light);

	transform: translateY(4px);
	cursor: pointer;
}

.switch__infoIcon-1lywZ svg {
	width: 100%;
	height: 100%;
}

@media (max-width: 1024px) {
	.switch__control-1HPO5 {
		padding-left: 0;
	}
}
