

div.segment-RoaMo {
	padding: 0 0 0 36px;
	min-height: 75px;
}

.multiple-t9VYx {
	min-height: 60px;
}

.flight_number-1tYbG {
	display: flex;
	align-self: stretch;

	font-size: 15px;
	text-transform: uppercase;
}

.flight_number__inner-2-rqy {
	display: flex;
	align-items: center;

	color: var(--gray90);
	font-weight: 500;
	font-size: 15px;
}

div.amenities-3BIat {
	justify-content: center;
	padding-right: 34px;
	padding-left: 19px;

	border: 1px dashed #cccccc;
	border-top: none;
	border-bottom: none;
}

.amenities-3BIat > div {
	margin-bottom: 3px;

	color: var(--gray90);
	font-weight: 500;
}

div.plane-3Jnl0 {
	display: none;
}

div.time-3QXIc {
	color: var(--gray90) !important;
	line-height: 25px;
	font-size: 25px;
}

.duration-1RyRl,
div.row_last-1uf0- .duration-1RyRl,
div.row_first-2qnjS .duration-1RyRl {
	color: var(--gray80);
	font-weight: 500;
}

.city-1vVpM,
.route_flight-Rb2C9 {
	display: none;
}

div.stop-2hrdb,
div.total_duration-3Xg0k {
	bottom: 8px;
}
