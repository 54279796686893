.wrapper-2CxOq {
	display: flex;
	align-items: center;
	padding-left: 20px;

	background-color: var(--white);
}

.info-uh-0R {
	margin-right: 16px;
}

.info-uh-0R,
.airport-zVpbt {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 40px 0;
}

.time-3mJxm,
.city-ofywI {
	margin-bottom: 4px;

	color: var(--gray90);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.time_iata-2PyyF {
	display: none;
}

.date-3KO1x {
	color: var(--gray50);
	font-size: 14px;
	line-height: 1.3;
	white-space: nowrap;
}

.date_icon-nJXpi {
	display: none;
}

.airport-zVpbt {
	flex: 1;

	color: var(--base);
}

.terminal-dBe0Q {
	line-height: 1.3;
	word-break: break-word;
}

.terminal_city-1KRp- {
	display: none;
}

.terminal_city-1KRp-:not(:last-child):after {
	content: ', ';
}

.terminal_iata-2fTyV {
	margin-right: 4px;

	text-transform: uppercase;
}

.terminal_icon-2V-Ub {
	display: flex;
	align-items: center;
	margin-left: 4px;

	color: #b3b3b3;
}

.terminal_airport-39ihL.terminal_airportTerminal-1N6wT:after {
	content: ', ';
}

.terminal_number-1nsMN {
	white-space: nowrap;
}

.weather-3s4Ma {
	position: relative;

	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	max-width: 40%;
	height: 100%;

	background-position: center;
	background-size: cover;
}

.weather-3s4Ma.weather_gradient-12NRM:before {
	content: '';

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: linear-gradient(0deg, rgba(0, 0, 0, 0.62) 0%, rgba(0, 0, 0, 0) 75%);
	opacity: 0.8;
}

.weather_wrapper-1X3N- {
	position: relative;

	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 10px;

	color: var(--white);
}

.weather_wrapper-1X3N- > svg {
	width: 44px;
	height: 44px;
	margin-right: 6px;
}

.weather_temp-1SJ5A {
	font-size: 31px;
	font-weight: 400;
	line-height: 1.3;
}

.wrapper-2CxOq.isMobile-2SU6g {
	flex-direction: column;
	align-items: flex-start;
	padding-left: 0;
}

.isMobile-2SU6g .date-3KO1x {
	display: flex;
	align-items: center;
	margin-bottom: 14px;
}

.isMobile-2SU6g .date_icon-nJXpi {
	display: flex;
	margin-right: 10px;
}

.isMobile-2SU6g .time-3mJxm {
	order: 1;
	margin-bottom: 0;
}

.isMobile-2SU6g .time_iata-2PyyF {
	display: inline-block;
	margin-left: 8px;

	color: var(--gray50);
	font-weight: 400;
}

.isMobile-2SU6g .info-uh-0R {
	padding: 20px;
}

.isMobile-2SU6g .airport-zVpbt {
	padding: 0 20px 20px;
}

.isMobile-2SU6g .info-uh-0R,
.isMobile-2SU6g .airport-zVpbt {
	margin: 0;
}

.isMobile-2SU6g .city-ofywI {
	display: none;
}

.isMobile-2SU6g .terminal_city-1KRp- {
	display: inline;
}

.isMobile-2SU6g .weather-3s4Ma {
	width: 100%;
	max-width: 100%;
	height: 170px;
	min-height: 170px;
	margin-top: auto;
	margin-left: 0;
}

@media (max-width: 768px) {
	.isMobile-2SU6g .weather-3s4Ma {
		width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
	}
}
