div.order_card-Uhq6d {
	position: relative;

	min-height: 150px;
	margin-bottom: 12px;

	color: #ffffff;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.order_card-Uhq6d .persons-cHFYj {
	padding: 15px 20px;

	font-size: 15px;
	font-weight: 500;
	color: #313131;
}

.persons-cHFYj p {
	display: flex;
	align-items: center;
	margin: 0;

	text-transform: capitalize;
}

.persons-cHFYj svg {
	min-width: 24px;
	margin-right: 12px;
}

@media (max-width: 1024px) {
	div.order_card-Uhq6d {
		position: relative;
	}
}

@media screen and (max-width: 600px) {
	.order_card-Uhq6d .persons-cHFYj {
		padding: 16px 12px;

		font-size: 14px;
	}
}
