.wrapper-1XvB4 {
	position: relative;

	background: var(--white);
}

.header-21RPL {
	border-bottom: 1px dashed var(--line-dot-line);
}

.content-3XQrC {
	padding: 12px 12px 0;
}

.services-10Ir2 {
	display: flex;
	flex-wrap: wrap;
}

.services__item-2fG51 {
	flex: 1;
	flex-basis: 32%;
	margin: 4px;
}

.barcode-1Dscs {
	display: none;
}

div.footer-2G_29 {
	padding: 12px 20px 20px;
}

.cancelModal-39oUk {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.services-10Ir2 {
		display: none;
	}

	.content-3XQrC {
		margin: 0 20px;
		padding: 0;
	}

	.barcode-1Dscs {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		grid-gap: 6px;
		gap: 6px;
		padding: 20px 0;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.barcode-1Dscs svg {
		width: 120px;
		height: 120px;
	}

	.barcode__direction-2RqHg {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		grid-gap: 6px;
		gap: 6px;

		font: var(--normal-mobile-bold);
		color: var(--text-base);
	}

	div.footer-2G_29 {
		padding: 16px 20px 20px;
	}
}
