.form-1YAWA {
	padding: 8px 0;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	box-sizing: border-box;
}

div.codeInput-13DV6 {
	margin-bottom: 12px;
}

button.button-46FJB {
	margin-right: 12px;
}

.title-bn3Zw {
	margin-bottom: 20px;

	color: var(--fg-default);
	font: var(--medium-1-desktop-bold);
}

div.button-46FJB {
	margin-right: 12px;

	background: var(--gray50);
}

/* stylelint-disable-next-line block-no-empty */
button.button_applied-2-qr5 {
}

button.button_notApplied-1QFJ5 {
	background: var(--active30);
}

.button_notApplied-1QFJ5 svg {
	position: relative;
	top: -1px;
}

/* stylelint-disable-next-line block-no-empty */
button.button_empty-3PJKh {
}

.statusMessage-23Hdf {
	display: flex;
	align-items: center;
}

.statusMessage_applied-3AuQv {
	color: var(--gray50);
}

.statusMessage_notApplied-e2bwz {
	color: var(--active);
}

.form_additionalFields-LUbGm {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

/* stylelint-disable-next-line block-no-empty */
.statusText-2Qidu {
}

@media screen and (max-width: 600px) {
	.form-1YAWA {
		padding: 0;
	}
}
