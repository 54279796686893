.container-2iHVW {
	width: 100%;
	height: 30px;
	overflow: hidden;
}

.list-2rzgX {
	display: flex;
	flex-direction: column;

	transition: transform 0.3s;
}

.item-1VpGY {
	height: 30px;
	min-height: 30px;
}

.item_gone-BrfOb {
	opacity: 0;

	transition: opacity 0.3s;
}

@media screen and (max-width: 600px) {
	.item-1VpGY {
		min-height: 50px;
	}

	.container-2iHVW,
	.item-1VpGY {
		height: 50px;
	}
}
