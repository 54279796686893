.container-nL4zn {
	position: relative;

	display: flex;
	flex-direction: column;
}

.header-Xzarx {
	display: flex;

	grid-gap: 20px;

	gap: 20px;
	align-items: center;

	margin-bottom: 12px;
	padding: 8px 12px;

	border-radius: 4px 4px 0 0;

	background: var(--brand-1-5);
}

.info-RO1VN {
	flex: 0 1 50%;
	display: flex;
	align-items: center;
}

.date-1wCj1 {
	color: var(--text-mid);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.direction-1p1UP {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	padding-left: 20px;

	border-left: 1px solid var(--line-separator);

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.type-3iwDH {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.prices-12MGO {
	flex: 1;
	display: flex;
	align-items: center;
	grid-gap: 14px;
	gap: 14px;
}

.price-T7GTg {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.money-dglnR {
	padding-left: 0;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
}

.passengers-3Ekwz {
	display: flex;
	flex-direction: column;
}

.passenger-1uzZl:not(:last-child) {
	margin-bottom: 12px;
	padding-bottom: 12px;

	border-bottom: 1px dashed var(--line-separator);
}

.number-254KO {
	display: flex;
	justify-content: center;
	align-items: center;

	min-width: 24px;
	min-height: 24px;

	margin-right: 12px;

	border-radius: 50%;

	color: var(--text-light);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.2;

	background: var(--bg-fill);
}

.label-1pIHJ {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.label-1pIHJ span:before {
	content: ', ';
}

@media (max-width: 1024px) {
	.info-RO1VN {
		flex: 0 1 35%;
		min-width: 35%;

		flex-direction: column;
		align-items: flex-start;
	}

	.direction-1p1UP {
		margin-left: 0;
		padding-left: 0;

		border: none;
	}
}

@media screen and (max-width: 600px) {
	.header-Xzarx {
		grid-gap: 10px;
		gap: 10px;
	}

	.info-RO1VN {
		flex: 0 1 40%;
		min-width: 40%;
	}

	.prices-12MGO {
		grid-gap: 8px;
		gap: 8px;
	}
}
