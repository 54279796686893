

.serviceWrapper-bdJnN {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	padding: 20px;
	box-sizing: border-box;

	background: var(--white);
}

.button-VxBVr {
	padding: 15px;

	border-radius: 5px;
	border: none;

	color: var(--white);

	background: var(--bright-blue);

	cursor: pointer;
}
