.input-1i4C5 {

	display: none;

	cursor: pointer;
}

.input-1i4C5:hover {
	cursor: pointer;
}

.input_focused-3yO1w {
}

.date__input-3msBn {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
	padding-left: 44px;

	border: none;

	font-weight: 500;
	font-size: 15px;

	background: none;
}

.date__input-3msBn.date__input_back-1BUMB {
	padding-left: 12px;
}

.datepicker-nV1j9 {
	display: flex;
	width: 100%;
}

.highlightedDate-dyt5I > div {
	width: 36px;
	margin: 0 auto;

	border-radius: 50%;

	color: var(--dark-gray);
	font-weight: 600;
}

.highlightedDate_lite-1LyY9 > div {
	color: #3e4a73;
	font-weight: 500;
}

.highlightedDate_withPrice-2N-pc > div {
	display: flex;
	flex-direction: column;

	line-height: 15px;
	color: var(--dark-gray);
	font-weight: 600;
}

.highlighted_minPrice-oQ19w {
	background: var(--datepicker-day-min-price-bg);
}

.highlighted_minPrice-oQ19w .day__price-dq_wd {
	color: var(--datepicker-day-min-price-color);
}

.highlightedDate-dyt5I > div:hover,
.highlightedDate_lite-1LyY9 > div:hover {
	border: 1px solid var(--datepicker-day-selected-background);
	border-radius: 50%;
}

.highlightedDate_withPrice-2N-pc {
	background: #ffffff;
}

.dates-3pX9V {
	position: absolute;
	top: 0;
	bottom: 0;

	display: flex;
	width: 100%;
	height: var(--input-height);

	padding: 0;

	color: #ffffff;
	font-weight: 500;
	font-size: 15px;
	line-height: var(--input-height);

	cursor: pointer;

	background: var(--white);

	transition: background-color 0.15s, color 0.15s;
}

.dates-3pX9V:not(.dates_focused-393gm):hover {
	background: var(--white);
}

.dates_focused-393gm {
	color: var(--datepicker-directions-default-color);

	background: var(--datepicker-directions-background);
}

/* stylelint-disable */
.dates__to_text-VOx0n {
}

/* stylelint-enable */
.date__inputWrapper-WFS4C {
	display: flex;
	align-items: center;
}

.dates__to-2DMYp,
.dates__back-SFEN7 {
	white-space: nowrap;
	overflow: hidden;

	color: var(--text-base);

	text-overflow: ellipsis;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.dates__to-2DMYp {
	position: relative;

	display: flex;
	align-items: center;

	flex-grow: 1;
	width: 50%;
	padding-left: 16px;

	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.dates__to-2DMYp .dates__icon-1haKW {
	display: flex;
	margin-right: 8px;
	margin-top: -1px;

	color: var(--text-pale);
}

.dates_focused-393gm .dates__to-2DMYp:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;

	height: 24px;
	margin: auto;

	border-left: 1px solid #cdcdcd;
	content: '';
}

.oneDate-iZ-LF .dates__to-2DMYp {
	width: 100%;

	border-radius: 2px;
}

.dates__back-SFEN7 {
	position: relative;

	flex-grow: 1;
	width: 50%;
	padding-right: 16px;
	padding-left: 12px;

	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.dates__to_focused-eYbg2,
.dates__back_focused-JPUjF {
	color: var(--datepicker-current-selected-date-color);
}

.dates__back-SFEN7:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;

	height: 24px;
	margin: auto;

	border-left: 1px solid var(--line-separator);

	content: '';
}

@media (max-width: 1024px) and (min-width: 601px) {
	.dates-3pX9V {
		border-radius: 4px 0 0 4px;
	}
}

.dates__current-3Cytk {
	color: var(--datepicker-directions-current-direction-color);

	background: #ffffff;
}

.dow-3Fn-P {
	opacity: 0.7;
}

.dow-3Fn-P:before {
	content: ', ';
}

.returnDelete-2auxV {
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;

	display: block;
	width: 20px;
	height: 20px;
	margin: auto;

	color: #ffffff;

	cursor: pointer;
}

.returnDelete-2auxV svg {
	display: block;
	width: 20px;
	height: 20px;
}

.returnDelete-2auxV:hover {
	opacity: 0.6;
}

.dates_focused-393gm .returnDelete-2auxV {
	color: #cdcdcd;
}

.dates_focused-393gm .returnDelete-2auxV:hover {
	color: #777777;
}

.placeholder-3gtgX {
	display: flex;
	align-items: center;

	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.alternative-qfbiZ.dates-3pX9V {
		height: 100%;

		line-height: normal;
	}

	.alternative-qfbiZ .dates__to-2DMYp {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0;

		font-weight: 600;
		font-size: 17px;
		text-align: center;
	}

	.alternative-qfbiZ .dow-3Fn-P {
		display: none;
	}

	.alternative_dow-M5rWe {
		position: relative;

		font-weight: 400;
		font-size: 12px;

		opacity: 0.7;
	}

	.alternative-qfbiZ.oneDate-iZ-LF .placeholder-3gtgX {
		display: none;
	}

	.dates__to-2DMYp .dates__icon-1haKW {
		margin-right: 0;

		color: var(--text-light);
	}
}
