

button.button-3NiXP {
	border-radius: 4px;

	font-weight: 700;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	color: var(--bg-white);

	background: var(--brand-3-0);
}

button.button-3NiXP:hover {
	color: var(--bg-white);

	background: var(--brand-3-1);
	box-shadow: none;
}

button.button-3NiXP:disabled {
	color: var(--bg-white);

	background: var(--brand-3-3);
}
