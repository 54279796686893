.sidebar-2XdJx {
	max-width: 280px;
	min-width: 280px;
	height: -moz-fit-content;
	height: fit-content;
	padding: 20px;

	border-radius: 4px;

	background-color: var(--account-sidebar-background);
}

.sidebar-2XdJx.root-1ELPi {
	padding: 20px;
}

.sidebar-2XdJx .sidebar__item-2NyEG {
	padding: 12px 16px 12px 16px;

	border-radius: 4px;
}

.sidebar-2XdJx .sidebar__item-2NyEG:hover,
.sidebar-2XdJx div.sidebar__item_selected-3Y9rG,
.sidebar-2XdJx div.sidebar__item_selected-3Y9rG:hover {
	background-color: var(--brand-1-1);
}

.sidebar-2XdJx .sidebar__item-2NyEG span {
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	font-family: inherit;
}

.title-3NaiI {
	display: none;
	margin-bottom: 10px;

	color: var(--bg-white);
	font-weight: 500;
	font-size: 18px;
	line-height: 1;
}

.sidebar-2XdJx .sidebar__item-2NyEG .icon-1HIjf {
	margin-right: 0;
	min-width: 42px;

	font-size: 24px;
	color: var(--brand-1-3);
}

.sidebar__item-2NyEG .item__text-31vdC {
	padding: 0 22px 0 0;

	color: var(--bg-white);
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
}

.rotate90-2KhGc {
	transform: rotate(90deg);
}

a {
	text-decoration: none;
}

.svg-26VNZ {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	min-width: 24px;
	height: 24px;
}

.sidebar__item-2NyEG span.badge-3bA5l {
	padding-right: 6px;
	padding-left: 6px;

	color: var(--brand-1-0);

	background-color: var(--bg-white);
}

@media (max-width: 1024px) {
	ul.sidebar-2XdJx {
		padding-top: 0;
		padding-bottom: 0;
		min-width: 75vw;
		max-width: unset;
	}

	.sidebar-2XdJx .sidebar__item-2NyEG {
		padding: 30px 22px;
	}
}
