.exchange-3_HZG {
	height: 100%;

	background: var(--checkout-background);
}

.close-Rnmde {
	grid-gap: 8px;
	gap: 8px;
	margin-left: auto;
}

.close__text-OF3zk {
	color: var(--brand-1--1);
	font: var(--small-desktop-Caps);
	white-space: nowrap;
}

.header-1Y_FT {
	position: relative;

	display: flex;
	padding: 40px 0 30px;

	font-weight: 600;
	font-size: 34px;

	background: #ffffff;
}

.header__inner-34f2O {
	max-width: 1100px;
	width: 1100px;
	margin: 0 auto;
	display: flex;
	align-items: center;
}

.header__text-nB5DL {
	margin-right: 32px;

	color: var(--gray50);
}

/* stylelint-disable */
.passengerPage-1zsw8 {
}

.flightPage-13mbX {
}

.dialog__paymentIcon-3Jbtz {
}

.dialog__failedIcon-1RNr4 {
}

/* stylelint-enable */
.container-t5ARc {
	position: relative;

	max-width: 1100px;
	margin: 0 auto;
}

.services-2hu-E {
	margin-bottom: 10px;
}

@media (max-width: 1024px) {
	.container-t5ARc {
		width: 100%;
		padding: 20px;
		padding-top: 0;
	}

	.header-1Y_FT {
		padding: 20px;

		font-size: 21px;

		background: none;
	}

	.header__text-nB5DL {
		margin-bottom: 24px;
	}

	.header__inner-34f2O {
		flex-direction: column;
		align-items: unset;
	}

	.close__text-OF3zk {
		display: none;
	}

	.closeIcon-1YILy {
		position: absolute;
		top: 28px;
		right: 28px;
	}
}
