

.header-3n6wr {
	display: flex;

	cursor: pointer;
}

.header__cell-2yzx4 {
	padding: 18px 0 18px 20px;

	display: flex;
	align-items: center;

	flex-basis: 33%;
	flex-grow: 1;

	line-height: 28px;
}

.header__cell-2yzx4:nth-child(1) {
	flex-basis: 364px;
}

.header__cell-2yzx4.header__cell_short-WkNVh {
	padding-right: 20px;
	overflow: hidden;
}

.header__cell-2yzx4:nth-child(2) {
	flex-basis: 432px;
}

div.header__cell-2yzx4:nth-child(2) {
	border-right: none;
}

.header__cell-2yzx4:nth-child(3) {
	flex-basis: 301px;

	color: #ffffff;

	background: #3290ff;
}

.header__cell_wide-15zpx:nth-child(3) {
	flex-basis: 33%;
}

.header__cell-2yzx4:last-of-type {
	position: relative;

	justify-content: space-between;
	padding-right: 40px;
}

.header__cell-2yzx4:not(:last-of-type) {
	border-right: 1px dashed #cdcdcd;
}

.header__icon-cu-Ad {
	margin-right: 14px;
	display: flex;
	align-items: center;

	transform: rotate(180deg);
}

.header__icon_reverse-mL2rW {
	transform: rotate(0deg);
}

.title-xZ_bn {
	font-size: 20px;
	font-weight: 600;
}

.header__cell_short-WkNVh .title-xZ_bn {
	max-width: 100%;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.travelTime-xT7jv {
	margin-left: 12px;
	margin-right: 12px;

	color: #a9a9a9;
	font-size: 20px;
	font-weight: 600;
}

.price-2QgId {
	font-size: 22px;
	font-weight: 600;
}

.arrowIcon-1s9bv {
	position: absolute;
	right: 20px;

	color: #ffffff;

	transition: transform 0.15s;
}

.arrowIcon_flipped-SU9qY {
	transform: rotate(-180deg);
}

.fareGroup-1K9XF {
	display: flex;
	align-items: center;
}

.fareGroup__logo-2ONsG {
	height: 20px;
	width: 20px;
	margin-right: 8px;
}

.fareGroup__logo-2ONsG img {
	width: 100%;
	height: 100%;
}

.fareGroup__name-2j5SP {
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.details-1CFVK {
	color: var(--common-blue);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}
