/* stylelint-disable */
.slider__header-3dFPd,
.modal__heading-1H-_9 {
}
/* stylelint-enable */
.compareFares-1Hx5n {
	padding: 40px;
}

.loaderWrp-3FUlc {
	height: 100%;
	min-height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader-2yzUr {
	color: #b7b7b7 !important;
}

.row-2VQVS {
	display: flex;
}

.col-j4ZYg {
	display: flex;
	flex-direction: column;
}

.headingWrp-zlXwb {
	position: relative;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-right: 2px;
}

.selectedFare-13y3B {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;

	border: 1px solid rgba(50, 144, 255, 0.6);

	background: rgba(50, 144, 255, 0.07);

	transition: height 100ms;
}

.fareHeadingItem-2F8u2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
	width: 100%;
	min-height: 56px;
	padding: 0 10px;

	color: var(--text-mid);
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;

	background: var(--brand-1-4);
}

.fareHeadingItem__title-3KfJ0 {
	display: block;
}

.fareHeadingItem__serviceClass-3XdDd {
	display: block;
}

.fareHeadingItem__serviceClass-3XdDd,
.fareHeadingItem__fareTitle-GV5ow {
	display: block;

	text-align: center;
}

.fareHeadingItem_isKey-FDh5- {
	background: var(--seat-business);
}

.fareHeadingItem_isFirstClass-3IPas {
	background: var(--seat-premium);
}

.availabilityIcon-2laWO {
	position: relative;
	top: 2px;

	color: var(--success-icon);
}

.title-3LxfE {
	flex: 25%;

	min-width: 270px;
}

.tableRow-M9U-n .title-3LxfE {
	position: relative;
	left: 12px;

	display: flex;
	align-items: center;
	min-width: min-content;

	color: var(--text-base);
	font-weight: 700;
	font-size: 13px;
}

.slider-31qMQ {
	min-width: 0;
	flex: 75%;
}

.slider-31qMQ:first-child > div > div {
	overflow-y: visible;
}

.tableRow-M9U-n .slider-31qMQ .slick-track {
	display: flex;
	align-items: center;
}

.tableRow_fullDescr-3rwPy .slider-31qMQ .slick-list {
	display: flex;
}

.tableRow_fullDescr-3rwPy .slider-31qMQ .slick-track {
	display: flex;
	align-content: flex-start;
}

.tableRow_fullDescr-3rwPy .slider-31qMQ .slick-slide {
	height: auto;
}

.slider-31qMQ .slick-prev svg {
	position: relative;
	left: -1px;
}

.slider-31qMQ .slick-next svg {
	position: relative;
	left: -1px;
}

.slider-31qMQ .slick-prev,
.slider-31qMQ .slick-next {
	top: 30px;
	left: -85px;

	display: flex !important;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-light);

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.1);

	background: var(--bg-white);
}

.arrows-31dip {
	display: flex;
	justify-content: flex-end;
	margin: 12px 10px 0 0;
}

.chevron-1DFJ1 {
	display: flex;

	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	cursor: pointer;

	background: #ededed;
}

.chevron_next-2nK4f {
	margin-left: 15px;

	transform: rotate(180deg);
}

.slider-31qMQ .slick-next {
	left: -42px;

	transform: rotate(180deg) translate(0, 50%);
}

.slider-31qMQ .slick-disabled svg {
	color: var(--text-light);

	opacity: 0.5;
}

.slider-31qMQ .slick-prev:before,
.slider-31qMQ .slick-next:before {
	display: none;
}

.slider-31qMQ .slick-next,
.slider-31qMQ .slick-prev,
.slider-31qMQ .slick-next:hover,
.slider-31qMQ .slick-prev:hover {
	color: #9a9a9a;

	background: #ededed;
}

.fareProps-FnJAZ {
	margin-top: 5px;
}

.value-2ysxi {
	margin-right: 5px;
}

.fareGroupsWrp-mYhMz {
	margin-top: 200px;
}

.groupTitle-13If6 {
	min-height: 40px;

	color: var(--brand-1-0);
	font-weight: 300;
	font-size: 24px;
	line-height: 31px;
	text-align: left;
	white-space: pre-line;
}

.groupTitle-13If6,
.headingCell-3l2Pz {
	padding: 30px 0 20px;

	border-bottom: 1px solid #ededed;
}

.headingCell-3l2Pz {
	min-height: 87px;
}

.cell-2tVVF {
	display: flex;
	justify-content: center;
	align-items: center;

	line-height: 16px;
	font-size: 14px;
	color: var(--dark-gray);
}

/* .cell_notFullFareConditions {
	max-width: 178px;
} */

.cellContent-1xo84 {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;

	font-size: 13px;

	text-align: center;
}

.cellContent_fullDescr-p4Wk9 {
	padding: 0 10px;
}

.cellContent_shortDescr-20g9p {
	margin-top: 15px;
}

.tableRow-M9U-n {
	display: flex;
	height: max-content;
	padding: 12px 0;

	border-bottom: 1px solid var(--line-separator);
}

.tableRow-M9U-n:nth-child(odd) .cellContent-1xo84 {
	font-weight: 700;
}

.tableRow-M9U-n:nth-of-type(even) {
	background: #f6f6f6;
}

.cell_fareProperty-25NUk {
	justify-content: flex-start;
	padding-left: 12px;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.link-3mu5T {
	margin-top: 45px;
	display: inline-block;

	font-weight: 500;
	text-decoration: none;
	font-size: 16px;
}

.link__wrapper-3D9MD {
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
}

.linkIcon-1SL-K {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 18px;
	height: 18px;

	transform: rotate(180deg);
}

.linkIcon_return-2pWkM {
	transform: none;
}

.notesHeading-3NCYB {
	margin: 40px 0 20px;

	color: var(--common-blue);
	font-weight: 300;
	font-size: 31px;
	line-height: 36px;
}

.notesList-17EhN {
	margin: 0;
	padding: 0;

	color: var(--dark-gray);
	font-size: 14px;
	line-height: 21px;

	list-style-type: none;
}

.notesListItem-cXzCY:not(:last-of-type) {
	margin-bottom: 20px;
}

.errorTitle-1t09T {
	margin: 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
}

.errorText-DuavA {
	line-height: 22px;
	color: var(--common-gray);
}

.image-1TtT2 {
	max-width: 120px;
	height: auto;
}

@media (max-width: 1024px) {
	.headerTitle-ILkDq {
		display: none;
	}

	div.compareFares-1Hx5n {
		padding: 0;
		height: 100%;

		box-shadow: none;
	}

	.fareHeadingItem-2F8u2 {
		height: 66px;

		font-weight: 300;
		font-size: 21px;
		line-height: 25px;
	}

	.slider-31qMQ .slick-prev,
	.slider-31qMQ .slick-next {
		top: 12%;

		width: 40px;
		height: 40px;
	}

	.slider_fullConditions-Z-j6T .slick-prev,
	.slider_fullConditions-Z-j6T .slick-next {
		top: 50%;
	}

	.notesHeading-3NCYB,
	.notesList-17EhN {
		padding: 12px;
	}

	.slider-31qMQ .slick-prev {
		z-index: 1;
		left: 12px;
	}

	.slider-31qMQ .slick-next {
		right: 12px;
		left: auto;
	}

	.title-3LxfE {
		flex: 50%;

		font-size: 14px;
		font-weight: 600;
	}

	.groupTitle-13If6 {
		padding-bottom: 10px;
		padding-left: 12px;

		font-size: 24px;
	}

	.image-1TtT2 {
		width: auto;
		height: 178px;
		margin: 0 auto;
		margin-top: 20px;
	}

	.link-3mu5T {
		display: flex;
		padding-left: 12px;
	}

	.headingWrp-zlXwb {
		margin-right: 0;
	}

	.slider_withoutImages-1z_7N .slick-next,
	.slider_withoutImages-1z_7N .slick-prev {
		top: 28px;

		border: 2px solid rgba(255, 255, 255, 0.2);

		background: transparent;
	}

	.slider_withoutImages-1z_7N .slick-prev {
		left: 10px;
	}

	.slider_withoutImages-1z_7N .slick-next {
		right: 10px;
	}

	.slider_withoutImages-1z_7N .slick-next svg *,
	.slider_withoutImages-1z_7N .slick-prev svg * {
		fill: #ffffff;
	}

	.cell_notFullFareConditions-39PqJ {
		max-width: unset;
	}

	.slider-31qMQ .slick-next:hover,
	.slider-31qMQ .slick-prev:hover {
		background: unset;
	}

	.container-3Yf1A {
		padding-bottom: 40px;
	}
}
