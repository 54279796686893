.wrapper-2y1NE {
	display: flex;
	align-items: center;
}

.content-2YnqD {
	min-width: 450px;
}

.icon-23vil {
	margin-right: 10px;

	color: var(--gray50);

	transform: translateY(1px);
}

.link-2ep7t {
	flex-basis: 25%;
	margin-left: auto;

	font-weight: 400;
	font-size: 15px;
	text-align: right;
	text-decoration: none;
}

@media screen and (max-width: 600px) {
	.content-2YnqD {
		min-width: 100%;
	}
}
