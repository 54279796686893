

.wrapper-3pryQ {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999999;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

.wrapper_aviasales-1cptU {
	background-color: #eff1f4;
}

.holder-37102 {
	position: relative;

	flex-basis: 917px;

	border-radius: 5px;

	background-color: #ffffff;
}

.wrapper_aviasales-1cptU .holder-37102 {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.21);
}

.logo-3FXGI {
	margin: 104px 0 100px;

	text-align: center;
}

.logo-3FXGI > svg {
	width: 274px;
	height: 42px;
}

.loader-2m8CK {
	width: 100%;
	height: 4px;
}

.aviasalesLoader-1-PTz {
	position: absolute;

	width: 100%;
	height: 4px;

	background-color: #2196f3;
	background-image: repeating-linear-gradient(135deg, #90caf9, #90caf9 20px, #2196f3 0, #2196f3 40px);
	background-size: 228px 32px;

	transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);

	animation: stripesMap-2aY2m 0.5s infinite linear;
}

.footer-x3P5h {
	margin: 48px 0 134px;

	text-align: center;
}

.footer_withErrors-BVUZZ {
	margin-bottom: 62px;
}

.text-2M1X7 {
	margin: 0;
}

.wrapper_aviasales-1cptU .text-2M1X7 {
	color: #79909b;
	font-family: inherit;
	font-size: 12px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

button.retryButton-1tWG8 {
	margin-top: 24px;
}

@keyframes showSearchProgressMapMain-ghJYK {
	0% {
		width: 0;
	}

	to {
		width: 100%;
	}
}

@keyframes stripesMap-2aY2m {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: -56px 0;
	}
}
