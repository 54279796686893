

.container-SdX0k {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	min-width: 0;

	border-radius: 8px;

	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
	background: #ffffff;
}

.container-SdX0k.container_active-3aZ2d {
	cursor: pointer;
}

.cover-1tc8w {
	position: relative;

	height: 104px;

	border-radius: 8px 8px 0 0;

	background: linear-gradient(258.45deg, #69a9e3 24.79%, #b4ef98 85.48%);
}

.cover-1tc8w:after {
	border-radius: inherit;
}

.content-2k1Hc {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 24px;

	border-radius: 0 0 8px 8px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
}

.content__services-MrjeC,
.description-1zqKP {
	flex: 1;
}

.header-Hw7NK {
	margin-bottom: 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;
}

.description-1zqKP a {
	color: var(--text-link);
}

button.add-3oO0F {
	border: 3px solid;
	border-radius: 50%;

	color: #ffffff;

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.14);
	background: var(--text-accent);
}

.checked-UCbZ0 {
	border-radius: 50%;

	box-shadow: 4px 7px 26px rgba(0, 0, 0, 0.14);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MSA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC4xNjYwMTYiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcng9IjIwIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTM2LjgzMjcgMTkuOTk5MkMzNi44MzI3IDI5LjIwMzkgMjkuMzcwOCAzNi42NjU5IDIwLjE2NjEgMzYuNjY1OUMxMC45NjEzIDM2LjY2NTkgMy40OTkzOSAyOS4yMDM5IDMuNDk5MzkgMTkuOTk5MkMzLjQ5OTM5IDEwLjc5NDQgMTAuOTYxMyAzLjMzMjUyIDIwLjE2NjEgMy4zMzI1MkMyOS4zNzA4IDMuMzMyNTIgMzYuODMyNyAxMC43OTQ0IDM2LjgzMjcgMTkuOTk5MlpNMjkuNjc4IDE2LjE3ODdDMzAuMzI4OSAxNS41Mjc4IDMwLjMyODkgMTQuNDcyNSAyOS42NzggMTMuODIxN0MyOS4wMjcxIDEzLjE3MDggMjcuOTcxOCAxMy4xNzA4IDI3LjMyMSAxMy44MjE3TDE3Ljk3MzIgMjMuMTY5NUwxMy4wMTEzIDE4LjIwNzZDMTIuMzYwNCAxNy41NTY3IDExLjMwNTIgMTcuNTU2NyAxMC42NTQzIDE4LjIwNzZDMTAuMDAzNCAxOC44NTg1IDEwLjAwMzQgMTkuOTEzOCAxMC42NTQzIDIwLjU2NDZMMTcuOTczMiAyNy44ODM1TDI5LjY3OCAxNi4xNzg3WiIgZmlsbD0iIzVGQ0E0MyIvPgo8L3N2Zz4K) center no-repeat;
}

.checked-UCbZ0.checked_removable-5wGa- {
	cursor: pointer;
}

.checked-UCbZ0.checked_removable-5wGa-:hover {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSIzNyIgaGVpZ2h0PSIzNyIgcng9IjE4LjUiIGZpbGw9IiNFMDIyMjIiLz4KPHBhdGggZD0iTTI0LjI0MjYgMjUuNjU2OEwyMCAyMS40MTQyTDE1Ljc1NzQgMjUuNjU2OEwxNC4zNDMxIDI0LjI0MjZMMTguNTg1OCAyMEwxNC4zNDMxIDE1Ljc1NzNMMTUuNzU3NCAxNC4zNDMxTDIwIDE4LjU4NThMMjQuMjQyNiAxNC4zNDMxTDI1LjY1NjkgMTUuNzU3M0wyMS40MTQyIDIwTDI1LjY1NjkgMjQuMjQyNkwyNC4yNDI2IDI1LjY1NjhaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSIxLjUiIHk9IjEuNSIgd2lkdGg9IjM3IiBoZWlnaHQ9IjM3IiByeD0iMTguNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIi8+Cjwvc3ZnPgo=) center no-repeat;

	transition: background 0.2s;
}

button.add-3oO0F,
.checked-UCbZ0 {
	position: absolute;
	right: 20px;
	bottom: 0;

	width: 40px;
	height: 40px;

	transform: translateY(50%);
}

.discount-1RZ_P {
	display: inline-block;
	margin-bottom: 16px;
	padding: 4px 8px;

	color: #ffffff;
	font-weight: 500;
	font-size: 13px;

	background: var(--success-icon);
}

.total-2gBnA {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 12px;
	padding-top: 12px;

	border-top: 1px dashed var(--line-dot-line);

	font-size: 16px;
}

.container-SdX0k.container_active-3aZ2d .total-2gBnA {
	justify-content: flex-end;
}

.totalPrice-34ktG {
	flex-shrink: 0;

	padding: 4px 0;

	color: var(--text-mid);

	font-weight: 700;
	line-height: normal;
}

.buttons-2Wnye {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.buttons-2Wnye button {
	font-weight: 400;
	font-size: 16px;
}

.buttons-2Wnye button:not(:last-child) {
	margin-right: 10px;
}

.icons-sbzTX {
	position: absolute;
	right: 24px;
	top: 16px;
}

.icons-sbzTX svg {
	width: 36px;
	height: 36px;

	color: #ffffff;

	opacity: 0.8;

	transition: opacity 0.2s;
}

.icons-sbzTX svg:not(:first-child) {
	margin-left: 12px;
}
