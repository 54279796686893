

.card-3p0aP {
	width: 340px;

	border-radius: 8px;

	background: linear-gradient(345.9deg, #f7f7f7 -2.27%, #ffffff 79.43%);
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.header-FXf84 {
	display: flex;
	padding: 22px 14px 0;
}

.document-l3Z67 {
	margin-left: 12px;
}

.title-1ORql {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	color: #9a9a9a;
	font-size: 14px;
	font-weight: 500;
}

.subtitle-1994K {
	display: flex;
	align-items: center;
	height: 25px;
	margin: 0;

	line-height: normal;
	font-size: 15px;
	color: var(--dark-gray);
	text-transform: uppercase;
}

.content-3NdwJ {
	display: flex;
	padding: 38px 14px 16px;
}

.content__item-PZaxR {
	display: flex;
	flex-direction: column;
}

.content__item-PZaxR:first-child {
	margin-right: 25px;
}

.content__item__name-1EWG0 {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	font-size: 13px;
	color: #9a9a9a;
}

.content__item__value-1ZKvz {
	display: flex;
	align-items: center;
	height: 20px;
	margin: 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 15px;
}

.footer-2Sq26 {
	padding: 10px 10px 10px 20px;

	display: flex;
	flex-direction: row-reverse;

	border-top: 1px dashed #d9d9d9;
}

.footer-2Sq26 p {
	display: flex;
	align-items: center;
	margin: 0;
	height: 20px;

	font-weight: 500;
	color: var(--common-blue);

	cursor: pointer;
}

.footer-2Sq26 > p > span {
	margin-right: 6px;

	color: #9a9a9a;
}

.footer-2Sq26 .footer__button-3-wNB {
	min-width: 35px;

	font-size: 13px;
	color: var(--cabinet-action);
	text-transform: none;

	font-family: inherit;
}

.footer-2Sq26 .footer__button-3-wNB .button__label-3-eao {
	font-family: inherit;
}

.footer__button-3-wNB:first-child {
	color: #9a9a9a;
}

.card_loyalty-1nU89 {
	background: var(--loyalty-card-background);
}

.card_loyalty-1nU89 .header-FXf84 .title-1ORql,
.card_loyalty-1nU89 .header-FXf84 .subtitle-1994K {
	color: #ffffff;
}

.card_loyalty-1nU89 .content-3NdwJ .content__item-PZaxR .content__item__name-1EWG0,
.card_loyalty-1nU89 .content-3NdwJ .content__item-PZaxR .content__item__value-1ZKvz {
	color: #ffffff;
}

.card_loyalty-1nU89 .footer-2Sq26 p button {
	color: #ffffff;
}

.card_dummy-8cv3C .footer-2Sq26 > p > button > span > span > svg > path {
	fill: var(--cabinet-action);
}

.card-3p0aP.card_loyalty-1nU89 > .footer-2Sq26 > p > button > span > span > svg > path {
	fill: #ffffff;
}

.card-3p0aP.card_dummy-8cv3C {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.card_dummy-8cv3C .footer-2Sq26 > p > button > span > span {
	display: flex;
	align-items: center;
	margin-left: 6px;
	margin-right: 0;
}

.card_dummy-8cv3C .footer-2Sq26 > p > button {
	color: var(--cabinet-action);
}

.card_dummy-8cv3C.card_loyalty-1nU89 .footer-2Sq26 > p > button > span {
	color: #ffffff;
}

.card_loyalty-1nU89 .footer-2Sq26 > p > button {
	color: #ffffff;
}

.card_loyalty-1nU89 .footer-2Sq26 > p > button:first-child {
	color: rgba(255, 255, 255, 0.5);
}

.card_dummy-8cv3C .content-3NdwJ .content__item-PZaxR .content__item__value-1ZKvz {
	color: #d9d9d9;
}

.card_dummy-8cv3C.card_loyalty-1nU89 .content-3NdwJ .content__item-PZaxR .content__item__value-1ZKvz {
	color: rgba(255, 255, 255, 0.5);
}

.card_dummy-8cv3C .footer-2Sq26 {
	padding: 10px 14px;
}

div.modal__content-3h0FI {
	padding: 0;
}

.modal__content-3h0FI:first-child {
	padding: 0 !important;
}
