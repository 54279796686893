span.checkbox_checked-2gdk7.checkbox-6b7bm {
	color: var(--common-blue);
}

span.label-3P1sd {
	font-family: inherit;
}

.rules-337yb {
	padding: 0 20px 20px;

	background-color: #ffffff;
}

div.tooltip-2zXsD {
	z-index: 15;
}

@media screen and (max-width: 600px) {
	.rules-337yb {
		padding: 20px;
	}

	label.root-3x9cb {
		padding-left: 20px;
	}
}

.rules__label-2evou {
	padding: 12px;

	border-radius: 4px;

	background-color: #f0f0f0;
}

div.rules__tooltip-WCwTS {
	z-index: 80;
}

.terms-cMOrW {
	color: var(--common-blue);
}

.toolbarCheckbox__wrapper-1lA0i {
	font-family: inherit;
}
