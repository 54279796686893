.insurance-2mpwf {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 380px;

	border-radius: 8px;

	background: #ffffff;
}

.insurance-2mpwf.selected-2KTE9 {
	position: relative;
}

.insurance__header-K6lAT {
	display: flex;
	padding: 20px;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px dashed #dfdfdf;

	font-size: 21px;
	color: #1d1d1d;
	font-weight: 700;
}

.insurance__money--JB1_ {
	padding-left: 0;

	color: var(--common-blue);
	font-weight: 700;
	font-size: 22px;
}

.insurance__content-17U5z {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;

	color: #1d1d1d;
	font-size: 14px;
	line-height: 20px;
}

.options-2UEKt {
	font-size: 14px;
	color: #1d1d1d;
}

.option-P6Zzr {
	position: relative;

	margin-bottom: 15px;
	padding-left: 15px;

	line-height: 160%;
}

.option-P6Zzr a {
	color: var(--link-color);
}

.option-P6Zzr:after {
	content: '\2022';

	position: absolute;
	left: 0;
	top: 0;

	color: #9a9a9a;
}

button.insurance__button-1koyT {
	position: relative;

	width: 100%;
	height: 48px;
	margin-top: 20px;

	border: 1px solid var(--light-blue);

	border-radius: 4px;

	color: var(--common-blue);
	line-height: 48px;

	background: #ffffff;
}

button.insurance__button-1koyT.insurance__button_selected-F-nsO {
	position: relative;

	color: #ffffff;

	background: var(--common-blue);
}

button.insurance__button-1koyT.insurance__button_selected-F-nsO:after {
	position: absolute;
	left: 12px;

	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS40NjQxIDE3LjY3N0wyMi4wNzA1IDcuMDcwNjJMMTkuOTQ5MiA0Ljk0OTNMOS4zNDI3NiAxNS41NTU3TDQuMzkzNTggMTAuNjA2NUwyLjI3MjI2IDEyLjcyNzlMNy4yMjE0MyAxNy42NzdMNy4yMjEyNSAxNy42NzcyTDkuMzQyNTcgMTkuNzk4NUw5LjM0Mjc1IDE5Ljc5ODRMOS4zNDMzMyAxOS43OTg5TDExLjQ2NDYgMTcuNjc3NkwxMS40NjQxIDE3LjY3N1oiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==);
	content: '';
}

@media (max-width: 1024px) {
	.insurance__header-K6lAT {
		flex-direction: column;
		align-items: inherit;

		font-size: 18px;
		line-height: 1.1;
	}

	.insurance__money--JB1_ {
		margin-top: 6px;
	}
}

@media screen and (max-width: 600px) {
	.insurance-2mpwf {
		min-width: 280px;
	}

	button.insurance__button-1koyT {
		justify-content: space-between;
		padding-right: 34px;
		padding-left: 12px;

		border: none;
		border-radius: 2px;

		color: #ffffff;
		font-size: 17px;

		background: var(--common-orange);
	}

	button.insurance__button-1koyT:after {
		position: absolute;
		right: 6px;
		left: auto;

		width: 24px;
		height: 24px;
		margin: 0;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgNkw4LjU4OTg0IDcuNDFMMTMuMTY5OCAxMkw4LjU4OTg0IDE2LjU5TDkuOTk5ODQgMThMMTUuOTk5OCAxMkw5Ljk5OTg0IDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
		content: '';
	}

	button.insurance__button-1koyT:hover {
		background: var(--common-orange);
	}

	button.insurance__button-1koyT.insurance__button_selected-F-nsO {
		color: #ffffff;

		background: #5fca43;
	}

	button.insurance__button-1koyT.insurance__button_selected-F-nsO:after {
		right: 6px;
		left: auto;
	}

	button.insurance__button-1koyT.insurance__button_selected-F-nsO:hover {
		background: #5fca43;
	}

	.insurance__money--JB1_ {
		color: #ffffff;
	}
}
