

.companions-382QT p {
	margin: 4px 0 0;

	line-height: 130%;
}

.description-3J0i0 {
	margin-bottom: 20px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 16px;
}
