

div.popper-2kDy3 {
	z-index: 1600;

	opacity: 1;
}

.freeSeat-2HXSM {
	font-size: inherit;
}

div.tooltip-3fpdg {
	position: relative;

	max-width: 400px;

	margin-bottom: 0;
	padding: 0;

	border-radius: 4px;

	box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
	background: var(--base);
}

div.tooltip-3fpdg.tooltip_recommend-2qZzT {
	background: var(--selected2);
}

div.tooltip_freeSeat-AfmLn {
	font-size: inherit;
}

.tooltip-3fpdg:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;

	width: 0;
	height: 0;
	margin: auto;

	border: 5px solid transparent;
	border-top: 5px solid var(--base);

	transform: translateY(100%);
	content: '';
}

.tooltip-3fpdg.tooltip_recommend-2qZzT:after {
	border-top-color: var(--selected2);
}

.tooltip_bottom-3FW-D:after {
	top: -10px;
	bottom: auto;

	transform: rotate(180deg);
}

.tooltip_withInfo-ZG0Tg.tooltip-3fpdg:after {
	border-top-color: var(--base15);
}

.container-12RCo {
	display: flex;
	align-items: center;

	padding: 10px 16px;
}

.number-1tgaf {
	margin-right: 16px;
	padding-right: 16px;

	border-right: 1px dashed var(--brand-1-2);

	color: var(--white);
	font-weight: 700;

	font-size: 31px;
	line-height: 1.3;
}

.details-2M6II {
	display: flex;
	flex-direction: column;

	font-weight: 500;
	font-size: 14px;
}

.details__price-3vR0u {
	padding-left: 0;

	color: var(--white);
	font-weight: 700;

	font-size: 16px;
	line-height: 1.3;
}

.details__seatName-2V1EP {
	color: var(--brand-1-4);

	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

div.info-2LuYw {
	padding: 8px;

	border-radius: 0 0 4px 4px;

	color: #ffffff;

	background: var(--base15);
}
