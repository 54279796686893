

.container-1O6Y- {
	padding: 32px;
	max-width: 594px;
}

.header-1GEaR {
	font-size: 24px;
	font-weight: 400;
	line-height: 40px;
	color: var(--gray80);
}

.items-1zG9s {
	margin-top: 20px;
}

.item-12pWF {
	display: flex;
	margin-bottom: 20px;
	align-items: center;
}

.item__controls-3Mx4p {
	display: flex;
	flex: 1;
}

.item__controls-3Mx4p input {
	width: 100%;
	height: var(--input-height);
	margin-right: 12px;
	padding: 11px 12px;

	border: 1px solid var(--gray05);

	color: var(--gray90);
	font-size: 17px;

	background: var(--gray05);
}

.autocomplete-2guVe {
	flex: 1;
	margin-right: 12px;
}

.value-2KqW_ {
	position: relative;

	flex: 1;

	height: var(--input-height);
	margin-right: 12px;

	border: 1px solid var(--gray05);

	color: var(--gray90);
	font-size: 17px;

	background: var(--gray05);

	cursor: pointer;
}

.value_focused-LNKJe {
	border-color: var(--base);
}

.value__dummy-w6FNQ {
	position: absolute;

	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 11px 12px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.value_empty-3-GBU {
	color: var(--gray50);
	text-overflow: ellipsis;
	overflow: hidden;
}

.value-2KqW_ input {
	width: 100%;
}

.value__code-2xgUw {
	position: absolute;
	top: 15px;
	right: 16px;

	color: var(--gray50);
	font-size: 17px;
}

.option-14Dm8 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	padding: 0 10px;

	border-bottom: 1px solid var(--gray10);

	color: var(--gray90);
	font-size: 16px;
	white-space: nowrap;

	cursor: pointer;
}

.code-2E9n- {
	color: var(--gray50);
}

input.count-1cXaD {
	width: 151px;

	font-size: 26px;
	font-weight: 500;
}

button.control-1ZF04 {
	display: flex;

	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	border-radius: 50%;

	color: var(--base);

	background: var(--base80);
}

button.control-1ZF04 svg {
	width: 20px;
	height: 20px;
}

.controls-jgWVd {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.controls-jgWVd button:not(:last-child) {
	margin-right: 8px;
}

/* stylelint-disable-next-line block-no-empty */
button.clear-3_gMd {
}

/* stylelint-disable-next-line block-no-empty */
button.done-29Wtu {
}
