.container-3KRzk {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr [content-end] 1.2fr [grid-end];
	grid-column-gap: 12px;
}

.container-3KRzk:not(:last-child) {
	margin-bottom: 24px;
}

.container-3KRzk.withIcons-19wVZ {
	grid-template-columns: 40px 1fr 1fr 1fr [content-end] 1.2fr [grid-end];
	align-items: center;
}

div.field_birthdate-3zf0f {
	z-index: 1;

	grid-column-start: content-end;
	grid-row-start: 1;
	width: 55%;
}

.field_sex-1Pjt0 {
	z-index: 0;

	grid-column-start: content-end;
	grid-row-start: 1;
	align-items: center;
}

/* stylelint-disable */
.documents-23rsn {
}

.field_middleName-2O3C8 {
}
/* stylelint-enable */

@media (max-width: 1024px) {
	.container-3KRzk {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: 56px;
		grid-gap: 25px 28px;
	}

	.container-3KRzk.withIcons-19wVZ {
		grid-template-columns: 1fr;
		align-items: initial;
	}

	.icon-2Dw1V {
		display: none;
	}

	.container-3KRzk.identity-15VXu {
		grid-template-rows: 1fr 1fr 1fr [row-content-end] 1fr;
	}

	.container-3KRzk:not(:last-child) {
		margin-bottom: 25px;
	}

	div.field_sex-1Pjt0,
	div.field_birthdate-3zf0f {
		grid-column: 1;
		grid-row: row-content-end;
	}
}

@media screen and (max-width: 600px) {
	.container-3KRzk {
		grid-auto-rows: initial;
	}
}
