.container-2L3A1 {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 30px;
	gap: 30px;
	justify-content: space-between;
}

.offer-2iEm4 {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	padding: 32px;
	flex: 1;

	background: var(--bg-canvas);
}

.offer__fullscreen-uUQ7A {
	max-width: unset;
	flex-direction: row-reverse;
	justify-content: space-between;

	background: var(--accent-subtle);
}

.offer__image-3t1HN {
	min-width: 160px;
	max-width: 160px;
	height: 160px;
	margin-right: 45px;
}

.offer__fullscreen-uUQ7A > .offer__image-3t1HN {
	min-width: 210px;
	max-width: 210px;
	height: 210px;
}

.offer__info-_XniD {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.offer__info_title-30GDY {
	margin-bottom: 7px;

	color: var(--accent-default);
	font-weight: 600;
	font-size: 32px;
}

.offer__info_description-3ktYO {
	font-size: 18px;
	font-weight: 400;
	color: var(--fg-muted);
}

@media (max-width: 1024px) {
	.container-2L3A1 {
		flex-direction: column;
	}

	.offer-2iEm4 {
		width: 100%;
		max-width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.offer-2iEm4 {
		flex-direction: column;
		align-items: flex-start;
	}

	.offer__info_title-30GDY {
		margin-top: 34px;

		font-size: 28px;
	}

	.offer__info_description-3ktYO {
		font-size: 16px;
	}

	.offer__fullscreen-uUQ7A > .offer__image-3t1HN {
		margin: 0 auto;
	}
}
