.wrapper-_mc9g {
	display: flex;
	margin-right: 20px;
}

.text-cWNUG {
	font-weight: 500;
	font-size: 14px;
	color: var(--cabinet-action);

	cursor: pointer;
}

div.scrollBody-677If {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.wrapper-_mc9g {
		margin-right: 0;
	}

	div.scrollBody-677If {
		flex-direction: column;
		justify-content: flex-end;
	}

	div.scrollBody-677If div.paper-2TAVv {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
