.passenger-30Rgd {
	display: flex;
	justify-content: space-between;
}

.controls-O5ziX {
	display: flex;
	align-items: center;
}

.info-2qgHC {
	position: relative;

	display: block;
	margin-right: 10px;
}

.info__icon-1kWdT {
	margin-left: 2px;
	display: flex;
	align-items: center;
}

.passengerType-3rRkM {
	color: var(--text-base);
	font: var(--medium--1-desktop-accent);
}

.age-1FAkK {
	display: inline-flex;
	align-items: center;

	color: var(--text-light);
	font: var(--small-mobile);

	transition: color 0.15s;
}

.age-1FAkK:hover {
	cursor: help;
}

button.control-3tDZz {
	flex-shrink: 0;
}

button.increase-2SJR5,
button.decrease-1KeRs {
	display: flex;
	justify-content: center;
	align-items: center;

	width: calc(12px * 2);
	height: calc(12px * 2);
	padding: 0;

	border: none;

	border-radius: 50%;

	color: #ffffff;

	cursor: pointer;

	background: var(--brand-1-0);

	transition: background 0.15s;

	appearance: none;
}

.increase-2SJR5 svg,
.decrease-1KeRs svg {
	fill: #ffffff;
}

button.increase-2SJR5:hover,
button.decrease-1KeRs:hover {
	background: var(--brand-1-1);
}

button.increase-2SJR5:disabled,
button.decrease-1KeRs:disabled {
	background: var(--bg-fill);
	cursor: default;
}

.increase-2SJR5:disabled path,
.decrease-1KeRs:disabled path {
	fill: var(--text-pale);
}

.counter-14RUn {
	width: 38px;

	color: var(--brand-1-0);

	font-weight: 500;
	font-size: 18px;
	text-align: center;

	transition: color 0.15s;
}

.counter_disabled-1hirv {
	color: var(--text-pale);
}

.description-PWCxH {
	display: none;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.3;
}

@media screen and (max-width: 600px) {
	.passenger-30Rgd {
		font-size: 16px;
	}

	.passengerType-3rRkM {
		font-size: 16px;
	}

	button.increase-2SJR5,
	button.decrease-1KeRs {
		width: 32px;
		height: 32px;
	}

	.info-2qgHC {
		flex-basis: 45%;
	}

	.age-1FAkK:hover {
		cursor: text;
	}
}
