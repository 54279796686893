

.wrapper-15GY6 {
	max-width: 720px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 4px;

	background: #ffffff;
	box-shadow: 0 24px 84px rgba(0, 0, 0, 0.13);
}

.timeImage-2mVND {
	margin: 40px auto 28px;
}

.text-1h5kV {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 20px;

	text-align: center;
}

.text-1h5kV > span {
	font-weight: 300;
	font-size: 27px;
	line-height: 37px;
	color: #9a9a9a;
}

.wrapper-15GY6 button.button-3o0rx {
	margin: 28px 0 40px;
}
