

.container-ItGzd {
	position: relative;

	width: 100%;
	padding-right: 30px;
	padding-left: 28px;
}

.slider-yOS3h {
	max-width: 100%;
	width: 100%;
	min-width: 0;
	overflow: hidden;
}

.inside-1ddD0 {
	position: relative;
	left: 0;

	display: inline-flex;
	min-width: 100%;
}

.inside_transition-2HvWv {
	transition: 0.3s left ease-in;
}

.elementsWrapper-1qpjZ {
	display: flex;
	min-width: 100%;
}

.arrow-2gjje:after {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.arrow_next-MpjZa,
.arrow_prev-2zKw2 {
	position: absolute;
	top: 0;

	width: 26px;
	height: 100%;

	cursor: pointer;
	background: #cccccc;
}

.arrow_next-MpjZa {
	position: absolute;
	right: 0;
}

.hide-2brHK {
	display: none;
}

.arrow_next-MpjZa:after {
	transform: rotate(-180deg);
}

.arrow_prev-2zKw2 {
	position: absolute;
	left: 0;
}

.disabled-3pvsc {
	opacity: 0.5;
	cursor: default;
}
