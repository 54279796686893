

.autocomplete-2vVMN {
	position: relative;

	width: 100%;
}

.autocomplete-2vVMN > div {
	width: 100%;
}

.input-1S4A_ {
	display: inline-block;
}
