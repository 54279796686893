div.modal-1-H6U {
	border-radius: 20px;
	overflow: hidden;
}

div.modal-1-H6U.modal_mobileFullScreen-3IAe8 {
	border-radius: 0;
	overflow: auto;
}

.modal__closeIcon-1xGyj {
	top: 8px;
	right: 8px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-light);

	background-color: var(--bg-fill);

	transition: background-color 0.15s;
}

.modal__closeIcon-1xGyj:hover,
.modal__closeIcon-1xGyj:focus {
	background-color: var(--bg-elements);
}

.modal__closeIcon-1xGyj > svg {
	width: 20px;
	height: 20px;
}

@media screen and (max-width: 600px) {
	.modal__closeIcon-1xGyj {
		top: 12px;
		right: 12px;
	}

	div.modal-1-H6U {
		border-radius: 12px 12px 0 0;
	}
}
