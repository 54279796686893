

button.plus-3gwWO,
button.minus-36OjN {
	min-width: 26px;
	max-width: 26px;
	min-height: 26px;
	max-height: 26px;

	background: var(--brand-1-5);
}

button.plus-3gwWO svg,
button.minus-36OjN svg {
	transform: scale(0.85);
}
