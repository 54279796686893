

label.label-3-96P {
	z-index: 1000;

	margin-right: 0;

	color: var(--gray80);
	line-height: 24px;
}

.label-3-96P.active-1PfAp,
span.radio-2He06.active-1PfAp {
	color: var(--base);
}

span.radio-2He06:hover {
	background: rgba(50, 144, 255, 0.1);
}
