.beforeAdornment__wrapper-IFOa3 {
	max-width: 1100px;
	margin: 0 auto 14px;
	grid-gap: 12px;
	gap: 12px;
}

.searchForm-2jaoq {
	padding: 24px 48px;

	color: var(--dark-gray);
	font-size: 15px;

	background-color: var(--common-blue);

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.searchForm_multiCity-2YR2N {
	padding: 24px 91px 24px 48px;
}

.group__label-2OufG {
	font-size: 11px;
}

.searchForm_advanced-29fA- {
	padding: 0;
}

.searchForm_advanced-29fA- .wrapper-226nK {
	padding: 24px 48px 5px;
}

@media screen and (max-width: 600px) {
	.searchForm-2jaoq {
		position: relative;

		width: 100%;
		max-width: none;
		padding: 16px 20px;
	}
}

.row-3NuSP {
	position: relative;

	display: flex;
	flex-wrap: wrap;

	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.row-3NuSP {
		position: static;

		display: block;
	}
}
