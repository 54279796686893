.content-2vqca {
	padding: 28px 28px 35px 28px;
}

.header-1CRDj {
	margin: 0 0 17px 0;

	color: var(--dark-gray);
	font-weight: 500;
	font-size: 22px;
	line-height: 31px;
}

.message-13X9p {
	margin: 0 0 14px 0;

	color: var(--common-gray);
	font-size: 15px;
	line-height: 21px;
}

.form-bEpSE {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.inputWrp-3DV7h {
	width: 100%;
}

button.submit-3Eo4Y,
div.submit-3Eo4Y {
	margin-left: 50px;
}

@media screen and (max-width: 600px) {
	.content-2vqca {
		padding-top: 11px;
	}

	.form-bEpSE {
		flex-direction: column;
		align-items: flex-start;
	}

	button.submit-3Eo4Y,
	div.submit-3Eo4Y {
		margin-left: auto;
		margin-top: 20px;
	}
}
