

.step-2WkVh {
	padding: 0 28px;

	border-color: var(--brand-1--1);
}

.results_header-3u4sQ .step-2WkVh {
	flex: 1;
	padding: 0;
}

.step-2WkVh.step_active-3A5UX {
	color: var(--white);

	background: var(--brand-1-0);
}

.step-2WkVh.step_disabled-LVxNE {
	color: var(--brand-1-2);
}

.stepbar-30GMX {
	border-color: var(--brand-1--1);
}

.label-2HfUn {
	white-space: nowrap;
}

.step-2WkVh:last-child {
	padding-right: 28px;
}
