.wrapper-2rN1S {
	display: flex;
	flex-direction: column;
}

.card-1ZbCZ {
	display: flex;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.locations-1Jshf {
	display: flex;
	flex: 1 0 60%;
	flex-direction: column;
}

.location-1oY6K:first-child {
	border-top-left-radius: 4px;
	border-bottom: 1px solid #d9d9d9;
}

.location-1oY6K:last-child {
	border-bottom-left-radius: 4px;
}

div.flight-2vNau {
	border-left: 1px solid #d9d9d9;

	box-shadow: none;
}

div.transfer-IT3HT {
	margin-top: 10px;

	padding: 12px 20px;

	border: 1px solid #d9d9d9;
	border-radius: 4px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	background-color: var(--warning20);
}

.isMobile-3YhNz .card-1ZbCZ {
	flex-direction: column;

	border-radius: 4px;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.isMobile-3YhNz .locations-1Jshf {
	flex-direction: row;
}

.isMobile-3YhNz .location-1oY6K {
	width: 50%;
}

.isMobile-3YhNz .location-1oY6K:first-child {
	border-right: 1px solid #d9d9d9;
	border-bottom: none;
	border-radius: 4px 0 0 0;
}

.isMobile-3YhNz .location-1oY6K:last-child {
	border-radius: 0 4px 0 0;
}

.isMobile-3YhNz .flight-2vNau {
	border-left: none;
	border-right: none;
	border-radius: 0 0 4px 4px;
}
