.title-QAfC9 {
	color: var(--fg-default);
	font-size: 36px;
}

.slider-3xBkx .slick-track {
	display: flex;
}

.slider-3xBkx .slick-slide > div {
	padding: 0 15px;
}

.slider-3xBkx .slick-list {
	margin: 0 -15px;
}

h2.header-3tX02 {
	margin-top: 0;
}

@media screen and (max-width: 600px) {
	.title-QAfC9 {
		font-size: 28px;
	}
}
