

.container-3oSrA {
	padding: 32px;
}

.header-QHBez {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__text-1ZuoD {
	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.notification-LzMRY {
	font-weight: 500;
}

.notification-LzMRY svg {
	display: none;
}

button.closeButton-2z1Iw {
	width: 28px;
	height: 28px;
}

.description-3o366 {
	font-size: 16px;
	color: var(--text-light);
	line-height: 130%;
}

.description-3o366,
.passengers-25ujU {
	margin: 16px 0;
}

.passengerType-1VO4I {
	font-weight: 700;
	color: var(--text-base);
}

.age-3SoW_ {
	font-size: 12px;
	color: var(--text-light);
}

/* stylelint-disable */
.info__icon-1Uz2O {
}

.item-2KUTP {
}

/* stylelint-enable */
.buttons-evBrm {
	width: 100%;
	margin-top: 16px;
}

.buttons-evBrm button:not(:last-child) {
	margin-bottom: 16px;
}

button.newSearch-1D5sw,
button.continueButton-3tlNV {
	width: 100%;
}

button.increase-19Kw2,
button.decrease-3yd_i {
	position: relative;

	color: var(--brand-1-0);

	opacity: 1;
	background-color: var(--brand-1-5);
}

button.increase-19Kw2:disabled,
button.decrease-3yd_i:disabled {
	color: var(--line-dot-line);

	background-color: var(--bg-fill);
}

button.increase-19Kw2:not(:disabled):hover,
button.decrease-3yd_i:not(:disabled):hover {
	color: #ffffff;

	background-color: var(--brand-1-0);
}

div.counter-2OnGf {
	font-size: 16px;
	font-weight: 500;
	color: var(--text-base);
}
