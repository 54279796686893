.wrapper-3vgvE {
	display: flex;
	flex-direction: column;
	max-width: 1100px;
	margin: 0 auto;
}

.cards-2pTeA {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.title-3zCkM {
	margin: 28px 0 24px;

	font-weight: 500;
	font-size: 27px;
	line-height: 40px;
	color: var(--dark-gray);
}

.baggage__dialogContent-3FG-4 {
	overflow: visible;
	overflow: initial;
}

.baggagePage__baggage-2MXxM {
	min-height: 100%;
	height: auto;
}

.meal__dialogContent-2KO4S {
	min-height: 100%;
	height: auto;
	padding-bottom: 80px;

	background: #ededed;
}

.meal__dialogContent-2KO4S > div > div {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.wrapper-3vgvE {
		background-color: #ffffff;
	}

	.cards-2pTeA {
		flex-wrap: wrap;
		margin-bottom: 0;
	}
}
