.icon-2EjgN {
	width: 36px;
	height: 36px;
	margin-bottom: 20px;
}

.icon-2EjgN > svg {
	width: 100%;
	height: 100%;
}

.content-2SVPy {
	display: flex;
	flex-direction: column;
	align-items: center;

	color: var(--gray80);
	font-size: 20px;
	line-height: 28px;
	text-align: center;
}

@media screen and (max-width: 600px) {
	div.scrollBody-3-oQS {
		display: flex;
	}

	div.scrollBody-3-oQS div.paper-3LACd {
		min-height: auto !important;

		margin: auto 0 0 !important;

		border-radius: 8px 8px 0 0;
	}

	.paper-3LACd {
		width: 100%;
	}
}
