

.flight-2Nfr2 {
	display: flex;
	width: 100%;
	max-width: 1100px;
	min-height: 95px;
	margin: 0 auto 5px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	background: #ffffff;
}

.routes-FLvNt {
	display: flex;
	flex-direction: column;
	width: 640px;
}

.segments-2Cm-C {
	display: flex;
	flex-direction: column;
	flex: 0 1 640px;
}

.duration-hiQ6E {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 31px;
	padding: 8px 4px;

	border-top: 1px solid var(--gray10);
}

.slider__wrapper-2Mk_9 {
	display: block;
	width: 460px;
}

button.modal__button_right-3BGNa {
	display: none;
}

.slider__wrapper-2Mk_9 .slick-track,
.slider__wrapper-2Mk_9 .slick-slider,
.slider__wrapper-2Mk_9 .slick-list,
.slider__wrapper-2Mk_9 .slick-slide > div {
	height: 100%;
}

.slider__wrapper-2Mk_9 .slick-track .slick-slide:not(:last-of-type) {
	border-right: 1px dashed #cccccc;
}
