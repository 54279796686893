

.group-c01r0 {
	margin: 0 -8px;
	padding: 0 8px;
}

.group-c01r0:not(:last-of-type) {
	padding-bottom: 6px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.group-c01r0:not(:first-of-type) {
	padding-top: 8px;
}

.item-h2RpY {
	display: flex;
	justify-content: space-between;

	list-style-type: none;
}

.label-182cb {
	color: #ffee92;
}

.value-SRm0P {
	margin-left: 20px;

	color: #ffffff;
	font-weight: 500;
	text-align: right;
}

.label-182cb,
.value-SRm0P {
	font-size: 13px;
	line-height: 20px;
}
