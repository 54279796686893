.wrapper-2T-ap {
	--insurance-page-padding: 20px 40px;
	max-width: 1100px;
	margin: 0 auto;
	height: 100%;

	border-radius: 12px;

	background: var(--white);
}

@media screen and (max-width: 600px) {
	.wrapper-2T-ap {
		--insurance-page-padding: 20px 12px;
	}
}

.header-2qKOB {
	padding: var(--insurance-page-padding);

	border-bottom: 1px solid var(--bg-fill);
}

.title-1IKWM {
	margin: 0 0 8px;

	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.subheader-1YHtP {
	display: none;
	margin: 0;

	color: #808080;

	color: var(--text-light, #808080);
	font: var(--medium-desktop);
}

.content-2X_bH {
	padding: var(--insurance-page-padding);
}

.mainInsurancesList-3TGxm {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

.insurancesList-3bCnN {
	display: grid;
	grid-gap: 12px;
	margin-top: 12px;
	grid-template: auto / repeat(3, 1fr);
}

@media (max-width: 1024px) and (min-width: 601px) {
	.insurancesList-3bCnN {
		grid-template: auto / repeat(2, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.insurancesList-3bCnN {
		grid-template: auto / repeat(1, 1fr);
	}
}
