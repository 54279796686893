

.segment-1GMdw {
	display: flex;
}

.points-1gpP2 {
	width: 35%;
}

.duration-3itdK {
	margin-left: 4px;

	padding: 12px 0 12px 16px;

	border-left: 2px solid #cdcdcd;

	color: var(--common-gray);
	line-height: 21px;
}

.weather-2GQJa {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 15%;
	margin-left: 30px;

	font-weight: 500;
}

.weather__row-3rCZe {
	display: flex;
	align-self: center;
	width: 100%;
}

.weather__icon-2taxX {
	margin-right: 10px;
	display: inline-flex;
	align-self: center;

	color: var(--common-gray);
}

.weather__temperature-1Hwzx {
	display: inline-flex;
	align-self: center;
}

.flight-1T2If {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
}

.flight__infoIcon-2zWr- {
	display: flex;
	margin-left: 4px;

	line-height: 20px;

	cursor: help;

	opacity: 0.4;

	transition: opacity 0.15s;
}

.flight__infoIcon-2zWr-:hover {
	opacity: 1;
}

.flight__ecoIcon-3wspo {
	display: flex;
	margin-left: 4px;

	line-height: 20px;

	cursor: help;
}

.airline-1MbaU {
	display: flex;
	align-items: center;
}

.logo-2z7Lh {
	height: 20px;
	width: 20px;
	margin-right: 8px;
}

.logo-2z7Lh img {
	width: 100%;
	height: 100%;
}

.plane-VKeGo {
	width: 210px;
	height: 70px;
	overflow: hidden;
}

.plane__image-1sY5a {
	width: 100%;
}

.plane__image__stub-3G-WU {
	width: 210px;
	height: 70px;

	background-image: url('https://cdn.websky.aero/content/frontend/images/mock-plane.png');
	background-repeat: no-repeat;
}

.transfer-lVS8M {
	margin: 20px 0;
}

.nextDayArrival-28plM {
	margin-left: 12px;

	color: #c21212;
}

.aircraft__name__link-1gcbX {
	display: flex;
}

.aircraft__name-2zDVU {
	display: none;
}
