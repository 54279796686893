/**
document.body.querySelectorAll('.selection_colors_panel--styleRow---AwfB').forEach(elem  => {
    let text = elem.textContent;
    text = text.replace(' ', '-')
    text = text.replace('/', '-')
    text = text.replace('#', ': #')
    text = text += ';'
    text = text.replace(',', '')
    text = text.toLowerCase()
    console.log(text)
})
 */

:root {
	--bg-elements: #eeeeee;
	--bg-fill: #f6f6f6;
	--bg-white: #ffffff;
	--brand-1--1: #007cd7;
	--brand-1--2: #0061b8;
	--brand-1-0: #0089e5;
	--brand-1-1: #1ea1f9;
	--brand-1-2: #69c3ff;
	--brand-1-3: #9ad6ff;
	--brand-1-4: #d0ebf7;
	--brand-1-5: #e5f3fc;
	--brand-2--1: #524a87;
	--brand-2--2: #494177;
	--brand-2-0: #61579f;
	--brand-2-1: #7970ad;
	--brand-2-2: #a09ac5;
	--brand-2-3: #a3a3b3;
	--brand-2-4: #dfddec;
	--brand-2-5: #efeef5;
	--brand-3--1: #dd2427;
	--brand-3--2: #d51416;
	--brand-3-0: #e53338;
	--brand-3-1: #ea5c60;
	--brand-3-2: #ef8588;
	--brand-3-3: #f5adaf;
	--brand-3-4: #fad6d7;
	--brand-3-5: #fceaeb;
	--error-bg: #fdf2f2;
	--error-icon: #e02222;
	--error-stroke-hover: #f3a7a7;
	--error-text: #be1d1d;
	--line-dot-line: #c2c2c2;
	--line-separator: #d9d9d9;
	--recommend-bg: #ffecce;
	--recommend-icon: #ff9e0c;
	--recommend-stroke-hover: #ffc56d;
	--success-bg: #e7f7e3;
	--success-icon: #5fca43;
	--success-stroke-hover: #bfeab4;
	--success-text: #479732;
	--text-accent: #0089e5;
	--text-base: #262626;
	--text-light: #808080;
	--text-link: #0089e5;
	--text-mid: #4d4d4d;
	--text-pale: #b3b3b3;
	--warning-icon: #efea6c;
	--warning-bg: #fffdcc;
	--warning-stroke-hover: #fffa80;

	--base: var(--brand-1-0);
	--active: var(--brand-3-0);

	--common-blue: var(--brand-1-0);
	--common-orange: var(--brand-3-0);
	--dark-orange: var(--brand-3--1);
	--cabinet-action: var(--brand-1-0);
	--brand1: #006eae;
	--brand1-4: var(--brand-1-4);
	--light-text: #7d7da0;
	--input-background-active: var(--white);
	--tooltip-background: var(--text-base);
	--quickSearchForm-background: var(--brand-1--2);
	--stepbar-background-color: var(--brand-1--2);
	--dark-gray: var(--text-mid);

	--input-outline: var(--brand-1-3);
	--input-background: var(--brand-1-5);

	--checkout-background: var(--bg-fill);
	--text-mid-checkin: #3b3b5d;
	--text-base-checkin: #191940;
	--text-light-checkin: #7d7da0;
	--brand-1-4-checkin: #c1e5f5;
	--mobileSummaryFlight--background: var(--white);

	--additional-services-banner-background: var(--brand-1-0);
	--navigation-edit-background-color: transparent;
	--navigation-edit-icon-color: var(--brand-1-3);

	/* fonts */
	--medium-mobile-bold: 700 18px/110% var(--font), sans-serif;
}

@media screen and (max-width: 600px) {
	:root {
		--navigation-edit-icon-color: var(--white);
	}
}

.MuiButton-root {
	font-family: inherit !important;
}

body {
	font-family: 'Geometria', Helvetica, Arial, sans-serif !important;

	background: #f6f6f6;

	background: var(--bg-fill);
}

#root {
	background: #f6f6f6;
	background: var(--bg-fill);
}
