

.container-1_3Q1 {
	position: relative;
}

.dayWrapper-3RXmG {
	padding: 12px 1px 0 1px;
	height: 100%;
}

.dayWrapper_selected-1qSyX.dayWrapper-3RXmG {
	padding-top: 0;
}
