

.aeroexpress__content-ZxLl9 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.description-2acfk {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 2;

	text-align: justify;
}

.selectedService-20Ue6 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 45%;
	padding: 8px 0;

	border-bottom: 1px dashed #d9d9d9;

	text-align: justify;
}

.selectedService-20Ue6:first-of-type {
	padding-top: 0;
}

.selectedService__content-h0xOI {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.selectedService__date-3Q9GS,
.selectedService__info-_MWCC {
	font-size: 15px;

	color: var(--dark-gray);
}

.selectedService__date-3Q9GS {
	text-transform: lowercase;
}

.selectedService__serviceClass-2Cz_J {
	text-transform: capitalize;
}

.selectedService__price-1mkEs {
	font-weight: 600;
	color: var(--common-blue);
}

.description_not_selected-3_dwc {
	font-size: 15px;
}

.schedule-3CQ2m {
	margin-top: 10px;

	font-size: 15px;
}
