button.calendar-28I0H[type='button'] {
	border: none;

	font-weight: 500;
	font-size: 16px;

	color: var(--brand1);

	background-color: transparent;

	background-color: initial;
}

/* stylelint-disable */
.day__day-2ELo5 {
}

.day_start-1QJCl {
}

.day_end-bCzkF {
}

.day__inside-MXi74 {
}

.day_selected-2_1Ta {
}
/* stylelint-enable */
.buttons-1K9CK {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-height: 50px;
	padding: 0 20px;
}

button.clear-Qrluf {
	color: #9a9a9a;
	color: var(--exchange-calendar-clear-button-color, #9a9a9a);

	background-color: #ededed;

	background-color: var(--exchange-calendar-clear-button-bg, #ededed);
}

button.clear-Qrluf:hover {
	background-color: #e1e1e1;
	background-color: var(--exchange-calendar-clear-button-hover-bg, #e1e1e1);
}

.buttons-1K9CK button {
	margin-left: 10px;
}

.calendar-28I0H svg {
	margin-left: 6px;
}

div.modal-1rgvR {
	border-radius: 12px 12px 0 0;
}

span.closeIcon-1GsCd {
	top: 20px;
	right: 8px;

	width: 32px;
	height: 32px;
	padding: 7px;

	border-radius: 100px;

	color: var(--text-pale);

	background-color: var(--white);
}

.closeIcon-1GsCd:hover {
	background: var(--bg-elements);
}

.closeIcon-1GsCd svg {
	width: 18px;
	height: 18px;

	color: var(--text-pale);
}

/* stylelint-disable */
.day-3O8IX {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
	color: var(--text-pale);
}

.day_inside-3QrvN {
	width: 36px;
	font-size: 16px;
}

.day_selected-2_1Ta .day_inside-3QrvN {
	border-radius: 4px;
}

.day_startPeriod-2_yUb .day_inside-3QrvN {
	border-radius: 4px 0 0 4px;
}

.day_endPeriod-27EUX .day_inside-3QrvN {
	border-radius: 0 4px 4px 0;
}

.weeklyHeader__dayOfWeekName-3VRJg {
	color: var(--text-pale);
	font-size: 16px;
	font-weight: 500;
}

.day_startPeriod-2_yUb:after,
.day_endPeriod-27EUX:before {
	display: none;
}

.day_startPeriod-2_yUb .day_inside-3QrvN,
.day_endPeriod-27EUX .day_inside-3QrvN {
	width: 38px;
}

.day-3O8IX.day_inPeriod-3VnAW .day_inside-3QrvN {
	border-radius: 0;
	width: 100%;
}

.day-3O8IX.day_inPeriod-3VnAW .day_inside-3QrvN:hover {
	border: 1px solid transparent;
	background: var(--brand-1-0);
	color: var(--white);
}

.day-3O8IX.day_inPeriod-3VnAW,
.day-3O8IX.day_weekend-1Zcfz.day_inPeriod-3VnAW {
	margin: 0 0.5px;
	background: var(--brand-1-5);
}

.day_notAvailable-2e9Ux {
}

.month_month-1PNWy {
	padding-right: 0;
}

div.day_highlight-1Q8km:not(.day_notAvailable-2e9Ux, .day_inPeriod-3VnAW) {
	border-radius: 4px;
}

.monthHeader__select_month-1aGUt,
.monthHeader__select_year-3f_gH {
	font-size: 18px;
	font-weight: 700;
}

.monthHeader__select_year-3f_gH {
	color: var(--text-pale);
}

div.day_highlight-1Q8km > div {
	color: var(--text-base);
	font-weight: 500;
}

.datepicker__paper-2M-7G {
}

.datepicker__closeIcon-3dWl7 {
}

.datepicker__modal-2pnfJ {
	margin-bottom: 0;
}

.monthsList-3c7E2 {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 40px;
	gap: 40px;
	justify-content: center;
}

@media (max-width: 1024px) {
	button.calendar-28I0H {
		flex: 1;

		height: 48px;

		border-color: var(--base15);
		border-radius: 0;

		color: #ffffff;
		font-weight: 400;
		font-size: 14px;

		background: var(--base15);
	}

	button.calendar-28I0H:focus:hover {
		background: var(--base15);
	}

	.buttons-1K9CK {
		position: absolute;
		bottom: 0;
		z-index: 5;

		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 48px;
		margin: 0;
		padding: 0 20px;

		background: #ededed;

		background: var(--exchange-calendar-footer-buttons, #ededed);
	}

	button.button-3rxBp {
		color: #313131;

		background: transparent;

		background: var(--exchange-calendar-footer-buttons-bg, transparent);
	}

	button.button-3rxBp:hover {
		background: transparent;
		background: var(--exchange-calendar-footer-buttons-bg-hover, transparent);
	}

	.monthsWrapper__wrapper-1lQXn nav {
		display: block;
	}
}

@media screen and (max-width: 600px) {
	.datepicker__modal-2pnfJ {
		padding-bottom: 60px;
	}

	.monthsWrapper__wrapper-1lQXn nav {
		position: relative;
		display: flex;
		align-items: center;
	}

	.monthsWrapper__navPrev-1h9Dd,
	.monthsWrapper__navNext-36Lgu {
		display: none;
	}

	.monthHeader__header-3KZJc {
		height: 32px;
		line-height: 32px;
	}

	.monthsList-3c7E2 {
		flex-direction: column;
	}

	span.closeIcon-1GsCd {
		top: 13px;
		right: 12px;
		background-color: var(--bg-fill);
	}

	.closeIcon-1GsCd svg {
		color: var(--text-light);
	}

	.day-3O8IX {
		font-size: 16px;
	}
}

/* stylelint-enable */
