

.flights-XbixS {
	display: flex;
	flex-direction: column;
}

.flight-3KuEN {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	padding-bottom: 16px;

	border-bottom: 1px dashed #d9d9d9;
}

.flight-3KuEN:last-child {
	margin-bottom: 10px;
}

.flight_icon-3yYDb {
	flex-shrink: 0;
	width: 44px;
	height: 44px;
	margin-right: 8px;
}

.flight_count-ZJrKi {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	margin-right: 16px;

	border-radius: 50%;

	color: var(--white);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	text-align: center;

	background-color: var(--success);
}

.flight_info-1ansk,
.flight_direction-pLvEU {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.flight_info-1ansk {
	color: var(--text);
}

.flight_direction-pLvEU {
	color: var(--success-20);
}

/* stylelint-disable */
.card-20RPg {
}
.card__icon-3IJkf {
}
/* stylelint-enable */
