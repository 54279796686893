

.wrapper-10Jde {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 11px 16px 11px 52px;
}

.wrapper-10Jde:hover {
	cursor: pointer;
	background: var(--base90);
}

.fare-26ePW {
	color: var(--gray50);
	line-height: 21px;
}

.price-35wrA {
	font-weight: 500;
	color: var(--gray50);
	text-decoration: none !important;
}

.fare-26ePW:hover,
.fare-26ePW:hover .price-35wrA {
	color: var(--base-15);
}

.code-eqFDS {
	position: absolute;
	top: 11px;
	left: 11px;

	display: none;

	color: var(--base50);
	font-size: 44px;
	line-height: 44px;
}

div.tooltip-3auCJ {
	padding: 12px;

	transform: translateY(5px) !important;
}

.tooltip-3auCJ:after {
	top: -14px;
	bottom: auto;

	transform: translate(-50%, calc(100% - 1px)) rotate(180deg);
}

.fare-26ePW:hover .code-eqFDS {
	display: block;
}

.fee-23OoI {
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;

	color: #ffffff;
	font-size: 14px;
	line-height: 18px;
}

.fee-23OoI.active-3PYze {
	color: var(--warning);
}

.name-1h7tf {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	color: inherit;
	font-weight: 500;
	text-decoration: none;
}

.name-1h7tf:hover {
	text-decoration: none;
}

.name-1h7tf:hover .name__inner-38Baq {
	text-decoration: underline;
}

.bold-xDwD_ {
	color: var(--gray90);
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;
}
