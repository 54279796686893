

div.stub-2m79t:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;

	border-radius: 4px;

	background: #cccccc;

	animation: stubAnim-3NIyu infinite 2s;
	content: '';
}

@keyframes stubAnim-3NIyu {
	from {
		background-color: var(--gray20);
	}

	50% {
		background-color: var(--gray10);
	}

	to {
		background-color: var(--gray20);
	}
}
