

.wrapper-3Y-Xw {
	display: flex;
	align-items: center;
}

.title-zrpj7 {
	margin-right: 4px;

	color: var(--gray50);
	font-size: 14px;
	line-height: 14px;
}

button.button--zfHX {
	margin: 0 4px;

	border-color: #ffffff;
}

.filter_wrapper-2ggG9 {
	position: relative;
}

.input_wrapper-C7wfB {
	position: absolute;
	bottom: -86px;
	left: 100%;
	z-index: 3;

	width: 360px;
	max-width: 360px;
	padding: 16px;

	border-radius: 8px;

	box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
	transform: translateX(-100%);
	background: #ffffff;
}

.button--zfHX.active-1pTmj,
.button--zfHX.active-1pTmj:hover {
	border-color: var(--base);

	color: #ffffff;

	background: var(--base);
}

div.text_field-JNpNX > div:before,
div.text_field-JNpNX > div:after {
	display: none;
}

div.adornment-11etc {
	margin-left: -16px;

	transform: translate(-10px, 1px);

	cursor: pointer;
}

.close-2JKkO svg {
	width: 17px;
	height: 17px;
}

button.input_trigger-YMa6K {
	width: 130px;
	min-width: 130px;
	max-width: 150px;
}

.filter_text-2bJkU {
	display: inline-block;
	overflow: hidden;

	text-overflow: ellipsis;
	white-space: nowrap;
}

button.with_input-3IPZT {
	position: relative;

	padding-right: 25px;

	text-transform: uppercase;
}

.close-2JKkO.in_button-vASUf {
	position: absolute;
	right: 5px;
	top: 50%;

	transform: translateY(-45%);
}

div.text_field-JNpNX input {
	padding: 11px 30px 11px 11px;

	border: 1px solid var(--base);

	color: var(--gray80);
	font-weight: 500;
	font-size: 14px;
	text-transform: uppercase;

	background: var(--gray05);
}

div.text_field-JNpNX input:-ms-input-placeholder {
	text-transform: none;
}

div.text_field-JNpNX input::placeholder {
	text-transform: none;
}
