.wrapper-2OJ9a {
	max-width: 1100px;
	margin: 30px auto 0;

	border-radius: 4px;

	background-color: #ffffff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.wrapper_arrowed-1iNCK {
	margin-top: 20px;
}

.wrapper_arrowed-1iNCK:before {
	content: '';

	position: absolute;
	left: 50%;

	width: 0;
	height: 0;

	border-style: solid;
	border-width: 0 11px 12px 11px;
	border-color: transparent transparent #ffffff transparent;

	transform: translate(-50%, -100%);
}

.header-34rFP {
	padding: 24px;

	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
}

.icon-36Ybb {
	display: flex;
	justify-content: center;
	margin-bottom: 17px;
}

.body-1GC6L {
	padding: 20px 80px;

	border-radius: 0 0 4px 4px;

	color: var(--common-gray);
	font-size: 15px;
	line-height: 160%;
	text-align: center;

	background-color: #f9f9f9;
}

.body-1GC6L a {
	color: var(--text-link);
}

@media screen and (max-width: 600px) {
	.body-1GC6L {
		padding: 20px 20px;
	}

	.wrapper_arrowed-1iNCK:before {
		display: none;
	}

	.icon-36Ybb {
		display: none;
	}
}
