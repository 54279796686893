.modal__paper-3TX9C {
	width: max-content;
}

div.modal__root-qbT-s {
	z-index: 1501 !important;
}

.root-SFT9U {
	width: 728px;
	padding: 0 34px 40px;
}

.row-38aLR {
	display: flex;
}

.body-1zIuO {
	margin-top: 20px;
}

.modalHeader-2IgUm {
	position: relative;

	width: 100%;
	padding: 30px 35px;

	color: var(--text);
	font-weight: 700;
	font-size: 22px;
}

.close-3c8BQ {
	position: absolute;
	top: 28px;
	right: 32px;

	margin: auto;

	cursor: pointer;
}
/* stylelint-disable */
.withTabs-3mL7b {
}
/* stylelint-enable */

@media (max-width: 768px) {
	.modal__paper-3TX9C {
		width: auto;
	}

	.root-SFT9U {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.row-38aLR {
		display: block;
	}

	.root-SFT9U {
		width: 100vw;
		padding: 12px;
	}

	.modalHeader-2IgUm {
		position: relative;

		display: flex;
		align-items: center;
		height: 56px;
		padding: 0 15px;

		color: #262626;
		font-weight: 600;
		font-size: 18px;

		box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	}

	.close-3c8BQ {
		position: absolute;
		right: 12px;
		top: 12px;

		cursor: pointer;
	}
}
