.container-jC0B- {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 16px 20px;
}

button.button-htD3V {
	margin-right: 4px;

	color: var(--text-link);
	font: var(--small-desktop-accent);
}

button.button-htD3V:last-child {
	margin-right: 0;
	margin-bottom: 0;
}

.button__icon-268Tq {
	display: none;
}

button.button-htD3V.cancel-1C4r8 {
	color: var(--text-light);
}

button.sendToEmailButton-3eu_- {
	margin-left: auto;
}

button.sendToEmailButton_disabled-xuM8F {
	opacity: 0.5;
	cursor: not-allowed;
}

@media screen and (max-width: 600px) {
	.container-jC0B- {
		align-items: flex-start;
		flex-direction: column;
		grid-gap: 4px;
		gap: 4px;
	}

	.container-jC0B-.disabled-2vNha {
		align-items: center;
	}

	button.button-htD3V {
		margin-right: 0;

		color: var(--text-mid);
		font-weight: 400;
		font-size: 14px;
	}

	.disabled-2vNha button.button-htD3V {
		border-radius: 4px;

		color: var(--white);
		font-weight: 700;

		background: var(--brand-3-0);
	}

	.button__icon-268Tq {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 24px;
		min-width: 24px;
		height: 24px;

		margin-right: 16px;

		color: var(--text-pale);
	}

	button.button-htD3V.cancel-1C4r8 {
		order: 1;

		color: var(--error-icon);
	}

	button.button-htD3V.cancel-1C4r8 .button__icon-268Tq {
		color: var(--error-icon);
	}

	div.scrollBody-2HiOn {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.paper-1aZEw {
		min-height: auto;

		border-radius: 8px 8px 0 0;
	}
}
