

.wrapper-2iXNI {
	height: 100%;
	display: flex;
	flex-direction: column;
	min-width: 375px;
	max-width: 375px;
}

.cart-66V9k {
	flex: 1;
	overflow-y: auto;

	counter-reset: cart-counter;
}

.toolbar-D9kC- {
	flex: 0;
}
