.wrapper-SEH68 {
	width: 100%;
	height: 100%;
}

@media (max-width: 1024px) {
	.container-3z6bj {
		padding: 0 20px;
	}
}

.header-1wOXo {
	position: relative;

	min-height: 122px;
	margin-bottom: 50px;

	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.header-1wOXo {
		padding: 20px 0;
		margin-bottom: 0;
		min-height: auto;

		background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;

		background: initial;
	}
}

.header_wrapper-TAGKK {
	padding: 40px 130px;

	grid-gap: 8px;

	gap: 8px;

	display: flex;
	align-items: center;
}

@media (max-width: 1024px) {
	.header_wrapper-TAGKK {
		padding: 40px 20px;
	}
}

@media screen and (max-width: 600px) {
	.header_wrapper-TAGKK {
		padding: 0;
	}
}

.title-lSi-p {
	margin: 0 19px 0 0;

	color: var(--gray50);
	font-size: 34px;
	line-height: 34px;
}

@media (max-width: 1024px) {
	.title-lSi-p {
		flex: 1;

		font-size: 26px;
	}
}

@media screen and (max-width: 600px) {
	.title-lSi-p {
		font-size: 21px;
		line-height: 31px;
		color: var(--gray80);
	}
}

.close__text-lnwSV {
	display: none;
	margin-right: 8px;

	font: var(--small-desktop-mini);
}
