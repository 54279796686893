

div.tooltip-31sJz {
	transform: translateY(-8px) !important;
}

div.tooltip-31sJz:after {
	border-top-color: var(--brand-1-0);
}

div.tooltip-31sJz.tooltip_recommend-2YswN {
	background: var(--brand-1-0);
}

.tooltip-31sJz.tooltip_withInfo-Gugur:after,
.tooltip-31sJz.tooltip_recommend-2YswN:after {
	border-top-color: var(--brand-1-0);
}

div.info-2FyuM,
.tooltip-31sJz.tooltip_recommend-2YswN .info-2FyuM {
	background: var(--brand-1-1);
}
