.flight-wOECR {
	position: relative;

	margin-left: 40px;

	border: 1px solid var(--brand-1-3);

	border-radius: 4px;
}

.flight-wOECR:empty {
	border: 0;
}

@media screen and (max-width: 600px) {
	.flight-wOECR {
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		grid-gap: 20px;
		gap: 20px;
	}

	.flight-wOECR.transfer-25al1 {
		grid-gap: 0;
		gap: 0;
	}
}

.segment-3y1Hp {
	border-radius: 4px;
}

.segment-3y1Hp.transfer-25al1.first-XNlg_ {
	border-bottom: 1px dashed var(--line-dot-line);
}

.segment-3y1Hp.transfer-25al1.last-2WYfG {
	border-top: 0;
}

.selected-ex3nA {
	background: var(--brand-1-5);
}

.flight-wOECR.selected-ex3nA {
	margin-left: 0;
	width: 100%;
}

.segment-3y1Hp,
.flightNumber-3A3BR {
	background: var(--gray);
}

.segment-3y1Hp.selected-ex3nA,
.flightNumber-3A3BR.selected-ex3nA {
	background: var(--brand-1-5);
}

.stopsInfo__stop-3-O5e {
	bottom: -16px;
}

.stopsInfo__label-3RV3S {
	border-radius: 20px;
}
