.point-xHwcA {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	height: 100%;
	flex: 1;
}

.point_arrival-24M7C {
	align-items: flex-start;
}

.mobile-3N83N.point-xHwcA,
.mobile-3N83N.point-xHwcA.point_arrival-24M7C {
	align-items: center;
}

.date__container-2-QuR {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	align-items: flex-end;
}

.point_arrival-24M7C .date__container-2-QuR {
	align-items: flex-start;
}

.time-39idp {
	margin-bottom: 3px;

	color: var(--text);
	font-weight: 600;
	font-size: 42px;
}

.point_thin-3ifg8 .time-39idp {
	color: var(--gray30);
}

.date-Olz8r,
.airport-1slwI {
	font-size: 13px;
	color: var(--text-light);
	text-align: right;
}

.airport-1slwI {
	text-transform: capitalize;
}

.point_arrival-24M7C .airport-1slwI {
	text-align: left;
}

.mobile-3N83N .time-39idp {
	font-size: 28px;
	text-align: center;
}

.mobile-3N83N .date-Olz8r,
.mobile-3N83N .airport-1slwI {
	text-align: center;
}

.mobile-3N83N .date-Olz8r {
	margin-bottom: 4px;
}

@media screen and (max-width: 320px) {
	.date-Olz8r {
		font-size: 12px;
		white-space: nowrap;
	}

	.date__container-2-QuR,
	.point_arrival-24M7C .date__container-2-QuR {
		align-items: center;
	}

	.mobile-3N83N .time-39idp {
		font-size: 21px;
	}
}
