div.root-x8-7E {
	background: var(--bg-canvas);
	box-shadow: none;
}

div.root-x8-7E:before {
	content: none;
}

div.root-x8-7E:not(:last-of-type) {
	margin-bottom: 24px;
}

.root-x8-7E .summary-1rU_m {
	padding: 24px 64px;
}

.root-x8-7E .summary-1rU_m > div {
	margin: 0;
}

.root-x8-7E .details-2A32U {
	padding: 0 64px 24px;
}

.root-x8-7E .heading-1AqLE {
	font-weight: 600;
	font-size: 23px;
	line-height: 30px;
}

.root-x8-7E .details-2A32U,
.root-x8-7E .heading-1AqLE {
	color: var(--fg-default);
}

.root-x8-7E .expandIcon-22fof svg {
	transform: scale(1.3);
}

.root-x8-7E .expandIcon-22fof svg path {
	fill: var(--accent-default);
}

@media screen and (max-width: 600px) {
	.root-x8-7E .summary-1rU_m {
		padding: 20px 24px;
	}

	.root-x8-7E .details-2A32U {
		padding: 0 20px 24px;
	}

	.root-x8-7E .heading-1AqLE {
		font-size: 20px;
	}
}
