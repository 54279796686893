

.item-2jqrK {
	display: flex;
	padding: 12px 20px;
	align-items: center;
	justify-content: space-between;

	border-radius: 4px;
	border: 1px solid var(--line-separator);

	background: var(--bg-white);
	cursor: pointer;
}

.info-1cyrO {
	display: flex;
	flex-direction: column;
	grid-gap: 4px;
	gap: 4px;

	color: var(--text-mid);
	font-size: 18px;
	line-height: 23px;
}

.type-3vTAE {
	font-weight: 700;
}

.icon-QnxMn {
	color: var(--text-light);
}

.item-2jqrK.selected-k3Tg7 {
	border-color: var(--brand-1-0);
}

.item-2jqrK.selected-k3Tg7 .icon-QnxMn {
	color: var(--brand-1-0);
}
