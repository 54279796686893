

.segment-3BUo3 {
	position: relative;

	display: flex;
	align-items: center;
	padding: 20px 16px;
}

.stopPointInfoMessage-qnL65 {
	padding: 14px 20px;

	border-top: 1px dashed var(--gray-line);
}

.stopPointInfoMessage__htmlMessage-1MxFE,
.stopPointInfoMessage__htmlMessage-1MxFE > div {
	width: 100%;
	height: 117px;
}

.segment-3BUo3.canceled-28Ma9 {
	opacity: 0.5;
}

.segment-3BUo3.segment_additional-3il9j {
	border-top: 1px dashed var(--gray-line);
}

.segment-3BUo3.oneStop-Lhtyy {
	padding-bottom: 36px;
}

.segment-3BUo3.oneStop-Lhtyy:after {
	content: '';

	position: absolute;
	left: 0;
	bottom: 30px;

	width: 100%;
	height: 1px;

	background: var(--gray10);
}

.flightInfo-UgjDn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	max-width: 15%;
	width: 100%;
	height: 100%;
	margin-right: 20px;

	font-size: 16px;
}

.flightInfo__containerAirline-2c83Y {
	display: none;
}

.flightInfo__date-1faz2 {
	color: var(--gray80);
	font-weight: 500;
}

.flightInfo__airline-3-QbF {
	color: #808080;
	font-size: 13px;
}

.flightInfo__singleFlight-3bKg7 .flightInfo__airline-3-QbF {
	font-size: 16px;
	color: var(--base-15);
}

.flightInfo__icon-3uIk2 {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	width: 28px;
	height: 28px;

	margin-right: 12px;

	border-radius: 4px;
}

.flightInfo__icon-3uIk2 img {
	width: 100%;
	height: 100%;
}

.flightInfo__container-2hKpb {
	display: flex;
	align-items: center;
	margin-bottom: 8px;

	flex: 1;
}

.route__plane-1sfq4 {
	display: flex;
	align-items: center;
	height: 100%;
}

.flightInfo__nextDay-Piukx .flightInfo__date-1faz2 {
	color: var(--error-20);
}

.route-gdANG {
	flex: 1;

	color: var(--gray90);
}

.planeContainer-1mJOP {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	grid-gap: 10px;
	gap: 10px;
	height: 100%;
	max-width: 25%;
}

.canceled__warning-16v2A {
	flex: 1;
	justify-content: flex-end;
	max-width: 25%;

	color: var(--error20);
	font-weight: 500;
	font-size: 14px;
	text-align: right;
}

.amenities-1m-tW {
	margin-left: 12px;

	border-left: 1px solid var(--line-separator);
}

.amenities-1m-tW svg {
	width: 16px;
	height: 16px;
}

/* stylelint-disable */
.plane-3bqCD {
}

.plane_return-3LTvC {
}
/* stylelint-enable */
.planeInfo-2PGTA {
	display: flex;
	flex: 0;
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
	min-width: 12%;
	height: 100%;

	color: var(--gray50);
	font-size: 13px;
}

.aircraft-20Jok {
	flex: 1;
	max-width: min-content;
	min-width: 93px;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: right;
}

.route__info-4MCYk {
	position: relative;

	flex: 1;
	padding: 0 30px;
	display: flex;
	height: 100%;
	justify-content: center;
}

.route__time-3OjoV {
	flex: 0;
	min-width: 30%;
	max-width: 30%;

	font-size: 24px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.route__time-3OjoV span {
	text-overflow: ellipsis;
	overflow: visible;
}

.totalDuration__decorator-2-9Pd {
	position: relative;

	width: 100%;
	height: 14px;

	border-top: 1px dashed var(--gray-line);
}

.totalDuration-3wbdn {
	position: absolute;
	bottom: 2px;
	left: 0;

	z-index: 2;

	width: 100%;

	color: var(--text);
	font-weight: 600;
	font-size: 13px;
	text-align: center;

	transform: translateY(200%);
}

.totalDuration-3wbdn span {
	padding: 0 4px;

	background: #ffffff;
}

.segment__technicalStops-1xxlF {
	padding: 8px 10% 8px 16px;

	border-top: 1px solid var(--gray10);

	color: var(--error-20);
	font-weight: 400;
	font-size: 14px;
	line-height: 1;
	text-align: center;
}

.segment__technicalStops-1xxlF span:not(:last-child) {
	margin-right: 4px;
}

.segment__technicalStops-1xxlF span:not(:last-child):after {
	content: '.';
}

.operatingCarrier-28GNu {
	margin-top: 2px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 10px;
	line-height: 1.3;
	text-align: center;
}
