.equipment_baggage-2ngSO,
.overweight_baggage-3nMHo,
.extra_baggage-123Ib,
.included_baggage-X1klX {
	background: unset;
}

.baggage-1iOra {
	width: 100%;
	height: 100%;
	padding-bottom: 80px;

	display: flex;
	flex-direction: column;
}

.fullscreenModal__content-WrkxT {
	background-color: #ededed;
}

.head-2QAtX {
	padding: 25px 0;

	border-bottom: 1px solid #d9d9d9;

	background: #ffffff;
}

.head__wrapper-OH2xn {
	position: relative;

	max-width: 1100px;
	margin: 0 auto;
}

.header-3d2xP {
	padding-bottom: 8px;

	font-weight: 600;
	font-size: 28px;
}

.subheader-29DYx {
	font-size: 16px;
	line-height: 28px;
}

div.infoMessage__htmlMessage-3ydXF {
	height: 48px;
	width: 100%;
}

.items-tgvM2 {
	display: inline-flex;
	flex-wrap: wrap;
	max-width: 1100px;
	min-width: 1100px;
	margin: 0 auto;
	justify-content: center;
}

div.items-tgvM2:last-of-type {
	margin-bottom: 50px;
}

.newBaggage-2n32u {
	display: flex;
	flex: 1;
	margin-top: 25px;
	max-width: 100%;
}

.items-tgvM2.additional-3fNOy {
	padding-top: 15px;
	width: 100%;
	grid-gap: 14px;
	gap: 14px;
}

.additionalBaggage-nZQog {
	flex: 1;
}

.additionalBaggage-nZQog:not(:last-child) {
	margin-right: 15px;
}

.newBaggage-2n32u > div {
	flex: 1;
	max-width: 100%;
	min-width: 0;
}

.newBaggage-2n32u > div:not(:last-child) {
	margin-right: 15px;
}

.newBaggage-2n32u > div:first-child:last-child {
	margin-right: 0;
}

.passenger-12Vjq {
	position: relative;
	top: -11px;
	z-index: 1;

	margin-bottom: 1px;
}

.passenger_active-2gzFQ {
	position: relative;
}

.rules-1cPaw {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: auto;

	height: 20px;
	margin: auto;
	padding-left: 24px;

	color: var(--common-blue);
	font-weight: 500;
	font-size: 14px;

	cursor: pointer;
}

.rules-1cPaw:after {
	position: absolute;
	top: -2px;
	left: 0;

	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjUgOS45OTk5NkMxLjY2NjUgNS4zOTk5NiA1LjM5OTg0IDEuNjY2NjMgOS45OTk4NCAxLjY2NjYzQzE0LjU5OTggMS42NjY2MyAxOC4zMzMyIDUuMzk5OTYgMTguMzMzMiA5Ljk5OTk2QzE4LjMzMzIgMTQuNiAxNC41OTk4IDE4LjMzMzMgOS45OTk4NCAxOC4zMzMzQzUuMzk5ODQgMTguMzMzMyAxLjY2NjUgMTQuNiAxLjY2NjUgOS45OTk5NlpNMTAuODMzMiA5LjE2NjYzVjE0LjE2NjZIOS4xNjY1VjkuMTY2NjNIMTAuODMzMlpNOS45OTk4MyAxNi42NjY2QzYuMzI0ODMgMTYuNjY2NiAzLjMzMzE2IDEzLjY3NSAzLjMzMzE2IDkuOTk5OTZDMy4zMzMxNiA2LjMyNDk2IDYuMzI0ODMgMy4zMzMyOSA5Ljk5OTgzIDMuMzMzMjlDMTMuNjc0OCAzLjMzMzI5IDE2LjY2NjUgNi4zMjQ5NiAxNi42NjY1IDkuOTk5OTZDMTYuNjY2NSAxMy42NzUgMTMuNjc0OCAxNi42NjY2IDkuOTk5ODMgMTYuNjY2NlpNMTAuODMzMiA1LjgzMzI5VjcuNDk5OTZIOS4xNjY1VjUuODMzMjlIMTAuODMzMloiIGZpbGw9IiMzMjkwRkYiLz4KPC9zdmc+Cg==) center no-repeat;
	content: '';
}

.baggage__passenger-2waJ0 {
	z-index: 4;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	height: 50px;

	border-radius: 8px 8px 0 0;

	color: #ffffff;
	text-transform: capitalize;

	background: var(--common-blue);
}

.baggage__passenger__value-3UltW {
	display: flex;
	align-items: center;
}

.baggage__passenger__icon-3QPuu {
	width: 24px;
	height: 24px;
	margin-left: 8px;

	opacity: 0.5;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5ODQgNkw4LjU4OTg0IDcuNDFMMTMuMTY5OCAxMkw4LjU4OTg0IDE2LjU5TDkuOTk5ODQgMThMMTUuOTk5OCAxMkw5Ljk5OTg0IDZaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo=) center no-repeat;

	transform: rotate(90deg);

	transition: all 0.15s;
}

.baggage__passenger_active-3fKaY .baggage__passenger__icon-3QPuu {
	transform: rotate(-90deg);
}

@media (max-width: 1024px) {
	.items-tgvM2 {
		display: flex;
		flex-direction: column;
		min-width: auto;
		max-width: 100%;
		width: 100%;
		padding: 12px;
	}

	.newBaggage-2n32u > div {
		margin-bottom: 12px;
	}

	.additional-3fNOy > div:first-child {
		margin-bottom: 12px;
	}

	.head-2QAtX {
		padding: 25px 20px;

		background-color: var(--baggage-head-background);
	}

	.header-3d2xP {
		font-size: 20px;

		color: var(--baggage-head-title-color);
	}

	.subheader-29DYx {
		font-size: 14px;
		color: var(--baggage-head-subtitle-color);
	}

	.newBaggage-2n32u {
		flex-direction: column;
	}
}

@media screen and (max-width: 600px) {
	.baggage-1iOra {
		padding-bottom: 0;
	}

	div.infoMessage__htmlMessage-3ydXF {
		min-width: unset;
		width: 100vw;
	}
}

@media (min-width: 1024px) {
	.infoMessage-kJRGP {
		margin: 0 auto;
		margin-top: 25px;
		max-width: 1100px;
		min-width: 1100px;
	}
}
