.table-1R9T4 {
	width: 100%;

	border-collapse: collapse;

	font-size: 15px;
	line-height: 23px;
}

.table-1R9T4 th {
	padding: 0 0 16px;

	font-weight: 500;
}

.table-1R9T4 td {
	padding: 12px 0;

	border-top: 1px solid #e4e4e4;

	color: #595959;
}

.table-1R9T4 th,
.table-1R9T4 td {
	text-align: right;
}

.table-1R9T4 tfoot td {
	color: var(--common-blue);
	font-weight: 500;

	text-align: right;
}

.table-1R9T4 th:nth-child(2),
.table-1R9T4 td:nth-child(2) {
	padding-right: 15px;
}

.table-1R9T4 thead th:first-of-type,
.table-1R9T4 tbody td:first-of-type {
	text-align: left;
}

@media screen and (max-width: 320px) {
	.table-1R9T4 td:first-of-type {
		max-width: 120px;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.money-3AFqW {
	padding-left: 0;

	color: var(--common-blue);
	font-weight: 500;
}
