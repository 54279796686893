.image-1twVk {
	display: none;
}

.title-vQX0q {
	padding-left: 48px;

	color: var(--text-base);
	line-height: 1.2;
}

.seat_free-1gc82 .title-vQX0q:before,
.seat_economy-1PSrZ .title-vQX0q:before,
.seat_business-3m_UT .title-vQX0q:before,
.seat_preferred-2q4W3 .title-vQX0q:before {
	width: 36px;
	height: 36px;

	margin-right: 12px;
}

.seat-2zxAD[data-rfisc='STX'] .title-vQX0q:before {
	background: #ffc692;
}

.seat-2zxAD.seat_business-3m_UT .title-vQX0q:after,
.seat-2zxAD[data-rfisc='SR1'] .title-vQX0q:after {
	content: '';

	position: absolute;
	top: 4px;
	left: 12px;

	width: 12px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
}

.seat-2zxAD[data-rfisc='SR1'] .title-vQX0q:after {
	transform: rotate(180deg);
}

.description-32Fqa {
	color: var(--text-mid);
	font-size: 14px;
	line-height: 1.1;
}

.description-32Fqa:not(:empty) {
	margin-top: 2px;
}

.seat__info-DguG4 {
	position: relative;

	border-top: none;
}

.seat__info-DguG4:before {
	content: '';

	position: absolute;
	top: 0;
	left: 12px;
	right: 12px;

	height: 100%;

	border-top: 1px dashed var(--line-separator);

	pointer-events: none;
}

.features-3iZuR {
	color: var(--text-light);
	font-size: 14px;
	line-height: 1.3;
}

.feature-28c-l:not(:last-child) {
	padding-bottom: 6px;
}

.price__wrapper-2-gFi,
.price-1b0M- {
	color: var(--text-base);
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
}

@media screen and (max-width: 600px) {
	.title-vQX0q {
		padding-left: 52px;
		min-height: 40px;
	}

	.title-vQX0q span {
		width: 40px;
	}

	.seat_free-1gc82 .title-vQX0q:before,
	.seat_economy-1PSrZ .title-vQX0q:before,
	.seat_business-3m_UT .title-vQX0q:before,
	.seat_preferred-2q4W3 .title-vQX0q:before {
		top: 7px;

		width: 40px;
		height: 40px;

		margin-right: 12px;
	}

	.seat-2zxAD.seat_business-3m_UT .title-vQX0q:after,
	.seat-2zxAD[data-rfisc='SR1'] .title-vQX0q:after {
		left: 14px;
		top: 10px;
	}

	.seat-2zxAD .title_withDescription-3LWe2 {
		justify-content: flex-start;
	}

	.header-3-SRz {
		margin-top: -12px;
		padding-bottom: 16px;
	}

	.seat-2zxAD {
		box-shadow: none;
		background: transparent;
	}

	.header-3-SRz,
	.seat__info-DguG4 {
		padding-left: 0;
		padding-right: 0;
	}

	.seat__info-DguG4:before {
		left: 0;
		right: 0;
	}

	.note-1YhZ5 {
		border: none;
		border-radius: 4px;
	}

	.note__header-2HnMO {
		font-weight: 400;
		line-height: 1;
	}

	.note__header-2HnMO svg {
		transform: translateY(-1px);
	}

	.note__header-2HnMO,
	.note__header-2HnMO svg,
	.additionalInfo-3wcFt {
		color: var(--text-base);
	}

	.price__wrapper-2-gFi,
	.price-1b0M- {
		font-size: 20px;
	}

	.features-3iZuR {
		display: none;
	}
}
