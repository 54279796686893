.container-1yvcm {
	display: flex;
	align-items: center;
	padding: 20px;
}

.info-6E0El {
	flex: 1;
	display: flex;
	align-items: center;
}

.passenger-pjCCC {
	display: flex;
	flex-direction: column;
}

.passenger__label-3L1cJ {
	display: none;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.passenger__name-3Vuu_ {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
	text-transform: capitalize;
}

.seats-15RFk {
	display: flex;
	align-items: center;

	margin-right: 20px;
	margin-left: auto;
	padding-right: 20px;

	border-right: 1px solid var(--line-separator);
}

.seats__label-1R1tB {
	display: none;

	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.seats__icon-fLjqW {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	min-width: 32px;
	height: 32px;
	margin-right: 4px;

	color: var(--brand-1-0);
}

.seats__text-1OKPg {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.controls-3t-H8 {
	display: flex;
	align-items: center;
	max-width: 60%;
	margin-left: auto;
}

@media (max-width: 768px) {
	.container-1yvcm {
		flex-direction: column;
		align-items: inherit;
	}

	.info-6E0El {
		margin-bottom: 16px;
	}

	.seats-15RFk {
		margin-right: 0;
		padding-right: 0;

		border-right: none;
	}

	.controls-3t-H8 {
		margin-left: 0;
	}
}

@media screen and (max-width: 600px) {
	.container-1yvcm {
		margin: 0 20px;
		padding: 0;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.info-6E0El {
		margin-bottom: 0;
		padding: 12px 0;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.container-1yvcm.disabled-2Zw6W,
	.disabled-2Zw6W .info-6E0El {
		border-bottom: none;
	}

	.passenger-pjCCC {
		max-width: 80%;
	}

	.disabled-2Zw6W .passenger-pjCCC {
		width: 100%;
		max-width: 100%;
		align-items: center;
	}

	.passenger__label-3L1cJ {
		display: block;
	}

	.passenger__name-3Vuu_ {
		color: var(--brand-1-0);
		font-size: 18px;
		text-transform: uppercase;
	}

	.seats-15RFk {
		flex-direction: column;
		align-items: flex-end;

		text-align: right;
	}

	.seats__label-1R1tB {
		display: block;
	}

	.seats__icon-fLjqW {
		display: none;
	}

	.seats__text-1OKPg {
		font-size: 18px;
	}

	.controls-3t-H8 {
		max-width: 100%;
		padding: 12px 0;
	}

	.disabled-2Zw6W .controls-3t-H8 {
		display: none;
	}
}
