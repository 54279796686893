.startSearch-S4Zt3 {
	display: inline-block;
	height: var(--input-height);

	flex-basis: 128px;
	flex-shrink: 0;
}

.startSearch-S4Zt3:not(.startSearch_iconMode-GEPyG) > svg {
	display: none;
}

.startSearch-S4Zt3 button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 48px;

	font-weight: 500;
	font-size: 16px;
}

.startSearch_multiCity-cB913 {
	flex-basis: 230px;
}

@media (max-width: 1024px) {
	.startSearch_multiCity-cB913 button {
		border-radius: 0 4px 4px 0;
	}
}

.startSearch_multiCity-cB913.startSearch_withCurrency-2ePKS button {
	border-radius: 0 4px 4px 0;
}

.startSearch-S4Zt3:hover button {
	background-color: var(--start-search-button-hover);
}

.startSearch-S4Zt3.startSearch_iconMode-GEPyG {
	flex-basis: 64px;
}

.startSearch-S4Zt3.startSearch_iconMode-GEPyG button {
	min-width: 64px;
	padding: 0 16px;

	border-radius: 0 4px 4px 0;
}

.startSearch-S4Zt3.startSearch_iconMode-GEPyG .text-1QZce {
	display: none;
}

@media (max-width: 1024px) {
	.startSearch-S4Zt3 {
		flex: 3 0 auto;
	}
}

@media (max-width: 1024px) {
	.startSearch_multiCity-cB913 {
		flex-basis: auto;
	}
}

@media screen and (max-width: 600px) {
	.startSearch-S4Zt3 {
		display: block;
		margin-top: 4px;
	}

	.startSearch_advanced-2Kwq3.startSearch-S4Zt3 {
		position: relative;
		left: 0;
	}

	.startSearch_multiCity-cB913 button {
		border-radius: 0 0 4px 4px;
	}

	.startSearch-S4Zt3.startSearch_iconMode-GEPyG button {
		border-radius: 0 0 4px 4px;
	}
}
