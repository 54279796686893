button.button-13Qki {
	height: 52px;

	border-radius: 6px;

	background: var(--brand-3-0);
}

button.button-13Qki:hover {
	background: var(--brand-3--1);
}

button.button-13Qki.back-1FFjX {
	width: 40px;
	min-width: 40px;
	height: 40px;

	border-radius: 50%;

	color: transparent;

	background: var(--bg-fill);
	overflow: hidden;
}

button.button-13Qki.back-1FFjX:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 60%;
	height: 60%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=) no-repeat 50% 50% / contain;
	transform: translate(-50%, -50%);
}

@media screen and (max-width: 600px) {
	.controls-2gCwe {
		height: 64px;

		padding: 12px;
	}

	button.button-13Qki {
		height: 40px;
	}
}
