.services-1aH7x {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 30px 40px;
	grid-gap: 12px;
}

.services-1aH7x > div {
	width: 100%;
}

@media screen and (max-width: 600px) {
	.services-1aH7x {
		grid-template-columns: 1fr;
		padding: 0;
	}
}
