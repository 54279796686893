.stub__content-2kEVy {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	min-height: 184px;
	padding: 20px;
	grid-gap: 20px;
	gap: 20px;

	border-radius: 6px;

	background: var(--white);
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
}

.stub__image-3c69i {
	width: 40%;
	min-width: 40%;
}

.stub__imageWrapper-1RYwe {
	position: relative;

	padding-top: 46%;
}

.stub__imageWrapper-1RYwe img {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 70%;
	height: 70%;
	object-fit: contain;

	transform: translate(-50%, -50%);
}

.stub__buttons-GUnQn {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
	width: 100%;
}

button.stub__button-37IEF {
	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 700;

	background: var(--brand-1-5);
}

button.stub__button-37IEF:hover {
	background: var(--brand-1-4);
}

@media (max-width: 1024px) {
	.stub__planeIcon-1FZx7 {
		padding-left: 0;
	}
}

@media screen and (max-width: 600px) {
	.stub__content-2kEVy {
		display: block;
		padding: 28px;
	}

	.stub__image-3c69i {
		width: 100%;
		min-width: 100%;
		margin-bottom: 28px;
	}
}
