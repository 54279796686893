.card-3f1HI {
	position: relative;

	padding: 32px 40px;

	color: var(--accent-default);
	text-align: center;
}

.card_minimum-2pTmu {
	background: var(--white);
}

.card_optimum-1oh28 {
	background: var(--accent-subtle);
}

.card_maximum-2X7fL {
	color: var(--white);

	background: linear-gradient(180deg, #aa65e0 0%, var(--accent-default) 100%);
}

.tag-2W_vr {
	position: absolute;
	top: 42px;
	right: 20px;

	padding: 4px 12px;

	border-radius: 100px;

	font-size: 14px;

	background: var(--white);
}

.icon-3BKDH svg {
	width: 48px;
	height: 48px;
}

.type-2SCKR {
	margin: 25px 0;

	font-size: 32px;
}

.services-1mCcy {
	display: inline-block;
	margin: 0 0 25px;
	padding-left: 15px;

	font-weight: 500;
}

.service-23jpd {
	display: flex;
	align-items: center;

	color: var(--fg-default);

	text-align: left;

	list-style-type: none;
}

.service-23jpd:not(:last-of-type) {
	margin-bottom: 14px;
}

.service-23jpd svg {
	margin-right: 5px;
	min-width: 24px;
}

.service-23jpd svg path {
	fill: var(--accent-default);
}

.price-3dUKI,
button.select-JGFic {
	font-size: 22px;
}

.type-2SCKR,
.price-3dUKI,
button.select-JGFic,
.tag-2W_vr {
	font-weight: 600;
}

.card-3f1HI button.select-JGFic {
	max-width: 100%;
	margin-top: 25px;
	padding: 16px 24px;

	border-radius: 6px;

	color: var(--white);
	line-height: 29px;
	white-space: normal;
	text-transform: unset;

	background: var(--accent-default);
}

.card_minimum-2pTmu .tag-2W_vr {
	background: var(--accent-subtle);
}

.card_maximum-2X7fL .tag-2W_vr {
	color: var(--accent-default);
}

.card_maximum-2X7fL .service-23jpd {
	color: var(--white);
}

.card_maximum-2X7fL .service-23jpd svg path {
	fill: var(--white);
}

.card_maximum-2X7fL button.select-JGFic {
	background: var(--accent-secondary);
}

@media (max-width: 1024px) {
	.title-1bPOm {
		width: 200px;
		min-width: 200px;
		margin-right: 15px;
	}

	.type-2SCKR {
		font-size: 22px;
	}

	.services-1mCcy {
		padding-left: 0;
		padding-right: 15px;
	}
}

@media (max-width: 768px) {
	.title-1bPOm {
		width: 130px;
		min-width: 130px;
	}

	.type-2SCKR {
		overflow: hidden;

		text-overflow: ellipsis;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.card-3f1HI {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 32px 24px;
	}

	.card-3f1HI:not(:last-of-type) {
		margin-bottom: 30px;
	}

	.type-2SCKR {
		margin: 16px 0;
	}

	.tag-2W_vr {
		position: static;
		position: initial;
	}

	.card-3f1HI button.select-JGFic {
		width: 186px;

		font-size: 18px;
	}

	.services-1mCcy {
		margin: 0;
	}
}

@media screen and (max-width: 600px) {
	.card-3f1HI {
		padding: 32px 35px;
	}

	.card-3f1HI:not(:last-of-type) {
		margin-bottom: 30px;
	}

	.services-1mCcy {
		padding-left: 0;
	}

	.title-1bPOm {
		width: auto;
		min-width: none;
	}
}
