.meal-3myKW {
	position: relative;

	height: 376px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding-bottom: 28px;

	border-radius: var(--baggage-additional-wrapped-border-radius);

	background: #ffffff;
	box-shadow: var(--meal-box-shadow);

	cursor: pointer;

	transition: box-shadow 0.2s;
}

.meal-3myKW:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	content: '';

	border-radius: var(--baggage-additional-wrapped-border-radius);
	border: var(--meal-selected-border);

	transition: border 0.2s;

	pointer-events: none;
}

.meal-3myKW:hover {
	box-shadow: var(--meal-box-shadow-active);
}

.meal-3myKW:hover:after {
	border-color: var(--base50);
}

.meal_fullSize-2ijo9 {
	height: 376px;

	flex-direction: row;

	padding-bottom: 0;
}

.header-3XSZM {
	position: relative;
	z-index: 1;
}

.meal_fullSize-2ijo9 .header-3XSZM {
	position: static;

	flex-basis: 550px;
	flex-grow: 1;
}

.image-3wbzr {
	height: 200px;

	border-radius: var(--baggage-additional-wrapped-border-radius) var(--baggage-additional-wrapped-border-radius) 0 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.meal_fullSize-2ijo9 .image-3wbzr {
	height: 100%;
	width: 100%;

	border-radius: var(--baggage-additional-wrapped-border-radius) 0 0 var(--baggage-additional-wrapped-border-radius);
}

.marker-1D6HS {
	position: absolute;
	top: 0;
	left: 50%;

	padding: 4px 16px;

	border-radius: 100px;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;

	background-color: var(--error);

	transform: translate(-50%, -50%);
}

.meal_fullSize-2ijo9 .marker-1D6HS {
	position: static;

	display: inline-block;

	transform: none;
}

.title-P_PXw {
	margin-top: 20px;
	padding: 0 16px;

	text-align: center;
}

.meal_fullSize-2ijo9 .title-P_PXw {
	padding: 0;

	text-align: left;
}

.name-3u13R {
	color: var(--gray80);
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	text-transform: uppercase;
}

.meal_fullSize-2ijo9 .name-3u13R {
	font-size: 27px;
}

.weight-32489 {
	margin-top: 4px;

	color: var(--gray50);
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
}

/* stylelint-disable block-no-empty */
.hasWeight-1OnsS {
}

/* stylelint-enable */
.meal_fullSize-2ijo9 .weight-32489 {
	margin-top: 20px;
}

.footer-3zjPR {
	margin-top: 20px;
}

.meal_fullSize-2ijo9 .footer-3zjPR {
	margin-top: 0;
	padding: 50px 40px 40px;
}

.footer__icons-1O7HX {
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
}

.meal_fullSize-2ijo9 .footer__icons-1O7HX {
	margin-top: 40px;

	justify-content: flex-start;
}

.price-1HKWy {
	padding: 4px 22px;

	border-radius: 100px;

	font-weight: 600;
	font-size: 22px;
	line-height: 150%;
	color: var(--base-30);

	background-color: var(--base90);

	transition: background-color 0.3s, color 0.3s;
}

.meal-3myKW:hover .price-1HKWy {
	color: #ffffff;

	background-color: var(--base);
}

.oldPrice-1EVTx {
	margin-right: 10px;
	padding-left: 0;

	font-weight: 600;
	font-size: 20px;
	line-height: 150%;

	color: var(--error-icon);
	text-decoration: line-through;
}

.count-1oOTR {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 10px;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;

	background-color: var(--success);
}

div.popup__root-AKJDO {
	z-index: 1650 !important;
}

div.popup-3puZL {
	width: 100%;

	border-radius: var(--baggage-additional-wrapped-border-radius);
}

.popup__close-H8hr7 {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}

@media screen and (max-width: 600px) {
	div.popup__scrollPaper-1GPx1 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	div.popup__scrollPaper-1GPx1 div.popup-3puZL {
		min-height: auto;

		border-radius: 16px 16px 0 0;
		overflow: hidden;
	}
}
