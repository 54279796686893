.wrapper-1U2lf {
	position: relative;

	width: 78px;
	height: 78px;

	border-radius: 50%;

	color: var(--base);

	background-color: #eaf4ff;
}

.wrapper_pointer-2j3Kh {
	cursor: pointer;
}

.wrapper-1U2lf.active-3MRZ7 {
	color: var(--white);

	background-color: var(--base);
}

.wrapper-1U2lf > svg {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 100%;
	height: 100%;

	transform: translate(-50%, -50%);

	pointer-events: none;
}

.selected-3I5dm {
	position: absolute;
	top: 0;
	right: 0;

	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;

	border: 3px solid var(--white);
	border-radius: 50%;

	color: var(--white);

	transform: translate(10%, -10%);
	background-color: var(--success);
}

.selected-3I5dm > svg {
	width: 70%;
	height: 70%;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.wrapper-1U2lf {
		width: 58px;
		height: 58px;
	}

	.selected-3I5dm {
		width: 22px;
		height: 22px;
	}
}
