.addMemberFields-1uj4f {
	display: grid;
	grid-gap: 25px;
	gap: 25px;
}

@media screen and (max-width: 600px) {
	.addMemberFields-1uj4f {
		display: flex;
		flex-direction: column;
	}
}

.addMemberFields__group-1PfQ6 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 25px;
	gap: 25px;
}

@media screen and (max-width: 600px) {
	.addMemberFields__group-1PfQ6 {
		display: flex;
		flex-direction: column;
		grid-gap: 25px;
		gap: 25px;
	}
}

.fieldInput-22sGS input {
	width: 100%;

	border-top: none;

	background-color: var(--very-pale-violet);
}

@media screen and (max-width: 600px) {
	.fieldInput-22sGS input {
		background-color: transparent;
	}
}

.fieldText-3MP8d {
	display: flex;
	align-items: center;

	font-size: 14px;
	font-weight: 400;

	color: var(--text-mid);
}

@media screen and (max-width: 600px) {
	.fieldText-3MP8d {
		margin-top: 20px;
	}
}
