.header-1aH_O {
	background: var(--bg-white);
}

.inner-LkEVr {
	display: flex;
	align-items: center;
	padding: 28px 20px;
	justify-content: space-between;
}

@media screen and (max-width: 600px) {
	.inner-LkEVr {
		flex-direction: column;
	}
}

.listItem-2VqgZ:first-of-type {
	margin-top: 12px;
}

.searchIcon-mc-8y {
	position: relative;
	top: 5px;

	color: var(--brand-1-0);

	transform: scale(1.3);
}

.filters-uGHb2 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
}

.filter--hRPB {
	display: inline-flex;
	flex: 1;
	align-items: center;

	text-decoration: none;
	color: var(--text-light);
}

.filter--hRPB:not(:last-of-type) {
	padding-right: 27px;

	margin-right: 27px;

	border-right: 1px solid var(--line-separator);
}

.filter--hRPB svg {
	margin-right: 8px;

	color: var(--text-light);
}

.filter--hRPB.active-Ihlqg {
	color: var(--brand-1-0);
}

div.closeIcon-r2OQ1 svg {
	color: var(--text-pale);

	cursor: pointer;
}

.filter--hRPB.active-Ihlqg svg {
	color: var(--brand-1-0);
}

div.collapse-h4qd8 {
	width: 100%;
}

.collapseActive-1fJYv {
	padding-top: 20px;
}

.listItem-2VqgZ:not(:last-of-type) {
	margin-bottom: 12px;
}

.title-2BLeo {
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
	color: #333333;
}

/* stylelint-disable */
.right-vYyyZ {
}
/* stylelint-enable */
label.label-26x7p {
	font-size: 14px;
}

div.drawerContainer-38Dxi,
div.drawerPaper-hAjl- {
	top: var(--map-status-header-height);

	height: calc(100vh - var(--map-status-header-height));
}

.drawerPaper-hAjl-.scroll-Nt8Jv {
	padding-right: 30px;
}

.delimiter-3-ZRC {
	position: relative;

	display: inline-block;
	margin-bottom: 12px;

	color: var(--text-light);
	text-align: center;
	font-size: 14px;
	line-height: 18px;
}

.delimiterText-3bAtc {
	position: relative;

	display: inline;
	padding: 0 10px;

	background: #ffffff;
}

.delimiter-3-ZRC:before {
	position: absolute;
	top: 50%;
	left: 0;

	display: inline-block;
	content: '';
	width: 100%;
	height: 1px;

	background: #b5bdcb;
	transform: translateY(-50%);
}

.delimiter-3-ZRC:last-of-type {
	margin-bottom: 0;
}

div.drawerContainer-38Dxi {
	pointer-events: none;
}

div.drawerPaper-hAjl- {
	padding: 20px;

	box-shadow: none;

	pointer-events: all;
}

.sliderContainer-3faHU {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1;

	width: 100vw;
}

.sliderContainer-3faHU .slick-list {
	padding-left: 12px;
}

.sliderContainer-3faHU .slick-track {
	display: flex;
	align-items: flex-end;
}

@media screen and (max-width: 600px) {
	div.searchAdornment-2AVVP {
		display: none;
	}
}

div.inputWrapper-1A_6k {
	width: 320px;
	margin-right: 12px;
}

.mobileFilters-JxVFp {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	max-width: 140px;
	margin-left: auto;
	margin-right: 15px;
	padding-right: 15px;

	border-right: 1px solid var(--line-separator);

	color: var(--base);
	font-weight: 400;
	font-size: 15px;
	line-height: 15px;
}

.mobileFiltersTrigger-1lXUG {
	display: flex;
	align-items: center;
}

li.mobileFiltersItem-3_OnT {
	display: flex;
	grid-gap: 8px;
	gap: 8px;
}

.rightWrapper-yu_xz {
	display: flex;
	align-items: center;
}

.lastUpdate-3tj9F {
	margin: 0 30px;

	color: var(--text-light);
	font-size: 16px;
	line-height: 20px;
}

@media screen and (max-width: 600px) {
	div.inputWrapper-1A_6k {
		width: 100%;
		margin-right: 0;
	}
}

.mobileHeaderContent-3F7OP {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
