.root-3upfy {
	padding-top: 20px;

	border-top: 1px dashed var(--gray20);
}

.root-3upfy:last-child {
	padding-bottom: 20px;

	border-bottom: 1px dashed var(--gray20);
}

.root-3upfy + .root-3upfy {
	margin-top: 20px;
}

.disabled-2sokz {
	opacity: 0.7;
}

.name_wrapper-29PEc {
	display: flex;
	align-items: center;
	flex: 1;
	max-width: 33%;
}

.counter_wrapper-2hLMJ,
.checkbox_wrapper-1dVCm {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.counter_wrapper-2hLMJ.equipment-3KvbP {
	flex: 0 1 auto;
	margin-left: auto;
}

.number-3B_nM {
	padding: 5px 8px;

	border-radius: 50%;

	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	color: var(--gray50);

	background: var(--gray05);
}

.name-3bNPD {
	margin-left: 14px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
	color: var(--gray80);
}

.row-2phQZ {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}

.baggages-HvOdb {
	display: flex;
	flex: 1;

	grid-gap: 12px;

	gap: 12px;
}

.baggages-HvOdb:not(:last-child) {
	margin-right: 12px;
}

span.checkbox_root-R0onr.checkbox_rootChecked-3aB75 {
	color: var(--base);
}

@media screen and (max-width: 600px) {
	.root-3upfy {
		padding-top: 25px;
	}

	.root-3upfy:last-child {
		padding-bottom: 25px;
	}

	.root-3upfy + .root-3upfy {
		margin-top: 25px;
	}

	.row-2phQZ {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.name_wrapper-29PEc,
	.radio_wrapper-2la2W {
		max-width: none;

		border-right: none;
	}

	.counter_wrapper-2hLMJ,
	.checkbox_wrapper-1dVCm {
		justify-content: flex-end;
	}
}
