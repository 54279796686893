.wrapper-1NKW3 {
	display: flex;
	justify-content: center;

	background-color: var(--white);
}

.wrapper_sticky-G7eiw {
	position: sticky;
	top: 0;
	z-index: 10;
}

.inner-15b8L {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 20px;
	gap: 20px;

	padding: 0 20px;
	max-width: 1140px;
	width: 100%;
	height: 80px;
}

.title-2jOTN {
	color: var(--text-base);
	font-weight: 600;
	font-size: 24px;
	line-height: 100%;
}

.segments-2iLlE {
	display: flex;
	align-items: center;

	flex: 1;
	justify-content: center;
}

.close-1jxTB {
	justify-content: flex-end;
}

.wrapper_center-8xx3l .title-2jOTN,
.wrapper_center-8xx3l .close-1jxTB {
	flex-basis: 20%;
}

.wrapper_center-8xx3l .segments-2iLlE {
	padding-right: calc(20% + 20px);
}

.wrapper_withClose-nlX2M .segments-2iLlE {
	padding-right: 0;
}

.alignmentStub-1lfRd {
	flex: 1;
}

@media (max-width: 1024px) {
	.title-2jOTN {
		display: none;
	}

	.wrapper_center-8xx3l .segments-2iLlE {
		padding-right: 0;
	}
}
