div.paper-1OaxB {
	display: flex;
	flex-direction: column;

	background: transparent;
}

div.paper_dropdown-lH2n8 {
	padding: 20px 0;
}

.content-3lPtV {
	overflow: hidden;
}

.content_wrapper-2InJQ {
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	padding: 20px 0 40px;
}

.paper_dropdown-lH2n8 .content_wrapper-2InJQ {
	padding: 20px 0 0;
}

.paper_pagination-1Ebjh .content_wrapper-2InJQ {
	padding-bottom: 20px;
}

.buttons-2So3C {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 1100px;
	margin: 20px auto 10px;
}

.paper_dropdown-lH2n8 .buttons-2So3C {
	order: 1;
	margin: 0;
}

.infoMessage-1DBaq {
	width: auto !important;
	display: flex !important;

	min-width: 256px;
	max-width: 375px;
	height: 100%;

	border-radius: 8px;
	overflow: hidden;
}

.infoMessage__htmlContainer-1EO1A {
	height: 100%;
}

.infoMessage-1DBaq > div {
	border-radius: 8px;
}

.slider-3VNl3 .slick-list {
	overflow: visible;
}

.slider-3VNl3 .slick-track {
	display: flex !important;
}

.slider-3VNl3 .slick-slide {
	height: inherit !important;
}

.slider-3VNl3 .slick-slide > div {
	height: 100%;
}

.fareGroup__slide-14AcH {
	height: 100%;
}

.fareInfo-2dcNW {
	margin-bottom: 10px;
}

.fares-JcudH {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	grid-column-gap: 24px;

	column-gap: 24px;
}

button.fareGroup__button-3bCf3 {
	border: 1px solid var(--gray10);

	color: var(--gray70);
	font-size: 16px;
	line-height: 19px;

	background: #ffffff;

	transition: none;
}

.infoMessage__htmlMessage-3NtYD {
	height: 100%;
	width: 100%;
}

.upsaleRightInfoMessage-2uep9 .infoMessage__contentClassName-1G3Hi {
	justify-content: center;
}

.topInfoMessage-3HLho {
	width: 100%;
	max-width: 1100px;

	margin: 20px auto 0;
}

div.topInfoMessage__htmlMessage-22pTF {
	width: 100%;
}

@media screen and (max-width: 600px) {
	button.fareGroup__button-3bCf3 {
		flex: 1;
	}

	.infoMessage-1DBaq {
		max-width: 100%;
		min-width: 100%;
	}
}

button.fareGroup__button-3bCf3:hover {
	color: #ffffff;
}

.buttons-2So3C button.fareGroup__button-3bCf3 {
	margin-right: 12px;
}

.buttons-2So3C button.fareGroup__button-3bCf3:last-child {
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	button.fareGroup__button_business-2vbUB.fareGroup__button_active-3DvNp {
		border-color: var(--base20);

		color: #ffffff;

		background: var(--base20);
	}

	.buttons-2So3C button.fareGroup__button-3bCf3 {
		margin-bottom: 4px;
	}
}

button.fareGroup__button_active-3DvNp {
	color: #ffffff;

	background: var(--base);
}

button.fareGroup__button_right-1Ys7g {
	margin-left: auto;
}

.title-1UrYN {
	margin: 0 0 24px;

	font-size: 30px;
	font-weight: 600;
	line-height: 100%;
	color: var(--gray80);
}

@media screen and (max-width: 600px) {
	.title-1UrYN {
		font-weight: 700;
		font-size: 20px;
		line-height: 20px;
	}
}

.closeIcon-14As7 {
	top: 20px;
	right: 20px;

	border-radius: 50%;

	background: #ffffff;
}

.slider__wrp-WhxNK {
	position: relative;

	margin-right: -18px;
	margin-bottom: 40px;
}

.paper_dropdown-lH2n8 .slider__wrp-WhxNK {
	margin-bottom: 0;
}

@media screen and (max-width: 600px) {
	.slider__wrp-WhxNK {
		margin-right: 0;
	}
}

.flightInfo-37P3m {
	padding: 21px;
}

@media screen and (max-width: 600px) {
	.flightInfo-37P3m {
		padding-top: 10px;
	}
}

.fareGroup_stub-2NyG1 {
	position: absolute;
	top: 20px;

	max-width: 280px;
	padding: 0;
}

.fareGroup_stub_small-2mLJm {
	min-height: 440px;
}

@media (max-width: 1024px) {
	.fareGroup_stub-2NyG1 {
		display: none;
	}

	.infoMessage__htmlMessage-3NtYD {
		margin-right: 0;
	}
}

.fareGroup_stub-2NyG1 svg {
	color: inherit;
}

.fareGroup_stub__img-ITlyL {
	width: 100%;

	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.fareGroup_stub__content-2HMqP {
	padding: 16px;
}

.stubOptions-1lPJk {
	display: flex;
	justify-content: flex-start;
	max-width: 100px;

	color: var(--base);
}

.compareFaresLink-3glTM svg {
	transform: translateY(2px);
}

.stubOptions-1lPJk svg:not(:last-of-type) {
	margin-right: 13px;
}

.fareGroup_stub_business-28A-r .stubOptions-1lPJk {
	color: var(--base2);
}

.stubDescription-hckkJ {
	margin: 15px 0 0;
	padding: 0;

	list-style-type: none;
}

.stubDescription__item-2aEra {
	padding: 2px 0;

	font-size: 14px;
	line-height: 18px;
}

.pagination__index-VMhld {
	position: absolute;
	bottom: -5px;
	left: 50%;

	transform: translate(-50%, 0);
}

.paper-1OaxB .slick-slider {
	padding-bottom: 40px;
}

.paper_dropdown-lH2n8 .slick-slider {
	padding-bottom: 20px;
}

.paper_pagination-1Ebjh .slick-slider {
	padding-bottom: 30px;
}

.paper-1OaxB .slick-list {
	padding-top: 20px;
	padding-left: 2px;
	padding-bottom: 2px;
}

.paper_dropdown-lH2n8 .slick-list {
	padding-top: 0;
}

@media screen and (max-width: 600px) {
	.paper-1OaxB .slick-slider {
		padding-bottom: 20px;
	}

	div.swipeable__paper-2Z8Tr {
		visibility: visible !important;
		overflow: visible;
	}

	.infoMessage-1DBaq {
		margin-top: -67px;
	}

	.infoMessage-1DBaq > div {
		width: 100%;
	}

	.content_offset-3N8gH {
		padding-bottom: 100px;
	}
}

@media screen and (max-width: 600px) {
	.slider__wrp_singleItem-1tDLV .slick-list {
		padding-left: 0;
	}

	.slider__wrp-WhxNK:not(.slider__wrp_singleItem-1tDLV) .slick-list {
		margin-left: 20px;
	}

	.slider__wrp_singleItem-1tDLV .slick-track,
	.slider__wrp_singleItem-1tDLV .slick-track .slick-slide > div > div {
		margin: 0 auto;
	}
}

.paper-1OaxB .slick-arrow {
	top: auto;

	bottom: -30px;

	width: 36px;
	height: 36px;

	border: 1px solid var(--gray05);
	border-radius: 50%;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.paper-1OaxB button.slick-arrow {
		display: none !important;
	}
}

.pagination-BymeR {
	max-width: 80px;
	margin: 0 auto;
}

/* @media laptop { */

/*	.paper :global .slick-slider { */

/*		padding-left: 300px; */

/*	} */

/* } */
.paper-1OaxB .slick-arrow:before {
	display: none;
}

.paper-1OaxB .slick-prev {
	left: 45%;

	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=) center no-repeat;

	transition: opacity 0.3s;
}

.paper-1OaxB .slick-next {
	right: 45%;

	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTcgNkw4LjU4OTk3IDcuNDFMMTMuMTcgMTJMOC41ODk5NyAxNi41OUw5Ljk5OTk3IDE4TDE2IDEyTDkuOTk5OTcgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) center no-repeat;

	transition: opacity 0.3s;
}

.paper-1OaxB .slick-prev.slick-disabled,
.paper-1OaxB .slick-next.slick-disabled {
	opacity: 0.5;

	pointer-events: none;
}

.paper-1OaxB .slick-prev:hover,
.paper-1OaxB .slick-prev:focus,
.paper-1OaxB .slick-prev:active {
	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNkw4IDEyTDE0IDE4TDE1LjQxIDE2LjU5TDEwLjgzIDEyTDE1LjQxIDcuNDFaIiBmaWxsPSIjNEQ0RDREIi8+Cjwvc3ZnPgo=) center no-repeat;
}

.paper-1OaxB .slick-next:hover,
.paper-1OaxB .slick-next:focus,
.paper-1OaxB .slick-next:active {
	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTcgNkw4LjU4OTk3IDcuNDFMMTMuMTcgMTJMOC41ODk5NyAxNi41OUw5Ljk5OTk3IDE4TDE2IDEyTDkuOTk5OTcgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) center no-repeat;
}

.compareFaresWrp-1xx-H {
	display: flex;
}

.compareFaresLink-3glTM {
	display: flex;
	align-items: center;
	margin-left: auto;

	color: var(--gray50);
	font-size: 14px;
	line-height: 1.3;
	text-decoration: none;
}

.compareFaresLink_icon-2RAQv {
	margin-left: 6px;

	color: #b3b3b3;
}

@media screen and (max-width: 600px) {
	.compareFaresWrp-1xx-H {
		margin: 15px 21px 0;
		padding: 8px 10px;

		border-radius: 4px;

		background: #ffffff;
	}

	.compareFaresLink-3glTM {
		margin: 0 auto;

		transform: none;
	}

	.compareFaresWrp-1xx-H svg {
		transform: translateY(4px) scale(0.9);
	}
}

@media (max-width: 1024px) {
	.paper-1OaxB .slick-prev {
		left: 42%;
	}

	.paper-1OaxB .slick-next {
		right: 42%;
	}
}

@media (max-width: 768px) {
	.paper-1OaxB .slick-prev {
		left: 40%;
	}

	.paper-1OaxB .slick-next {
		right: 40%;
	}
}

div.fareGroup_withoutUpgradeOption-oabTy {
	min-height: 470px;
}

@media screen and (max-width: 600px) {
	.paper-1OaxB .slick-prev {
		left: 28%;
	}

	.paper-1OaxB .slick-next {
		right: 28%;
	}
}

.paper-1OaxB .slick-slide > div > div {
	margin-right: 24px;
}

@media (max-width: 1024px) {
	.buttons-2So3C,
	.content-3lPtV {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	.paper-1OaxB .slick-slide > div > div {
		margin-right: 5px;
	}

	.segments-2r1zf {
		padding: 0 20px;
	}

	.buttons-2So3C {
		flex-wrap: wrap;
		justify-content: center;
	}

	.content-3lPtV {
		padding: 0;
	}

	.topInfoMessage-3HLho {
		width: 87vw;
	}
}

.total_time-1uc09 {
	margin-top: 10px;
	padding: 12px 20px;

	border: 1px solid #d9d9d9;
	border-radius: 4px;

	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	background-color: var(--warning20);
}

.content_footer-jVkHc {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
}

button.scroll_up-1fz3Z {
	background-color: var(--results-faregroup-button-economy);
}

button.scroll_up-1fz3Z:hover {
	background-color: var(--results-faregroup-button-economy-hover);
}

.flight__selected-3KmUj {
	flex: 1;
}

.segments__flight-37tja:not(:first-child) {
	margin-top: 10px;
}

div.aircraft__paper-1Jy30 {
	border-radius: 20px;

	background: transparent;
}

/* stylelint-disable */
.slider_allOnce-1FWAg {
}

.slider_business-3fX6z {
}

.slider_miles-3Y7e- {
}

.slider_economy-17frJ {
}
/* stylelint-enable */
