.row-1LpPC {
	display: flex;
	margin-top: 45px;
	justify-content: space-between;
}

.buttons_row-3RAMV {
	display: flex;
	flex: 1;
}

.price_block-2jpsh {
	display: flex;
	align-items: center;
}

.price_block__delimiter-2DFkP {
	width: 1px;
	height: 24px;
	margin: 0 12px;

	background: var(--gray20);
}

.price_wrapper-1zmrq {
	font-weight: 700;
	font-size: 22px;
	line-height: 1.4;
}

.clear-34upl {
	font-weight: 500;
	font-size: 15px;
	line-height: 1.4;
	color: var(--base);

	cursor: pointer;
}

.root-3_Yh- button.cancel_btn-1Z5Ku {
	margin-left: auto;
}

.root-3_Yh- button.confirm_btn-1vw9o {
	margin-left: 12px;
}

@media screen and (max-width: 600px) {
	.row-1LpPC {
		flex-direction: column;
		align-items: flex-end;
		margin-top: 15px;
	}

	.buttons_row-3RAMV {
		margin-top: 20px;
		width: 100%;
	}

	.root-3_Yh- button.cancel_btn-1Z5Ku,
	button.confirm_btn-1vw9o {
		flex: 1;
		width: 100%;
	}

	.price_block-2jpsh {
		margin-top: 15px;
	}
}
