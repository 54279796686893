.container--szfd {
	position: relative;
}

.content-U1rLd {
	max-width: 1100px;
	margin: 0 auto;
	padding: 40px 0;
}

.controls-3b4Ia {
	z-index: 1;

	display: flex;
	align-items: center;
	margin-bottom: 24px;
}

@media (max-width: 1024px) {
	.content-U1rLd {
		padding: 40px 20px;
	}
}

@media screen and (max-width: 600px) {
	.content-U1rLd {
		padding: 20px 12px 40px;
	}
}
