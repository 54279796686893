.addSelectedMemberForm-1EWti {
	display: grid;
	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	.addSelectedMemberForm-1EWti {
		margin-top: 10px;
	}
}
