

.title-JDN8p {
	display: inline-block;

	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
}

div.tooltip-23GLo {
	padding: 8px;
	margin-top: 0;
	margin-bottom: 0;

	color: var(--tooltip-color);
	font-family: inherit;

	background-color: var(--tooltip-background);

	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.35);
}

span.tooltip_arrow-3DK49 {
	border-width: 8px 6px 0 6px;

	color: var(--tooltip-background);
}

div.popper-3nJZn {
	opacity: 1;
}
