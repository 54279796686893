.container-NUjS6 {
	max-width: 1100px;
	margin: 28px auto auto;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 12px;
	gap: 12px;
}

.conditions-1dBup {
	padding: 32px 32px 42px;

	border-radius: var(--desktop-win-border-radius);

	background: var(--desktop-win-background);
}

.conditionsButton-2EKNw {
	display: flex;
	align-items: center;

	cursor: pointer;
}

.conditionsButtonTitle-1muW4 {
	font-size: 16px;

	color: var(--text-light);
}

.conditionsButtonIcon-3cXM8 {
	margin-left: 6px;
	display: flex;

	color: var(--text-pale);
}

.checkin-1Cj99 {
	display: flex;
	flex-direction: column;

	padding: 32px;

	border-radius: var(--desktop-win-border-radius);

	background: var(--desktop-win-background);
}

.checkinTitle-jpzrv {
	margin: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.form-4AjLk {
	display: flex;
	flex-direction: column;

	grid-gap: 20px;

	gap: 20px;
	margin-top: 20px;
}

.form-4AjLk div.input-KdhfA {
	margin-top: 0;
}

/* input */
.input-KdhfA input {
	height: 56px;
	padding: 15px 12px 0;

	color: var(--text-base);
}

div.input-KdhfA input {
	border: none;
}

div.input-KdhfA:before {
	height: 56px;

	border: solid 1px var(--brand-1-3);
	border-radius: 4px;

	background: var(--brand-1-5);
}

div.input-KdhfA.inputError-VOCVS:not(.inputFocused-1RTD1):before {
	border: solid 1px var(--error-icon);

	background: var(--error-bg);
}

label.inputLabel-3Gmme {
	padding-right: 40px;

	color: var(--text-light);
	font-size: 16px;

	transform: translate(12px, 19px) scale(1);
}

label.inputFocusedLabel-1_204 {
	color: var(--brand-1-0);

	transform: translate(12px, 8px) scale(0.8);
}

label.inputShrinkLabel-2ylE9 {
	transform: translate(12px, 8px) scale(0.8);
}

label.inputLabel-3Gmme.inputErrorLabel-3bC3O {
	color: var(--text-light);
}

/* input-end */
.buttons-2yT2X {
	display: flex;
	align-self: end;
}

.buttons-2yT2X button {
	height: 49px;
	padding: 12px 24px;

	border-radius: 6px;

	font-size: 17px;
}

button.submitButton-1LFuz {
	color: white;

	font-weight: 700;

	background: var(--brand-3-0);
}

button.submitButton-1LFuz:hover,
button.submitButton-1LFuz:focus {
	background: var(--brand-3-1);
}

button.submitButton-1LFuz:disabled {
	background: var(--brand-3-3);
}

.ticketIcon-3XoTP {
	color: var(--text-pale);

	cursor: pointer;
}

div.helper-1FgtG {
	max-width: 400px;
}

.notify-23VW8 {
	display: flex;
	align-items: center;

	padding: 12px;

	border: 1px solid var(--error-stroke-hover);
	border-radius: 4px;

	background: var(--error-bg);
}

.notifyIcon-15Nyp {
	margin-right: 14px;

	color: var(--error-icon);
}

.notifyText-bNvMl {
	color: var(--error-text);

	font-size: 14px;
	font-weight: 500;
}

@media screen and (max-width: 600px) {
	.container-NUjS6 {
		max-width: unset;
		margin: 0;

		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 0;
		gap: 0;
	}

	div.conditionsModalPaper-f-TWY {
		border-radius: 0;
	}

	.conditions-1dBup {
		padding: 0;

		border-radius: 0;
	}

	.checkin-1Cj99 {
		display: grid;
		grid-template-columns: 1fr auto;
		padding: 20px;

		border-radius: 12px 12px 0 0;
	}

	.checkinTitle-jpzrv {
		grid-area: 1 / 1 / 2 / 2;

		font-size: 18px;
	}

	.form-4AjLk {
		grid-area: 2 / 1 / 3 / 3;
	}

	.notify-23VW8 {
		order: -1;
	}

	.notifyIcon-15Nyp {
		margin-right: 12px;
	}

	/* input */
	.input-KdhfA input {
		padding: 16px 8px 0;

		color: var(--text-base);
	}

	div.input-KdhfA input {
		border: none;
	}

	div.input-KdhfA:before {
		height: 56px;

		border: none;
		border-bottom: solid 1px var(--input-def);
		border-radius: 0;

		background: white;
	}

	div.input-KdhfA.inputFocused-1RTD1:before {
		border: none;
		border-bottom: solid 1px var(--input-focus);
		border-radius: 0;

		background: var(--brand-1-5);
	}

	div.input-KdhfA.inputError-VOCVS:not(.inputFocused-1RTD1):before {
		border: none;
		border-bottom: solid 1px var(--error-icon);

		background: var(--error-bg);
	}

	label.inputShrinkLabel-2ylE9 {
		transform: translate(8px, 8px) scale(0.8);
	}

	/* input-end */
	.ticketIcon-3XoTP > svg {
		width: 20px;
		height: 20px;
	}

	.buttons-2yT2X {
		align-self: stretch;
		justify-content: space-between;
		grid-gap: 12px;
		gap: 12px;
	}

	.buttons-2yT2X button {
		width: 100%;

		height: 41px;
		padding: 10px 24px;

		border-radius: 4px;

		font-size: 17px;
	}

	button.cancelButton-2hPRu {
		color: var(--text-light);
		font-weight: 400;

		font-size: 16px;

		background: var(--bg-fill);
	}

	button.cancelButton-2hPRu:hover,
	button.cancelButton-2hPRu:focus {
		background: var(--bg-elements);
	}

	button.cancelButton-2hPRu:disabled {
		width: calc(50% - 12px);

		color: var(--text-pale);

		background: var(--bg-fill);
	}
}
