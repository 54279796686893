.root-G64DF {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.icon-22_HP {
	color: var(--brand-1-0);
}

.name-1Dlwl {
	margin-top: 12px;

	font-weight: 500;
	font-size: 13px;
	line-height: 1.5;

	color: var(--text-mid);
	text-align: center;
}

.size-1xe84 {
	max-width: 150px;
	margin-top: 1px;

	font-size: 12px;
	line-height: 1.5;
	text-align: center;

	color: var(--text-light);
}

.root-G64DF.selected-7myTp .icon-22_HP {
	color: var(--success-icon);
}

.iconWrapper_pointer-3uMpa {
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.size-1xe84 {
		display: none;
	}
}
