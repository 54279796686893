

.toolbar-tq2rq {
	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
}

button.button-3yw0L {
	border-radius: 6px;
}

.price-1Qdvh {
	color: var(--text-base);
	font-weight: 700;
	line-height: 1.1;
}

button.toolbar__backButton-zX-jj,
button.toolbar__backButton-zX-jj:hover {
	padding: 0;

	font-size: 0;

	background: none;
}

button.toolbar__backButton-zX-jj:before {
	width: 40px;
	height: 40px;

	border-radius: 50%;

	background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=) no-repeat center;
	content: '';
}

.backButton__wrapper-339zR {
	color: var(--text-light);
	font-weight: 500;
}

.miles-BKd54 {
	margin-top: 2px;

	color: var(--text-light);
	line-height: 1.1;
}
