.wrapper-33yCC {
	position: relative;
}

.content-Bn5JF {
	max-width: 1100px;
	margin: 0 auto;
	padding-bottom: 100px;
}

.statusMessage-3fYYZ {
	margin-bottom: 20px;
}

.segment-21qYw {
	margin-bottom: 12px;
}

.weatherWrapper-1WqOB {
	display: flex;
	margin-top: 12px;
}

.timelimit-28bgx {
	display: flex;
	align-items: center;
	margin-bottom: 12px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.timelimit__icon-2XaSr {
	display: flex;
	margin-right: 8px;
}

.checkinCancelModal-2JQN2 {
	display: flex;
	align-items: center;
}

.checkinCancelModal__content-3saXB {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 28px;
}

.checkinCancelModal__text-2m7zI {
	display: flex;
	margin-bottom: 20px;

	font-weight: 500;
	font-size: 18px;
	line-height: 26px;
	text-align: center;

	color: var(--dark-gray);
}

.checkinCancelModal__buttons-3rPIC {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.timelimit__info-rSjuo {
	margin-left: 4px;
}

.notifications-rr-sF {
	margin: 28px 0 12px;
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

div.notification-FrQfE {
	display: flex;
	padding: 16px;

	border: 1px solid var(--error-stroke--hover);
	border-radius: 4px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

div.notification-FrQfE div.notification__icon-2g7as {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 18px;
}

div.notification-FrQfE div.notification__title-2mYM7 {
	margin-bottom: 2px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

div.notification-FrQfE div.notification__text-3bezr {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

div.notification-FrQfE.notification__context-32ykp {
	border: 1px solid var(--error-stroke--hover);

	background-color: var(--errorBG);
}

div.notification-FrQfE.notification__context-32ykp div.notification__icon-2g7as {
	color: var(--error-icon);
}

div.notification-FrQfE.notification__context-32ykp div.notification__text-3bezr,
div.notification-FrQfE.notification__context-32ykp div.notification__title-2mYM7 {
	color: var(--error-text);
}

@media (max-width: 1024px) {
	.content-Bn5JF {
		padding: 0;
	}
}

@media screen and (max-width: 600px) {
	.wrapper-33yCC {
		padding-bottom: 120px;
	}

	.weatherWrapper-1WqOB {
		flex-direction: column;
	}

	.segments-1j8kf {
		padding: 0 12px;
	}

	.segment-21qYw {
		margin-bottom: 12px;

		border-radius: 12px;

		box-shadow: 4px 9px 38px rgba(0, 0, 0, 0.1);
	}

	.statusMessage-3fYYZ {
		border-radius: 12px;
	}

	.notifications-rr-sF {
		padding: 0 12px;
		margin-bottom: 20px;
	}
}
