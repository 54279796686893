button.flightPrice_button-37h0l {
	background: var(--brand-3-0);
}

button.flightPrice_button-37h0l:hover {
	background: var(--brand-3-1);
}

.point__time-LNV_Y {
	font-weight: 700;
}

.segment_route__info-95jAy {
	padding: 10px 30px 0;
}

.dropdown__icon-3biBk {
	display: none;
}

@media screen and (max-width: 320px) {
	.route-1ud1s {
		top: -7px;
	}
}

@media (min-width: 1024px) {
	.route-1ud1s {
		top: -12px;
	}
}

.timeInRoute-3eyJG {
	top: 30px;

	font-weight: 500;
}
