

.container-GN37l {
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
	padding: 20px 28px;

	background: var(--bg-white);
}

.conditions-3ZFq0 {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;

	margin-left: 30px;
	padding: 28px;

	border-radius: 4px;

	background: var(--bg-fill);
}

label.label-3lrBW {
	grid-gap: 12px;
	gap: 12px;

	margin-left: 0;
	margin-right: 0;
}

span.label-3lrBW {
	color: var(--text-light);
	font: var(--medium--1-desktop);
}

span.label-3lrBW a,
span.label-3lrBW span[data-type] {
	color: var(--brand-1-0);
	font-weight: normal;
	font-weight: initial;
	text-decoration: underline;
}

span.label_main-1JCuj {
	color: var(--text-base);
}
