

.dialog-18Nn5 {
	max-height: 100%;
	padding-top: 56px;

	box-shadow: none;
	transform: none;
}

.header-2I1uv {
	position: fixed;
	top: 0;

	display: flex;
	align-items: center;
	width: 100%;
	height: 56px;

	border-bottom: 1px solid #d9d9d9;

	background-color: #ededed;

	transition: background-color 0.2s;
}

.header_white-2NeTn {
	background-color: #ffffff;
}

.content-3cXJ_ {
	height: 100%;
	width: 100%;

	overflow-y: auto;
	padding-top: 8px;
}

.input-3KTl6 {
	padding-left: 20px;

	border: none;

	font-weight: 600;
	font-size: 24px;

	line-height: 34px;

	background: none;

	appearance: none;
}

.input-3KTl6:-ms-input-placeholder {
	color: #9a9a9a;
}

.input-3KTl6::placeholder {
	color: #9a9a9a;
}

.hint-2TPhj {
	position: absolute;
	right: 52px;

	color: #9a9a9a;
	font-size: 12px;
	line-height: 56px;
	text-align: right;

	transition: opacity 0.2s;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
	pointer-events: none;
}

.hint_hidden-2Gcmi {
	opacity: 0;
}

.closer-1ZNPy {
	position: absolute;
	top: 50%;
	right: 12px;

	display: flex;

	transform: translateY(-50%);
}

/* stylelint-disable */
.modal-3atGb {
	z-index: 1501 !important; /* for .mui-fixed */

	padding-right: 0 !important; /* for .mui-fixes */
}

/* stylelint-enable */
