

.promoMessage_root-1ovhP {
	padding: 5px 12px 7px;

	border: 1px solid var(--success-stroke-hover);

	color: var(--success-text);

	background: var(--success-bg);
}

.header-3Lldi div:first-of-type {
	font-size: 18px;
}

.upgrade_block-3cFbL {
	margin: 0;
}
