.monthsWrapper-2cOoP,
.monthsList-OrqAs {
	display: flex;
}

.wrapper-2vQI0 {
	position: relative;

	display: inline-block;
	padding: 20px;

	border: 1px solid #cccccc;
	border-radius: 4px;

	font-size: 13px;

	background: #ffffff;
}

.frameless-3Bu3o {
	padding: 0;

	border: unset;
}

.nav_next-19DEu,
.nav_prev-2cVx0 {
	position: absolute;

	font-size: 21px;

	cursor: pointer;
}

.nav_next-19DEu {
	right: 20px;
}

@media (min-width: 1024px) and (max-width: 1160px) {
	.wrapper-2vQI0 {
		min-height: 375px;
	}
}

@media (max-width: 1024px) {
	.monthsList-OrqAs {
		flex-direction: column;
		grid-gap: 45px;
		gap: 45px;
	}
}
