.communicationPage-3bCXO {
	padding: 40px;

	border-radius: 12px;

	background-color: var(--bg-white);
}

.communicationPage__content-2M7Ph {
	overflow-y: auto;
}

.title-2-8uM {
	margin-bottom: 5px;

	font-weight: 500;
	font-size: 31px;
	line-height: 40px;
}

@media screen and (max-width: 600px) {
	.communicationPage-3bCXO {
		padding: 20px;
	}
}
