

.container-3oZwc.fares-3kAX3 {
	margin: 70px 0;

	background: var(--bg-canvas);
}

.content-1xhiH {
	max-width: 1100px;
	margin: 0 auto;

	font-family: 'Montserrat', 'Roboto', sans-serif;
}

.content-1xhiH:not(.content_fullWidth-2TOD-) {
	padding: 0 20px;
	max-width: 1140px;
}

.content-1xhiH button {
	font-family: 'Montserrat', 'Roboto', sans-serif;
}

.container-3oZwc.fares-3kAX3 .content-1xhiH {
	padding: 70px 20px;
}
