.nasServicesRefund-1EEhe {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
}

.nasServicesActions-Z6Na8 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;
	grid-gap: 20px;
	gap: 20px;
}

label.agree-3GIut {
	flex: 1;
	margin: 0;
	max-width: none;

	display: flex;
}

.nasServicesActions-Z6Na8 button.confirm-1K1VO {
	height: 45px;
	min-height: 45px;

	font-size: 16px;
}

.nasServicesActions-Z6Na8 button.cancel-2TQgQ {
	height: 41px;
	min-height: 41px;
}

.nasServices-9QwkT {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 8px;
	gap: 8px;
}

span.checkbox-1-8S9.active-p0-zs {
	color: var(--base);
}

@media screen and (max-width: 600px) {
	.nasServices-9QwkT {
		grid-template-columns: 1fr;
	}

	.nasServicesActions-Z6Na8 {
		flex-direction: column;
	}

	.nasServicesActions-Z6Na8 button {
		flex: 1;
		width: 100%;
	}
}
