.modal__root-2rET6 .modal__paper-1L-kI {
	max-width: 800px;
	padding: 0;
}

@media (max-width: 1024px) {
	.modal__root-2rET6 .modal__paper-1L-kI {
		height: 100%;
		max-height: none;
		max-height: initial;
	}
}
