.switchWrapper-3MslX {
	z-index: 0;

	margin-left: 20px;
}

.switchBox-2fMYG {
	font-weight: 500;
	font-size: 14px;
	color: #ffffff;
}

div.loyalty__modal-3JBea {
	z-index: 1600 !important;
}

/* stylelint-disable-next-line block-no-empty */
.switchActive-p29ie {
}

@media screen and (max-width: 600px) {
	.switchWrapper-3MslX {
		display: flex;
		align-items: center;
		margin-left: 0;
	}
}
