

.month-3_c1h {
	display: inline-block;
	padding-right: 15px;
}

.month-3_c1h:last-child {
	padding-right: 0;
}

.week-1KuC9 {
	display: flex;
}
