.header-30F9G {
	display: block;
}

.header__title-2uf14 {
	margin: 0 0 12px;

	color: var(--text-base);
	font: var(--medium-desktop-bold);
}

.header__desc-3qvuE {
	margin: 0;

	color: var(--text-light);
	font: var(--medium--1-desktop);
}

.form_container-1yMRs {
	margin: 24px 0 20px;
}

.traveller-1rWFc {
	display: flex;
	justify-content: space-between;
	grid-gap: 12px;
	gap: 12px;
}

.traveller-1rWFc:not(:last-of-type) {
	margin-bottom: 24px;
}

.traveller__name-2KXyK {
	width: 100%;
	max-width: 160px;
	display: flex;
	align-items: center;

	color: var(--text-base);
	font: var(--medium--1-mobile);
	text-overflow: ellipsis;
	overflow: hidden;
}

.traveller__input-3R589 {
	position: relative;
}

.traveller__fields-2OdgH {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}

.traveller__fields-2OdgH,
.traveller__select-3LlYU,
.traveller__input-3R589 {
	flex: 1;
}

.traveller__input-3R589 > .input__root-3j6vg {
	padding-right: 0;
}

.traveller__select-3LlYU > div:before {
	background: var(--brand-1-5);
}

.traveller__select-3LlYU div.MuiSelect-root {
	padding: 6px 0 8px;
}

.footer-KGDDn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
	margin-top: 43px;
}

.footer__info-3JdUN p {
	margin: 0;

	color: var(--text-light);
	font: var(--medium--1-mobile);
}

.footer__info-3JdUN a {
	color: var(--brand-1-0);
	text-decoration: none;
}

.traveller__select-3LlYU div.input-2a8Nj {
	border: 1px solid var(--brand-1-3);
}

div.input-2a8Nj.input_disabled-19mEq {
	color: var(--text-light);
}

p.helperText-1-2_h {
	position: absolute;
	bottom: -20px;
}

@media (max-width: 1024px) {
	.footer-KGDDn {
		grid-gap: 20px;
		gap: 20px;
	}

	.traveller-1rWFc {
		flex-direction: column;
	}

	.traveller__name-2KXyK {
		padding-top: 0;
	}
}

@media screen and (max-width: 600px) {
	.traveller__fields-2OdgH {
		flex-direction: column;
	}

	.traveller__select-3LlYU {
		margin-bottom: 12px;
	}

	.footer-KGDDn {
		position: sticky;
		bottom: 0;
		z-index: 1;

		flex-direction: column;

		margin-top: 0;
		padding-top: 43px;

		background: var(--white);
	}
}
