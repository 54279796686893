.title-q8TZk {
	height: 45px;
	padding: 14px 30px 14px 14px;

	border-radius: 50px;

	background: var(--white);
}

.title-q8TZk:after {
	top: 53%;
}

.sortBy-3ci6B {
	color: var(--text-mid);
}

.currentSorting-2_Is1 {
	color: var(--text-base);
}

button.flightType-2pSSR {
	height: 45px;
	padding: 0 14px;

	line-height: 30px;
}

button.flightType-2pSSR.flightType__active-31CHE {
	padding-right: 10px;
}

button.flightType-2pSSR.flightType__active-31CHE:before {
	display: none;
}

button.flightType-2pSSR.flightType__active-31CHE:after {
	width: 21px;
	height: 21px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb24iPgo8cGF0aCBpZD0iVW5pb24iIGQ9Ik0xNC4yMTI0IDE1Ljk0OTdMMTAuNSAxMi4yMzc0TDYuNzg3NzMgMTUuOTQ5N0w1LjU1MDI5IDE0LjcxMjNMOS4yNjI2IDExTDUuNTUwMjkgNy4yODc2N0w2Ljc4NzczIDYuMDUwMjNMMTAuNSA5Ljc2MjU0TDE0LjIxMjQgNi4wNTAyM0wxNS40NDk4IDcuMjg3NjdMMTEuNzM3NSAxMUwxNS40NDk4IDE0LjcxMjNMMTQuMjEyNCAxNS45NDk3WiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8L3N2Zz4K);
	content: '';
}

@media screen and (max-width: 600px) {
	.title-q8TZk {
		padding: 10px 17px 10px 41px;
	}

	.title-q8TZk:before {
		left: 11px;
	}
}
