

.header-89cnY {
	margin-bottom: 13px;

	color: var(--fareGroup-header-color);
	font-weight: 500;
	font-size: 24px;
	line-height: 100%;
}

div.recommended-2OVMa {
	box-shadow: 0 0 0 1px var(--fareGroup-recommended-border-color);
}

.container-3m0k6 {
	display: flex;
	flex-wrap: wrap;
	margin: 4px -4px 12px auto;
}

.option-15A0m {
	position: relative;

	display: flex;
	flex: 1 0 48%;

	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-top: 4px;
	margin-right: 4px;
	padding: 13px 8px 8px;

	border-radius: 2px;
	border: 1px solid var(--brand-1-5);

	font-size: 12px;
	line-height: 1.3;
	text-align: center;

	color: var(--brand-1-0);

	list-style-type: none;

	cursor: help;

	background: var(--brand-1-5);
}

.option-15A0m.small-1jAFf {
	flex: 1 0 23%;
	padding: 8px;

	border: none;

	font-size: 13px;
	color: var(--white);

	background: var(--brand-1-1);
}

.option-15A0m.small-1jAFf.paid-3XG3x {
	background: var(--bg-elements);
}

.icon-35d4J {
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--fareGroup-option-svg-size);
	height: var(--fareGroup-option-svg-size);

	margin-bottom: 6px;

	color: var(--white);
}

.size-2IE3z {
	font-size: 13px;
}

.availabilityIcon-1WrJD {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--brand-1-2);
}

.option-15A0m.paid-3XG3x .availabilityIcon-1WrJD,
.option-15A0m.not_available-3gnWK .availabilityIcon-1WrJD {
	color: var(--text-pale);
}

.upgradeOffer__text-3bsA4:after {
	content: '?';
}

.upgradeOffer__options-2eUap > svg {
	margin-right: 4px;
}

/* stylelint-disable */
.upgradeOffer__button-2VHuT {
}
/* stylelint-enable */
.upgradeOffer__options-2eUap > svg:last-child {
	margin-right: 0;
}

.option-15A0m:not(.small-1jAFf) .availabilityIcon-1WrJD {
	position: absolute;
	top: 10px;
	right: 10px;
}

.option-15A0m.small-1jAFf:not(.paid-3XG3x) .availabilityIcon-1WrJD {
	display: none;
}

.description-c9XgM {
	max-width: 113px;
	max-height: 90px;

	text-overflow: ellipsis;
	overflow: hidden;
}

.option-15A0m.paid-3XG3x,
.option-15A0m.not_available-3gnWK {
	border: 1px solid var(--line-separator);

	color: var(--text-pale);

	background: var(--white);
}

.option-15A0m.not_available-3gnWK {
	opacity: 0.6;
}

.option-15A0m .icon-35d4J {
	margin-bottom: 8px;

	color: var(--brand-1-0);
}

.option-15A0m.small-1jAFf .icon-35d4J {
	width: 28px;
	height: 28px;

	color: var(--white);
}

.option-15A0m.paid-3XG3x .icon-35d4J,
.option-15A0m.not_available-3gnWK .icon-35d4J,
.option-15A0m.small-1jAFf.paid-3XG3x .icon-35d4J {
	color: var(--text-pale);
}

.option-15A0m.small-1jAFf .size-2IE3z {
	display: none;
}

.option-15A0m.small-1jAFf.paid-3XG3x .title-2oMu4 {
	display: none;
}

.upgradeOffer-2DL9w {
	display: flex;
	align-items: flex-end;
	margin-bottom: 12px;
	padding: 16px;

	order: 1;

	border-radius: var(--fareGroup-upgrade-option-bdrs);

	cursor: pointer;
	background: var(--fareGroup-upgrade-option-bg);
}

.upgradeOffer__content-1Dn-0 {
	display: flex;
	flex-direction: column;
	margin-right: 8px;

	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

/* stylelint-disable-next-line block-no-empty */
.upgradeOffer__choose-1gQ3x {
}

.upgradeOffer__options-2eUap {
	display: flex;

	align-items: center;

	margin-top: 4px;

	color: var(--brand-1-0);
}

.upgradeOffer__optionsPlus-2EAYO {
	position: relative;

	width: 24px;
	height: 24px;
	margin-right: 6px;
}

.upgradeOffer__optionsPlus-2EAYO:before,
.upgradeOffer__optionsPlus-2EAYO:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 60%;
	height: 2px;

	background: currentColor;
	transform: translate(-50%, -50%);
}

.upgradeOffer__optionsPlus-2EAYO:after {
	transform: translate(-50%, -50%) rotate(90deg);
}
