.container-2hFaF {
	position: relative;
}

.header-APBlV {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;

	padding: 0 34px;
	margin-bottom: 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 21px;
	line-height: 140%;
}

.header__info-ausFx {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.header__icon-3wxg6 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40%;
}

.header__icon-3wxg6 img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.subheader-3b266 {
	padding: 0 34px 24px;

	color: var(--text-base);
	font-size: 15px;
	line-height: 150%;

	white-space: break-spaces;
}

.subheader-3b266 > span:not(:last-child):after {
	content: ', ';
}

.content-3nWPz {
	display: flex;
	flex-direction: column;

	padding: 0 34px;
	grid-gap: 10px;
	gap: 10px;
}

.switch__wrapper-1x-6l {
	display: flex;
	justify-content: flex-end;
}

div.switch__container-31SCW {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 68px;
	margin: 0;

	border-top: 1px dashed var(--line-separator);
}

.segment-2clZJ:not(:last-child) {
	margin-bottom: 20px;
}

.onlySegment__switch-2dgyW {
	opacity: 1;
}

div.switch__container-31SCW:after,
div.onlySegment__switch-2dgyW:after {
	background: var(--bg-elements);
}

div.switch__container-31SCW:before,
div.onlySegment__switch-2dgyW:before {
	background: var(--white);
	opacity: 1;
}

div.switch__container-31SCW.switch__container_active-37KMc:after,
div.onlySegment__switch-2dgyW.switch__container_active-37KMc:after {
	background: var(--brand-1-0);
}

div.switch__container-31SCW.switch__container_disable-yTHTa:before,
div.onlySegment__switch-2dgyW.switch__container_disable-yTHTa:before {
	background: var(--text-pale);
}

div.switch__container-31SCW.switch__container_disable-yTHTa:after,
div.onlySegment__switch-2dgyW.switch__container_disable-yTHTa:after {
	background: var(--bg-elements);
}

div.switch__container-31SCW:last-child {
	border-bottom: 1px dashed var(--line-separator);
}

div.label-AuX22 {
	opacity: 1;
}

.passenger__wrapper_counter-FErDl {
	display: flex;
	justify-content: space-between;
}

.segment-2clZJ .passenger__wrapper_counter-FErDl {
	padding: 16px 0;
}

.passenger__wrapper_counter-FErDl:not(:last-child) {
	margin-bottom: 16px;
}

.counter__root-o3Mk0 {
	margin-left: 10px;
	flex-shrink: 0;
}

button.counter-F0LUj {
	min-height: 24px;
	min-width: 24px;
	max-height: 24px;
	max-width: 24px;
}

.counter__value-3FSli {
	font-size: 15px;
	font-weight: 500;
	color: var(--gray80);
}

span.checkbox-3ykya.checkbox_checked-21nWM {
	color: var(--brand-1-0);
}

span.checkbox_label-3se_J {
	color: var(--text-base);
	font-weight: 400;
	line-height: 1.3;
	text-transform: capitalize;
}

.passenger-1qb4y {
	display: flex;
	align-items: center;

	font-size: 15px;
	color: var(--gray80);
	font-weight: 500;
	text-transform: capitalize;
}

.number-2ELSI {
	flex-shrink: 0;
	width: 24px;
	height: 24px;
	margin-right: 14px;

	border-radius: 50%;

	color: var(--gray50);
	line-height: 24px;
	text-align: center;

	background: var(--gray05);
}

.direction-2J0Lc {
	height: 52px;
	padding: 0 10px;

	color: var(--gray80);
	font-weight: 700;
	font-size: 16px;
	line-height: 52px;

	background: #ebf4ff;
}

.controls-3mP57 {
	padding: 30px 34px 40px;

	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.footer-16FtB {
	padding: 30px 34px 0;

	display: flex;
	flex-direction: column;
}

.onlySegment-3helI {
	font-weight: 500;
	color: var(--base);
	font-size: 15px;

	opacity: 1;
}

button.cancel-2pLNk,
button.cancel-2pLNk:hover,
button.cancel-2pLNk:focus {
	margin-right: 12px;

	color: var(--gray70);
}

button.confirm-PErnB {
	font-weight: 500;

	background: var(--base);
}

.total-1dM9m {
	margin-top: 10px;
	margin-left: auto;

	color: var(--text-base);

	font-size: 20px;
	font-weight: 700;
}

.price_from-1d6Xf {
	font-size: 20px;
	font-weight: 500;
	line-height: 1.2;
}

@media screen and (max-width: 600px) {
	.container-2hFaF {
		padding: 0;
	}

	.controls-3mP57 {
		justify-content: space-between;
	}

	button.cancel-2pLNk,
	button.confirm-PErnB {
		width: 49%;
	}

	.header__icon-3wxg6 img {
		width: 100%;
		height: auto;
	}
}
