

.wrapper-1qNq9 {
	max-width: 1100px;
	margin: 0 auto;

	border-radius: 4px;

	color: #ffffff;

	background: var(--mobileSummaryFlight--background);
}

.wrapper-1qNq9.wrapper_business-2leop {
	background: linear-gradient(180deg, #6a86e6 0%, #3b56b2 100%);
}

.header-3CtSO {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 24px 15px 20px;

	border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

.segmentInfo-1GJ85 {
	display: flex;
	align-items: center;

	font-size: 24px;
	font-weight: 500;
	line-height: 33px;
}

.segmentInfo__item-2eLoz:not(:last-child) {
	padding-right: 20px;
	margin-right: 20px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.planeIcon-HQKo- {
	display: flex;
	align-items: center;
	margin-right: 12px;

	transform: rotate(180deg);
}

.fareFamily-1O6f5 {
	display: flex;
	align-items: center;

	font-size: 16px;
	font-weight: 500;
}

.fareFamily__img-1L_Hi {
	display: flex;
	height: 28px;
	width: 28px;
	margin-right: 12px;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.footer-1_CAL {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 20px 21px;
}

.flightInfo-1n8T1 {
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
}

.flightInfo__item-BBMKD:not(:last-child) {
	margin-right: 20px;
	padding-right: 20px;

	border-right: 1px solid rgba(255, 255, 255, 0.5);
}
